import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IMAGE_ROOT } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import { fetchStoreData } from "../store/slices/fetchStore";

export default function Help() {
  const { t, i18n } = useTranslation(); // Initialize translation hook for language changes

  // Hooks for navigation and dispatching Redux actions
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [store, setStore] = useState([]); // Local state to store fetched store data

  // Function to fetch store data from IndexedDB
  const storeFetch = async () => {
    const store = await dbconn.getStoreFromIndexedDB(); // Get store data from IndexedDB
    setStore(store); // Set the store data to local state
  };

  // Effect hook to run on component mount
  useEffect(() => {
    // Get selected language from localStorage and change the language
    var lang = localStorage.getItem("store_language");
    i18n.changeLanguage(lang).then((r) => {});

    // Dispatch an action to fetch store data (if needed)
    dispatch(fetchStoreData());

    // Call storeFetch function to load store data from IndexedDB
    storeFetch();
  }, []); // Empty dependency array to run this effect only once on component mount

  const initialLogRef = useRef(false); // Reference to keep track of the initial log

  return (
    <div className="confirmPageMainDiv">
      <div className="loginLogo">
        <Link to="/">
          <img
            style={{ width: "70px" }}
            className="center-block"
            src={
              IMAGE_ROOT +
              "/images/product/" +
              localStorage.getItem("store_image")
            }
            alt="Takecharge Diner Logo"
          />
        </Link>
      </div>
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div
            className="col-md-7 col-sm-7 col-xs-12 m-auto vh1-100 vscroll bg-white"
            style={{
              height: "74vh",
              margin: "80px auto 20px auto",
              borderRadius: "10px",
            }}
          >
            <span className="backToHomeBtn" onClick={() => navigate("/")}>
              <IoIosArrowBack />
            </span>
            <div className="LoginRegisterBlock h-100">
              <div className="LoginRegisterForm float-left w-100">
                <form action="#" method="post" encType="multipart/form-data">
                  {/* Help Center Heading */}
                  <h3 className="mb-4 mt-4 roboto-medium-22">
                    {t("help_center")}
                  </h3>
                  {/* Assistance Subheading */}
                  <h3 className="mb-4 mt-4 roboto-medium-18">
                    {t("assistance")}
                  </h3>

                  {/* Display store contact information */}
                  <div className="form-group mb-4 text-center user_info">
                    {store.store_email != "" ? (
                      <p className="roboto-regular-16">
                        {t("email")}: {store.store_email}{" "}
                      </p>
                    ) : (
                      ""
                    )}
                    {store.store_phone != "" ? (
                      <p className="roboto-regular-16">
                        {t("phone")}: {store.store_phone}
                      </p>
                    ) : (
                      ""
                    )}
                    <p className="roboto-regular-16">{store.store_address}</p>
                    <p className="roboto-regular-16">
                      {store.store_city}, {store.store_state} {store.store_zip}
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
