import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IMAGE_ROOT } from "../api/apiHandler";
// import OrderSummary from '../components/OrderSummary';
import { IoIosArrowBack } from "react-icons/io";
import CartSummary from "../components/CartSummary";
import Checkout from "./Checkout";
import Guest from "./Guest";

function CheckoutSteps() {
  const navigate = useNavigate();
  const [stepNumber, setStepNumber] = useState(1); // To track current step

  useEffect(() => {
    // Check user type and set the step number accordingly
    const userMethod = sessionStorage.getItem("userType");
    if (userMethod === "checkout") {
      setStepNumber(2); // Move to step 2 if user is logged in for checkout
    } else if (userMethod === "checkoutGuest") {
      setStepNumber(1); // Stay at step 1 for guest users
    } else {
      navigate("/"); // Navigate to home page if no valid userType
      setStepNumber(0); // Set step to 0 to indicate invalid state
    }

    // Remove session data related to previous SSO login
    localStorage.removeItem("sso_user_id");
    localStorage.removeItem("sso_user_name");
    localStorage.removeItem("sso_user_phone");
    localStorage.removeItem("sso_user_email");
    localStorage.removeItem("isSSOLogin");
    localStorage.removeItem("ssoType");
  }, [navigate]);

  // Handles form change (from guest to checkout)
  const handleChangeForm = (data) => {
    setStepNumber(2); // Switch to checkout step
  };

  // Switches back to the guest step
  const handleChangePage = () => {
    setStepNumber(1); // Go back to the guest step
  };

  return (
    <>
      <div className="guestNewDiv">
        <div className="loginLogo">
          <img
            style={{ width: "70px" }}
            className="center-block"
            src={
              IMAGE_ROOT +
              "/images/product/" +
              localStorage.getItem("store_image")
            }
            alt="Store Logo"
            onClick={() => navigate(-1)} // Navigate back on logo click
          />
        </div>
        <div className="guestnewMainDiv container-fluid p-0">
          <div className="formComponent">
            <div className="leftForm">
              <div className="progressForm bg-white p-3">
                <h3 className="roboto-medium-20 text-center text-green position-relative">
                  Checkout
                  <span
                    className="backToHomeBtn position-absolute"
                    onClick={() => {
                      // Navigate back to cart or previous step based on the state
                      if (sessionStorage.getItem("guestFlag") === "N") {
                        navigate("/cart");
                      } else {
                        if (stepNumber === 1) {
                          navigate("/cart");
                        } else {
                          setStepNumber(1);
                        }
                      }
                    }}
                    style={{ margin: "0 0 10px 0", top: "0" }}
                  >
                    <IoIosArrowBack />
                  </span>
                </h3>
                {sessionStorage.getItem("guestFlag") === "N" ? (
                  ""
                ) : stepNumber === 1 ? (
                  <>
                    {/* Progress bar for guest user info step */}
                    <div className="progressBar d-flex align-items-center justify-content-center">
                      <h3 className="roboto-medium-16 m-0 progressNumber bg-green">
                        1
                      </h3>
                      <hr className="progressHr" />
                      <h3 className="roboto-medium-16 m-0 progressNumber">2</h3>
                    </div>
                    <div className="progressBar2 d-flex align-items-center justify-content-center">
                      <h3 className="roboto-medium-16 m-0 progressNumber2 text-center text-green">
                        Guest
                        <br />
                        User Info
                      </h3>
                      <hr className="progressHr2" />
                      <h3 className="roboto-medium-16 m-0 progressNumber2">
                        Card Details
                      </h3>
                    </div>
                  </>
                ) : (
                  <>
                    {/* Progress bar for logged-in user checkout step */}
                    <div className="progressBar d-flex align-items-center justify-content-center">
                      <h3
                        className="roboto-medium-16 m-0 progressNumber bg-green"
                        onClick={() => handleChangePage()}
                      >
                        1
                      </h3>
                      <hr className="progressHr text-green" />
                      <h3 className="roboto-medium-16 m-0 progressNumber bg-green">
                        2
                      </h3>
                    </div>
                    <div className="progressBar2 d-flex align-items-center justify-content-center">
                      <h3 className="roboto-medium-16 m-0 progressNumber2  text-center text-green">
                        Guest
                        <br />
                        User Info
                      </h3>
                      <hr className="progressHr2" />
                      <h3 className="roboto-medium-16 m-0 progressNumber2 text-green">
                        Card Details
                      </h3>
                    </div>
                  </>
                )}
                <div className={stepNumber === 1 ? "overpage" : "overpage2"}>
                  {stepNumber === 1 ? (
                    <Guest onSendData={handleChangeForm} /> // Display Guest component for step 1
                  ) : (
                    <Checkout /> // Display Checkout component for step 2
                  )}
                </div>
              </div>
            </div>
            <div className="rightOrderSummary">
              <CartSummary stepNumber={stepNumber} />{" "}
              {/* Order summary component */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CheckoutSteps;
