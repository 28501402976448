// src/components/GoogleAddress.js
import { Autocomplete } from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";

function GoogleAddress({ onPlaceSelect, location, setLocation }) {
  const url = window.location.href; // Get the current URL for conditional checks
  const autocompleteRef = useRef(null); // Ref for the Autocomplete component
  const [scriptLoaded, setScriptLoaded] = useState(false); // State to track if Google Maps script has loaded

  // Effect hook to check if Google Maps script is loaded
  useEffect(() => {
    const checkGoogle = setInterval(() => {
      if (window.google) {
        setScriptLoaded(true); // If script loaded, update state
        clearInterval(checkGoogle); // Stop checking once Google is available
      }
    }, 100);
    return () => clearInterval(checkGoogle); // Clean up the interval on component unmount
  }, []);

  // useEffect(() => {
  //      if (url) {
  //           const isGuest = url.includes('/checkout')
  //           setIsGuestPage(isGuest)
  //      }
  // }, [])

  // Function to remove certain words from the address
  const removeWords = (str, wordsToRemove) => {
    let newStr = str;

    // Iterate over each word to remove and replace them from the string
    wordsToRemove.forEach((word) => {
      const regex = new RegExp(`\\b${word}\\b`, "gi"); // Case-insensitive regex to match the word
      newStr = newStr.replace(regex, "");
    });
    // Clean up multiple spaces and return the trimmed string
    return newStr.replace(/\s{2,}/g, " ").trim();
  };

  // Function to handle when a place is selected from the Autocomplete input
  const handlePlaceSelect = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace(); // Get the selected place details
      onPlaceSelect(place); // Call the provided onPlaceSelect function

      // Extract the formatted address and ADR address
      const formattedAdd = place.formatted_address;
      const allAddress = place.adr_address;

      // Parse the ADR address into HTML to extract components
      const parser = new DOMParser();
      const doc = parser.parseFromString(allAddress, "text/html");

      // Extract specific address components (city, region, zip, country)
      const city = doc.querySelector(".locality")?.textContent.trim();
      const region = doc.querySelector(".region")?.textContent.trim();
      const zipElement = doc.querySelector(".postal-code");
      let zip = zipElement ? zipElement.textContent.trim() : "";
      // Clean up zip code if it contains a hyphen
      if (zip.includes("-")) {
        zip = zip.split("-")[0]; // Get the first part of the zip code
      } else {
        zip = zip.replace(/-.*$/, ""); // Remove anything after a hyphen in the zip code
      }
      const country = doc.querySelector(".country-name")?.textContent.trim();

      // Prepare an array of address components to remove
      const removeFromAddress = [city, region, zip, country];

      // Remove the unwanted address components and clean up the formatted address
      const newAddress = removeWords(formattedAdd, removeFromAddress);

      // Clean the address further and join by commas
      const cleanedAddress = newAddress
        .split(",")
        .filter((part) => part.trim() !== "")
        .join(", ")
        .trim();

      // Check if the current page is the checkout page and update the location
      if (url) {
        const isGuest = url.includes("/checkout");
        if (isGuest === false) {
          setLocation(cleanedAddress); // Set the cleaned address in the state
        }
      }
      // if (isGuestPage === false) {
      // setLocation(cleanedAddress);
      // }
    }
  };

  // Prevent form submission on pressing "Enter" key
  const preventDefault = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action (form submission)
    }
  };

  // If the Google Maps script is not loaded yet, show a loading message
  if (!scriptLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return (
    <div className="form-group mb-2" style={{ padding: "0 5px" }}>
      <label className="roboto-medium-16 inputLabel">Address*</label>
      <Autocomplete
        onLoad={(autocomplete) => {
          // Assign the Autocomplete instance to the ref
          autocompleteRef.current = autocomplete;
        }}
        // Call the handlePlaceSelect function when a place is selected
        onPlaceChanged={handlePlaceSelect}
      >
        <input
          type="text"
          className="roboto-regular-16 inputBox"
          onKeyDown={preventDefault} // Prevent form submission on Enter key press
          value={location} // Bind the value to the location state
          onChange={(e) => setLocation(e.target.value)} // Update the location state on input change
        />
      </Autocomplete>
    </div>
  );
}

export default GoogleAddress;
