import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import spanish from "../image/spanishFlag.png";
import usa from "../image/usaFlag.webp";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();

  // Array of available languages with their IDs, names, and language codes
  const language = [
    { id: 1, name: "English", image: "en" },
    { id: 2, name: "Spanish", image: "es" },
  ];

  // State to toggle language dropdown and to store current language from localStorage or default to English ('en')
  const [listLanguage, setListLanguage] = useState(false);
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("store_language") || "en"
  ); // Check localStorage for the saved language
  const popupRef = useRef(null); // Reference for language dropdown to handle outside clicks

  // Function to select a language and update localStorage, state, and i18n language setting
  const langSelected = (langName) => {
    return () => {
      const selectedLang = langName === "Spanish" ? "es" : "en";
      localStorage.setItem("store_language", selectedLang);
      setCurrentLang(selectedLang);
      i18n.changeLanguage(selectedLang).then((r) => {});
      setListLanguage(false);
    };
  };

  // Toggle language dropdown visibility
  const languageHandle = () => {
    setListLanguage((prev) => !prev);
  };

  useEffect(() => {
    // Initialize with the saved language from localStorage or default to English
    const savedLanguage = localStorage.getItem("store_language") || "en";
    i18n.changeLanguage(savedLanguage);

    // Close dropdown if clicked outside of it
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setListLanguage(false);
      }
    };

    // Add event listener to detect outside clicks when dropdown is open
    if (listLanguage) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [listLanguage, i18n]);

  return (
    <div>
      {/* Button to toggle language dropdown; displays current language flag */}
      <button
        className="homeFlagImagebutton float-right"
        onClick={languageHandle}
      >
        {currentLang === "en" ? (
          <img className="langImage" src={usa} alt="" width={30} height={30} />
        ) : (
          <img
            className="langImage"
            src={spanish}
            alt=""
            width={30}
            height={30}
          />
        )}
      </button>
      {/* Dropdown menu for language selection */}
      {listLanguage && (
        <ul className="flagImageDiv py-1 px-2" ref={popupRef}>
          {language.map((lang, index) => (
            <li
              key={index}
              className="roboto-medium-16 my-1"
              onClick={langSelected(lang.name)}
            >
              {/* Display flag icon next to language name */}
              {lang.image === "en" ? (
                <img src={usa} alt="" width={15} height={15} />
              ) : (
                <img src={spanish} alt="" width={15} height={15} />
              )}{" "}
              {lang.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LanguageSelector;
