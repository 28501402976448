import axios from "axios";
import { signInWithPopup } from "firebase/auth";
import React from "react";
import { FcGoogle } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ROOT, Method } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import { auth, googleProvider } from "../firebase";

function GoogleLogin({ setRefresh }) {
  // Initialize navigation and location hooks
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  // Function to trigger a refresh of the parent component
  const refreshComponent = () => {
    setRefresh(true);
  };

  // Handles Google login process
  const handleLogin = async () => {
    try {
      // Initiate Google sign-in popup
      const result = await signInWithPopup(auth, googleProvider);
      const userData = result.user.providerData[0];

      // Store Google login data in localStorage
      localStorage.setItem("ssoType", "G");
      localStorage.setItem("isSSOLogin", true);

      localStorage.setItem("sso_user_id", userData.uid);
      localStorage.setItem(
        "sso_user_name",
        userData.displayName === null ? "" : userData.displayName
      );
      localStorage.setItem(
        "sso_user_phone",
        userData.phoneNumber === null ? "" : userData.phoneNumber
      );
      localStorage.setItem(
        "sso_user_email",
        userData.email === null ? "" : userData.email
      );

      // Proceed to verify SSO if user ID is valid
      if (userData.uid.length > 0) {
        verifySSO(userData.uid);
      }

      // Refresh component to reflect login state
      refreshComponent();
    } catch (error) {
      // Clear login-related session data on error
      localStorage.removeItem("isSSOLogin");
      localStorage.removeItem("ssotype");
      sessionStorage.setItem("guestFlag", "Y");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_email");
      localStorage.removeItem("customer_fb_id");
      console.error("Error logging in with Google:", error);

      // Specific handling for user closing popup
      if (error.code === "auth/popup-closed-by-user") {
        console.error("Popup closed by user. Please try again.");
      } else {
        console.error("Login failed. Please try again.");
      }
    }
  };

  // Verifies SSO user ID and updates IndexedDB with user data
  const verifySSO = async (id) => {
    // Fetch store details from IndexedDB
    const store = await dbconn.getStoreFromIndexedDB();

    // Send request to backend to verify SSO
    const response = await axios.post(API_ROOT + Method.VERIFY_SSO, {
      sso_id: id,
      sso_type: "G",
      store_id: store.store_id,
    });

    if (response.data.status === 1) {
      // Clear existing user and cart data in IndexedDB
      dbconn.deleteUserFromIndexedDB(4);
      dbconn.deleteCartIndexedDB(3);

      // Add new customer data to IndexedDB
      const data = response.data.customer_data[0];
      dbconn.addUserInfoToIndexedDB(data);

      // Remove SSO data from localStorage after successful login
      sessionStorage.setItem("guestFlag", "N");
      sessionStorage.setItem("cartTotal", "0.00");
      localStorage.removeItem("sso_user_id");
      localStorage.removeItem("sso_user_name");
      localStorage.removeItem("sso_user_phone");
      localStorage.removeItem("sso_user_email");
      sessionStorage.removeItem("isSSOLogin");
      localStorage.setItem("isLogin", true);
      localStorage.removeItem("ssoType");

      // Navigate to the home page after successful login
      navigate("/");
    } else {
      console.log("Error : ", response.data.message);
      navigate("/signup");
    }
  };

  // Redirect to signup page if verification fails
  return (
    <FcGoogle
      style={{ fontSize: "25px", cursor: "pointer" }}
      onClick={handleLogin}
    />
  );
}

export default GoogleLogin;
