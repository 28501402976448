import axios from "axios";
import { signInWithPopup } from "firebase/auth";
import React from "react";
import toast from "react-hot-toast";
import { BsFacebook } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ROOT, Method } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import { auth, facebookProvider } from "../firebase";

function FacebookLogin({ setRefresh }) {
  // Get the current location and path
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();

  // Refreshes the component by setting a refresh state
  const refreshComponent = () => {
    setRefresh(true);
  };

  // Handles the Facebook login process
  const handleLogin = async () => {
    try {
      // Sign in with Facebook popup
      const result = await signInWithPopup(auth, facebookProvider);
      const userData = result.user.providerData[0];

      // Store user data in localStorage for session management
      localStorage.setItem("ssoType", "F");
      localStorage.setItem("isSSOLogin", true);

      localStorage.setItem("sso_user_id", userData.uid);
      localStorage.setItem(
        "sso_user_name",
        userData.displayName === null ? "" : userData.displayName
      );
      localStorage.setItem(
        "sso_user_phone",
        userData.phoneNumber === null ? "" : userData.phoneNumber
      );
      localStorage.setItem(
        "sso_user_email",
        userData.email === null ? "" : userData.email
      );

      // If user data exists, proceed with verification// if (pathname.includes('/login') === true) {
      if (userData.uid.length > 0) {
        verifySSO(userData.uid);
      }

      // Refresh and show success message
      refreshComponent();
      toast.dismiss();
      toast.success("Login Successful.");
    } catch (error) {
      // Clear session data on error and show error message
      localStorage.removeItem("isSSOLogin");
      localStorage.removeItem("ssotype");
      sessionStorage.setItem("guestFlag", "Y");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_email");
      localStorage.removeItem("customer_fb_id");
      console.error("Error logging in with Facebook:", error);

      // Display specific error if login is not allowed
      if (error.code === "auth/operation-not-allowed") {
        console.error("Facebook login is not enabled. Please contact support.");
      } else {
        console.error("Login failed. Please try again.");
      }
    }
  };

  // Verifies the SSO user and stores additional customer data in IndexedDB
  const verifySSO = async (id) => {
    // Retrieve the store from IndexedDB
    const store = await dbconn.getStoreFromIndexedDB();

    // Send a request to verify SSO with user ID and store ID
    const response = await axios.post(API_ROOT + Method.VERIFY_SSO, {
      sso_id: id,
      sso_type: "F",
      store_id: store.store_id,
    });

    // If verified successfully, update session data and IndexedDB
    if (response.data.status === 1) {
      dbconn.deleteUserFromIndexedDB(4);
      dbconn.deleteCartIndexedDB(3);
      const data = response.data.customer_data[0];
      dbconn.addUserInfoToIndexedDB(data);

      // Clear SSO data from localStorage after successful login
      sessionStorage.setItem("guestFlag", "N");
      sessionStorage.setItem("cartTotal", "0.00");
      localStorage.removeItem("sso_user_id");
      localStorage.removeItem("sso_user_name");
      localStorage.removeItem("sso_user_phone");
      localStorage.removeItem("sso_user_email");
      sessionStorage.removeItem("isSSOLogin");
      localStorage.setItem("isLogin", true);
      localStorage.removeItem("ssoType");

      // Navigate to the home page after login
      navigate("/");
    } else {
      // Redirect to signup if verification fails
      console.log("Error : ", response.data.message);
      navigate("/signup");
    }
  };

  // Render the Facebook icon for login
  return (
    <BsFacebook
      style={{ fontSize: "25px", cursor: "pointer", color: "#316FF6" }}
      onClick={handleLogin}
    />
  );
}

export default FacebookLogin;
