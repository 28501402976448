import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { IMAGE_ROOT } from '../api/apiHandler';
import * as dbconn from '../api/dbconn';
import { fetchStoreData } from '../store/slices/fetchStore';

function Policy() {
     const { t, i18n } = useTranslation();
     const dispatch = useDispatch()
     const navigate = useNavigate()
     // const store = useSelector((state) => state.fetchstore.data)
     const [store, setStore] = useState([])


     // const initialLogRef = useRef(false);

     // useEffect(() => {
     //      if (store && !initialLogRef.current) {
     //           initialLogRef.current = true;
     //      }
     // }, [store])

     useEffect(() => {
          dispatch(fetchStoreData())
          storeFetch()
     }, [])

     const storeFetch = async () => {
          const store = await dbconn.getStoreFromIndexedDB();
          setStore(store)
     }
     return (
          <div className='confirmPageMainDiv'>
               {/* <header className="header-div bg-white position-fixed w-100 shadow">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                         <Link to="/" className="float-left">
                              <BsChevronLeft />
                         </Link>
                         <h2 className="text-center mb-0 roboto-bold-24">{t("policy")}</h2>
                    </div>
               </header> */}
               <div className="loginLogo">
                    <Link to="/">
                         <img
                              style={{ width: "70px" }}
                              className="center-block"
                              src={
                                   IMAGE_ROOT +
                                   "/images/product/" +
                                   localStorage.getItem("store_image")
                              }
                              alt="Takecharge Diner Logo"
                         />
                    </Link>
               </div>
               <div className="container-fluid h-100 position-relative">
                    <div className="row h-100 ">
                         <div className="col-md-8 col-sm-7 col-xs-12 mx-auto vscroll bg-white" style={{ marginTop: "80px", borderRadius: "10px", height: "85vh" }}>
                              <span className="backToHomeBtn" onClick={() => navigate('/')}><IoIosArrowBack /></span>
                              <div className="LoginRegisterBlock h-100" style={{ marginTop: "0" }}>
                                   <div className="LoginRegisterForm float-left w-100">
                                        <form action="#" method="post" encType="multipart/form-data">
                                             <h3 className="mb-4 mt-4 roboto-medium-22 text-green">{t('refund_policy')}</h3>
                                             <div className="form-group mb-4 text-center user_info">
                                                  <p className="roboto-regular-16">
                                                       {store.store_refund_policy === null ? "No refund" : store.store_refund_policy}

                                                  </p>
                                             </div>
                                             <h3 className="mb-4 mt-4 roboto-medium-22 text-green">{t('privacy_policy')}</h3>
                                             <div className="form-group mb-4 text-center user_info">
                                                  <p className="roboto-regular-16">
                                                       {store.store_privacy_policy === null ? "No privacy policy" : store.store_privacy_policy}
                                                       {/* {"hello"} */}
                                                  </p>
                                             </div>
                                             <h3 className="mb-4 mt-4 roboto-medium-22 text-green">{t('tos')}</h3>
                                             <div className="form-group mb-4 text-center user_info">
                                                  <p className="roboto-regular-16">
                                                       {store.store_tos === null ? "No term of service" : store.store_tos}
                                                       {/* {"hello"} */}
                                                  </p>
                                             </div>
                                        </form>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default Policy