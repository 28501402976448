import { Button, Col, DatePicker, Input, Row } from "antd";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import {
  API_ROOT,
  IMAGE_ROOT,
  Method
} from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import FacebookLogin from "../components/FacebookLogin";
import GoogleAddress from '../components/GoogleAddress';
import GoogleLogin from "../components/GoogleLogin";
import LanguageSelector from "../components/LanguageSelector";
import TwitterLogin from "../components/TwitterLogin";
import { fetchStoreData } from "../store/slices/fetchStore";
import "../style/Signup.css";

export default function Signup() {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [location, setLocation] = useState("");
  const [refresh, setRefresh] = useState(false)
  // const store = useSelector((state) => state.fetchstore.data)

  useEffect(() => {
    dispatch(fetchStoreData())
  }, [])
  const [initialValue, setInitialValue] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    password: "",
    confirmpassword: "",
    birthdate: "",
    termsChkBox: false,
    marketChkBox: false,
  });


  useEffect(() => {
    const isSSOLogin = sessionStorage.getItem('isSSOLogin');

    if (isSSOLogin) {
      const name = localStorage.getItem('sso_user_name') || "";
      let [firstName, lastName] = name.split(" ");
      const phone = localStorage.getItem('sso_user_phone') || "";
      const email = localStorage.getItem('sso_user_email') || "";

      setInitialValue({
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: phone,
        address: "",
        city: "",
        state: "",
        zipcode: "",
        password: "",
        confirmpassword: "",
        birthdate: "",
        termsChkBox: false,
        marketChkBox: false,
      });
    }
  }, [refresh]);

  const validationSchema = Yup.object({
    firstname: Yup.string().required(t('required')),
    lastname: Yup.string().required(t('required')),
    email: Yup.string().email(t('invalid_email_format')).required(t('required')),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "phone_number_must_be_only_digits")
      .min(10, t('phoneMin'))
      .max(10, t('phoneMax'))
      .required(t('required')),
    address: Yup.string().required(t('required')),
    city: Yup.string().required(t('required')),
    zipcode: Yup.string().required(t('required')),
    state: Yup.string().required(t('required')),
    password: Yup.string().min(8, t('passwordMin')).required(t('required')),
    confirmpassword: Yup.string().oneOf([Yup.ref('password'), null], t('passwordMatch')).required(t('required')),
    // birthdate: Yup.date().required('Required'),
    birthdate: Yup.date()
      .required(t('required'))
      .test("age", t('ageRequirement'), function (value) {
        const today = moment();
        const birthDate = moment(value);
        const age = today.diff(birthDate, "years");
        return age >= 13;
      }),
    termsChkBox: Yup.bool().oneOf([true], t('termsRequired')).required(t('required')),
    // marketChkBox: Yup.bool().required('Required'),
  });

  useEffect(() => {
    if (!localStorage.getItem('store_language')) {
      localStorage.setItem("store_language", "en");
    }
    i18n.changeLanguage(localStorage.getItem('store_language'))
  }, []);

  const onSubmit = async (values) => {
    setLoad(true)
    const store = await dbconn.getStoreFromIndexedDB();
    axios
      .post(API_ROOT + Method.SIGNUP, {
        customer_firstname: values.firstname,
        customer_lastname: values.lastname,
        customer_email: values.email,
        customer_phone: values.phone,
        customer_address: values.address,
        customer_city: values.city,
        customer_state: values.state,
        customer_zip: values.zipcode,
        customer_username: "",
        customer_password: values.password,
        customer_marketing_optin: values.marketChkBox === true ? "Y" : "N",
        customer_dob: values.birthdate,
        customer_signup_type: localStorage.getItem("ssoType") == null ? "" : localStorage.getItem("ssoType"),
        store_id: store.store_id,
        merchant_id: store.merchant_id,
        customer_fb_id: localStorage.getItem("sso_user_id") == null ? "" : localStorage.getItem("sso_user_id"),
      })
      .then((response) => {
        setLoad(false);
        dbconn.deleteUserFromIndexedDB(4)
        if (response.data.status == 1) {
          Swal.fire({
            title: i18n.t("account_success"),
            showCancelButton: false,
            confirmButtonColor: "#a42f1e",
            confirmButtonText: t("ok"),
          }).then((result) => {
            if (result.value) {

              var userData = response.data.customer_data[0];
              sessionStorage.setItem("guestFlag", "N");
              localStorage.setItem("isLogin", true)
              dispatch(fetchStoreData())
              dbconn.addUserInfoToIndexedDB(userData)

              localStorage.removeItem("sso_user_id")
              localStorage.removeItem("sso_user_name")
              localStorage.removeItem("sso_user_phone")
              localStorage.removeItem("sso_user_email")
              localStorage.removeItem("isSSOLogin")
              localStorage.removeItem("ssoType")
              navigate('/')
            }
          });
        } else {
          sessionStorage.setItem("guestFlag", "Y");
          localStorage.setItem("isLogin", false)
          if (
            response.data.message ==
            "Phone Number already exists, please register with a different phone number."
          ) {
            Swal.fire({
              title: i18n.t("phone_exists"),
              showCancelButton: false,
              confirmButtonColor: "#a42f1e",
              confirmButtonText: t("ok"),
            });
          } else if (
            response.data.message ==
            "Email already exists, please register with a different email."
          ) {
            Swal.fire({
              title: i18n.t("email_exists"),
              showCancelButton: false,
              confirmButtonColor: "#a42f1e",
              confirmButtonText: t("ok"),
            });
          } else {
            Swal.fire({
              title: response.data.message,
              showCancelButton: false,
              confirmButtonColor: "#a42f1e",
              confirmButtonText: t("ok"),
            });
          }
        }
      })
      .catch((error) => {
        setLoad(false);
        sessionStorage.setItem("guestFlag", "Y");
        localStorage.setItem("isLogin", false)
        console.log(error);
      });
  };

  const termAndCondition = async () => {
    const store = await dbconn.getStoreFromIndexedDB();
    const tos = store.store_tos
    if (tos === i18n.t("no_tos_available") || tos == null) {
      Swal.fire({
        title: t("tos"),
        text: t("no_tos_available"),
        confirmButtonText: t("ok"),
      });
    } else {
      Swal.fire({
        title: `<h4 id="guidelineHeading">${i18n.t("tos")}</h4>`,
        allowOutsideClick: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        width: "90%",
        html: `<div id='guideLine'>${tos}</div>`,
      });
    }
  }

  return (
    <div className="signupPage">
      <div className="loginLogo">
        <Link to="/">
          {load && (
            <img
              style={{ width: "70px" }}
              className="center-block"
              src={`${IMAGE_ROOT}/images/product/${localStorage.getItem("store_image")}`}
              alt="Takecharge Diner Logo"
            />
          )}
        </Link>
      </div>
      <div className="language">
        <LanguageSelector />
      </div>
      <div className="signupCard px-3 py-2">

        <Formik initialValues={initialValue} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize={true}>
          {({ submitForm, setFieldValue }) => (
            <Form className="signupForm p-3" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} autoComplete="off">
              <div className="w-100 text-center position-relative">
                <h3 className="roboto-bold-24 my-2" style={{ color: "var(--primaryColor)" }}>SIGN UP</h3>
                <div className="backButtonSign">
                  <FaChevronLeft onClick={() => navigate('/')} />
                </div>
              </div>
              <div className="ssoDiv d-flex align-items-center gap-3 justify-content-center my-1">
                <FacebookLogin setRefresh={setRefresh} />
                <GoogleLogin setRefresh={setRefresh} />
                <TwitterLogin setRefresh={setRefresh} />
              </div>
              <Row gutter={10} className="w-100">
                <Col xs={24} sm={12}>
                  <div>
                    <label htmlFor="firstname" className="roboto-medium-16 inputLabel">{t("first_name")}*</label>
                    <Field as={Input} id="firstname" className='roboto-regular-16 inputBox' name="firstname" />
                    <ErrorMessage name="firstname" className='roboto-regular-14' component="div" style={{ color: 'red' }} />
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div>
                    <label htmlFor="lastname" className="roboto-medium-16 inputLabel">{t("last_name")}*</label>
                    <Field as={Input} id="lastname" className='roboto-regular-16 inputBox' name="lastname" />
                    <ErrorMessage name="lastname" className='roboto-regular-14' component="div" style={{ color: 'red' }} />
                  </div>
                </Col>
              </Row>
              <Row gutter={10} className="w-100 mt-2">
                <Col xs={24} sm={12}>
                  <div>
                    <label htmlFor="email" className="roboto-medium-16 inputLabel">{t("email_address")}*</label>
                    <Field as={Input} id='email' className='roboto-regular-16 inputBox' name='email' autoComplete="off" />
                    <ErrorMessage name="email" className='roboto-regular-14' component='div' style={{ color: "red" }} />
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div>
                    <label htmlFor="phone" className="roboto-medium-16 inputLabel">{t("phone_number")}*</label>
                    <Field as={Input} id='phone' className='roboto-regular-16 inputBox' name='phone' />
                    <ErrorMessage name='phone' className='roboto-regular-14' component='div' style={{ color: 'red' }} />
                  </div>
                </Col>
              </Row>
              <Row className="w-100 mt-2">
                <Col xs={24}>
                  <GoogleAddress
                    onPlaceSelect={place => {
                      setFieldValue('address', place.formatted_address);

                      const city = place.address_components.find(c => c.types.includes('locality'))?.long_name || "";
                      const state = place.address_components.find(c => c.types.includes('administrative_area_level_1'))?.long_name || "";
                      const zipcode = place.address_components.find(c => c.types.includes('postal_code'))?.long_name || "";

                      setFieldValue('city', city);
                      setFieldValue('state', state);
                      setFieldValue('zipcode', zipcode);
                      setLocation(place.formatted_address);
                    }}
                    location={location}
                    setLocation={setLocation}
                  />
                  <ErrorMessage name="address" className='roboto-regular-14' component='div' style={{ color: "red" }} />
                </Col>
              </Row>
              <Row gutter={10} className="w-100 mt-2">
                <Col xs={24} sm={8}>
                  <div>
                    <label htmlFor="city" className="roboto-medium-16 inputLabel">{t("city")}*</label>
                    <Field as={Input} id='city' className='roboto-regular-16 inputBox' name='city' />
                    <ErrorMessage name="city" className='roboto-regular-14' component='div' style={{ color: "red" }} />
                  </div>
                </Col>
                <Col xs={24} sm={8}>
                  <div>
                    <label htmlFor="state" className="roboto-medium-16 inputLabel">{t("state")}*</label>
                    <Field as={Input} id='state' className='roboto-regular-16 inputBox' name='state' />
                    <ErrorMessage name='state' className='roboto-regular-14' component='div' style={{ color: 'red' }} />
                  </div>
                </Col>
                <Col xs={24} sm={8}>
                  <div>
                    <label htmlFor="zipcode" className="roboto-medium-16 inputLabel">{t("zip_code")}*</label>
                    <Field as={Input} id='zipcode' className='roboto-regular-16 inputBox' name='zipcode' />
                    <ErrorMessage name='zipcode' className='roboto-regular-14' component='div' style={{ color: 'red' }} />
                  </div>
                </Col>
              </Row>
              <Row gutter={10} className="w-100 mt-2">
                <Col xs={24} sm={12}>
                  <div>
                    <label htmlFor="password" className="roboto-medium-16 inputLabel">{t("password")}*</label>
                    <Field as={Input.Password} id='password' className='roboto-regular-16 inputBox' name='password' autoComplete="new-password" />
                    <ErrorMessage name="password" className='roboto-regular-14' component='div' style={{ color: "red" }} />
                  </div>
                </Col>
                <Col xs={24} sm={12}>
                  <div>
                    <label htmlFor="confirmpassword" className="roboto-medium-16 inputLabel">{t("confirm_password")}*</label>
                    <Field as={Input.Password} id='confirmpassword' className='roboto-regular-16 inputBox' name='confirmpassword' />
                    <ErrorMessage name='confirmpassword' className='roboto-regular-14' component='div' style={{ color: 'red' }} />
                  </div>
                </Col>
              </Row>
              <Row gutter={16} className="w-100 pt-2">
                <Col xs={24}>
                  <div>
                    <label htmlFor="birthdate" className="roboto-medium-16 inputLabel">Birthdate</label>
                    <DatePicker
                      id="birthdate"
                      className='roboto-regular-16 inputBox'
                      style={{ width: '100%' }}
                      onChange={(date, dateString) => setFieldValue('birthdate', dateString)}
                      disabledDate={(current) =>
                        current && current > moment().endOf("day")
                      }
                    />
                    <ErrorMessage name="birthdate" className='roboto-regular-14' component="div" style={{ color: 'red' }} />
                  </div>
                </Col>
              </Row>
              <Row gutter={16} className="w-100 pt-2">
                <Col xs={24}>
                  <div className="form-group mb-2 clearfix">
                    <div className="custom-control custom-checkbox float-left">
                      <Field
                        type="checkbox"
                        className="custom-control-input"
                        id="termsChkBox"
                        name="termsChkBox"
                      />
                      <label
                        className="custom-control-label pt-6px"
                        htmlFor="termsChkBox"
                      >
                        {t("age_disclaimer")}{" "}
                        <Link
                          onClick={() => {
                            termAndCondition()
                          }}
                          className="underline"
                          style={{ color: "var(--primaryColor)" }}
                        >
                          {t("terms_link")}
                        </Link>
                        .
                      </label>
                      <ErrorMessage name="termsChkBox" component="div" style={{ color: 'red' }} />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={16} className="w-100 pt-2">
                <Col xs={24}>
                  <div className="form-group mb-2 clearfix">
                    <div className="custom-control custom-checkbox float-left">
                      <Field
                        type="checkbox"
                        className="custom-control-input"
                        id="marketChkBox"
                        name="marketChkBox"
                      />
                      <label
                        className="custom-control-label pt-6px"
                        htmlFor="marketChkBox"
                      >
                        {t("signup_check")}
                      </label>
                      <ErrorMessage name="marketChkBox" component="div" style={{ color: 'red' }} />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row style={{ width: '80%', marginTop: '20px', justifyContent: "center" }}>
                <Col xs={24} md={12}>
                  <Button type="primary" className="btn-yellow" onClick={submitForm} block>
                    {t("signup")}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
