import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronLeft } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as dbconn from "../api/dbconn";
import { clearCart } from "../store/slices/CartSlice";
import "../style/MyAccount.css";

export default function MyAccount() {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [redirect, setRedirect] = useState(false);
  const [buildItemFlag, setBuildItemFlag] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [nosessionredirect, setNosessionredirect] = useState(false);
  const [custname, setCustname] = useState("");

  useEffect(() => {
    var primaryColor = localStorage.getItem("store_primary_color");
    var secondaryColor = localStorage.getItem("store_secondary_color");
    var buttonColor = localStorage.getItem("store_button_color");

    if (primaryColor != null) {
      document.documentElement.style.setProperty(
        "--primaryColor",
        primaryColor
      );
    }
    if (buttonColor != null) {
      document.documentElement.style.setProperty(
        "--buttonColor",
        buttonColor
      );
    }

    if (secondaryColor != null) {
      document.documentElement.style.setProperty(
        "--secondaryColor",
        secondaryColor
      );
    }
  }, [])

  useEffect(() => {
    if (!localStorage.getItem("store_language")) {
      localStorage.setItem("store_language", "en");
    }
    i18n.changeLanguage(localStorage.getItem("store_language")).then((r) => { });

    if (!sessionStorage.getItem("guestFlag")) {
      setNosessionredirect(true);
    } else {
      // dbconn.getUser().then((data) => {
      //   if (!data || data.length < 1) {
      //     setNosessionredirect(true);
      //   } else {
      //     const customer_id = data[0].customer_id;
      //     const customer_firstname = data[0].customer_firstname;
      //     setCustname(customer_firstname);
      //   }
      // });
    }
  }, []);

  // useEffect(() => {
  //   document.title =
  //     "My Account - " + localStorage.getItem("store_name") + " - " + PROJECT;
  //   const primaryColor = localStorage.getItem("store_primary_color");
  //   if (primaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--primaryColor",
  //       primaryColor
  //     );
  //   }
  //   const secondaryColor = localStorage.getItem("store_secondary_color");
  //   if (secondaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--secondaryColor",
  //       secondaryColor
  //     );
  //   }
  // }, []);

  const logout = () => {
    dbconn.deleteUser();
    dbconn.deleteAllOrder();
    localStorage.removeItem("remember");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_email");
    sessionStorage.removeItem("guestFlag");
    localStorage.removeItem("cart")
    dispatch(clearCart())
    setNosessionredirect(true);
  };


  useEffect(() => {
    if (nosessionredirect) {
      navigate("/");
    }
  }, [nosessionredirect]);

  return (
    <div>
      <header className="header-div bg-white position-fixed w-100 shadow">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Link to="/" className="float-left">
            <BsChevronLeft />
          </Link>
          <h2 className="text-center mb-0 roboto-bold-24">{t("my_account")}</h2>
        </div>
      </header>
      <div className="container-fluid h-100 position-relative pt-40">
        <div className="row h-100 bg-f0f3f4" >
          <div className="col-md-7 col-sm-7 col-xs-12 m-auto" style={{ height: "94.5vh", backgroundColor: "White" }}>
            <div className="LoginRegisterBlock h-100">
              <div className="LoginRegisterForm float-left w-100">
                <form action="#" method="post" encType="multipart/form-data">
                  <div className="form-group mb-4 user_info w-175 m-auto text-left">
                    <p className="mt-4 mb-4 roboto-medium-16">
                      {t("hi")} {custname}!
                    </p>
                    <p className="roboto-regular-16">
                      <Link to="/profile" className="color333">
                        {t("profile")}
                      </Link>
                    </p>
                    {/* <p>
                      <Link to="/payment/methods" className="color333">
                        {t("payment_methods")}
                      </Link>
                    </p> */}
                    <p className="roboto-regular-16">
                      <Link to="/order/history" className="color333">
                        {t("order_history")}
                      </Link>
                    </p>
                    <p className="roboto-regular-16">
                      <Link to="/help" className="color333">
                        {t("help")}
                      </Link>
                    </p>
                  </div>
                  <div className="form-group mb-2 w-30 m-auto w-m-42">
                    <input
                      type="button"
                      onClick={() => logout()}
                      className="btn btnLogin btn-yellow w-100 btn-lg mb-3"
                      value={t("signout")}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
