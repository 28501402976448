import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Method } from "../api/apiHandler";

export default function PaymentMethods() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [cardList, setCardList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState();

  const customer = JSON.parse(localStorage.getItem("customer_data"));

  // useEffect(() => {
  //   var primaryColor = localStorage.getItem("store_primary_color");
  //   var secondaryColor = localStorage.getItem("store_secondary_color");
  //   var buttonColor = localStorage.getItem("store_button_color");

  //   if (primaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--primaryColor",
  //       primaryColor
  //     );
  //   }
  //   if (buttonColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--buttonColor",
  //       buttonColor
  //     );
  //   }

  //   if (secondaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--secondaryColor",
  //       secondaryColor
  //     );
  //   }
  // }, [])

  // Function to handle radio button change
  const handleRadioChange = (event) => {
    setSelectedCardId(event.target.value);
  };
  const fetchCardList = async () => {
    try {
      const response = await axios.get(
        Method.FETCH_SAVED_CARDS +
        "?customer_id=" +
        customer[0].customer_id +
        "&customer_token=" +
        customer[0].userToken
      );
      if (response.data.status === 1) {
        setCardList(response.data.card_data);
        setIsLoading(false);
      } else {
        Swal.fire(response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCardList()
  }, [])

  const deleteCard = () => {
    if (selectedCardId !== undefined) {
      // console.log("Selected Card ID:", selectedCardId);

    } else {
      // Swal.fire('Please select card first.')
      Swal.fire({
        text: 'Please select card first.',
        showCancelButton: false,
        confirmButtonColor: '#a42f1e',
        confirmButtonText: t("ok")
      })
    }

  }
  return (
    <div>
      <header className="header-div bg-white position-fixed w-100 shadow">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Link to="/myaccount" className="float-left">
            <i className="icon icon-angle-left"></i>{" "}
          </Link>
          <h2 className="text-center mb-0">{t("payment_methods")}</h2>
        </div>
      </header>
      <div className="container-fluid h-100 position-relative pt-40">
        <div className="row h-100">
          <div className="col-md-7 col-sm-7 col-xs-12 m-auto vh1-100 vscroll bg-white">
            <div className="LoginRegisterBlock h-100">
              <div className="LoginRegisterForm float-left w-100">
                <form action="#" method="post" encType="multipart/form-data">
                  <h3 className="mb-4">{t("saved_payment_methods")}</h3>

                  {cardList.map((item, index) => {
                    return (
                      <div
                        key={item.card_id}
                        className="form-group mb-4 text-center"
                      >
                        <input
                          type="radio"
                          className="mr-1 userCards"
                          id={item.card_id}
                          name="userCards"
                          value={item.card_safe_id}
                          style={{ marginTop: "-2px" }}
                          onChange={handleRadioChange}
                        />
                        <label
                          className=""
                          htmlFor={item.card_id}
                        >
                          {item.card_type} - Exp.{" "}
                          {item.card_month + "/" + item.card_year} -{" "}
                          {item.card_number}
                        </label>
                      </div>
                    );
                  })}
                  <div className="form-group mb-2 w-30 m-auto w-m-42">
                    <input
                      type="button"
                      className="btn btn-yellow w-100 btn-lg mb-3"
                      id="btnRemoveCard"
                      onClick={deleteCard}
                      value={t("remove_card")}
                    />
                    <input
                      type="button"
                      className="btn btn-yellow w-100 btn-lg"
                      onClick={() => navigate('/card/add/0')}
                      value={t("add_card")}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
