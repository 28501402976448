import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as dbconn from "../api/dbconn";
import loader from "../image/res_home_page_loader.gif";
import { fetchStoreData } from "../store/slices/fetchStore";

function CartSummary({ stepNumber }) {
  // Initialize translation hook for multi-language support
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Access cart items and store data from Redux state
  const cartItem = useSelector((state) => state.cart.cart);
  const [taxes, setTaxes] = useState([]); // State for storing tax data
  const [grandTotal, setGrandTotal] = useState(0); // State for storing grand total of the order
  const [store, setStore] = useState([]); // State for storing store data

  // Function to fetch store data from IndexedDB
  const storeFetch = async () => {
    const store = await dbconn.getStoreFromIndexedDB(); // Fetch store data
    setStore(store); // Set the fetched store data to state
  };

  // useEffect hook to run on component mount
  useEffect(() => {
    dispatch(fetchStoreData()); // Dispatch an action to fetch store data
    storeFetch(); // Fetch store data from IndexedDB
    const taxes = sessionStorage.getItem("tax"); // Get taxes from sessionStorage
    if (taxes) {
      setTaxes(JSON.parse(taxes)); // Parse and set the taxes
    }

    // Retrieve and calculate the Grand Total, including discounts and delivery charges
    const totalDiscount = sessionStorage.getItem("totalDiscount");
    const couponType = sessionStorage.getItem("couponType");
    const orderType = sessionStorage.getItem("orderType");
    const orderDeliveryFee = sessionStorage.getItem("orderDeliveryFee");
    const GrandTotal = sessionStorage.getItem("GrandTotal");

    // Logic for calculating Grand Total based on order type and discount
    if (orderType === "D") {
      if (couponType == "C" || couponType == "D") {
        const addDeliveryInTotal =
          Number(parseFloat(GrandTotal).toFixed(2)) +
          Number(parseFloat(orderDeliveryFee).toFixed(2));
        const minusDiscout =
          addDeliveryInTotal - Number(parseFloat(totalDiscount).toFixed(2));

        setGrandTotal(minusDiscout); // Set the adjusted Grand Total
      } else {
        const addDeliveryInTotal =
          Number(parseFloat(GrandTotal).toFixed(2)) +
          Number(parseFloat(orderDeliveryFee).toFixed(2));
        setGrandTotal(addDeliveryInTotal); // Set Grand Total with delivery charge
      }
    } else {
      setGrandTotal(Number(parseFloat(GrandTotal).toFixed(2))); // Set Grand Total for non-delivery orders
    }
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  return (
    <div className="leftsideBox w-100 bg-white p-2">
      {/* Show loading state if store data is not fetched */}
      {store == null && (
        <div className="loader">
          <img src={loader} alt="" width={"300px"} height={"210px"} />
        </div>
      )}

      {/* Display order summary */}
      <span className="d-flex flex-column h-100">
        <h3
          className="my-2 text-center roboto-medium-18"
          style={{ color: "var(--primaryColor)" }}
        >
          {t("order_summary")}
        </h3>
        <span className="h-100">
          <div className="ViewOrderBlock Checkout_info float-left w-100 scroll-styled">
            <ul>
              {/* Loop through cart items and display their details */}
              {cartItem.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="roboto-medium-16 d-flex align-items-center gap-1"
                    style={{ justifyContent: "space-between" }}
                  >
                    {/* Display product name based on store language */}
                    <label>
                      {localStorage.getItem("store_language") === "en"
                        ? item.product_data.product_name
                        : item.product_data.product_name_es.length > 0
                        ? item.product_data.product_name_es
                        : item.product_data.product_name}{" "}
                      {`(${item.product_data.product_quantity}x)`}
                    </label>

                    {/* Display product price, depending on loyalty points */}
                    {item.product_data.loyalty == "N" ? (
                      <span className="float-right">
                        $
                        <span id={"product_price_" + item.product_data.index}>
                          {/* {console.log("item.product_data.product_price)", item.product_data.product_price)} */}
                          {(
                            (parseFloat(item.product_data.product_price) +
                              parseFloat(item.product_data.modifier_price)) *
                            parseInt(item.product_data.product_quantity)
                          ).toFixed(2)}
                        </span>
                      </span>
                    ) : (
                      <span className="float-right">
                        <span id={"product_price_" + item.product_data.index}>
                          {(
                            parseFloat(
                              item.product_data.product_loyalty_price
                            ) * parseInt(item.product_data.product_quantity)
                          ).toFixed(0)}{" "}
                          {t("points")}
                        </span>
                      </span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          {/* Display summary of charges and taxes */}
          <div
            className="Checkout_info float-left w-100 p-2 scroll-styled"
            style={{ borderRadius: "10px", backgroundColor: "#F5F5F5" }}
          >
            <ul>
              {/* Display subtotal */}
              {sessionStorage.getItem("cartTotal") && (
                <li className="roboto-medium-16">
                  <label>{t("subtotal")}:</label>
                  <span className="float-right">
                    $
                    <span id="sumDiv">
                      {sessionStorage.getItem("cartTotal")}
                    </span>
                  </span>
                </li>
              )}

              {/* Display discount if applicable */}
              {sessionStorage.getItem("couponApplied") == "Y" &&
              sessionStorage.getItem("totalDiscount") > 0 &&
              sessionStorage.getItem("couponType") != "C" &&
              sessionStorage.getItem("couponType") != "D" ? (
                <li className="roboto-medium-16">
                  <label>{t("discount")}:</label>
                  <span className="float-right">
                    $
                    {parseFloat(
                      sessionStorage.getItem("totalDiscount")
                    ).toFixed(2)}
                  </span>
                </li>
              ) : sessionStorage.getItem("couponApplied") == "Y" &&
                (sessionStorage.getItem("couponType") == "C" ||
                  sessionStorage.getItem("couponType") == "D") &&
                sessionStorage.getItem("orderType") == "D" ? (
                <li className="roboto-medium-16">
                  <label>{t("discount")}:</label>
                  <span className="float-right">
                    $
                    {parseFloat(
                      sessionStorage.getItem("totalDiscount")
                    ).toFixed(2)}
                  </span>
                </li>
              ) : (
                ""
              )}

              {/* Display taxes */}
              {taxes.map((t, index) => (
                <li
                  className="d-flex justify-content-between roboto-medium-16 py-1 m-0"
                  key={index}
                >
                  <span>
                    {t.tax_name}{" "}
                    <span className="text-grey">
                      ({t.tax_percentage.toFixed(2)}%)
                    </span>
                    :
                  </span>
                  <span>${t.tax_value}</span>
                </li>
              ))}

              {/* Display delivery charge for delivery orders */}
              {sessionStorage.getItem("orderType") === "D" ? (
                <li className="roboto-medium-16">
                  <label>{t("delivery_charge")}: </label>
                  <span className="float-right">
                    $
                    {parseFloat(
                      sessionStorage.getItem("orderDeliveryFee")
                    ).toFixed(2)}
                  </span>
                </li>
              ) : (
                ""
              )}

              {/* Display tip if selected */}
              {Number(sessionStorage.getItem("selectedTipCalculation")) > 0 ? (
                <li className="d-flex justify-content-between roboto-medium-16 py-1">
                  <label>{t("tip")}</label>
                  <span className="float-right">
                    $
                    {parseFloat(
                      sessionStorage.getItem("selectedTipCalculation")
                    ).toFixed(2)}
                  </span>
                </li>
              ) : (
                ""
              )}

              {/* Display NCA charge if applicable */}
              {store != null && store.store_nca_enabled && (
                <li className="d-flex justify-content-between roboto-medium-16 py-1 m-0">
                  <span>
                    {store.store_nca_name}{" "}
                    <span className="text-grey">{`${
                      store.store_nca_amount_flag === "Y"
                        ? `(${store.store_default_nca}$)`
                        : `(${store.store_default_nca}%)`
                    }`}</span>
                    :
                  </span>
                  <span>
                    $
                    {parseFloat(sessionStorage.getItem("store_nca")).toFixed(2)}
                  </span>
                </li>
              )}

              {/* Display points redeemed */}
              {store != null &&
              Number(sessionStorage.getItem("useblePoint")) > 0 ? (
                <li className="roboto-medium-16">
                  <label>{t("points_redeemed")}:</label>

                  <span className="float-right">
                    {" "}
                    {Number(sessionStorage.getItem("useblePoint"))}
                  </span>
                </li>
              ) : (
                ""
              )}

              {/* Grand total display */}
              <hr className="my-2" />
              <li className="d-flex justify-content-between roboto-medium-16 py-1">
                <span>{t("grand_total")}:</span>
                <span>${grandTotal.toFixed(2)}</span>
              </li>
            </ul>
          </div>
        </span>
      </span>
    </div>
  );
}

export default CartSummary;
