import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import Color from "color";
import { useTranslation } from "react-i18next";
import { FaEyeSlash, FaRegEye } from "react-icons/fa";
import Swal from "sweetalert2";
import { API_ROOT, IMAGE_ROOT, Method, SUB_DOMAIN } from "../api/apiHandler";
import Header from "../components/Header";
import loader from '../image/res_home_page_loader.gif';

export default function ResetPassword() {
  let { randomNumber } = useParams();
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [passwordShow, setPasswordShow] = useState(false)
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    fetchStoreID()
    if (!localStorage.getItem("store_language")) {
      localStorage.setItem("store_language", "en");
    }
    i18n.changeLanguage(localStorage.getItem("store_language"));

    const primaryColor = localStorage.getItem("store_primary_color");
    if (primaryColor != null) {
      document.documentElement.style.setProperty(
        "--primaryColor",
        primaryColor
      );
    }

    // const secondaryColor = localStorage.getItem("store_secondary_color");
    // if (secondaryColor != null) {
    //   document.documentElement.style.setProperty(
    //     "--secondaryColor",
    //     secondaryColor
    //   );
    // }
  }, []);


  const fetchStoreID = () => {

    var url = API_ROOT + '/fetchstoreid?sub_domain=' + SUB_DOMAIN;

    fetch(url)
      .then(response => {
        return response.json();
      })
      .then(storeData => {

        if (storeData.status == 1 && storeData.store_data.length > 0) {
          localStorage.setItem('store_id', storeData.store_data[0].store_id);
          localStorage.setItem('store_image', storeData.store_data[0].store_image);
          localStorage.setItem('store_primary_color', storeData.store_data[0].store_primary_color);
          if (!localStorage.getItem("store_language")) {
            localStorage.setItem("store_language", storeData.store_data[0].store_language)
          }
          // localStorage.setItem("store_language", );
          var color = Color(storeData.store_data[0].store_primary_color).alpha(0.6).lighten(0.6);
          localStorage.setItem('store_light_color', color.hex());
          localStorage.setItem('store_secondary_color', storeData.store_data[0].store_secondary_color);
          localStorage.setItem('store_button_color', storeData.store_data[0].store_button_color);
          localStorage.setItem('store_name', storeData.store_data[0].store_button_color);
          localStorage.setItem('store_address', storeData.store_data[0].store_address);
          localStorage.setItem('store_city', storeData.store_data[0].store_city);
          localStorage.setItem('store_state', storeData.store_data[0].store_state);
          localStorage.setItem('store_zip', storeData.store_data[0].store_zip);
          localStorage.setItem('store_phone', storeData.store_data[0].store_phone);
          localStorage.setItem('store_email', storeData.store_data[0].store_email);
          localStorage.setItem('merchant_id', storeData.store_data[0].merchant_id);

        }
      });
  }

  const passShowToggel = () => {
    setPasswordShow(!passwordShow)
  }

  const confPassShowToggel = () => {
    setConfirmPasswordShow(!confirmPasswordShow)
  }


  const submitPassword = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setOpen(true)
      axios.post(API_ROOT + Method.RESET_PASSWORD, {
        customer_password: confirmPassword,
        random_number: randomNumber,
        store_id: localStorage.getItem("store_id"),
        merchant_id: localStorage.getItem("merchant_id"),
      })
        .then((response) => {
          setOpen(false)
          if (response.data.status === 1) {
            Swal.fire({
              title: t('reset_success'),
              text: t('login_inst'),
              showCancelButton: false,
              confirmButtonColor: "var(--primaryColor)",
              confirmButtonText: t('login'),
            }).then((result) => {
              if (result.isConfirmed) {
                // console.log("Redirect")
                navigate("/login")
              }
            });
          } else {
            if (response.data.message == "Your link has expired. Please try again with a new link") {
              Swal.fire({
                title: t('link_has_expire'),
                confirmButtonColor: "var(--primaryColor)",
              });
            } else {
              Swal.fire({
                title: response.data.message,
                confirmButtonColor: "var(--primaryColor)",
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const validateForm = () => {
    if (!password) {
      Swal.fire({
        // title: 'Password is required.',
        title: t('password_required'),
        confirmButtonColor: "var(--primaryColor)",
        allowOutsideClick: false
      });
      return false
    } else if (password.length < 6) {
      Swal.fire({
        // title: 'Password must be atleast 6 characters long.',
        title: t('password_must_be'),
        confirmButtonColor: "var(--primaryColor)",
        allowOutsideClick: false
      });
      return false
    } else if (password !== confirmPassword) {
      Swal.fire({
        // title: 'confirm Password does not match with Password.',
        title: t('confirm_passowrd_and_password'),
        confirmButtonColor: "var(--primaryColor)",
        allowOutsideClick: false
      });
      return false
    }
    return true
  };

  return (
    <div>
      <Header
        header={t("reset_password")}
        path="/"
      />
      {/* <header className="header-div bg-white position-fixed w-100 shadow">
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/" className="float-left">
            <BsChevronLeft />
          </Link>
          <h2 className="text-center mb-0">
            <strong className="roboto-bold-24"></strong>
          </h2>
          <button className="flagImagebutton float-right" onClick={() => languageHandle()}>{lang == "en" ? <img className="langImage" src={usa} alt="" width={30} height={30} /> : <img className="langImage" src={spanish} alt="" width={30} height={30} />}</button>
          {listLanguage &&
            <ul className="flagImageDiv p-2">
              {language.map((lang, index) =>
              (
                <li key={index}
                  className="roboto-medium-16 mb-2"
                  onClick={langSelected(lang.name)}>
                  {lang.image == "en" ? <img src={usa} alt="" width={15} height={15} /> : <img src={spanish} alt="" width={15} height={15} />} {lang.name}
                </li>
              ))}
            </ul>
          }

        </div>
      </header> */}
      <div className="container-fluid position-relative pt-40">
        {/* <div className="select d-flex justify-content-end custom-select w-100 loginSelect position-absolute border-0">
          <select className="bg-transparent roboto-regular-20" value={lang} onChange={changeLanguage}>
            {language.map((item, index) => (
              <option key={item} value={item}>
                {langname[index]}
              </option>
            ))}
          </select>
        </div> */}
        <div className="row">
          <div className="col-md-7 col-xs-12 m-auto vscroll bg-white">
            <div className="LoginRegisterBlock">
              <div className="Logo text-center">
                <Link to="/">
                  <img
                    style={{ width: "140px" }}
                    className="center-block"
                    src={
                      IMAGE_ROOT +
                      "/images/product/" +
                      localStorage.getItem("store_image")
                    }
                    alt="Takecharge Diner Logo"
                  />
                </Link>
              </div>
              <div className="LoginRegisterForm float-left w-100">
                <div className="form-box">
                  <form onSubmit={submitPassword}>
                    <div className="form-group mb-2 reset-password">
                      <input
                        type={passwordShow ? "text" : "password"}
                        className="w-100 border rounded p-2 borderGrey roboto-regular-16 "
                        value={password}
                        id="customerNewPassword"
                        placeholder={t('new_password')}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="passEye" onClick={passShowToggel}>
                        {passwordShow ? <FaRegEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                    <div className="form-group mb-4 resetConfirm-password">
                      <input
                        type={confirmPasswordShow ? "text" : "password"}
                        className="w-100 border rounded p-2 borderGrey roboto-regular-16"
                        name=""
                        value={confirmPassword}
                        id="customerConfirmPassword"
                        placeholder={t('confirm_password')}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                      <div className="confirmPass" onClick={confPassShowToggel}>
                        {confirmPasswordShow ? <FaRegEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                    <div className="form-group mb-4 col-12 col-md-6 m-auto">
                      <div className="form-group">
                        <input
                          type="submit"
                          className="btn btn-yellow btnLogin bg-green w-100 btn-lg p-2"
                          name="submitButton"
                          id="submitButton"
                          value={t('change_password')}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {open && (
        <div className="loader">
          <img src={loader} alt="" width={"300px"} height={"210px"} />
        </div>
      )}
    </div>
  );
}
