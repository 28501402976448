import moment from "moment";
import React, { useEffect, useState } from "react";
import BModal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { API_ROOT, Method, SUB_DOMAIN } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import CartCard from "../components/CartCard";
import ProductDetails from "../components/ProductDetails";
import loader from "../image/res_home_page_loader.gif";
import { addToCart, clearCart } from "../store/slices/CartSlice";
import { selectModalState, toggleModal } from "../store/slices/modalSlice";
import OrderType from "./OrderType";

function YourOrder(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isModalOpen = useSelector(selectModalState);
  const cartItem = useSelector((state) => state.cart.cart);
  const cartReduxTotal = cartItem.reduce((total, item) => {
    total +=
      item.product_data.product_quantity *
      ((item.product_data.loyalty !== "Y"
        ? item.product_data.product_price
        : 0) +
        item.product_data.modifier_price);
    return total;
  }, 0);
  sessionStorage.setItem("cartTotal", cartReduxTotal.toFixed(2));
  const { t, i18n } = useTranslation();

  // const [captureredirect, setCaptureredirect] = useState(false);
  // const [showImageUpload, setShowImageUpload] = useState(false);
  // const [showCapture, setShowCapture] = useState(false);
  const [showOrderType, setShowOrderType] = useState(false);
  const [open, setOpen] = useState(false);
  const [storeOpenTime, setStoreOpenTime] = useState("");
  // const [productList, setProductList] = useState([]);
  // const [nca, setNca] = useState(0);
  // const [ncaValue, setNcaValue] = useState(0);
  // const [ncaAmtFlag, setNcaAmtFlag] = useState(false);
  // const [deleteG, setDeleteG] = useState(false);
  // const [ncaAmtName, setNcaAmtName] = useState("");
  // const [tax, setTax] = useState([]);
  // const [checkoutRedirectFlag, setCheckoutRedirectFlag] = useState(false);
  // const [cartTotal, setCartTotal] = useState(0);
  // const [finalTax, setFinalTax] = useState(0);
  // const [guestRedirectFlag, setGuestRedirectFlag] = useState(false);
  // const [storeOrderType, setStoreOrderType] = useState("");
  // const [storeDeliveryFee, setStoreDeliveryFee] = useState(0);
  // const [storeDeliveryFeeDiscount, setStoreDeliveryFeeDiscount] = useState(0);
  // const [orderTypeDialog, setOrderTypeDialog] = useState("");
  // const [homeRedirectFlag, setHomeRedirectFlag] = useState(false);
  const [customTipVisible, setCustomTipVisible] = useState("");
  // const [noTipVisible, setNoTipVisible] = useState("inline-block");
  // const [notTipVisible, setNotTipVisible] = useState("none");
  const [isTipEnabled, setIsTipEnabled] = useState("");
  const [tipOne, setTipOne] = useState(0);
  const [tipTwo, setTipTwo] = useState(0);
  const [tipThree, setTipThree] = useState(0);
  // const [tipSelected, setTipSelected] = useState(0);
  const [tip, setTip] = useState(0);
  const [customTip, setCustomTip] = useState(0);
  // const [storeDeliveryTime, setStoreDeliveryTime] = useState("");
  // const [storePickupTime, setStorePickupTime] = useState("");
  // const [storeWeeklyOrder, setStoreWeeklyOrder] = useState("");
  // const [ncaMarkup, setNcaMarkup] = useState("N");
  // const [ncaOnTipEnabled, setNcaOnTipEnabled] = useState("Y");
  const [totalLoyaltyPoints, setTotalLoyaltyPoints] = useState(0);
  // const [couponApplied, setCouponApplied] = useState("N");
  // const [couponType, setCouponType] = useState("");
  // const [couponDiscount, setCouponDiscount] = useState(0);
  // const [couponMinSubtotal, setCouponMinSubtotal] = useState(0);
  // const [nosessionredirect, setNosessionredirect] = useState();
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedTip, setSelectedTip] = useState();
  const [couponInput, setCouponInput] = useState("");
  const [couponInputContainer, setCouponInputContainer] =
    useState("inline-table");
  const [appliedCouponContainer, setAppliedCouponContainer] = useState("none");
  const [appliedCouponText, setAppliedCouponText] = useState("");
  const [storeDetails, setStoreDetails] = useState([]);
  const [calculatedTax, setCalculatedTax] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  // const store = useSelector((state) => state.fetchstore.data)
  const [store, setStore] = useState([]);
  // const [isStoreFound, setIsStoreFound] = useState(false)

  useEffect(() => {
    localStorage.setItem("isEdit", "Y");

    const tipButtonActive = sessionStorage.getItem("selectedTipOption");

    if (tipButtonActive) {
      setSelectedTip(tipButtonActive);
    } else {
      sessionStorage.setItem("selectedTipOption", 0);
      sessionStorage.setItem("selectedTipCalculation", 0);
    }

    fetchStoreID();
  }, []);

  const fetchStoreID = async () => {
    setOpen(true);
    // try {
    const url = `${API_ROOT}${Method.FETCH_STORE_FROM_ID}?sub_domain=${SUB_DOMAIN}`;

    const response = await fetch(url);
    const res = await response.json();

    if (res.status === 1) {
      const data = res.store;
      if (data) {
        setStore(data);
        dbconn.addStoreToIndexedDB(data);

        setStoreDetails(data);
        setIsTipEnabled(data.store_tip_enabled);
        setTipOne(data.store_default_tip1);
        setTipTwo(data.store_default_tip2);
        setTipThree(data.store_default_tip3);
        // setStore(store)

        // let currentday = moment.tz(data.store_timezone).isoWeekday();
        // let scheduleList = [];

        // for (var i = data.store_schedule.length - 1; i > -1; i--) {
        //   if (data.store_schedule[i].schedule_enabled == "Y") {
        //     scheduleList.push({
        //       day:
        //         `${moment().isoWeekday(data.store_schedule[i].schedule_day).format("ddd")}:`,
        //       time: `${data.store_schedule[i].schedule_from} - ${data.store_schedule[i].schedule_to}`,
        //     });
        //   } else {
        //     scheduleList.push({
        //       day:
        //         `${moment().isoWeekday(data.store_schedule[i].schedule_day).format("ddd")}:`,
        //       time: "Closed",
        //     });
        //   }

        //   if (currentday === data.store_schedule[i].schedule_day) {
        //     if (data.store_schedule[i].schedule_enabled === "Y") {
        //       setStoreOpenTime(`${data.store_schedule[i].schedule_from} - ${data.store_schedule[i].schedule_to}`)
        //       const format = "h:mm A";
        //       const currentTime = moment.tz(data.store_timezone);
        //       var beforeTime = moment(data.store_schedule[i].schedule_from, format);
        //       var afterTime = moment(data.store_schedule[i].schedule_to, format);

        //       if (currentTime.isAfter(beforeTime) && currentTime.isBefore(afterTime)) {
        //         // setIsStoreClose(false)
        //       }
        //     } else {
        //       Swal.fire({
        //         title: 'SORRY! WE ARE CLOSED',
        //         text: `We apologize for any inconvenience,<br /> but we are currently closed. Our<br /> operating hour are <br /><h5>${storeOpenTime}</h5><br /> Please visit us again during our business day. <br /><br /> Thank you for your understanding.`,
        //         confirmButtonColor: "var(--primaryColor)",
        //         confirmButtonText: 'OK'
        //       }).then(() => {
        //         navigate('/');
        //       });
        //       // console.log("Store Close This Time")
        //       // // setIsStoreClose(true)
        //       // navigate('/')
        //     }
        //   }
        // }

        let currentDay = moment.tz(data.store_timezone).isoWeekday();
        let currentTime = moment.tz(data.store_timezone);
        let scheduleList = [];

        for (let i = 0; i < data.store_schedule.length; i++) {
          const schedule = data.store_schedule[i];

          // Create a schedule entry
          scheduleList.push({
            day: `${moment().isoWeekday(schedule.schedule_day).format("ddd")}:`,
            time:
              schedule.schedule_enabled === "Y"
                ? `${schedule.schedule_from} - ${schedule.schedule_to}`
                : "Closed",
          });

          // Check if the schedule matches the current day
          if (currentDay === schedule.schedule_day) {
            if (schedule.schedule_enabled === "Y") {
              const format = "h:mm A";
              const openTime = moment.tz(
                schedule.schedule_from,
                format,
                data.store_timezone
              );
              const closeTime = moment.tz(
                schedule.schedule_to,
                format,
                data.store_timezone
              );

              // Determine if current time is between open and close time
              if (currentTime.isBetween(openTime, closeTime, null, "[]")) {
                // storeOpen = true; // Store is open
                // setIsStoreClose(false)
                // console.log("Store open")
                setStoreOpenTime(
                  `${schedule.schedule_from} - ${schedule.schedule_to}`
                );
              } else {
                // storeOpen = false; // Store is closed
                // setIsStoreClose(true)
                Swal.fire({
                  title: "SORRY! WE ARE CLOSED",
                  text: `We apologize for any inconvenience,<br /> but we are currently closed. Our<br /> operating hour are <br /><h5>${storeOpenTime}</h5><br /> Please visit us again during our business day. <br /><br /> Thank you for your understanding.`,
                  confirmButtonColor: "var(--primaryColor)",
                  confirmButtonText: "OK",
                }).then(() => {
                  navigate("/");
                });
              }
            } else {
              // console.log("// Store is closed today")
            }
          }
        }

        if (data.store_primary_color != null) {
          document.documentElement.style.setProperty(
            "--primaryColor",
            data.store_primary_color
          );
        }
        localStorage.setItem("store_image", data.store_image);
        localStorage.setItem("store_name", data.store_name);
        // await dbconn.addStoreToIndexedDB(store);
        setOpen(false);
      } else {
        setOpen(false);
        navigate("/");
      }
    } else {
      setOpen(false);
      navigate("/");
      // throw new Error('Failed to fetch store data');
    }
    // } catch (error) {
    //   setOpen(false)
    //   console.log("error", error)
    // }
  };

  let AllProductData = [];
  let AllTempModifiresData = [];

  const fetchCartItem = () => {
    AllProductData = [];
    cartItem.forEach((item, index) => {
      AllProductData.push(JSON.parse(JSON.stringify(item.product_data)));
      AllTempModifiresData.push(JSON.parse(JSON.stringify(item.dropdown_mod)));
    });
  };

  useEffect(() => {
    fetchCartItem();
  }, []);

  useEffect(() => {
    if (cartItem.length === 0) {
      sessionStorage.setItem("cartTotal", 0);
      sessionStorage.setItem("selectedTipOption", 0);
      sessionStorage.setItem("selectedTipCalculation", 0);
      sessionStorage.setItem("couponApplied", "N");
      navigate("/");
    }
    const couponApplied = sessionStorage.getItem("couponApplied");
    if (couponApplied && couponApplied === "Y") {
      const couponDetails = JSON.parse(sessionStorage.getItem("couponDetails"));
      if (couponDetails.coupon_min_subtotal > cartReduxTotal) {
        cancelCoupon();
      }
    }
    fetchCartItem();
  }, [cartItem]);

  // const fetchStore = () => {

  //   var url = "";

  //   var cartTotal = cartReduxTotal.toFixed(2);
  //   const storeID = localStorage.getItem('store_id')

  //   // if (!sessionStorage.getItem("guestFlag") || sessionStorage.getItem("guestFlag") == "Y") {

  //   //   url = `${API_ROOT}${Method.FETCH_STORE_STATE}?guestFlag=Y&store_id=${storeID}`

  //   //   fetch(url)
  //   //     .then((response) => {
  //   //       return response.json();
  //   //     })
  //   //     .then((storeData) => {
  //   //       // onCloseModal();
  //   //       if (storeData.status == 1) {
  //   //         setNca(storeData.store_data[0].store_default_nca.toFixed(2));
  //   //         setNcaAmtFlag(storeData.store_data[0].store_nca_amount_flag);
  //   //         setNcaAmtName(storeData.store_data[0].store_nca_name);
  //   //         setNcaMarkup(storeData.store_data[0].store_product_markup_enabled);
  //   //         setStoreOrderType(storeData.store_data[0].store_order_type);
  //   //         setStoreDeliveryFee(
  //   //           parseFloat(storeData.store_data[0].store_delivery_fee)
  //   //         );
  //   //         setStoreDeliveryFeeDiscount(
  //   //           parseFloat(storeData.store_data[0].store_delivery_fee)
  //   //         );
  //   //         setNcaOnTipEnabled(storeData.store_data[0].store_tip_nca_enabled);
  //   //         setStoreWeeklyOrder(storeData.store_data[0].store_weekly_order);
  //   //         setStoreDeliveryTime(storeData.store_data[0].store_delivery_time);

  //   //         if (storeData.store_data[0].store_tip_enabled == "Y") {
  //   //           setTipEnabled(true);

  //   //           setTipOne(storeData.store_data[0].store_default_tip1);
  //   //           setTipTwo(storeData.store_data[0].store_default_tip2);
  //   //           setTipThree(storeData.store_data[0].store_default_tip3);

  //   //           if (tipOne == 0 && tipTwo == 0 && tipThree == 0) {
  //   //             setCustomTipVisible("none");
  //   //             // setNoTipVisible("inline-bloack");
  //   //           } else {
  //   //             setCustomTipVisible("none");
  //   //             // setNoTipVisible("inline-bloack");
  //   //           }
  //   //         } else {
  //   //           setTipEnabled(false);
  //   //         }

  //   //         var taxArray = storeData.store_data[0].store_taxes;

  //   //         var totalTax = 0;

  //   //         for (var i = 0; i < taxArray.length; i++) {
  //   //           const taxCalculate =
  //   //             (parseFloat(cartTotal) *
  //   //               parseFloat(taxArray[i].tax_percentage.toFixed(2))) /
  //   //             100;

  //   //           taxArray[i].tax_value = taxCalculate.toFixed(2);

  //   //           totalTax += parseFloat(taxCalculate.toFixed(2));
  //   //         }
  //   //         setTax(taxArray);
  //   //         setFinalTax(totalTax.toFixed(2));

  //   //         // for service fee add
  //   //         const ncaArray = storeData.store_data[0].store_default_nca.toFixed(2);
  //   //         const totalNcaValue =
  //   //           (parseFloat(cartTotal) * parseFloat(ncaArray)) / 100;
  //   //         setNcaValue(totalNcaValue);

  //   //         calculateCoupon();
  //   //       }
  //   //     });
  //   //   // console.log("cartItem", cartItem)

  //   //   // dbconn.getAllOrder().then((productData) => {
  //   //   var totalLoyalty = 0;
  //   //   AllProductData.forEach(function (product) {
  //   //     // if (product.product_loyalty_enabled == "Y") {
  //   //     if (product.loyalty == "Y") {
  //   //       totalLoyalty +=
  //   //         parseInt(product.product_loyalty_price) *
  //   //         parseInt(product.product_quantity);
  //   //     }
  //   //   });
  //   //   // console.log("totalLoyalty", totalLoyalty)
  //   //   setTotalLoyaltyPoints(totalLoyalty);

  //   //   calculateModifiers(AllProductData);
  //   //   // });
  //   // } else {
  //   //   dbconn.getAll().then((data) => {
  //   //     // getAllStore().then((data) => {
  //   //     if (!data || data.length < 1) {
  //   //       setNosessionredirect(true);
  //   //     } else {
  //   //       var customer_id = data[0].customer_id;
  //   //       var customer_token = data[0].customer_token;

  //   //       // onOpenModal();
  //   //       url =
  //   //         API_ROOT +
  //   //         Method.FETCH_STORE_STATE +
  //   //         "?guestFlag=N&customer_id=" +
  //   //         customer_id +
  //   //         "&customer_token=" +
  //   //         customer_token +
  //   //         "&store_id=" +
  //   //         localStorage.getItem("store_id");

  //   //       fetch(url)
  //   //         .then((response) => {
  //   //           return response.json();
  //   //         })
  //   //         .then((storeData) => {
  //   //           // console.log("store", storeData);
  //   //           onCloseModal();
  //   //           if (storeData.status == 1) {
  //   //             setNca(storeData.store_data[0].store_default_nca.toFixed(2));
  //   //             setNcaAmtFlag(storeData.store_data[0].store_nca_amount_flag);
  //   //             setTax(storeData.store_data[0].store_taxes);
  //   //             setNcaAmtName(storeData.store_data[0].store_nca_name);
  //   //             setNcaMarkup(
  //   //               storeData.store_data[0].store_product_markup_enabled
  //   //             );
  //   //             setStoreOrderType(storeData.store_data[0].store_order_type);
  //   //             setStoreDeliveryFee(storeData.store_data[0].store_delivery_fee);
  //   //             sessionStorage.setItem("isStoreDeliveryFee", storeData.store_data[0].store_delivery_fee)
  //   //             setStoreDeliveryFeeDiscount(
  //   //               parseFloat(storeData.store_data[0].store_delivery_fee)
  //   //             );
  //   //             setStoreWeeklyOrder(storeData.store_data[0].store_weekly_order);
  //   //             setNcaOnTipEnabled(
  //   //               storeData.store_data[0].store_tip_nca_enabled
  //   //             );
  //   //             setStoreDeliveryTime(
  //   //               storeData.store_data[0].store_delivery_time
  //   //             );
  //   //             setStorePickupTime(storeData.store_data[0].store_pickup_time);

  //   //             if (storeData.store_data[0].store_tip_enabled == "Y") {
  //   //               setTipEnabled(true);
  //   //               setTipOne(storeData.store_data[0].store_default_tip1);
  //   //               setTipTwo(storeData.store_data[0].store_default_tip2);
  //   //               setTipThree(storeData.store_data[0].store_default_tip3);

  //   //               if (tipOne == 0 && tipTwo == 0 && tipThree == 0) {
  //   //                 setCustomTipVisible("none");
  //   //                 // setNoTipVisible("inline-block");
  //   //               } else {
  //   //                 setCustomTipVisible("none");
  //   //                 // setNoTipVisible("inline-block");
  //   //               }
  //   //             } else {
  //   //               setTipEnabled(false);
  //   //             }
  //   //             var taxArray = storeData.store_data[0].store_taxes;

  //   //             var totalTax = 0;

  //   //             for (var i = 0; i < taxArray.length; i++) {
  //   //               const taxCalculate =
  //   //                 (parseFloat(cartTotal) *
  //   //                   parseFloat(taxArray[i].tax_percentage.toFixed(2))) /
  //   //                 100;

  //   //               taxArray[i].tax_value = taxCalculate.toFixed(2);

  //   //               totalTax += parseFloat(taxCalculate.toFixed(2));
  //   //             }
  //   //             setTax(taxArray);
  //   //             setFinalTax(totalTax.toFixed(2));

  //   //             // for service fee add
  //   //             const ncaArray = storeData.store_data[0].store_default_nca.toFixed(2);
  //   //             const totalNcaValue =
  //   //               (parseFloat(cartTotal) * parseFloat(ncaArray)) / 100;
  //   //             setNca(ncaArray);
  //   //             setNcaValue(totalNcaValue);
  //   //             calculateCoupon();
  //   //           }
  //   //         });

  //   //       // dbconn.getAllOrder().then((productData) => {
  //   //       var totalLoyalty = 0;
  //   //       AllProductData.forEach(function (product) {
  //   //         // if (product.product_loyalty_enabled == "Y") {
  //   //         if (product.loyalty == "Y") {
  //   //           totalLoyalty +=
  //   //             parseInt(product.product_loyalty_price) *
  //   //             parseInt(product.product_quantity);
  //   //         }
  //   //       });
  //   //       setTotalLoyaltyPoints(totalLoyalty);
  //   //       calculateModifiers(AllProductData);
  //   //       setProductList(AllProductData);
  //   //       // });
  //   //     }
  //   //   });
  //   // }
  // }

  useEffect(() => {
    // "View Order - " + localStorage.getItem("store_name") + " - " + PROJECT;

    sessionStorage.removeItem("editCartID");
    if (!localStorage.getItem("store_language")) {
      localStorage.setItem("store_language", "en");
    }
    i18n.changeLanguage(localStorage.getItem("store_language")).then((r) => {});

    sessionStorage.removeItem("editCartID");
    // fetchStore()
  }, [sessionStorage.getItem("cartTotal")]);

  // const calculateCartTotal = (isCouponLoad) => {
  //   var cartTotal = 0;

  //   dbconn.getAllOrder().then((products) => {
  //     // getAllOrdersFromStore().then((products) => {
  //     // products.map((item, index) => {
  //     AllProductData.forEach(function (item) {
  //       if (item.loyalty == "N") {
  //         var discount = 0;
  //         if (
  //           item.product_discount_type == "P" ||
  //           item.product_discount_type == "H" ||
  //           item.product_discount_type == "G"
  //         ) {
  //           discount =
  //             ((Number(parseFloat(item.product_price)) + Number(parseFloat(item.mod_price))) *
  //               Number(parseFloat(item.product_discount))) /
  //             100;
  //         } else if (item.product_discount_type == "A") {
  //           discount = Number(parseFloat(item.product_discount));
  //         }

  //         cartTotal +=
  //           (Number(parseFloat(item.product_price)) +
  //             Number(parseFloat(item.mod_price)) -
  //             discount) *
  //           Number(parseInt(item.product_quantity));
  //       } else {
  //         cartTotal +=
  //           Number(parseFloat(item.mod_original_price)) * Number(parseInt(item.product_quantity));
  //       }
  //     });

  //     // sessionStorage.setItem("cartTotal", cartTotal);
  //     setCartTotal(cartReduxTotal);
  //     // calculateTax(isCouponLoad);
  //   });
  // };

  useEffect(() => {
    if (cartReduxTotal > 0) {
      calculateTax1(cartReduxTotal.toFixed(2));
      const selectedValue = sessionStorage.getItem("selectedTipOption");

      if (selectedValue != 0 && selectedValue != "other") {
        const calculatedTip =
          (Number(parseFloat(cartReduxTotal)) *
            Number(parseFloat(selectedValue))) /
          100;
        setTip(calculatedTip.toFixed(2));
        sessionStorage.setItem(
          "selectedTipCalculation",
          calculatedTip.toFixed(2)
        );

        calculateNCA1(cartReduxTotal.toFixed(2));
      }
    } else {
      sessionStorage.removeItem("tax");
      sessionStorage.removeItem("totalPayTax");

      sessionStorage.setItem("couponApplied", "N");
      if (sessionStorage.getItem("couponApplied") === "Y") {
        cancelCoupon();
      }
    }
  }, [cartReduxTotal, store]);

  useEffect(() => {
    let totalLoyalty = 0;

    cartItem.map((item) => {
      if (item.product_data.loyalty === "Y") {
        totalLoyalty +=
          Number(parseInt(item.product_data.product_loyalty_price)) *
          Number(parseInt(item.product_data.product_quantity));
      }
    });
    sessionStorage.setItem("useblePoint", totalLoyalty);
    setTotalLoyaltyPoints(totalLoyalty);
  }, [cartItem]);

  const calculateTax1 = (subTotal) => {
    // const store = storeDetails.data
    const isTaxEnable = storeDetails.store_tax_enabled;
    const allTax = storeDetails.store_taxes;
    // const tax = storeDetails

    // if (!allTax || !Array.isArray(allTax)) {
    //   console.error("Tax is not properly defined.");
    //   return;
    // }
    let totalPayTax = 0;
    const updatedTaxes = [];

    if (isTaxEnable === "Y") {
      for (let i = 0; i < allTax.length; i++) {
        if (allTax[i].tax_deleted === "N" && allTax[i].tax_percentage > 0) {
          const calculateTax =
            (Number(parseFloat(subTotal)) * allTax[i].tax_percentage) / 100;
          const updatedTax = {
            ...allTax[i],
            tax_value: calculateTax.toFixed(2),
          };
          updatedTaxes.push(updatedTax);
          totalPayTax += Number(parseFloat(calculateTax.toFixed(2)));
        }
      }
    }
    setCalculatedTax(updatedTaxes);
    sessionStorage.setItem("tax", JSON.stringify(updatedTaxes));
    sessionStorage.setItem("totalPayTax", totalPayTax.toFixed(2));
    // console.log("finalTotal", )
    calculateNCA1(subTotal);
  };

  const handlesetCustomTip = (e) => {
    let inputValue = e.target.value;

    // Prevent more than 4 digits from being entered
    if (inputValue.length > 4) {
      return; // Exit the function without updating anything
    }

    // Ensure inputValue is properly parsed and handle empty input
    if (inputValue.trim() !== "") {
      const parsedTip = Number(parseFloat(inputValue).toFixed(2));

      // Update the states and session storage
      // setTipSelected("other");
      setTip(parsedTip);
      setCustomTip(parsedTip);
      sessionStorage.setItem("selectedTipCalculation", inputValue);
    } else {
      // Handle case for empty input
      setTip(0);
      sessionStorage.setItem("selectedTipCalculation", 0);
    }

    // Calculate the new total
    calculateNCA1(cartReduxTotal.toFixed(2));
  };

  const updateTip = (value) => {
    sessionStorage.setItem("selectedTipOption", value);
    sessionStorage.setItem("selectedTipCalculation", 0);

    if (cartReduxTotal > 0) {
      const selectedValue = sessionStorage.getItem("selectedTipOption");
      setSelectedTip(value);
      if (selectedValue === "other") {
        setCustomTipVisible("inline-block");
        // setTipSelected("other");
        const selectedTip = Number(
          parseFloat(sessionStorage.getItem("selectedTipCalculation"))
        );
        setCustomTip(selectedTip > 0 ? selectedTip : 0);
      } else {
        if (tipOne === 0 && tipTwo === 0 && tipThree === 0) {
          setCustomTipVisible("inline-block");
          // setNotTipVisible("inline-block");
        } else {
          setCustomTipVisible("none");
          // setNotTipVisible("inline-block");
        }

        // setNoTipVisible("inline-block");
        setCustomTipVisible("none");

        // setTipSelected(Number(parseFloat(selectedValue)));
        const calculatedTip =
          (Number(parseFloat(cartReduxTotal)) *
            Number(parseFloat(selectedValue))) /
          100;
        setTip(calculatedTip.toFixed(2));
        sessionStorage.setItem(
          "selectedTipCalculation",
          calculatedTip.toFixed(2)
        );
      }
      calculateNCA1(cartReduxTotal.toFixed(2));
    } else {
      Swal.fire({
        title: "Subtotal must be greter then zero when adding a tip.",
        allowOutsideClick: false,
      });
      sessionStorage.setItem("selectedTipCalculation", 0);
      sessionStorage.setItem("selectedTipOption", 0);
    }
  };

  const calculateNCA1 = (subTotal) => {
    sessionStorage.removeItem("store_nca");
    const isEnable = storeDetails.store_default_nca;
    const inAmount = storeDetails.store_nca_amount_flag;
    const ncaValue = storeDetails.store_default_nca;
    const onTipNcaEnable = storeDetails.store_tip_nca_enabled;
    const isTipEnable = storeDetails.store_tip_enabled;

    if (isEnable > 0) {
      if (onTipNcaEnable === "Y" && isTipEnable === "Y") {
        // code here if nca calulate on tip
        if (inAmount === "N") {
          const selectedTipCalculation = sessionStorage.getItem(
            "selectedTipCalculation"
          );
          const total =
            parseFloat(subTotal) + parseFloat(selectedTipCalculation);
          const calculateNCA = (parseFloat(total) * parseFloat(ncaValue)) / 100;
          sessionStorage.setItem("store_nca", calculateNCA.toFixed(2));
        } else {
          // const calculateNCA = parseFloat(subTotal) + parseFloat(ncaValue)
          sessionStorage.setItem("store_nca", ncaValue);
        }
      } else {
        if (inAmount === "Y") {
          sessionStorage.setItem("store_nca", ncaValue);
          // console.log("NCA in amount", calculateNCA.toFixed(2))
        } else {
          const calculateNCA =
            (parseFloat(subTotal) * parseFloat(ncaValue)) / 100;
          sessionStorage.setItem("store_nca", calculateNCA.toFixed(2));
        }
      }
      let finalTotal = 0;
      const taxTotal = sessionStorage.getItem("totalPayTax");
      const nca = sessionStorage.getItem("store_nca");
      const tipTotal = sessionStorage.getItem("selectedTipCalculation");
      const couponApplied = sessionStorage.getItem("couponApplied");
      if (couponApplied === "Y") {
        const couponType = sessionStorage.getItem("couponType");
        const totalDiscount = sessionStorage.getItem("totalDiscount");
        if (
          couponType == "C" ||
          couponType == "D" ||
          couponType == "H" ||
          couponType == "G"
        ) {
          finalTotal +=
            Number(parseFloat(cartReduxTotal).toFixed(2)) +
            Number(parseFloat(taxTotal).toFixed(2)) +
            Number(parseFloat(nca).toFixed(2)) +
            Number(parseFloat(tipTotal).toFixed(2));
        } else {
          finalTotal +=
            Number(parseFloat(cartReduxTotal).toFixed(2)) +
            Number(parseFloat(taxTotal).toFixed(2)) +
            Number(parseFloat(nca).toFixed(2)) +
            Number(parseFloat(tipTotal).toFixed(2)) -
            Number(parseFloat(totalDiscount).toFixed(2));
        }
      } else {
        finalTotal +=
          Number(parseFloat(cartReduxTotal).toFixed(2)) +
          Number(parseFloat(taxTotal).toFixed(2)) +
          Number(parseFloat(nca).toFixed(2)) +
          Number(parseFloat(tipTotal).toFixed(2));
      }
      setGrandTotal(parseFloat(finalTotal));
    } else {
      sessionStorage.setItem("store_nca", 0);
      const nca = sessionStorage.getItem("store_nca");
      let finalTotal = 0;
      const taxTotal = sessionStorage.getItem("totalPayTax");
      const tipTotal = sessionStorage.getItem("selectedTipCalculation");
      // const finalTotal = Number(parseFloat(cartReduxTotal).toFixed(2)) + Number(parseFloat(taxTotal).toFixed(2)) + Number(parseFloat(tipTotal).toFixed(2))

      const couponApplied = sessionStorage.getItem("couponApplied");
      if (couponApplied === "Y") {
        const couponType = sessionStorage.getItem("couponType");
        const totalDiscount = sessionStorage.getItem("totalDiscount");
        if (
          couponType == "C" ||
          couponType == "D" ||
          couponType == "H" ||
          couponType == "G"
        ) {
          finalTotal +=
            Number(parseFloat(cartReduxTotal).toFixed(2)) +
            Number(parseFloat(taxTotal).toFixed(2)) +
            Number(parseFloat(nca).toFixed(2)) +
            Number(parseFloat(tipTotal).toFixed(2));
        } else {
          finalTotal +=
            Number(parseFloat(cartReduxTotal).toFixed(2)) +
            Number(parseFloat(taxTotal).toFixed(2)) +
            Number(parseFloat(nca).toFixed(2)) +
            Number(parseFloat(tipTotal).toFixed(2)) -
            Number(parseFloat(totalDiscount).toFixed(2));
        }
      } else {
        finalTotal +=
          Number(parseFloat(cartReduxTotal).toFixed(2)) +
          Number(parseFloat(taxTotal).toFixed(2)) +
          Number(parseFloat(nca).toFixed(2)) +
          Number(parseFloat(tipTotal).toFixed(2));
      }
      setGrandTotal(Number(parseFloat(finalTotal).toFixed(2)));
    }
    const tipOption = sessionStorage.getItem("selectedTipOption");
    if (tipOption === "other") {
      const tipTotal = sessionStorage.getItem("selectedTipCalculation");
      setCustomTip(Number(parseFloat(tipTotal).toFixed(2)));
    }
  };

  // const calculateTax = (isCouponLoad) => {
  //   // const cartTotal = parseFloat(sessionStorage.getItem("cartTotal"));
  //   const cartTotal = cartReduxTotal;

  //   if (!tax || !Array.isArray(tax)) {
  //     console.error("Tax is not properly defined.");
  //     return;
  //   }

  //   var taxArray = tax;
  //   var totalTax = 0;

  //   for (var i = 0; i < taxArray.length; i++) {
  //     const taxCalculate =
  //       (parseFloat(cartTotal) * parseFloat(taxArray[i].tax_percentage)) / 100;
  //     taxArray[i].tax_value = taxCalculate.toFixed(2);
  //     totalTax += taxCalculate;
  //   }

  //   setTax(taxArray);
  //   setFinalTax(totalTax.toFixed(2));
  //   // calculateTip(isCouponLoad);

  //   const ncaArray = nca;
  //   const totalNcaValue = (parseFloat(cartTotal) * parseFloat(ncaArray)) / 100;
  //   setNca(ncaArray);
  //   setNcaValue(totalNcaValue);
  // };

  // const calculateTip = (isCouponLoad) => {
  //   if (customTipVisible == "none") {
  //     // const cartTotal = parseFloat(sessionStorage.getItem("cartTotal"));
  //     const cartTotal = cartReduxTotal;
  //     var tips = (parseFloat(cartTotal) * parseFloat(tipSelected)) / 100;
  //     sessionStorage.setItem("totalTipValue", tips.toFixed(2))
  //     console.log("totalTipValue", tips.toFixed(2))
  //     setTip(tips.toFixed(2));
  //   }

  //   if (isCouponLoad) {
  //     calculateCoupon();
  //   }
  // };

  useEffect(() => {
    const couponApplied = sessionStorage.getItem("couponApplied");
    if (couponApplied === "Y") {
      setCouponInput("");
      setCouponInputContainer("none");
      setAppliedCouponContainer("inline-table");
      setAppliedCouponText(sessionStorage.getItem("couponCode"));
    }

    // const updateWindowHeight = () => {
    //   const height = $(window).height();
    //   setWindowHeight(height);
    // };

    // updateWindowHeight(); // Call the function initially

    // $(window).on('resize', updateWindowHeight); // Add event listener for window resize

    // return () => {
    // $(window).off('resize', updateWindowHeight);
    // };
  }, []);

  const checkOutBtn = () => {
    // let flag = true;
    // let productName = "";
    // let overAgeFlag = false;
    // for (let i = 0; i < productList.length; i++) {
    //   if (productList[i].product_overage_enabled === "Y") {
    //     overAgeFlag = true;
    //   }

    //   if (productList[i].product_modifiers_isOkey === "N") {
    //     flag = false;
    //     productName = productList[i].productName;
    //     break;
    //   }
    // }

    // if (flag) {
    //   handleShowOrderType();
    //   sessionStorage.setItem("cartTotal", cartReduxTotal);
    // } else {
    //   Swal.fire({
    //     title: i18n.t("select_option_inst_2", productName),
    //     allowOutsideClick: false,
    //   });
    // }

    // var finalDeliveryCharge = parseFloat(
    //   sessionStorage.getItem("orderDeliveryFee")
    // ).toFixed(2);

    // // var finalTip = parseFloat(sessionStorage.getItem("tip"));
    // var finalNca =
    //   (parseFloat(sessionStorage.getItem("cartTotal")) * parseFloat(nca)) /
    //   100;

    // console.log("finalTax =>", parseFloat(parseFloat(finalTax).toFixed(2)))
    // console.log("couponDiscount =>", parseFloat(parseFloat(couponDiscount.toFixed(2))))
    // // console.log("storeDeliveryFee =>", parseFloat(storeDeliveryFee).toFixed(2))
    // console.log("tip =>", parseFloat(parseFloat(tip).toFixed(2)))
    // console.log("finalNca =>", parseFloat(parseFloat(finalNca.toFixed(2))))
    // console.log("cart total =>", parseFloat(sessionStorage.getItem("cartTotal")))

    // const subTotal =
    //   parseFloat(finalTax).toFixed(2) -
    //   parseFloat(couponDiscount.toFixed(2)) +
    //   parseFloat(storeDeliveryFee.toFixed(2)) +
    //   parseFloat(tip.toFixed(2)) +
    //   parseFloat(finalNca.toFixed(2)) +
    //   parseFloat(sessionStorage.getItem("cartTotal"));
    // console.log("tip", tip)
    // console.log("tip type", typeof tip)
    // const subTotal =
    //   parseFloat(parseFloat(finalTax).toFixed(2)) -
    //   parseFloat(parseFloat(couponDiscount.toFixed(2))) +
    //   parseFloat(parseFloat(tip.toFixed(2))) +
    //   parseFloat(parseFloat(finalNca.toFixed(2))) +
    //   parseFloat(sessionStorage.getItem("cartTotal"));

    // // console.log("subTotal", subTotal)
    // sessionStorage.setItem("isSubTotal", subTotal.toFixed(2))
    // sessionStorage.setItem("isStoreDeliveryFee", parseFloat(storeDeliveryFee.toFixed(2)))\

    const guestFlag = sessionStorage.getItem("guestFlag");
    if (guestFlag === "N") {
      let checkNonLoyaltyProduct = 0; // for when user want to create order at least one item must in cart, which is loyaly is "N"
      for (let i = 0; i < cartItem.length; i++) {
        if (cartItem[i].product_data.loyalty === "N") {
          checkNonLoyaltyProduct++;
        }
      }

      if (checkNonLoyaltyProduct > 0) {
        sessionStorage.setItem("GrandTotal", grandTotal);
        handleShowOrderType();
      } else {
        Swal.fire({
          title:
            "Your cart must contain items with a total value greater than $0.00 to proceed. Please review your cart and try again.",
          allowOutsideClick: false,
        });
      }
    } else {
      sessionStorage.setItem("GrandTotal", grandTotal);
      dbconn.deleteUserFromIndexedDB(4);
      handleShowOrderType();
    }
  };

  const addCoupon = () => {
    // console.log("cartItem", cartItem)
    if (couponInput.trim().length === 0) {
      Swal.fire({
        title: i18n.t("enter_coupon_code"),
        showCancelButton: false,
        confirmButtonText: t("ok"),
        allowOutsideClick: false,
        customClass: {
          confirmButton: "my-confirm-button",
        },
      });
    } else {
      setOpen(true);
      // let sub_amount

      const guestFlag = sessionStorage.getItem("guestFlag");

      if (guestFlag) {
        //`${API_ROOT}${Method.VERIFY_COUPON}?guestFlag=${guestFlag}&store_id=${storeDetails.store_id}&coupon=${couponInput.trim()}&timezone=${moment.tz.guess()}&subtotal=${parseFloat(cartReduxTotal)}`
        fetch(
          `${API_ROOT}${Method.VERIFY_COUPON}?guestFlag=${guestFlag}&store_id=${
            storeDetails.store_id
          }&coupon=${couponInput.trim()}&timezone=${moment.tz.guess()}&subtotal=${parseFloat(
            cartReduxTotal
          ).toFixed(2)}`
        )
          .then((response) => {
            if (response.status === 403 || response.status === 401) {
              // Handle 403 error
              setOpen(false);
              // toast.error("Please login", {
              //   duration: 1000,
              // })
              dbconn.deleteUserFromIndexedDB(4);
              sessionStorage.setItem("guestFlag", "Y");
              navigate("/login"); // or redirect to a relevant page
              // return
            }
            return response.json();
          })
          .then((data) => {
            setOpen(false);
            if (data.status == 1) {
              const coupon = data.coupon[0];
              // console.log("coupon", coupon)
              sessionStorage.setItem("couponDetails", JSON.stringify(coupon));
              sessionStorage.setItem("couponApplied", "Y");
              sessionStorage.setItem(
                "couponCode",
                coupon.coupon_code.toUpperCase()
              );
              // sessionStorage.setItem("couponID", coupon.coupon_id);
              // sessionStorage.setItem("couponDesc", coupon.coupon_desc);
              // sessionStorage.setItem("couponType", coupon.coupon_type);
              // sessionStorage.setItem("couponMinSubtotal", coupon.coupon_min_subtotal);
              // sessionStorage.setItem("couponDeliveryType", coupon.coupon_delivery_type);
              // sessionStorage.setItem("couponDiscount", coupon.coupon_discount);
              // sessionStorage.setItem("couponDiscountType", coupon.coupon_discount_type);
              // sessionStorage.setItem("couponProductDetails", coupon.product_details);
              // sessionStorage.setItem("couponDiscountedProductDetails", coupon.product_discounted_details);
              setCouponInputContainer("none");
              setAppliedCouponContainer("inline-table");
              setAppliedCouponText(coupon.coupon_code.toUpperCase());
              // setDeleteG(true);
              // calculateCoupon();
              calculateCoupon();
            } else {
              sessionStorage.setItem("couponApplied", "N");
              sessionStorage.removeItem("couponCode");
              sessionStorage.removeItem("couponDetails");
              const sub_amount = data.message.split(" ").slice(-1)[0];

              if (data.message == "COUPON CODE NOT FOUND/INVALID.") {
                Swal.fire({
                  title: t("coupon_code_not_found"),
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
              } else if (
                data.message ==
                "You can not redeem this coupon more than 1 times."
              ) {
                Swal.fire({
                  title: t("you_can_not_redeem_this_coupon"),
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
              } else if (
                data.message ==
                "This coupon has reached it's maximum redemption value."
              ) {
                Swal.fire({
                  title: t("coupon_has_reached_maximum_value"),
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
              } else if (
                data.message ==
                `You can not redeem this coupon because subtotal is less than ${sub_amount}`
              ) {
                Swal.fire({
                  title: t("subtotal_less_then", { amount: sub_amount }),
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
              } else {
                Swal.fire({
                  title: data.message,
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
              }
            }
          });
      }

      // if (!sessionStorage.getItem("guestFlag") || sessionStorage.getItem("guestFlag") == "Y") {
      //   // var subtotal = sessionStorage.getItem("cartTotal");
      //   var subtotal = cartReduxTotal;
      //   var url =
      //     API_ROOT +
      //     Method.VERIFY_COUPON +
      //     "?guestFlag=Y&store_id=" +
      //     localStorage.getItem("store_id") +
      //     "&coupon=" +
      //     // $("#couponInput").val().trim() +
      //     couponInput.trim() +
      //     "&timezone=" +
      //     moment.tz.guess() +
      //     "&subtotal=" +
      //     subtotal;

      //   fetch(url)
      //     .then((response) => {
      //       return response.json();
      //     })
      //     .then((coupon) => {
      //       onCloseModal();
      //       if (coupon.status == 1) {
      //         // sub_amount = coupon.message.split(' ').slice(-1)[0]
      //         sessionStorage.setItem("couponApplied", "Y");
      //         sessionStorage.setItem("couponID", coupon.coupon[0].coupon_id);
      //         sessionStorage.setItem(
      //           "couponCode",
      //           coupon.coupon[0].coupon_code.toUpperCase()
      //         );
      //         sessionStorage.setItem(
      //           "couponType",
      //           coupon.coupon[0].coupon_type
      //         );
      //         sessionStorage.setItem(
      //           "couponMinSubtotal",
      //           coupon.coupon[0].coupon_min_subtotal
      //         );
      //         sessionStorage.setItem(
      //           "couponDeliveryType",
      //           coupon.coupon[0].coupon_delivery_type
      //         );
      //         sessionStorage.setItem(
      //           "couponDiscount",
      //           coupon.coupon[0].coupon_discount
      //         );
      //         sessionStorage.setItem(
      //           "couponProductDetails",
      //           coupon.coupon[0].product_details
      //         );
      //         sessionStorage.setItem(
      //           "couponDiscountedProductDetails",
      //           coupon.coupon[0].product_discounted_details
      //         );
      //         setCouponInputContainer("none");
      //         setAppliedCouponContainer("inline-table");
      //         setAppliedCouponText(coupon.coupon[0].coupon_code.toUpperCase());
      //         setDeleteG(true);
      //         calculateCoupon();
      //       } else {
      //         sub_amount = coupon.message.split(' ').slice(-1)[0]

      //         if (coupon.message == "COUPON CODE NOT FOUND/INVALID.") {
      //           Swal.fire({
      //             title: t('coupon_code_not_found'),
      //             allowOutsideClick: false,
      //           });
      //         } else if (coupon.message == "You can not redeem this coupon more than 1 times.") {
      //           Swal.fire({
      //             title: t('you_can_not_redeem_this_coupon'),
      //             allowOutsideClick: false,
      //           });
      //         } else if (coupon.message == "This coupon has reached it's maximum redemption value.") {
      //           Swal.fire({
      //             title: t('coupon_has_reached_maximum_value'),
      //             allowOutsideClick: false,
      //           });
      //         } else if (coupon.message == `You can not redeem this coupon because subtotal is less than ${sub_amount}`) {
      //           Swal.fire({
      //             title: t('subtotal_less_then', { amount: sub_amount }),
      //             allowOutsideClick: false,
      //           });
      //         } else {
      //           Swal.fire({
      //             title: coupon.message,
      //             allowOutsideClick: false,
      //           });
      //         }

      //       }
      //     });
      // } else {
      //   dbconn.getUser().then((data) => {
      //     // getAllStore().then((data) => {
      //     if (!data || data.length < 1) {
      //       setNosessionredirect(true);
      //     } else {
      //       var customer_id = data[0].customer_id;
      //       var customer_token = data[0].customer_token;

      //       // var subtotal = sessionStorage.getItem("cartTotal");
      //       var subtotal = cartReduxTotal;
      //       onOpenModal();
      //       url =
      //         API_ROOT +
      //         Method.VERIFY_COUPON +
      //         "?guestFlag=N&customer_id=" +
      //         customer_id +
      //         "&customer_token=" +
      //         customer_token +
      //         "&store_id=" +
      //         localStorage.getItem("store_id") +
      //         "&coupon=" +
      //         couponInput.trim() +
      //         "&timezone=" +
      //         moment.tz.guess() +
      //         "&subtotal=" +
      //         subtotal;

      //       fetch(url)
      //         .then((response) => {
      //           return response.json();
      //         })
      //         .then((coupon) => {
      //           onCloseModal();
      //           if (coupon.status == 1) {
      //             sessionStorage.setItem("couponApplied", "Y");
      //             sessionStorage.setItem(
      //               "couponID",
      //               coupon.coupon[0].coupon_id
      //             );
      //             sessionStorage.setItem(
      //               "couponCode",
      //               coupon.coupon[0].coupon_code.toUpperCase()
      //             );
      //             sessionStorage.setItem(
      //               "couponType",
      //               coupon.coupon[0].coupon_type
      //             );
      //             sessionStorage.setItem(
      //               "couponMinSubtotal",
      //               coupon.coupon[0].coupon_min_subtotal
      //             );
      //             sessionStorage.setItem(
      //               "couponDeliveryType",
      //               coupon.coupon[0].coupon_delivery_type
      //             );
      //             sessionStorage.setItem(
      //               "couponDiscount",
      //               coupon.coupon[0].coupon_discount
      //             );
      //             sessionStorage.setItem(
      //               "couponProductDetails",
      //               coupon.coupon[0].product_details
      //             );
      //             sessionStorage.setItem(
      //               "couponDiscountedProductDetails",
      //               coupon.coupon[0].product_discounted_details
      //             );
      //             setCouponInputContainer("none");
      //             setAppliedCouponContainer("inline-table");
      //             setAppliedCouponText(
      //               coupon.coupon[0].coupon_code.toUpperCase()
      //             );
      //             setDeleteG(true);
      //             calculateCoupon();
      //           } else {
      //             sub_amount = coupon.message.split(' ').slice(-1)[0]
      //             if (coupon.message == "COUPON CODE NOT FOUND/INVALID.") {
      //               Swal.fire({
      //                 title: t('coupon_code_not_found'),
      //                 allowOutsideClick: false,
      //               });
      //             } else if (coupon.message == "You can not redeem this coupon more than 1 times.") {
      //               Swal.fire({
      //                 title: t('you_can_not_redeem_this_coupon'),
      //                 allowOutsideClick: false,
      //               });
      //             } else if (coupon.message == "This coupon has reached it's maximum redemption value.") {
      //               Swal.fire({
      //                 title: t('coupon_has_reached_maximum_value'),
      //                 allowOutsideClick: false,
      //               });
      //             } else if (coupon.message == `You can not redeem this coupon because subtotal is less than ${sub_amount}`) {
      //               Swal.fire({
      //                 title: t('subtotal_less_then', { amount: sub_amount }),
      //                 allowOutsideClick: false,
      //               });
      //             } else {
      //               Swal.fire({
      //                 title: coupon.message,
      //                 allowOutsideClick: false,
      //               });
      //             }
      //           }
      //         });
      //     }
      //   });
      // }
    }
  };

  const cancelCoupon = () => {
    const productItem = JSON.parse(JSON.stringify(cartItem));

    let removeCoupon = [];

    for (let i = 0; i < productItem.length; i++) {
      if (productItem[i].product_data.coupon_type != undefined) {
        if (
          productItem[i].product_data.coupon_type === "H" ||
          productItem[i].product_data.coupon_type === "G"
        ) {
        } else {
          delete productItem[i].product_data.coupon_type;
          delete productItem[i].product_data.product_discount;
          delete productItem[i].product_data.product_discount_type;
          removeCoupon.push(productItem[i]);
        }
      } else {
        removeCoupon.push(productItem[i]);
      }

      if (removeCoupon.length > 0) {
        dispatch(clearCart());

        for (let i = 0; i < removeCoupon.length; i++) {
          let createNewOrder = JSON.parse(JSON.stringify(removeCoupon[i]));
          dispatch(
            addToCart({
              product_data: { ...createNewOrder.product_data },
              dropdown_mod: [...createNewOrder.dropdown_mod],
            })
          );
        }

        sessionStorage.removeItem("couponID");
        sessionStorage.removeItem("couponDesc");
        // sessionStorage.removeItem("couponDetails");
        sessionStorage.removeItem("totalDiscount");
        sessionStorage.removeItem("couponType");
        sessionStorage.removeItem("couponCode");
        sessionStorage.removeItem("couponMinSubtotal");
        sessionStorage.removeItem("couponDeliveryType");
        sessionStorage.removeItem("couponDiscount");
        sessionStorage.removeItem("couponDiscountType");
        sessionStorage.removeItem("couponProductDetails");
        sessionStorage.removeItem("couponDiscountedProductDetails");
        sessionStorage.setItem("couponApplied", "N");

        setCouponInput("");
        setCouponInputContainer("inline-table");
        setAppliedCouponContainer("none");
        setAppliedCouponText("");
        // setCouponDiscount(0);
      }
    }

    calculateNCA1(cartReduxTotal.toFixed(2));
  };

  const calculateCoupon = () => {
    if (cartReduxTotal > 0) {
      const couponApplied = sessionStorage.getItem("couponApplied");
      if (couponApplied) {
        if (couponApplied === "Y") {
          const couponDetails = JSON.parse(
            sessionStorage.getItem("couponDetails")
          );
          if (couponDetails.coupon_min_subtotal <= cartReduxTotal) {
            sessionStorage.removeItem("totalDiscount");
            sessionStorage.removeItem("orderList");

            // ************ When coupon type "A"
            if (couponDetails.coupon_type === "A") {
              // setCouponDiscount(couponDetails.coupon_discount);
              sessionStorage.setItem(
                "totalDiscount",
                couponDetails.coupon_discount
              );
              sessionStorage.setItem("couponType", "A");
              // sessionStorage.setItem("orderList", JSON.stringify(cartItem))
              toast.dismiss();
              toast.success(i18n.t("coupon_success"), {
                duration: 2000,
              });
            }

            // ************ When coupon type "B"
            if (couponDetails.coupon_type === "B") {
              const discount =
                (Number(parseFloat(cartReduxTotal.toFixed(2))) *
                  Number(parseFloat(couponDetails.coupon_discount))) /
                100;
              // setCouponDiscount(discount);
              sessionStorage.setItem("totalDiscount", discount);
              sessionStorage.setItem("couponType", "B");
              // sessionStorage.setItem("orderList", JSON.stringify(cartItem))
              toast.dismiss();
              toast.success(i18n.t("coupon_success"), {
                duration: 2000,
              });
            }

            // ************ When coupon type "C"
            if (couponDetails.coupon_type === "C") {
              // setCouponDiscount(couponDetails.coupon_discount);
              sessionStorage.setItem(
                "totalDiscount",
                couponDetails.coupon_discount
              );
              sessionStorage.setItem("couponType", "C");

              // sessionStorage.setItem("orderList", JSON.stringify(cartItem))
              toast.dismiss();
              toast.success(i18n.t("coupon_success"), {
                duration: 2000,
              });
            }

            // ************ When coupon type "D"
            if (couponDetails.coupon_type === "D") {
              const discount =
                (Number(parseFloat(store.store_delivery_fee.toFixed(2))) *
                  Number(parseFloat(couponDetails.coupon_discount))) /
                100;
              // setCouponDiscount(discount);
              sessionStorage.setItem("totalDiscount", discount);
              sessionStorage.setItem("couponType", "D");
              toast.dismiss();
              toast.success(i18n.t("coupon_success"), {
                duration: 2000,
              });
            }

            // ************ When coupon type "E"
            if (couponDetails.coupon_type === "E") {
              let couponProducts = JSON.parse(couponDetails.product_details);
              let orderProducts = cartItem.map((item) => {
                return item;
              });

              // console.log("couponProducts", couponProducts)
              // console.log("orderProducts", orderProducts)

              let productMatchCounter = 0;
              let productArray = [];
              var totalLoyalty = 0;
              let selectedProductTotal = 0;
              for (var i = 0; i < orderProducts.length; i++) {
                for (var j = 0; j < couponProducts.length; j++) {
                  productArray.push(couponProducts[j].product_name);

                  let orderProductCopy = JSON.parse(
                    JSON.stringify(orderProducts[i])
                  );

                  if (
                    orderProductCopy.product_data.product_id ==
                      couponProducts[j].product_id &&
                    orderProductCopy.product_data.loyalty == "N" &&
                    couponProducts[j].coupon_details_type == "Q"
                  ) {
                    orderProductCopy.product_data.product_discount =
                      couponDetails.coupon_discount;
                    orderProductCopy.product_data.product_discount_type =
                      couponDetails.coupon_discount_type;
                    orderProductCopy.product_data.coupon_type =
                      couponDetails.coupon_type;

                    selectedProductTotal +=
                      Number(
                        parseFloat(orderProductCopy.product_data.product_price)
                      ) *
                      Number(
                        parseFloat(
                          orderProductCopy.product_data.product_quantity
                        )
                      );
                    productMatchCounter += Number(
                      parseFloat(orderProductCopy.product_data.product_quantity)
                    );
                  }

                  if (orderProductCopy.product_data.loyalty === "Y") {
                    totalLoyalty +=
                      Number(
                        parseFloat(
                          orderProductCopy.product_data.product_loyalty_price
                        )
                      ) *
                      Number(
                        parseFloat(
                          orderProductCopy.product_data.product_quantity
                        )
                      );
                  }

                  // Update the product_data in the original array
                  orderProducts[i] = orderProductCopy;
                }
              }

              if (productMatchCounter > 0 && selectedProductTotal > 0) {
                sessionStorage.removeItem("totalDiscount");
                toast.dismiss();
                toast.success(i18n.t("coupon_success"));

                const totalDiscount =
                  (Number(parseFloat(selectedProductTotal)) *
                    Number(parseFloat(couponDetails.coupon_discount))) /
                  100;
                sessionStorage.setItem(
                  "totalDiscount",
                  totalDiscount.toFixed(2)
                );
                sessionStorage.setItem("couponType", "E");

                setTotalLoyaltyPoints(totalLoyalty);
                // console.log("totalLoyalty", totalLoyalty)
              } else {
                Swal.fire({
                  title:
                    i18n.t("coupon_item_invalid") +
                    " " +
                    productArray.join(","),
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
                cancelCoupon();
              }
              // });
            }

            // ************ When coupon type "F" & "I"
            if (
              couponDetails.coupon_type === "I" ||
              couponDetails.coupon_type === "F"
            ) {
              let couponProducts = JSON.parse(couponDetails.product_details);
              let orderProducts = cartItem.map((item) => {
                return item;
              });
              // var products = JSON.parse(sessionStorage.getItem("couponProductDetails"));
              // console.log(products);
              var productMatchCounter = 0;
              var productArray = [];
              let selectedProductTotal = 0;
              for (var i = 0; i < orderProducts.length; i++) {
                for (var j = 0; j < couponProducts.length; j++) {
                  productArray.push(couponProducts[j].product_name);
                  // if (orderProducts[i].product_data.product_id == couponProducts[j].product_id && orderProducts[i].product_data.loyalty == "N" && couponProducts[j].coupon_details_type == "Q") {
                  //   orderProducts[i].product_data.product_discount = couponDetails.coupon_discount;
                  //   orderProducts[i].product_data.product_discount_type = couponDetails.coupon_discount_type
                  //   orderProducts[i].product_data.coupon_type = couponDetails.coupon_type
                  //   selectedProductTotal += Number(parseFloat(orderProducts[i].product_data.product_price))
                  //   productMatchCounter = productMatchCounter + Number(parseFloat(orderProducts[i].product_data.product_quantity))
                  // }
                  let orderProductCopy = JSON.parse(
                    JSON.stringify(orderProducts[i])
                  );

                  if (
                    orderProductCopy.product_data.product_id ==
                      couponProducts[j].product_id &&
                    orderProductCopy.product_data.loyalty == "N" &&
                    couponProducts[j].coupon_details_type == "Q"
                  ) {
                    orderProductCopy.product_data.product_discount =
                      couponDetails.coupon_discount;
                    orderProductCopy.product_data.product_discount_type =
                      couponDetails.coupon_discount_type;
                    orderProductCopy.product_data.coupon_type =
                      couponDetails.coupon_type;

                    selectedProductTotal += Number(
                      parseFloat(orderProductCopy.product_data.product_price)
                    );
                    productMatchCounter += Number(
                      parseFloat(orderProductCopy.product_data.product_quantity)
                    );
                  }

                  // Handling loyalty logic
                  if (orderProductCopy.product_data.loyalty === "Y") {
                    totalLoyalty +=
                      Number(
                        parseFloat(
                          orderProductCopy.product_data.product_loyalty_price
                        )
                      ) *
                      Number(
                        parseFloat(
                          orderProductCopy.product_data.product_quantity
                        )
                      );
                  }

                  // Update the product_data in the original array
                  orderProducts[i] = orderProductCopy;
                }
              }

              if (productMatchCounter > 0 && selectedProductTotal > 0) {
                // toast.success(i18n.t("coupon_success"))
                if (
                  couponDetails.coupon_type === "F" &&
                  couponDetails.coupon_discount_type === "P"
                ) {
                  const discountCalculate =
                    (Number(parseFloat(cartReduxTotal.toFixed(2))) *
                      Number(parseFloat(couponDetails.coupon_discount))) /
                    100;
                  sessionStorage.setItem(
                    "totalDiscount",
                    discountCalculate.toFixed(2)
                  );
                  sessionStorage.setItem("couponType", "F");
                  // setCouponDiscount(discountCalculate.toFixed(2));
                  toast.dismiss();
                  toast.success(i18n.t("coupon_success"));
                } else {
                  if (couponDetails.coupon_type === "I") {
                    if (selectedProductTotal > 0) {
                      // setCouponDiscount(couponDetails.coupon_discount);
                      sessionStorage.setItem(
                        "totalDiscount",
                        couponDetails.coupon_discount
                      );
                      sessionStorage.setItem("couponType", "I");
                      toast.dismiss();
                      toast.success(i18n.t("coupon_success"));
                    } else {
                      Swal.fire({
                        title: `Min order total require more then ${couponDetails.coupon_min_subtotal}`,
                        showCancelButton: false,
                        confirmButtonText: t("ok"),
                        allowOutsideClick: false,
                        customClass: {
                          confirmButton: "my-confirm-button",
                        },
                      });
                      cancelCoupon();
                    }
                  }
                  // setCouponDiscount()
                }
              } else {
                Swal.fire({
                  title:
                    i18n.t("coupon_item_invalid") +
                    " " +
                    productArray.join(","),
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
                cancelCoupon();
              }
              // });
            }

            // ************ When coupon type "J" & "K"
            if (
              couponDetails.coupon_type === "J" ||
              couponDetails.coupon_type === "K"
            ) {
              let couponProducts = JSON.parse(couponDetails.product_details);
              let orderProducts = cartItem.map((item) => {
                return item;
              });

              let productMatchCounter = 0;
              let productArray = [];
              let selectedProductTotal = 0;
              for (var i = 0; i < orderProducts.length; i++) {
                for (var j = 0; j < couponProducts.length; j++) {
                  productArray.push(couponProducts[j].product_name);
                  // if (orderProducts[i].product_data.product_id == couponProducts[j].product_id && orderProducts[i].product_data.product_loyalty_enabled == "N" && couponProducts[j].coupon_details_type == "Q") {
                  //   orderProducts[i].product_data.product_discount = couponDetails.coupon_discount;
                  //   orderProducts[i].product_data.product_discount_type = couponDetails.coupon_discount_type
                  //   orderProducts[i].product_data.coupon_type = couponDetails.coupon_type
                  //   selectedProductTotal += Number(parseFloat(orderProducts[i].product_data.product_price))
                  //   productMatchCounter = productMatchCounter + Number(parseFloat(orderProducts[i].product_data.product_quantity));
                  // }
                  let orderProductCopy = JSON.parse(
                    JSON.stringify(orderProducts[i])
                  );

                  if (
                    orderProductCopy.product_data.product_id ==
                      couponProducts[j].product_id &&
                    orderProductCopy.product_data.loyalty == "N" &&
                    couponProducts[j].coupon_details_type == "Q"
                  ) {
                    orderProductCopy.product_data.product_discount =
                      couponDetails.coupon_discount;
                    orderProductCopy.product_data.product_discount_type =
                      couponDetails.coupon_discount_type;
                    orderProductCopy.product_data.coupon_type =
                      couponDetails.coupon_type;

                    selectedProductTotal +=
                      Number(
                        parseFloat(orderProductCopy.product_data.product_price)
                      ) *
                      Number(
                        parseFloat(
                          orderProductCopy.product_data.product_quantity
                        )
                      );
                    productMatchCounter += Number(
                      parseFloat(orderProductCopy.product_data.product_quantity)
                    );
                  }

                  // Handling loyalty logic
                  if (orderProductCopy.product_data.loyalty === "Y") {
                    totalLoyalty +=
                      Number(
                        parseFloat(
                          orderProductCopy.product_data.product_loyalty_price
                        )
                      ) *
                      Number(
                        parseFloat(
                          orderProductCopy.product_data.product_quantity
                        )
                      );
                  }

                  // Update the product_data in the original array
                  orderProducts[i] = orderProductCopy;
                }
              }

              if (productMatchCounter > 0 && selectedProductTotal > 0) {
                if (
                  couponDetails.coupon_type === "K" &&
                  couponDetails.coupon_discount_type === "P"
                ) {
                  const discountCalculate =
                    (Number(parseFloat(selectedProductTotal.toFixed(2))) *
                      Number(parseFloat(couponDetails.coupon_discount))) /
                    100;
                  sessionStorage.setItem(
                    "totalDiscount",
                    discountCalculate.toFixed(2)
                  );
                  sessionStorage.setItem("couponType", "K");
                  // setCouponDiscount(discountCalculate.toFixed(2));
                  toast.dismiss();
                  toast.success(i18n.t("coupon_success"));
                } else {
                  if (couponDetails.coupon_type === "J") {
                    // if (selectedProductTotal > 0 && couponDetails.coupon_min_subtotal < selectedProductTotal) {
                    // setCouponDiscount(couponDetails.coupon_discount);
                    if (selectedProductTotal > couponDetails.coupon_discount) {
                      sessionStorage.setItem(
                        "totalDiscount",
                        couponDetails.coupon_discount
                      );
                      sessionStorage.setItem("couponType", "J");
                      toast.dismiss();
                      toast.success(i18n.t("coupon_success"));
                    } else {
                      // `Total price of qualifying items ($${totalQualifyingItemsPrice}) is less than the discount amount ($${discountAmount}).`
                      Swal.fire({
                        title: t("coupon_j_error", {
                          totalQualifyingItemsPrice: selectedProductTotal,
                          discountAmount: couponDetails.coupon_discount,
                        }),
                        showCancelButton: false,
                        confirmButtonText: t("ok"),
                        allowOutsideClick: false,
                        customClass: {
                          confirmButton: "my-confirm-button",
                        },
                      });
                      cancelCoupon();
                    }

                    // } else {
                    //   Swal.fire({
                    //     title: `Min order total requre more then ${couponDetails.coupon_min_subtotal}`,
                    //     allowOutsideClick: false,
                    //   });
                    //   cancelCoupon()
                    // }
                  }
                  // setCouponDiscount()
                }
              } else {
                Swal.fire({
                  title:
                    i18n.t("coupon_item_invalid") +
                    " " +
                    productArray.join(","),
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
                cancelCoupon();
              }
              // });
            }

            // ************ When coupon type "H" //BOGO
            if (couponDetails.coupon_type === "H") {
              let couponProducts = JSON.parse(couponDetails.product_details);
              let orderProducts = JSON.parse(JSON.stringify(cartItem)); // Create deep copy of cart items

              let matchProductCounter = 0;
              let productArray = [];

              for (let i = 0; i < orderProducts.length; i++) {
                for (let j = 0; j < couponProducts.length; j++) {
                  productArray.push(couponProducts[j].product_name);
                  if (
                    orderProducts[i].product_data.product_id ===
                      couponProducts[j].product_id &&
                    orderProducts[i].product_data.loyalty === "N" &&
                    couponProducts[j].coupon_details_type === "Q"
                  ) {
                    let discountedProduct = JSON.parse(
                      JSON.stringify(orderProducts[i])
                    ); // Create a copy of the order product

                    // Apply 100% discount logic
                    if (
                      couponDetails.coupon_discount_type === "P" &&
                      couponDetails.coupon_discount === 100
                    ) {
                      discountedProduct.product_data.modifier_original_price = 0;
                      discountedProduct.product_data.modifier_price = 0;
                      discountedProduct.product_data.product_discount = 100;
                      discountedProduct.product_data.product_discount_type =
                        couponDetails.coupon_discount_type;
                      discountedProduct.product_data.product_price = 0;
                      discountedProduct.product_data.product_original_price = 0;
                      discountedProduct.product_data.coupon_type =
                        couponDetails.coupon_type;

                      // Dispatch action to add the product to the cart with the discount
                      dispatch(
                        addToCart({
                          product_data: { ...discountedProduct.product_data },
                          dropdown_mod: [...discountedProduct.dropdown_mod],
                        })
                      );
                    } else {
                      // Apply partial discount
                      discountedProduct.product_data.product_discount =
                        couponDetails.coupon_discount;
                      discountedProduct.product_data.product_discount_type =
                        couponDetails.coupon_discount_type;
                      discountedProduct.product_data.modifier_price =
                        parseFloat(
                          (
                            (orderProducts[i].product_data.modifier_price *
                              (100 - couponDetails.coupon_discount)) /
                            100
                          ).toFixed(2)
                        );
                      discountedProduct.product_data.modifier_original_price =
                        parseFloat(
                          (
                            (orderProducts[i].product_data
                              .modifier_original_price *
                              (100 - couponDetails.coupon_discount)) /
                            100
                          ).toFixed(2)
                        );
                      discountedProduct.product_data.product_price = parseFloat(
                        (
                          (orderProducts[i].product_data.product_price *
                            (100 - couponDetails.coupon_discount)) /
                          100
                        ).toFixed(2)
                      );
                      discountedProduct.product_data.product_original_price =
                        parseFloat(
                          (
                            (orderProducts[i].product_data
                              .product_original_price *
                              (100 - couponDetails.coupon_discount)) /
                            100
                          ).toFixed(2)
                        );
                      discountedProduct.product_data.coupon_type =
                        couponDetails.coupon_type;

                      // Dispatch action to add the product to the cart with the discount
                      dispatch(
                        addToCart({
                          product_data: { ...discountedProduct.product_data },
                          dropdown_mod: [...discountedProduct.dropdown_mod],
                        })
                      );
                    }

                    // Increment match product counter
                    matchProductCounter +=
                      orderProducts[i].product_data.product_quantity;
                  }
                }
              }
              sessionStorage.setItem("totalDiscount", "0.00");
              sessionStorage.setItem("couponType", "H");

              if (matchProductCounter === 0) {
                Swal.fire({
                  title:
                    i18n.t("coupon_item_invalid") +
                    " " +
                    productArray.join(","),
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
                cancelCoupon();
              }

              // setCouponDiscount(0.00); // If needed in your component
            }

            // ************ When coupon type "G" //BXGY
            if (couponDetails.coupon_type === "G") {
              // cancelCoupon();
              let couponProducts = JSON.parse(couponDetails.product_details); // Products that qualify for the offer
              // console.log("couponProducts", couponProducts)
              let discountedProducts = JSON.parse(
                couponDetails.product_discounted_details
              ); // Products offered as discounted/free
              // console.log("discountedProducts", discountedProducts)
              let orderProducts = JSON.parse(JSON.stringify(cartItem)); // Deep copy of cart items
              // console.log("orderProducts", orderProducts)
              let buyXproduct = [];
              let productArray = [];
              let matchProductQty = 0;

              for (let i = 0; i < orderProducts.length; i++) {
                for (let j = 0; j < couponProducts.length; j++) {
                  productArray.push(couponProducts[j].product_name);
                  if (
                    orderProducts[i].product_data.product_id ===
                      couponProducts[j].product_id &&
                    orderProducts[i].product_data.loyalty === "N"
                  ) {
                    buyXproduct.push(orderProducts[i]);
                    matchProductQty +=
                      orderProducts[i].product_data.product_quantity;
                  }
                }
              }

              // console.log("matchProductQty", matchProductQty)
              if (buyXproduct.length > 0 && matchProductQty > 0) {
                for (let x = 0; x < discountedProducts.length; x++) {
                  const discountItem = JSON.parse(
                    JSON.stringify(discountedProducts[0])
                  );

                  if (
                    couponDetails.coupon_discount_type === "P" &&
                    couponDetails.coupon_discount === 100
                  ) {
                    discountItem.modifier_price = 0;
                    discountItem.product_discount = 100;
                    discountItem.product_quantity = matchProductQty;
                    discountItem.product_discount_type =
                      couponDetails.coupon_discount_type;
                    discountItem.product_price = 0;
                    discountItem.product_original_price = 0;
                    discountItem.coupon_type = couponDetails.coupon_type;
                    discountItem.loyalty = "N";
                    delete discountItem.product_modifiers;

                    // Dispatch action to add the product to the cart with the discount
                    dispatch(
                      addToCart({
                        product_data: { ...discountItem },
                        dropdown_mod: [...discountItem.product_modifiers],
                      })
                    );
                  } else {
                    discountItem.product_discount =
                      couponDetails.coupon_discount;
                    discountItem.product_discount =
                      couponDetails.coupon_discount;
                    discountItem.product_quantity = matchProductQty;
                    discountItem.product_discount_type =
                      couponDetails.coupon_discount_type;
                    discountItem.product_price = parseFloat(
                      (
                        (discountItem.product_price *
                          (100 - couponDetails.coupon_discount)) /
                        100
                      ).toFixed(2)
                    );
                    discountItem.product_original_price =
                      discountItem.product_price;
                    discountItem.modifier_price = 0;
                    discountItem.loyalty = "N";
                    discountItem.coupon_type = couponDetails.coupon_type;
                    delete discountItem.product_modifiers;

                    // Dispatch action to add the product to the cart with the discount
                    // console.log("discountItem", discountItem)
                    dispatch(
                      addToCart({
                        product_data: { ...discountItem },
                        dropdown_mod: [],
                      })
                    );
                  }
                }

                sessionStorage.setItem("totalDiscount", "0.00");
                sessionStorage.setItem("couponType", "G");

                // console.log("cart Item", cartItem)
              } else {
                Swal.fire({
                  title:
                    i18n.t("coupon_item_invalid") +
                    " " +
                    productArray.join(","),
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
                cancelCoupon();
              }

              // // let tempBuyXProduct = []
              // // for (let a = 0; a < buyXproduct.length; a++) {
              // //   for (let b = 0; b < getYproduct.length; b++) {
              // //     if (buyXproduct[a].product_data.product_id == getYproduct[b].product_data.product_id) {
              // //       isSameProduct.push(buyXproduct[a])
              // //       // buyXproduct.splice([a], 1)
              // //       buyXproduct.map((item) => {
              // //         if (item.product_data?.product_id != buyXproduct[a].product_data.product_id) {
              // //           tempBuyXProduct.push(item)
              // //         }
              // //       })
              // //     }
              // //   }
              // // }

              // const tempBuyXProduct = buyXproduct.filter(buyItem => {
              //   const match = getYproduct.some(getItem =>
              //     buyItem.product_data.product_id === getItem.product_data.product_id
              //   );
              //   if (match) {
              //     isSameProduct.push(buyItem);
              //   }
              //   return !match; // Keep items not matching
              // });

              // // Create tempGetYProduct based on remaining getYproduct
              // const tempGetYProduct = getYproduct.filter(getItem =>
              //   !buyXproduct.some(buyItem =>
              //     buyItem.product_data.product_id === getItem.product_data.product_id
              //   )
              // );
              // console.log("tempBuyXProduct", tempBuyXProduct)
              // console.log("tempGetYProduct", tempGetYProduct)

              // if (tempBuyXProduct.length > 0) {
              //   if (tempGetYProduct.length > 0) {

              //     //if coupon product and discount product same
              //     if (isSameProduct.length > 0) {
              //       for (let x = 0; x < isSameProduct.length; x++) {
              //         const discountItem = JSON.parse(JSON.stringify(isSameProduct[x]))
              //         if (couponDetails.coupon_discount_type === "P" && couponDetails.coupon_discount === 100) {
              //           discountItem.product_data.modifier_original_price = 0;
              //           discountItem.product_data.modifier_price = 0;
              //           discountItem.product_data.product_discount = 100;
              //           discountItem.product_data.product_discount_type = couponDetails.coupon_discount_type;
              //           discountItem.product_data.product_price = 0;
              //           discountItem.product_data.product_original_price = 0;
              //           discountItem.product_data.coupon_type = couponDetails.coupon_type;

              //           // Dispatch action to add the product to the cart with the discount
              //           dispatch(
              //             addToCart({
              //               product_data: { ...discountItem.product_data },
              //               dropdown_mod: [...discountItem.dropdown_mod],
              //             })
              //           );
              //         } else {
              //           discountItem.product_data.product_discount = couponDetails.coupon_discount;
              //           discountItem.product_data.product_discount_type = couponDetails.coupon_discount_type;
              //           discountItem.product_data.modifier_price = parseFloat(
              //             ((isSameProduct[x].product_data.modifier_price * (100 - couponDetails.coupon_discount)) / 100).toFixed(2)
              //           );
              //           discountItem.product_data.modifier_original_price = parseFloat(
              //             ((isSameProduct[x].product_data.modifier_original_price * (100 - couponDetails.coupon_discount)) / 100).toFixed(2)
              //           );
              //           discountItem.product_data.product_price = parseFloat(
              //             ((isSameProduct[x].product_data.product_price * (100 - couponDetails.coupon_discount)) / 100).toFixed(2)
              //           );
              //           discountItem.product_data.product_original_price = parseFloat(
              //             ((isSameProduct[x].product_data.product_original_price * (100 - couponDetails.coupon_discount)) / 100).toFixed(2)
              //           );
              //           discountItem.product_data.coupon_type = couponDetails.coupon_type;

              //           // console.log("discountItem", discountItem)
              //           // Dispatch action to add the product to the cart with the discount
              //           dispatch(
              //             addToCart({
              //               product_data: { ...discountItem.product_data },
              //               dropdown_mod: [...discountItem.dropdown_mod],
              //             })
              //           );
              //         }
              //       }

              //     }

              //     const totalBuyXQuantity = tempBuyXProduct.reduce((total, item) => {
              //       return total + item.product_data.product_quantity;
              //     }, 0);

              //     const totalGetYQuantity = tempGetYProduct.reduce((total, item) => {
              //       return total + item.product_data.product_quantity;
              //     }, 0);

              //     console.log("totalBuyXQuantity", totalBuyXQuantity)
              //     console.log("totalGetYQuantity", totalGetYQuantity)

              //   } else {
              //     Swal.fire({
              //       title:
              //         i18n.t("coupon_item_invalid") +
              //         " " +
              //         discountArray.join(","),
              //       allowOutsideClick: false,
              //     });
              //     cancelCoupon();
              //   }
              // } else {
              //   Swal.fire({
              //     title:
              //       i18n.t("coupon_item_invalid") +
              //       " " +
              //       productArray.join(","),
              //     allowOutsideClick: false,
              //   });
              //   cancelCoupon();
              // }
            }

            calculateNCA1(cartReduxTotal.toFixed(2));
          } else {
            Swal.fire({
              title:
                i18n.t("coupon_amount_invalid") +
                couponDetails.coupon_min_subtotal.toFixed(2),
              showCancelButton: false,
              confirmButtonText: t("ok"),
              allowOutsideClick: false,
              customClass: {
                confirmButton: "my-confirm-button",
              },
            });
            sessionStorage.removeItem("couponDetails");
            cancelCoupon();
          }
        } else {
          console.log("error", "coupon code not applied.");
        }
      } else {
        sessionStorage.removeItem("couponDetails");
      }
    } else {
      Swal.fire({
        title: "Subtotal must be greter then zero when adding a coupon.",
        showCancelButton: false,
        confirmButtonText: t("ok"),
        allowOutsideClick: false,
        customClass: {
          confirmButton: "my-confirm-button",
        },
      });
    }
  };

  // console.log("cartItem", cartItem)

  // const calculateCoupon = () => {
  //   var couponApplied = sessionStorage.getItem("couponApplied");

  //   if (couponApplied) {
  //     // sessionStorage.removeItem('totalDiscount')
  //     var type = sessionStorage.getItem("couponType");
  //     var minSubTotal = parseFloat(sessionStorage.getItem("couponMinSubtotal"));

  //     // setCouponMinSubtotal(minSubTotal);

  //     if (type == "A" || type == "B" || type == "C" || type == "D" || type == "E" || type == "F" || type == "G" || type == "H" || type == "I" || type == "J" || type == "K") {
  //       if (cartReduxTotal < minSubTotal) {
  //         Swal.fire({
  //           title: i18n.t("coupon_amount_invalid") + minSubTotal.toFixed(2),
  //           allowOutsideClick: false,
  //         });

  //         cancelCoupon();
  //       } else {
  //         // console.log("type ==>", type)
  //         if (type == "A") {
  //           toast.success(i18n.t("coupon_success"))
  //           setCouponDiscount(parseFloat(sessionStorage.getItem("couponDiscount")));
  //           sessionStorage.setItem("totalDiscount", parseFloat(sessionStorage.getItem("couponDiscount")))
  //         }
  //         if (type == "B") {
  //           toast.success(i18n.t("coupon_success"))
  //           const couponDiscount = parseFloat(sessionStorage.getItem("couponDiscount"));
  //           const discount = (parseFloat(cartReduxTotal) * couponDiscount) / 100;
  //           setCouponDiscount(discount);
  //         }

  //         if (type == "E") {

  //           let productsCoupon = JSON.parse(sessionStorage.getItem("couponProductDetails"));

  //           var productMatchCounter = 0;
  //           var productArray = [];
  //           for (var i = 0; i < cartItem.length; i++) {
  //             productArray = [];

  //             for (var j = 0; j < productsCoupon.length; j++) {
  //               productArray.push(productsCoupon[j].product_name);

  //               if (
  //                 cartItem[i].product_id == productsCoupon[j].product_id &&
  //                 cartItem[i].product_loyalty_enabled == "N" &&
  //                 productsCoupon[j].coupon_details_type == "Q"
  //               ) {
  //                 productMatchCounter++;
  //               }
  //             }
  //           }

  //           if (productMatchCounter == productsCoupon.length) {
  //             toast.success(i18n.t("coupon_success"))

  //             //calculateTax(false);

  //             // dbconn.getAllOrder().then((productData) => {
  //             var totalLoyalty = 0;

  //             var i = 0;
  //             cartItem.forEach(function (product) {
  //               if (product.loyalty == "Y") {
  //                 totalLoyalty += parseInt(product.product_loyalty_price) * parseInt(product.product_quantity);
  //               }

  //               for (var j = 0; j < productsCoupon.length; j++) {
  //                 if (
  //                   product.product_id == productsCoupon[j].product_id &&
  //                   product.product_loyalty_enabled == "N" &&
  //                   productsCoupon[j].coupon_details_type == "Q"
  //                 ) {
  //                   // dbconn.deleteOrder(parseInt(productData[i].cart_id)).then(data => {

  //                   cartItem[i].product_discount = sessionStorage.getItem("couponDiscount");
  //                   cartItem[i].product_discount_type = sessionStorage.getItem("couponDiscountType");

  //                   dbconn
  //                     .setOrder(
  //                       {
  //                         product_name: product.product_name,
  //                         product_desc: product.product_desc,
  //                         product_name_es: product.product_name_es,
  //                         product_desc_es: product.product_desc_es,
  //                         product_id: product.product_id,
  //                         product_img: product.product_img,
  //                         product_quantity: product.product_quantity,
  //                         product_notes: product.product_notes,
  //                         product_price: product.product_price,
  //                         product_original_price:
  //                           product.product_original_price,
  //                         product_discount:
  //                           sessionStorage.getItem("couponDiscount"),
  //                         product_discount_type: sessionStorage.getItem("couponDiscountType"),
  //                         product_modifiers: product.product_modifiers,
  //                         product_loyalty_enabled:
  //                           product.product_loyalty_enabled,
  //                         product_overage_enabled:
  //                           product.product_overage_enabled,
  //                         product_loyalty_price:
  //                           product.product_loyalty_price,
  //                         mod_price: product.mod_price,
  //                         mod_original_price: product.mod_original_price,
  //                         isModifierAvailable: product.isModifierAvailable,
  //                         cart_id: product.cart_id,
  //                       },
  //                       product.cart_id
  //                     )
  //                     .then((data) => { });
  //                   // });
  //                   //  productMatch = true;
  //                   //break;
  //                 }
  //               }

  //               i++;
  //             });
  //             setTotalLoyaltyPoints(totalLoyalty);
  //             console.log("totalLoyalty", totalLoyalty)
  //             // calculateModifiers(cartItem);
  //             // calculateCartTotal(false);
  //             // });
  //           } else {
  //             Swal.fire({
  //               title:
  //                 i18n.t("coupon_item_invalid") +
  //                 " " +
  //                 productArray.join(","),
  //               allowOutsideClick: false,
  //             });
  //             cancelCoupon();
  //           }
  //           // });
  //         }
  //         if (type == "I" || type == "F") {
  //           dbconn.getAllOrder().then((productData) => {
  //             // getAllOrdersFromStore().then((productData) => {
  //             // console.log(productData);

  //             var products = JSON.parse(
  //               sessionStorage.getItem("couponProductDetails")
  //             );
  //             // console.log(products);
  //             var productMatchCounter = 0;
  //             var productArray = [];
  //             for (var i = 0; i < productData.length; i++) {
  //               productArray = [];

  //               for (var j = 0; j < products.length; j++) {
  //                 productArray.push(products[j].product_name);

  //                 if (
  //                   productData[i].product_id == products[j].product_id &&
  //                   productData[i].product_loyalty_enabled == "N" &&
  //                   products[j].coupon_details_type == "Q"
  //                 ) {
  //                   productMatchCounter++;
  //                 }
  //               }
  //             }

  //             if (productMatchCounter == products.length) {
  //               toast.success(i18n.t("coupon_success"))
  //               if (type == "F") {
  //                 var discount = parseFloat(
  //                   sessionStorage.getItem("couponDiscount")
  //                 );
  //                 discount = (parseFloat(cartTotal) * discount) / 100;
  //                 setCouponDiscount(discount);
  //               } else {
  //                 setCouponDiscount(
  //                   parseFloat(sessionStorage.getItem("couponDiscount"))
  //                 );
  //               }
  //             } else {
  //               Swal.fire({
  //                 title:
  //                   i18n.t("coupon_item_invalid") +
  //                   " " +
  //                   productArray.join(","),
  //                 allowOutsideClick: false,
  //               });
  //               cancelCoupon();
  //             }
  //           });
  //         }

  //         if (type == "J" || type == "K") {
  //           dbconn.getAllOrder().then((productData) => {
  //             // getAllOrdersFromStore().then((productData) => {
  //             // console.log(productData);
  //             var products = JSON.parse(
  //               sessionStorage.getItem("couponProductDetails")
  //             );
  //             // console.log(products);
  //             var productMatchCounter = 0;
  //             var productArray = [];
  //             for (var i = 0; i < productData.length; i++) {
  //               productArray = [];

  //               for (var j = 0; j < products.length; j++) {
  //                 productArray.push(products[j].product_name);

  //                 if (
  //                   productData[i].product_id == products[j].product_id &&
  //                   productData[i].product_loyalty_enabled == "N" &&
  //                   products[j].coupon_details_type == "Q"
  //                 ) {
  //                   productMatchCounter++;
  //                 }
  //               }
  //             }

  //             if (productMatchCounter == products.length) {
  //               var discountType = "";
  //               if (type == "J") {
  //                 discountType = "A";
  //               } else {
  //                 discountType = "P";
  //               }
  //               toast.success(i18n.t("coupon_success"))

  //               //calculateTax(false);

  //               dbconn.getAllOrder().then((productData) => {
  //                 // getAllOrdersFromStore().then((productData) => {
  //                 var totalLoyalty = 0;
  //                 var i = 0;
  //                 productData.forEach(function (product) {
  //                   if (product.loyalty == "Y") {
  //                     totalLoyalty += parseInt(product.product_loyalty_price) * parseInt(product.product_quantity);
  //                   }

  //                   for (var j = 0; j < products.length; j++) {
  //                     if (
  //                       product.product_id == products[j].product_id &&
  //                       product.product_loyalty_enabled == "N" &&
  //                       products[j].coupon_details_type == "Q"
  //                     ) {
  //                       productData[i].product_discount =
  //                         sessionStorage.getItem("couponDiscount");
  //                       productData[i].product_discount_type = discountType;
  //                       dbconn
  //                         .setOrder(
  //                           // setOrderToStore(
  //                           {
  //                             product_name: product.product_name,
  //                             product_desc: product.product_desc,
  //                             product_name_es: product.product_name_es,
  //                             product_desc_es: product.product_desc_es,
  //                             product_id: product.product_id,
  //                             product_img: product.product_img,
  //                             product_quantity: product.product_quantity,
  //                             product_notes: product.product_notes,
  //                             product_price: product.product_price,
  //                             product_original_price:
  //                               product.product_original_price,
  //                             product_discount:
  //                               sessionStorage.getItem("couponDiscount"),
  //                             product_discount_type: discountType,
  //                             product_modifiers: product.product_modifiers,
  //                             product_loyalty_enabled:
  //                               product.product_loyalty_enabled,
  //                             product_overage_enabled:
  //                               product.product_overage_enabled,
  //                             product_loyalty_price:
  //                               product.product_loyalty_price,
  //                             mod_price: product.mod_price,
  //                             mod_original_price: product.mod_original_price,
  //                             isModifierAvailable: product.isModifierAvailable,
  //                             cart_id: product.cart_id,
  //                           },
  //                           product.cart_id
  //                         )
  //                         .then((data) => { });
  //                     }
  //                   }
  //                   i++;
  //                 });

  //                 setTotalLoyaltyPoints(totalLoyalty);
  //                 console.log("totalLoyalty", totalLoyalty)
  //                 // calculateModifiers(productData);
  //                 // calculateCartTotal(false);
  //               });
  //             } else {
  //               Swal.fire({
  //                 title:
  //                   i18n.t("coupon_item_invalid") +
  //                   " " +
  //                   productArray.join(","),
  //                 allowOutsideClick: false,
  //               });
  //               cancelCoupon();
  //             }
  //           });
  //         }

  //         if (type == "H") {
  //           dbconn.deleteAllCouponOrder("H").then((db1) => {
  //             // deleteAllCouponOrdersFromStore("H").then((db1) => {
  //             dbconn.getAllOrder().then((productData) => {
  //               // getAllOrdersFromStore().then((productData) => {
  //               // console.log(productData);

  //               var products = JSON.parse(
  //                 sessionStorage.getItem("couponProductDetails")
  //               );
  //               // console.log(products);
  //               var productMatchCounter = 0;
  //               var productArray = [];
  //               for (var j = 0; j < products.length; j++) {
  //                 var flag = false;
  //                 for (var i = 0; i < productData.length; i++) {
  //                   if (
  //                     productData[i].product_id == products[j].product_id &&
  //                     productData[i].product_loyalty_enabled == "N" &&
  //                     productData[i].product_discount_type != "H" &&
  //                     products[j].coupon_details_type == "Q"
  //                   ) {
  //                     flag = true;
  //                   }
  //                 }

  //                 if (flag) {
  //                   productMatchCounter++;
  //                 }

  //                 productArray.push(products[j].product_name);
  //               }

  //               if (productMatchCounter == products.length) {
  //                 toast.success(i18n.t("coupon_success"))

  //                 dbconn.getAllKeys().then((keys) => {
  //                   // getAllOrderKeys().then((keys) => {
  //                   var cartLength = 0;
  //                   if (keys.length == 0) {
  //                     cartLength = 0;
  //                   } else {
  //                     cartLength = Math.max(...keys);
  //                   }

  //                   var products = JSON.parse(
  //                     sessionStorage.getItem("couponProductDetails")
  //                   );
  //                   // console.log(products);

  //                   var productArray = JSON.parse(JSON.stringify(productData));
  //                   for (var i = 0; i < productData.length; i++) {
  //                     for (var j = 0; j < products.length; j++) {
  //                       if (
  //                         productData[i].product_id == products[j].product_id &&
  //                         productData[i].product_loyalty_enabled == "N" &&
  //                         products[j].coupon_details_type == "Q"
  //                       ) {
  //                         cartLength++;
  //                         productData[i].product_discount =
  //                           sessionStorage.getItem("couponDiscount");
  //                         productData[i].product_discount_type = "H";
  //                         productData[i].cart_id = cartLength;

  //                         productArray.push(productData[i]);
  //                         dbconn
  //                           .setOrder(
  //                             // setOrderToStore(
  //                             productData[i],
  //                             cartLength
  //                           )
  //                           .then((data) => { });
  //                       }
  //                     }
  //                   }

  //                   // console.log(JSON.parse(JSON.stringify(productArray)));

  //                   // calculateModifiers(productArray);
  //                   // setProductList(productArray)
  //                   // calculateCartTotal(false);
  //                 });

  //                 // setCouponDiscount(parseFloat(sessionStorage.getItem("couponDiscount")))
  //               } else {
  //                 Swal.fire({
  //                   title:
  //                     i18n.t("coupon_item_invalid") +
  //                     " " +
  //                     productArray.join(","),
  //                   allowOutsideClick: false,
  //                 });
  //                 cancelCoupon();
  //               }
  //             });
  //           });
  //         }
  //         if (type == "G") {
  //           if (deleteG) {
  //             setDeleteG(false);
  //             dbconn.deleteAllCouponOrder("G").then((db1) => {
  //               // deleteAllCouponOrdersFromStore("G").then((db1) => {
  //               dbconn.getAllOrder().then((productData) => {
  //                 // getAllOrdersFromStore().then((productData) => {
  //                 // console.log(productData);

  //                 var products = JSON.parse(
  //                   sessionStorage.getItem("couponProductDetails")
  //                 );
  //                 // console.log(products);
  //                 var productMatchCounter = 0;
  //                 var productArray = [];
  //                 for (var j = 0; j < products.length; j++) {
  //                   var flag = false;
  //                   for (var i = 0; i < productData.length; i++) {
  //                     if (
  //                       productData[i].product_id == products[j].product_id &&
  //                       productData[i].product_loyalty_enabled == "N" &&
  //                       productData[i].product_discount_type != "H" &&
  //                       products[j].coupon_details_type == "Q"
  //                     ) {
  //                       flag = true;
  //                     }
  //                   }

  //                   if (flag) {
  //                     productMatchCounter++;
  //                   }

  //                   productArray.push(products[j].product_name);
  //                 }

  //                 // console.log(productMatchCounter + " A " + products.length);

  //                 if (productMatchCounter == products.length) {
  //                   toast.success(i18n.t("coupon_success"))
  //                   dbconn.getAllKeys().then((keys) => {
  //                     // getAllOrderKeys().then((keys) => {
  //                     var cartLength = 0;
  //                     if (keys.length == 0) {
  //                       cartLength = 0;
  //                     } else {
  //                       cartLength = Math.max(...keys);
  //                     }

  //                     var products = JSON.parse(
  //                       sessionStorage.getItem("couponDiscountedProductDetails")
  //                     );

  //                     for (var j = 0; j < products.length; j++) {
  //                       cartLength++;

  //                       var prod = {
  //                         product_name: products[j].product_name,
  //                         product_desc: products[j].product_description,
  //                         product_name_es: products[j].product_name_es,
  //                         product_desc_es: products[j].product_desc_es,
  //                         product_id: products[j].product_id,
  //                         product_img: products[j].product_image,
  //                         product_quantity: 1,
  //                         product_notes: "",
  //                         product_price: products[j].product_price,
  //                         product_discount:
  //                           sessionStorage.getItem("couponDiscount"),
  //                         product_discount_type: "G",
  //                         product_modifiers: products[j].product_modifiers,
  //                         product_loyalty_enabled:
  //                           products[j].product_loyalty_enabled,
  //                         product_loyalty_price:
  //                           products[j].product_loyalty_price,
  //                         mod_price: 0,
  //                         product_mod_price: 0,
  //                         isModifierAvailable:
  //                           products[j].product_modifiers.length > 0 ||
  //                             products[j].product_notes_enabled == "Y"
  //                             ? true
  //                             : false,
  //                         cart_id: cartLength,
  //                       };

  //                       productData.push(prod);
  //                       dbconn.setOrder(prod, cartLength).then((data) => { console.log("Data", data) });
  //                       // setOrderToStore(prod, cartLength).then((data) => { });
  //                     }

  //                     // console.log(JSON.parse(JSON.stringify(productData)));
  //                     // calculateModifiers(productData);
  //                     // setProductList(productData);
  //                     // calculateCartTotal(false);
  //                   });
  //                 } else {
  //                   Swal.fire({
  //                     title:
  //                       i18n.t("coupon_item_invalid") +
  //                       " " +
  //                       productArray.join(","),
  //                     allowOutsideClick: false,
  //                   });
  //                   cancelCoupon();
  //                 }
  //               });
  //             });
  //           } else {
  //             dbconn.getAllOrder().then((productData) => {
  //               // getAllOrdersFromStore().then((productData) => {
  //               // console.log(productData);

  //               var products = JSON.parse(
  //                 sessionStorage.getItem("couponProductDetails")
  //               );
  //               // console.log(products);
  //               var productMatchCounter = 0;
  //               var productArray = [];
  //               for (var j = 0; j < products.length; j++) {
  //                 var flag = false;
  //                 for (var i = 0; i < productData.length; i++) {
  //                   if (
  //                     productData[i].product_id == products[j].product_id &&
  //                     productData[i].product_loyalty_enabled == "N" &&
  //                     productData[i].product_discount_type != "H" &&
  //                     products[j].coupon_details_type == "Q"
  //                   ) {
  //                     flag = true;
  //                   }
  //                 }

  //                 if (flag) {
  //                   productMatchCounter++;
  //                 }

  //                 productArray.push(products[j].product_name);
  //               }

  //               // console.log(productMatchCounter + " A " + products.length);

  //               if (productMatchCounter == products.length) {
  //                 toast.success(i18n.t("coupon_success"))
  //               } else {
  //                 Swal.fire({
  //                   title:
  //                     i18n.t("coupon_item_invalid") +
  //                     " " +
  //                     productArray.join(","),
  //                   allowOutsideClick: false,
  //                 });
  //                 cancelCoupon();
  //               }
  //             });
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     dbconn.getAllOrder().then((productData) => {
  //       // getAllOrdersFromStore().then((productData) => {
  //       // calculateModifiers(productData);
  //     });
  //   }
  // };

  // const calculateModifiers = (productData) => {
  //   for (var i = 0; i < productData.length; i++) {
  //     var productMod = productData[i].product_modifiers;

  //     productData[i].product_modifiers_isOkey = "Y";

  //     if (productData[i].product_discount_type == "G") {
  //       var modifiers = [];

  //       for (var j = 0; j < productData[i].product_modifiers.length; j++) {
  //         var mod_id = productData[i].product_modifiers[j].mod_id;
  //         var mod_required = productData[i].product_modifiers[j].mod_required;

  //         var flag = true;
  //         for (var k = 0; k < modifiers.length; k++) {
  //           if (modifiers[k].mod_id == mod_id) {
  //             flag = false;
  //           }
  //         }
  //         // console.log('flag', flag)
  //         if (flag) {
  //           modifiers.push({
  //             mod_id: mod_id,
  //             mod_required: mod_required,
  //             mod_option: [],
  //           });
  //         }
  //       }

  //       for (var j = 0; j < modifiers.length; j++) {
  //         for (var k = 0; k < productData[i].product_modifiers.length; k++) {
  //           if (
  //             modifiers[j].mod_id == productData[i].product_modifiers[k].mod_id
  //           ) {
  //             modifiers[j].mod_option.push(
  //               productData[i].product_modifiers[k].mod_selected
  //             );
  //           }
  //         }
  //       }

  //       for (var j = 0; j < modifiers.length; j++) {
  //         if (modifiers[j].mod_required == "Y") {
  //           var flag = 0;
  //           for (var k = 0; k < modifiers[j].mod_option.length; k++) {
  //             if (
  //               modifiers[j].mod_required == "Y" &&
  //               modifiers[j].mod_option[k] == "Y"
  //             ) {
  //               flag = 1;
  //             }
  //           }

  //           if (flag == 0) {
  //             productData[i].product_modifiers_isOkey = "N";

  //             break;
  //           }
  //         } else {
  //         }
  //       }
  //     }
  //   }
  //   setProductList(productData);
  // };

  // const closeCustomTip = (e) => {
  //   // if (tipOne == 0) {
  //   //   setTip1Visible("none");
  //   // } else {
  //   //   setTip1Visible("inline-block");
  //   // }

  //   // if (tipTwo == 0) {
  //   //   setTip2Visible("none");
  //   // } else {
  //   //   setTip2Visible("inline-block");
  //   // }
  //   // if (tipThree == 0) {
  //   //   setTip3Visible("none");
  //   // } else {
  //   //   setTip3Visible("inline-block");
  //   // }

  //   if (tipOne == 0 && tipTwo == 0 && tipThree == 0) {
  //     setCustomTipVisible("none");
  //     // setNoTipVisible("inline-block");
  //   } else {
  //     setCustomTipVisible("none");
  //     // setNoTipVisible("inline-block");
  //   }

  //   setTipSelected(0);
  //   setTip(0);
  // };

  const handleShowOrderType = () => {
    setShowOrderType(true);
  };

  const handleCloseOrderType = () => {
    setShowOrderType(false);
  };

  const onOpenModal = () => {
    setOpen(true);
  };
  const onCloseModal = () => {
    setOpen(false);
  };

  const handleOpenImageUpload = () => {
    handleCloseOrderType();
    // setShowImageUpload(true);
    handleCloseCapture();
  };

  const handleCloseImageUpload = () => {
    // setShowImageUpload(false);
  };

  // const onCompleteImageUpload = () => {
  //   handleCloseImageUpload();
  //   //  handleShowOrderType();

  //   dbconn.getAllOrder().then((productData) => {
  //     // getAllOrdersFromStore().then((productData) => {
  //     var overAgeFlag = false;
  //     for (var i = 0; i < productData.length; i++) {
  //       if (productData[i].product_overage_enabled == "Y") {
  //         overAgeFlag = true;
  //       }
  //     }

  //     if (overAgeFlag && !sessionStorage.getItem("orderType") == "I") {
  //       if (
  //         sessionStorage.getItem("idUpload1") &&
  //         sessionStorage.getItem("idUpload2")
  //       ) {
  //         if (
  //           sessionStorage.getItem("orderType") == "P" ||
  //           sessionStorage.getItem("orderType") == "C"
  //         ) {
  //           if (!sessionStorage.getItem("guestFlag" || sessionStorage.getItem("guestFlag") == "Y")) {
  //             setGuestRedirectFlag(true);
  //           } else {
  //             setCheckoutRedirectFlag(true);
  //           }
  //         } else if (sessionStorage.getItem("orderType") == "D") {
  //           if (!sessionStorage.getItem("guestFlag") || sessionStorage.getItem("guestFlag") == "Y") {
  //             setGuestRedirectFlag(true);
  //           } else {
  //             if (sessionStorage.getItem("orderType") == "D") {
  //               checkCustomerDistance();
  //             } else {
  //               setCheckoutRedirectFlag(true);
  //             }
  //           }
  //         }
  //       } else {
  //         props.handleOpenImageUpload();
  //       }
  //     } else {
  //       if (
  //         sessionStorage.getItem("orderType") == "P" ||
  //         sessionStorage.getItem("orderType") == "C" ||
  //         sessionStorage.getItem("orderType") == "I"
  //       ) {
  //         if (!sessionStorage.getItem("guestFlag") || sessionStorage.getItem("guestFlag") == "Y") {
  //           setGuestRedirectFlag(true);
  //         } else {
  //           setCheckoutRedirectFlag(true);
  //         }
  //       } else if (sessionStorage.getItem("orderType") == "D") {
  //         if (!sessionStorage.getItem("guestFlag") || sessionStorage.getItem("guestFlag") == "Y") {
  //           setGuestRedirectFlag(true);
  //         } else {
  //           if (sessionStorage.getItem("orderType") == "D") {
  //             checkCustomerDistance();
  //           } else {
  //             setCheckoutRedirectFlag(true);
  //           }
  //         }
  //       }
  //     }
  //   });
  // };

  // const checkCustomerDistance = () => {
  //   onOpenModal();

  //   dbconn.getUser().then((data) => {
  //     // getAllStore().then(data => {

  //     if (!data || data.length < 1 || !sessionStorage.getItem("guestFlag")) {
  //       setNosessionredirect(true);
  //     } else {
  //       if (
  //         Number(parseInt(localStorage.getItem("store_delivery_range"))) > 0 &&
  //         localStorage.getItem("store_latitude") != "" &&
  //         localStorage.getItem("store_longitude") != ""
  //       ) {
  //         var address = "";
  //         if (data[0].customer_addreess != "") {
  //           address = data[0].customer_address;
  //         }
  //         if (data[0].customer_city != "") {
  //           address += ", " + data[0].customer_city;
  //         }
  //         if (data[0].customer_state != "") {
  //           address += ", " + data[0].customer_state;
  //         }
  //         if (data[0].customer_zipcode != "") {
  //           address += ", " + data[0].customer_zipcode;
  //         }
  //         var url = API_ROOT + Method.FETCH_DISTANCE;

  //         var options = {
  //           address: address,
  //           latitude: localStorage.getItem("store_latitude"),
  //           longitude: localStorage.getItem("store_longitude"),
  //           distance: localStorage.getItem("store_delivery_range"),
  //         };

  //         axios
  //           .post(url, options)
  //           .then((response) => {
  //             onCloseModal();

  //             if (response.data.status == 1) {
  //               orderTypeDialog.close();
  //               setCheckoutRedirectFlag(true);
  //             } else {
  //               Swal.fire({
  //                 title: response.data.message,
  //                 allowOutsideClick: false,
  //               });
  //             }
  //           })
  //           .catch(function (error) {
  //             onCloseModal();
  //             console.log(error);
  //           });
  //       } else {
  //         onCloseModal();
  //         setCheckoutRedirectFlag(true);
  //       }
  //     }
  //   });
  // };

  const handleOpenCapture = () => {
    handleCloseImageUpload();
    sessionStorage.setItem("cameraOpen", "Y");
    // setShowCapture(true);
  };

  const handleCloseCapture = () => {
    sessionStorage.removeItem("cameraOpen");
    // setShowCapture(false);
  };

  // useEffect(() => {
  //   var finalNca = 0;

  //   // if (ncaAmtFlag === "Y") {
  //   //   finalNca = nca;
  //   //   setNcaOnTipEnabled("Y");
  //   // } else {
  //   //   if (ncaMarkup === "N") {
  //   //     var nca = 0;

  //   //     if (ncaOnTipEnabled === "Y") {
  //   //       nca = (
  //   //         ((Number(parseFloat(cartTotal)) + Number(parseFloat(tip)) + Number(parseFloat(finalTax))) *
  //   //           Number(parseFloat(nca))) /
  //   //         100
  //   //       ).toFixed(2);
  //   //     } else {
  //   //       nca = (
  //   //         ((Number(parseFloat(cartTotal)) + Number(parseFloat(finalTax))) * Number(parseFloat(nca))) /
  //   //         100
  //   //       ).toFixed(2);
  //   //     }

  //   //     finalNca = nca;
  //   //   }
  //   // }
  // }, [nca, ncaAmtFlag, ncaMarkup, ncaOnTipEnabled, cartTotal, tip, finalTax]);

  // if (nosessionredirect) {
  //   navigate("/");
  // }
  // if (captureredirect) {
  //   navigate("/capture");
  // }
  // if (checkoutRedirectFlag) {
  //   navigate("/checkout");
  // }
  // if (guestRedirectFlag) {
  //   navigate("/checkout/guest");
  // }
  // if (homeRedirectFlag) {
  //   navigate("/");
  // }

  return (
    <>
      <div className="yourOrderMainDiv w-100">
        {open && (
          <div className="loader">
            <img src={loader} alt="" width={"300px"} height={"210px"} />
          </div>
        )}
        <div className="cartDetails">
          <div className="cartLeft">
            <div className="backToHome d-flex align-items-center gap-2">
              <FaChevronLeft
                onClick={() => {
                  cancelCoupon();
                  navigate("/");
                }}
              />
              <h2 className="yourOrderTitle roboto-bold-20 m-0">
                {t("ordering_continue")}
              </h2>
            </div>
            <hr />
            <div className="cartHeader">
              <h3 className="roboto-medium-18 m-0">{t("ordering_cart")}</h3>
              <p className="roboto-regular-16 m-0">
                {t(
                  cartItem.length === 1
                    ? t("ordering_cart_singular")
                    : t("ordering_cart_plural"),
                  { count: cartItem.length }
                )}
              </p>
            </div>
            <div className="cartBody">
              {cartItem.map((item, index) => (
                <CartCard
                  key={index}
                  productIndex={index}
                  itemData={item.product_data}
                  removeCoupon={cancelCoupon}
                  clickOnProduct={() => {
                    sessionStorage.setItem("type", "A");
                    sessionStorage.setItem("loyalty", "N");
                    const data = {
                      productID: item.product_data.product_id,
                      productIndex: index,
                      productEdit: true,
                    };
                    setSelectedItemId(data);
                    if (
                      item.product_data.coupon_type === "G" ||
                      item.product_data.coupon_type === "H"
                    ) {
                      toast.dismiss();
                      toast.error(t("cant_change_discount_item"));
                    } else {
                      dispatch(toggleModal());
                    }
                  }}
                />
              ))}
            </div>
          </div>
          <div className="cartRight">
            <div className="cartDetailsPage p-3">
              <div
                className="tipSection couponSection d-flex align-items-center w-100"
                style={{ marginBottom: "10px" }}
              >
                <span
                  id="couponInputContainer w-100"
                  className="couponInputContainer"
                  style={{ display: couponInputContainer, width: "100%" }}
                >
                  <input
                    type="text"
                    className="cupponInput roboto-medium-16 p-1"
                    style={{ width: "82%" }}
                    value={couponInput}
                    onChange={(e) => setCouponInput(e.target.value)}
                    placeholder={t("coupon_code")}
                  />
                  <input
                    type="button"
                    className="cupponBtn float-right roboto-medium-16 p-1"
                    id="couponBtn"
                    style={{ width: "18%" }}
                    onClick={addCoupon}
                    value={t("add")}
                  />
                </span>
                <span
                  className="couponBlock w-100"
                  id="appliedCouponContainer"
                  style={{ display: appliedCouponContainer }}
                >
                  <input
                    type="text"
                    className="cupponInput roboto-medium-16 p-1"
                    style={{ width: "82%" }}
                    defaultValue={appliedCouponText}
                  />

                  <input
                    type="button"
                    className="cupponBtn float-right roboto-medium-16 p-1"
                    style={{ width: "18%" }}
                    onClick={cancelCoupon}
                    value={t("remove")}
                  />
                </span>
              </div>
              {isTipEnabled === "Y" ? (
                <div className="tipSection w-100">
                  <h4 className="tipTitle roboto-medium-18 text-grey">
                    {t("want_tip")}
                  </h4>
                  <div className="tipButton d-flex align-items-center flex-wrap gap-1">
                    <button
                      className={`border-2 rounded roboto-medium-16 ${
                        selectedTip == tipOne ? "activeTipButton" : ""
                      }`}
                      onClick={() => updateTip(tipOne)}
                    >
                      {tipOne}%
                    </button>
                    <button
                      className={`border-2 rounded roboto-medium-16 ${
                        selectedTip == tipTwo ? "activeTipButton" : ""
                      }`}
                      onClick={() => updateTip(tipTwo)}
                    >
                      {tipTwo}%
                    </button>
                    <button
                      className={`border-2 rounded roboto-medium-16 ${
                        selectedTip == tipThree ? "activeTipButton" : ""
                      }`}
                      onClick={() => updateTip(tipThree)}
                    >
                      {tipThree}%
                    </button>
                    <button
                      className={`border-2 rounded roboto-medium-16 ${
                        selectedTip == "other" ? "activeTipButton" : ""
                      }`}
                      onClick={() => updateTip("other")}
                    >
                      {t("other")}
                    </button>
                    {sessionStorage.getItem("selectedTipOption") == "other" ? (
                      <input
                        onWheel={() => document.activeElement.blur()}
                        style={{ display: customTipVisible }}
                        type="number"
                        min="0"
                        onChange={handlesetCustomTip}
                        onKeyUp={handlesetCustomTip}
                        className="customTip roboto-medium-16"
                        placeholder="$0.00"
                        id="otherAmount"
                        name="numAmount"
                        value={customTip == 0 ? "$0.00" : customTip}
                      />
                    ) : (
                      ""
                    )}
                    {selectedTip != 0 && (
                      <button
                        className={`border-2 rounded roboto-medium-16 ${
                          selectedTip == 0 ? "activeTipButton" : ""
                        }`}
                        onClick={() => updateTip(0)}
                      >
                        {t("no_tip")}
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="billSection">
                <div className="w-100 p-2 d-flex flex-column">
                  {/* <hr className="my-2" /> */}
                  <ul className="p-0">
                    <li className="d-flex justify-content-between roboto-medium-16 py-1 m-0">
                      <span>{t("subtotal")}:</span>
                      <span>${sessionStorage.getItem("cartTotal")}</span>
                    </li>

                    {sessionStorage.getItem("couponApplied") == "Y" &&
                    sessionStorage.getItem("totalDiscount") > 0 &&
                    sessionStorage.getItem("couponType") != "C" &&
                    sessionStorage.getItem("couponType") != "D" ? (
                      <li className="d-flex justify-content-between roboto-medium-16 py-1 m-0">
                        <span>{t("discount")}</span>
                        <span className="float-right text-grey">
                          $
                          {parseFloat(
                            sessionStorage.getItem("totalDiscount")
                          ).toFixed(2)}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {calculatedTax.map((t, index) => (
                      <li
                        className="d-flex justify-content-between roboto-medium-16 py-1 m-0"
                        key={index}
                      >
                        <span>
                          {t.tax_name}{" "}
                          <span className="text-grey">
                            ({t.tax_percentage.toFixed(2)}%)
                          </span>
                          :
                        </span>
                        <span>${t.tax_value}</span>
                      </li>
                    ))}
                    {parseFloat(
                      sessionStorage.getItem("selectedTipCalculation")
                    ) > 0 ? (
                      <li className="d-flex justify-content-between roboto-medium-16 py-1">
                        <label>{t("tip")}</label>
                        <span className="float-right">
                          $
                          {parseFloat(
                            sessionStorage.getItem("selectedTipCalculation")
                          ).toFixed(2)}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                    {storeDetails.store_nca_enabled &&
                      storeDetails.store_default_nca > 0 &&
                      sessionStorage.getItem("store_nca") && (
                        <li className="d-flex justify-content-between roboto-medium-16 py-1 m-0">
                          <span>
                            {storeDetails.store_nca_name}{" "}
                            <span className="text-grey">{`${
                              storeDetails.store_nca_amount_flag === "Y"
                                ? `(${storeDetails.store_default_nca}$)`
                                : `(${storeDetails.store_default_nca}%)`
                            }`}</span>
                            :
                          </span>
                          <span>
                            $
                            {parseFloat(
                              sessionStorage.getItem("store_nca")
                            ).toFixed(2)}
                          </span>
                        </li>
                      )}
                    <hr className="my-2" />
                    <li className="d-flex justify-content-between roboto-medium-16 py-1">
                      <span>{t("grand_total")}:</span>
                      <span>${grandTotal.toFixed(2)}</span>
                    </li>
                    {/* {console.log("totaly", totalLoyaltyPoints)} */}
                    {totalLoyaltyPoints > 0 ? (
                      <li className="roboto-medium-16">
                        <label>{t("points_redeemed")}:</label>
                        <span className="float-right">
                          {totalLoyaltyPoints}
                        </span>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </div>
              </div>
              <div className="checkOutBtn">
                <button
                  className="w-100 border bg-green roboto-medium-22 rounded-3"
                  style={{
                    height: "45px",
                    backgroundColor: "var(--primaryColor)",
                    color: "white",
                  }}
                  onClick={checkOutBtn}
                >
                  {t("checkout")}
                </button>
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="custom-modal">
            <ProductDetails productData={selectedItemId} />
          </div>
        )}

        {/* <BModal
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showImageUpload}
          onHide={handleCloseImageUpload}
        >
          <BModal.Body>
            <ImageUploads
              onComponentClose={handleCloseImageUpload}
              onCompleteImageUpload={onCompleteImageUpload}
              onOpenCapture={handleOpenCapture}
            />
          </BModal.Body>
        </BModal> */}

        {/* <BModal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showCapture}
          onHide={handleCloseCapture}
        >
          <BModal.Body>
            <Capture
              // onComponentClose={handleCaptureClose}
              onImageCaptured={handleOpenImageUpload}
            />
          </BModal.Body>
        </BModal> */}

        <BModal
          enforceFocus={false}
          autoFocus={false}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showOrderType}
          onHide={handleCloseOrderType}
        >
          <BModal.Body>
            <OrderType
              storeOrderType={store.store_order_type}
              tip={tip}
              storeDeliveryFee={store.store_delivery_fee}
              storeDeliveryFeeDiscount={store.store_delivery_fee}
              storeWeeklyOrder={store.store_weekly_order}
              handleOpenImageUpload={handleOpenImageUpload}
              onComponentClose={handleCloseOrderType}
              // onComponentUp={componentUP}
              // onComponentDown={componentDown}
            />
          </BModal.Body>
        </BModal>
        <ToastContainer />
      </div>
      {/* ========================== New Code END ========================== */}
    </>
  );
}

export default YourOrder;
