import axios from "axios";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Webcam from "react-webcam";
import { API_ROOT, Method } from "../api/apiHandler";
import idImg from "../image/id_image-removebg-preview.png";

function OverageImageUpload({ toggleFalse, modalcloseWithButton }) {
  const { t } = useTranslation();
  const [mode, seMode] = useState("B"); // State for toggling between browser upload (B) and camera capture (C) modes
  const [frontImageUpload, setFrontImageUpload] = useState(); // State for storing front image from browser upload
  const [backImageUpload, setBackImageUpload] = useState(); // State for storing back image from browser upload
  const [frontImageCapture, setFrontImageCapture] = useState(); // State for storing captured front image from webcam
  const [backImageCapture, setBackImageCapture] = useState(); // State for storing captured back image from webcam
  const frontWebcamRef = useRef(null); // Ref for accessing front webcam component
  const backWebcamRef = useRef(null); // Ref for accessing back webcam component

  //* handleModeToggel funcation:
  // Function to handle toggling between two modes: "B" for browser upload and "C" for camera capture.
  // Depending on the selected mode, it clears the respective image states (upload or capture).
  const handleModeToggel = (value) => {
    seMode(value); // Updates the mode state (either "B" for browser upload or "C" for camera capture)
    if (value == "B") {
      // If the mode is set to "B" (browser upload), clear the captured images
      setFrontImageCapture();
      setBackImageCapture();
    } else if (value == "C") {
      // If the mode is set to "C" (camera capture), clear the uploaded images
      setFrontImageUpload();
      setBackImageUpload();
    }
  };

  const closeWithoutSaving = () => {
    toggleFalse(false);
  };

  const closeModalAndClearData = () => {
    modalcloseWithButton(false);
    if (sessionStorage.getItem("idUpload1")) {
      sessionStorage.removeItem("idUpload1");
    }

    if (sessionStorage.getItem("idUpload2")) {
      sessionStorage.removeItem("idUpload2");
    }
  };

  /*
     * convertDataURLToFile function
       Converts a data URL to a file object, useful for handling image or file uploads from base64 data URLs.
  */
  const convertDataURLToFile = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const arrayBuffer = event.target.result;
        const blob = new Blob([arrayBuffer], { type: file.type });
        const url = URL.createObjectURL(blob);
        const filename = file.name;
        const newFile = new File([blob], filename, { type: file.type });
        resolve(newFile); // Resolves the promise with the new File object.
      };
      reader.onerror = function (error) {
        reject(error); // Rejects the promise in case of error during reading.
      };
      reader.readAsArrayBuffer(file); // Reads the input file as an array buffer to be converted into a file.
    });
  };

  /*
     * handleUploadFrontImage function
          Handles uploading of the front image. It reads the file, converts it to a data URL, and then sends the file to the server.
   */
  const handleUploadFrontImage = (event) => {
    const file = event.target.files[0]; // Retrieves the file selected by the user.
    if (file) {
      const reader = new FileReader();
      reader.onload = function () {
        setFrontImageUpload(reader.result); // Sets the data URL of the image for display.
      };
      reader.readAsDataURL(file); // Reads the file as a data URL.

      convertDataURLToFile(file) // Converts the selected file (data URL) to a File object.
        .then((file) => {
          const formData = new FormData();
          formData.append("file", file); // Appends the file to the FormData object for uploading.

          // Sends the file to the server using an HTTP POST request.
          axios
            .post(API_ROOT + Method.UPLOAD_FILES, formData)
            .then((response) => {
              if (response.data.status === 1) {
                sessionStorage.setItem("idType", "B"); // Sets session storage for the uploaded file.
                sessionStorage.setItem("idUpload1", response.data.filename); // Stores the uploaded file's filename.
              } else {
                // alert(response.data.message);
                console.log("ERROR:", response.data.message); // Logs the error message if the upload fails.
              }
            })
            .catch((error) => {
              // Handles specific error related to large file uploads and logs other errors.
              if (error.message == "Request failed with status code 413") {
                alert("File is too large.");
              }
              console.log(error);
            });
        })
        .catch((error) => {
          console.error("Error converting data URL to File:", error); // Logs any errors during the conversion process.
        });
    }
  };

  /*
   * handleUploadBackImage function:
     Handles uploading of the back image. It reads the file, converts it to a data URL, and then sends the file to the server.
  */
  const handleUploadBackImage = (event) => {
    const file = event.target.files[0]; // Retrieves the file selected by the user.
    if (file) {
      const reader = new FileReader();
      reader.onload = function () {
        setBackImageUpload(reader.result); // Sets the data URL of the image for display.
      };
      reader.readAsDataURL(file); // Reads the file as a data URL.

      convertDataURLToFile(file) // Converts the selected file (data URL) to a File object
        .then((file) => {
          const formData = new FormData();
          formData.append("file", file); // Appends the file to the FormData object for uploading
          // Sends the file to the server using an HTTP POST request
          axios
            .post(API_ROOT + Method.UPLOAD_FILES, formData)
            .then((response) => {
              if (response.data.status === 1) {
                sessionStorage.setItem("idType", "B"); // Sets session storage for the uploaded file
                sessionStorage.setItem("idUpload2", response.data.filename); // Stores the uploaded file's filename

                // If both front and back images are uploaded, close the modal without saving.
                if (
                  sessionStorage.getItem("idUpload1") &&
                  sessionStorage.getItem("idUpload2")
                ) {
                  closeWithoutSaving(); // Calls function to close the upload modal
                }
              } else {
                // alert(response.data.message);
                console.log("ERROR:", response.data.message); // Logs the error message if the upload fails
                // console.log("Front cemara res =>", response.data.message)
              }
            })
            .catch((error) => {
              // Handles specific error related to large file uploads and logs other errors.
              if (error.message == "Request failed with status code 413") {
                alert("File is too large.");
              }
              console.log(error);
            });
        })
        .catch((error) => {
          console.error("Error converting data URL to File:", error); // Logs any errors during the conversion process.
        });
    }
  };

  /*
   * handleFrontCapture function:
     Captures the front image from the webcam, converts it to a file, and uploads it to the server.
  */
  const handleFrontCapture = () => {
    // Check if the front webcam reference is valid
    if (frontWebcamRef.current) {
      const imageSrc = frontWebcamRef.current.getScreenshot(); // Captures the image from the webcam

      // If an image is successfully captured
      if (imageSrc) {
        setFrontImageCapture(imageSrc); // Sets the captured image to the state for display

        // Extract MIME type from the captured image source
        const mime = imageSrc.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
        const strings = imageSrc.split(","); // Splits the base64 string to extract image data
        let extension;

        // Determines the file extension based on the image format
        switch (strings[0]) {
          case "data:image/jpeg;base64":
            extension = "jpeg";
            break;
          case "data:image/png;base64":
            extension = "png";
            break;
          default:
            extension = "jpg";
            break;
        }

        // Decodes the base64 image data into binary
        const byteCharacters = atob(strings[1]);

        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Creates a Blob from the decoded binary data
        const blob = new Blob([byteArray], { type: mime });

        // Creates a new File object from the Blob
        const file = new File([blob], `image.${extension}`, { type: mime });
        const formData = new FormData();
        formData.append("file", file); // Appends the file to FormData for upload

        // Sends the file to the server via a POST request
        axios
          .post(API_ROOT + Method.UPLOAD_FILES, formData)
          .then((response) => {
            if (response.data.status === 1) {
              sessionStorage.setItem("idType", "C"); // Sets session storage with the uploaded file information
              sessionStorage.setItem("idUpload1", response.data.filename); // Stores the filename for the uploaded front image

              // If both front and back images are uploaded, close the modal without saving
              if (
                sessionStorage.getItem("idUpload1") &&
                sessionStorage.getItem("idUpload2")
              ) {
                closeWithoutSaving(); // Closes the upload modal
              }
            } else {
              alert(response.data.message); // Alerts any errors from the server
              // console.log("Front cemara res =>", response.data.message)
            }
          })
          .catch((error) => {
            alert(error); // Alerts any network errors
            console.log(error);
          });
      } else {
        console.error("Failed to capture front image"); // Logs error if no image was captured
      }
    } else {
      console.error("Front webcam ref is null"); // Logs error if the webcam reference is invalid
    }
  };

  /*
   * handleBackCapture function:
     Captures the back image from the webcam, converts it to a file, and uploads it to the server
  */
  const handleBackCapture = () => {
    // Check if the back webcam reference is valid
    if (backWebcamRef.current) {
      const imageSrc = backWebcamRef.current.getScreenshot(); // Captures the image from the back webcam.

      // If an image is successfully captured
      if (imageSrc) {
        setBackImageCapture(imageSrc); // Sets the captured image to the state for display.

        // Extract MIME type from the captured image source
        const mime = imageSrc.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
        const strings = imageSrc.split(","); // Splits the base64 string to extract image data.
        let extension;

        // Determines the file extension based on the image format
        switch (strings[0]) {
          case "data:image/jpeg;base64":
            extension = "jpeg";
            break;
          case "data:image/png;base64":
            extension = "png";
            break;
          default:
            extension = "jpg";
            break;
        }

        // Decodes the base64 image data into binary
        const byteCharacters = atob(strings[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Creates a Blob from the decoded binary data
        const blob = new Blob([byteArray], { type: mime });

        // Creates a new File object from the Blob
        const file = new File([blob], `image.${extension}`, { type: mime });
        const formData = new FormData();
        formData.append("file", file); // Appends the file to FormData for upload.

        // Sends the file to the server via a POST request
        axios
          .post(API_ROOT + Method.UPLOAD_FILES, formData)
          .then((response) => {
            if (response.data.status === 1) {
              // If both front and back images are uploaded, close the modal without saving
              if (sessionStorage.getItem("idType") == "C") {
                sessionStorage.setItem("idType", "C"); // Stores the uploaded back image information.
                sessionStorage.setItem("idUpload2", response.data.filename); // Stores the filename of the back image.
                if (
                  sessionStorage.getItem("idUpload1") &&
                  sessionStorage.getItem("idUpload2")
                ) {
                  closeWithoutSaving(); // Closes the modal after both images are uploaded.
                }
              } else {
                toast.dismiss(); // Dismiss any existing toast notifications.
                toast.error("Please capture second Image."); // Prompts the user to capture the second image.
              }
            } else {
              console.log("ERROR:", response.data.message); // Logs server error message.
            }
          })
          .catch((error) => {
            alert(error); // Alerts any network errors.
            console.log(error);
          });
      } else {
        console.error("Failed to capture back image"); // Logs error if no image was captured.
      }
    } else {
      console.error("Back webcam ref is null"); // Logs error if the webcam reference is invalid.
    }
  };

  const videoConstraints = {
    facingMode: { exact: "environment" }, // Use back camera
  };

  return (
    <div className="OverageImageUpload p-3">
      <div className="header text-center">
        <h6 className="roboto-regular-18">{t("upload_image_description")}</h6>
        <h5 className="roboto-bold-20 py-2">{t("required_age")}</h5>
      </div>

      <div className="button text-center">
        <div className="judvaBtn">
          {/* Button for switching to browser upload mode */}
          <span
            className={`browserUploadBtn ${mode == "B" ? "active" : ""}`} // Applies 'active' class when mode is 'B'
            onClick={() => handleModeToggel("B")} // Calls handleModeToggel to switch to 'B' mode
          >
            {t("browser_upload")}
          </span>
          <span
            className={`cameraBtn ${mode == "C" ? "active" : ""}`} // Applies 'active' class when mode is 'C'
            onClick={() => handleModeToggel("C")} // Calls handleModeToggel to switch to 'C' mode
          >
            {t("capture_camera")}
          </span>
        </div>
      </div>
      <div className="mainContent w-100">
        {/* If the mode is 'B' (Browser Upload), display the file upload options */}
        {mode == "B" ? (
          <div className="browserUpload p-2 d-flex align-items-center justify-content-between">
            {/* Front image upload section */}
            <div className="frontDiv text-center">
              <div className="uploadImageShow">
                {/* Display uploaded front image or a placeholder if no image is uploaded */}
                {frontImageUpload ? (
                  <img src={frontImageUpload} alt="" />
                ) : (
                  <img className="idFakeImg" src={idImg} alt="" />
                )}
              </div>
              <label
                htmlFor="front-upload"
                className="custom-file-upload clickBtn my-2"
              >
                {t("choose_file")}
                <input
                  id="front-upload"
                  type="file"
                  onChange={handleUploadFrontImage}
                />
              </label>
            </div>
            {/* Back image upload section */}
            <div className="backDiv text-center">
              {/* Display uploaded back image or a placeholder if no image is uploaded */}
              <div className="uploadImageShow">
                {backImageUpload ? (
                  <img src={backImageUpload} alt="" />
                ) : (
                  <img className="idFakeImg" src={idImg} alt="" />
                )}
              </div>
              <label
                htmlFor="back-upload"
                className="custom-file-upload clickBtn my-2"
              >
                {t("choose_file")}
                <input
                  id="back-upload"
                  type="file"
                  onChange={handleUploadBackImage} // Handle back image file upload
                />
              </label>
            </div>
          </div>
        ) : null}

        {/* If the mode is 'C' (Camera Capture), display the camera capture option */}
        {mode == "C" ? (
          <div className="browserUpload p-2 d-flex align-items-center justify-content-between">
            <div className="frontDiv text-center">
              {/* <div className="uploadImageShow">
                                   <img src="https://w7.pngwing.com/pngs/850/1001/png-transparent-identity-document-forgery-identification-card-printer-badge-id-card-miscellaneous-template-label.png" alt="" />
                              </div> */}
              {frontImageCapture ? (
                <div className="uploadImageShow">
                  <img src={frontImageCapture} alt="" />
                </div>
              ) : (
                <Webcam
                  audio={false}
                  ref={frontWebcamRef} // Reference to the front webcam
                  screenshotFormat="image/jpeg"
                  className="webcamCapture"
                  // videoConstraints={videoConstraints} // Optional video constraints for the webcam
                />
              )}
              <button
                className="clickBtn my-2"
                onClick={() => handleFrontCapture()} // Capture the front image
              >
                {t("front_click")}
              </button>
            </div>

            {/* Back camera capture section */}
            <div className="backDiv text-center">
              {backImageCapture ? (
                <div className="uploadImageShow">
                  <img src={backImageCapture} alt="" />
                </div>
              ) : (
                <Webcam
                  audio={false}
                  ref={backWebcamRef} // Reference to the back webcam
                  screenshotFormat="image/jpeg"
                  className="webcamCapture"
                  // videoConstraints={videoConstraints} // Optional video constraints for the webcam
                />
              )}
              <button
                className="clickBtn my-2"
                onClick={() => handleBackCapture()} // Capture the back image
              >
                {t("back_click")}
              </button>
            </div>
          </div>
        ) : null}
      </div>
      <div className="OverageImageUploadBottom text-center">
        {/* <button className='confrimBtn' onClick={() => uploadImage()}>upload</button> */}
        <button className="cancelBtn" onClick={() => closeModalAndClearData()}>
          {" "}
          {t("cancel")}
        </button>
      </div>
    </div>
  );
}

export default OverageImageUpload;
