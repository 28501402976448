import { createSlice } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
     name: 'sidebar',
     initialState: {
          isOpen: false,
     },
     reducers: {
          toggleSidebar: (state, action) => {
               state.isOpen = action.payload
          },
     },
});

export const { toggleSidebar } = sidebarSlice.actions;

export const selectSidebarStatus = (state) => state.sidebar.isOpen;

export default sidebarSlice.reducer;
