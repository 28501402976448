import { Rating } from "@mui/material";
import axios from "axios";
import fetch from "isomorphic-fetch";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaRegStar } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_ROOT, IMAGE_ROOT, Method } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import loader from "../image/res_home_page_loader.gif";
import "../style/Signup.css";

export default function ConfirmationPage(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  const [rewardRedirect, setRewardRedirect] = useState(false);
  const [nosessionredirect, setNosessionRedirect] = useState(false);
  const [nextReward, setNextReward] = useState(0);
  const [customerLoyaltyPoints, setCustomerLoyaltyPoints] = useState(0);
  const [availablePoints, setAvailablePoints] = useState(0);
  const [open, setOpen] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [idUpload1, setIdUpload1] = useState("");
  const [idUpload2, setIdUpload2] = useState("");
  const [productList, setProductList] = useState([]);
  const [password, setPassword] = useState("");
  const [marketingChkBox, setMarketingChkBox] = useState(false);
  const [termsChkBox, setTermsChkBox] = useState(false);
  const [rating, setRating] = useState(0);
  const [ratingTrue, setRatingTrue] = useState(true);
  const [earnedPoints, setEarnedPoints] = useState(0);
  // const store = useSelector((state) => state.fetchstore.data)
  const [store, setStore] = useState([]);

  const storeFetch = async () => {
    const store = await dbconn.getStoreFromIndexedDB();
    setStore(store);
  };

  useEffect(() => {
    storeFetch();
    const rating = sessionStorage.getItem("rating");
    // dispatch(fetchStoreData())

    if (rating == "true") {
      setRatingTrue(false);
    }
    fetchMenu();
  }, []);

  useEffect(() => {
    if (redirect) {
      return () => {
        setRedirect(false);
      };
    }
  }, [redirect]);

  useEffect(() => {
    if (rewardRedirect) {
      return () => {
        setRewardRedirect(false);
      };
    }
  }, [rewardRedirect]);

  useEffect(() => {
    if (nosessionredirect) {
      return () => {
        setNosessionRedirect(false);
      };
    }
  }, [nosessionredirect]);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const onOpenLoadingModal = () => {
    setOpenLoading(true);
  };

  const onCloseLoadingModal = () => {
    setOpenLoading(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      var lastOrderID = sessionStorage.getItem("lastOrderID");
      var lastOrderAmount = sessionStorage.getItem("grandTotalForConfirm");
      var usedPoint = "";

      if (sessionStorage.getItem("useblePoint")) {
        usedPoint = sessionStorage.getItem("useblePoint");
      }

      var front = sessionStorage.getItem("front");
      var back = sessionStorage.getItem("back");

      var rating = false;

      if (sessionStorage.getItem("rating")) {
        rating = sessionStorage.getItem("rating");
      }

      var guestFlag = "";
      if (sessionStorage.getItem("guestFlag")) {
        guestFlag = sessionStorage.getItem("guestFlag");
      }

      sessionStorage.clear();

      if (rating) {
        sessionStorage.setItem("rating", rating);
      }
      if (lastOrderID !== "") {
        sessionStorage.setItem("lastOrderID", lastOrderID);
        sessionStorage.setItem("grandTotalForConfirm", lastOrderAmount);
      }

      if (front && back) {
        sessionStorage.setItem("front", front);
        sessionStorage.setItem("back", back);
      }

      if (usedPoint) {
        usedPoint = sessionStorage.setItem("usedPoint", usedPoint);
      }

      if (guestFlag !== "") {
        sessionStorage.setItem("guestFlag", guestFlag);
      }

      if (!localStorage.getItem("store_language")) {
        localStorage.setItem("store_language", "en");
      }
      await i18n.changeLanguage(localStorage.getItem("store_language"));
    };

    fetchData();
  }, []);

  const fetchMenu = () => {
    dbconn.getStoreFromIndexedDB().then((data) => {
      if (!data || data.length < 1) {
        // sessionStorage.removeItem("guestFlag");
        if (sessionStorage.getItem("guestFlag") === "N") {
          setNosessionRedirect(true);
        }
      } else {
        onOpenLoadingModal();
        fetch(
          `${API_ROOT + Method.FETCH_LOYALTY_MENU_END}?store_id=${
            data.store_id
          }`
        )
          .then((response) => {
            return response.json();
          })
          .then((res) => {
            onCloseLoadingModal();
            if (res.status === 1) {
              let usePoint = res.customer_points;
              if (sessionStorage.getItem("guestFlag") === "N") {
                let total = parseInt(
                  sessionStorage.getItem("grandTotalForConfirm")
                );

                if (total) {
                  const earnPoint = total / data.store_loyalty_amount;
                  setEarnedPoints(earnPoint);
                }

                let points = localStorage.getItem("points");
                if (points) {
                  // console.log("points / store.store_loyalty_amount", points / store.store_loyalty_amount)
                  setCustomerLoyaltyPoints(res.customer_points);
                  // setEarnedPoints
                } else {
                  setCustomerLoyaltyPoints(res.customer_points);
                }
              }

              const points = localStorage.getItem("points");
              if (points) {
                let point = parseInt(
                  parseInt(sessionStorage.getItem("grandTotalForConfirm")) /
                    parseInt(data.store_loyalty_amount)
                );
                const usedPoint = parseInt(sessionStorage.getItem("usedPoint"));
                console.log("usedPoint", usedPoint);
                if (usedPoint) {
                  setAvailablePoints(
                    Number(points) + Number(point) - Number(usedPoint)
                  );
                } else {
                  setAvailablePoints(Number(points) + Number(point));
                }
              } else {
                setAvailablePoints(Number(usePoint));
              }

              // localStorage.setItem("points", res.customer_points)
              for (let i = 0; i < res.product_data.length; i++) {
                if (
                  parseInt(res.product_data[i].product_loyalty_price) >
                  parseInt(res.customer_points)
                ) {
                  setNextReward(
                    parseInt(res.product_data[i].product_loyalty_price)
                  );
                  break;
                }
              }

              setProductList(res.product_data);
            }
          });
      }
    });
  };

  const fetchTermAndCon = () => {
    const heading = i18n.t("tos");
    const text = store.store_tos || i18n.t("no_tos_available");
    Swal.fire({
      title: '<h4 id="guidelineHeading">' + heading + "</h4>",
      allowOutsideClick: true,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      width: "90%",
      html: "<div id='guideLine'>" + text + "</div>",
    });
  };

  const loadMenu = () => {
    var guestFlag = sessionStorage.getItem("guestFlag") || "";
    sessionStorage.clear();

    if (guestFlag !== "") {
      sessionStorage.setItem("guestFlag", guestFlag);
    }
    setRedirect(true);
  };

  const signup = () => {
    dbconn.getUserInfoFromIndexedDB().then((user) => {
      if (user) {
        // console.log("user", user)
        var firstname = user.customer_firstname;
        var lastname = user.customer_lastname;
        var email = user.customer_email;
        var phone = user.customer_phone;
        var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        var address = user.customer_address;
        var city = user.customer_city;
        var state = user.customer_state;
        var zip = user.customer_zip;
        var cust_pwd = password;
        var customer_marketing_optin = marketingChkBox ? "Y" : "N";
        var allGood = true;

        if (cust_pwd.length < 7) {
          allGood = false;
          Swal.fire({
            title: i18n.t("password_inst"),
            allowOutsideClick: false,
          });
          return;
        }

        if (!termsChkBox) {
          allGood = false;
          Swal.fire({
            title: i18n.t("terms_and_conditions"),
            showCancelButton: false,
            confirmButtonText: t("ok"),
            allowOutsideClick: false,
            customClass: {
              confirmButton: "my-confirm-button",
            },
          });
          return;
        }

        if (allGood) {
          onOpenModal();
          const today = moment();
          const thirteenYearsAgo = today.subtract(13, "years");

          var lastOrderID = "";
          var options = {
            customer_firstname: firstname,
            customer_lastname: lastname,
            customer_email: email,
            customer_phone: phone,
            customer_address: address,
            customer_city: city,
            customer_state: state,
            customer_zip: zip,
            customer_username: "",
            customer_password: cust_pwd,
            customer_marketing_optin: customer_marketing_optin,
            customer_dob: thirteenYearsAgo.format("YYYY-MM-DD"),
            store_id: store.store_id,
            merchant_id: store.merchant_id,
            customer_fb_id: 0,
            customer_signup_type: "",
          };

          if (sessionStorage.getItem("lastOrderID")) {
            options.lastOrderID = sessionStorage.getItem("lastOrderID");
          }

          axios
            .post(API_ROOT + Method.SIGNUP, options)
            .then((response) => {
              onCloseModal();
              if (response.data.status === 1) {
                Swal.fire({
                  title: i18n.t("account_success"),
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.value) {
                    var data = response.data.customer_data[0];
                    dbconn.deleteUserFromIndexedDB(4);
                    // // dbconn.deleteCartIndexedDB(3)
                    dbconn.addUserInfoToIndexedDB(data);
                    sessionStorage.clear();
                    sessionStorage.setItem("guestFlag", "N");
                    setRedirect(true);
                  }
                });
              } else {
                if (
                  response.data.message ===
                  "Phone Number already exists, please register with a different phone number."
                ) {
                  Swal.fire({
                    title: i18n.t("phone_exists"),
                    allowOutsideClick: false,
                  });
                } else if (
                  response.data.message ===
                  "Email already exists, please register with a different email."
                ) {
                  Swal.fire({
                    title: i18n.t("email_exists"),
                    allowOutsideClick: false,
                  });
                } else {
                  Swal.fire({
                    title: response.data.message,
                    allowOutsideClick: false,
                  });
                }
              }
            })
            .catch((error) => {
              onCloseModal();
              console.log(error);
            });
        }
      }
    });
    // navigate("/signup")
    // sessionStorage.setItem("guestFlag", "Y");
    // sessionStorage.clear();
    // dbconn.getUser().then((data) => {
    //   if (data && data.length > 0) {
    //     var firstname = data[0].customer_firstname;
    //     var lastname = data[0].customer_lastname;
    //     var email = data[0].customer_email;
    //     var phone = data[0].customer_phone;
    //     var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
    //     var address = data[0].customer_address;
    //     var city = data[0].customer_city;
    //     var state = data[0].customer_state;
    //     var zip = data[0].customer_zip;
    //     var cust_pwd = password;
    //     var customer_marketing_optin = marketingChkBox ? "Y" : "N";
    //     var allGood = true;

    //     if (cust_pwd.length < 7) {
    //       allGood = false;
    //       Swal.fire({
    //         title: i18n.t("password_inst"),
    //         allowOutsideClick: false,
    //       });
    //       return;
    //     }

    //     if (!termsChkBox) {
    //       allGood = false;
    //       Swal.fire({
    //         title: i18n.t("terms_and_conditions"),
    //         allowOutsideClick: false,
    //       });
    //       return;
    //     }

    //   if (allGood) {
    //     onOpenModal();

    //     var lastOrderID = "";
    //     var options = {
    //       customer_firstname: firstname,
    //       customer_lastname: lastname,
    //       customer_email: email,
    //       customer_phone: phone,
    //       customer_address: address,
    //       customer_city: city,
    //       customer_state: state,
    //       customer_zip: zip,
    //       customer_username: "",
    //       customer_password: cust_pwd,
    //       customer_marketing_optin: customer_marketing_optin,
    //       customer_dob: "0000-00-00",
    //       store_id: store.store_id,
    //       merchant_id: store.merchant_id,
    //       customer_fb_id: 0,
    //     };

    //     if (sessionStorage.getItem("lastOrderID")) {
    //       options.lastOrderID = sessionStorage.getItem("lastOrderID");
    //     }
    //     axios
    //       .post(API_ROOT + Method.SIGNUP, options)
    //       .then((response) => {
    //         onCloseModal();
    //         if (response.data.status === 1) {
    //           Swal.fire({
    //             title: i18n.t("account_success"),
    //             allowOutsideClick: false,
    //           }).then((result) => {
    //             if (result.value) {

    //               var data = response.data.customer_data[0];
    //               dbconn.deleteUserFromIndexedDB(4)
    //               // dbconn.deleteCartIndexedDB(3)
    //               dbconn.addUserInfoToIndexedDB(data)
    //               // dbconn.setUser({
    //               //   customer_id: data.customer_id,
    //               //   customer_token: data.userToken,
    //               //   customer_firstname: data.customer_firstname,
    //               //   customer_lastname: data.customer_lastname,
    //               //   customer_email: data.customer_email,
    //               //   customer_address: data.customer_address,
    //               //   customer_city: data.customer_city,
    //               //   customer_state: data.customer_state,
    //               //   customer_zipcode: data.customer_zipcode,
    //               //   customer_phone: data.customer_phone,
    //               //   customer_fb_id: 0,
    //               // });
    //               // localStorage.setItem("user_id", data.customer_id);
    //               // localStorage.setItem(
    //               //   "user_name",
    //               //   data.customer_firstname + " " + data.customer_lastname
    //               // );
    //               // localStorage.setItem("user_email", data.customer_email);
    //               sessionStorage.clear();
    //               sessionStorage.setItem("guestFlag", "N");
    //               setRedirect(true);
    //             }
    //           });
    //         } else {
    //           if (
    //             response.data.message ===
    //             "Phone Number already exists, please register with a different phone number."
    //           ) {
    //             Swal.fire({
    //               title: i18n.t("phone_exists"),
    //               allowOutsideClick: false,
    //             });
    //           } else if (
    //             response.data.message ===
    //             "Email already exists, please register with a different email."
    //           ) {
    //             Swal.fire({
    //               title: i18n.t("email_exists"),
    //               allowOutsideClick: false,
    //             });
    //           } else {
    //             Swal.fire({
    //               title: response.data.message,
    //               allowOutsideClick: false,
    //             });
    //           }
    //         }
    //       })
    //       .catch((error) => {
    //         onCloseModal();
    //         console.log(error);
    //       });
    //   }
    // }
    // });
  };

  const saveRating = (value) => {
    const guestFlag = sessionStorage.getItem("guestFlag");
    dbconn.getUserInfoFromIndexedDB().then((data) => {
      // console.log("data", data)
      if (!data || data.length < 1 || guestFlag === "Y") {
        // sessionStorage.removeItem("guestFlag");
        setNosessionRedirect(true);
      } else {
        const options = {
          user_id: data.user_id,
          store_id: store.store_id,
          guestFlag: guestFlag,
          rating_star: value,
        };

        const url = API_ROOT + Method.SAVE_RATING;
        axios
          .post(url, options)
          .then((response) => {
            onCloseModal();

            if (response.data.status == 1) {
              sessionStorage.setItem("rating", true);
            } else {
              Swal.fire({
                title: response.data.message,
                showCancelButton: false,
                confirmButtonText: t("ok"),
                allowOutsideClick: false,
                customClass: {
                  confirmButton: "my-confirm-button",
                },
              });
            }
          })
          .catch(function (error) {
            alert(error);
            console.log(error);
          });
      }
    });
  };

  const goToProduct = () => {
    var guestFlag = sessionStorage.getItem("guestFlag") || "";
    sessionStorage.clear();

    if (guestFlag !== "") {
      sessionStorage.setItem("guestFlag", guestFlag);
    }
    setRewardRedirect(true);
  };

  var inst = localStorage.getItem("store_curbside_desc");
  if (nosessionredirect) {
    navigate("/");
    // console.log("480")
  }
  if (rewardRedirect) {
    navigate("/rewards");
  }
  if (redirect) {
    navigate("/");
    // console.log("487")
  }

  return (
    <>
      {/* <div className="confirmPageMainDiv">

      </div> */}
      <div className="confirmPageMainDiv">
        {/* <header className="header-div position-fixed w-100 shadow">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <a
              onClick={loadMenu}
              style={{ cursor: "pointer" }}
              className="float-left"
            >
              <BsChevronLeft />
            </a>
            <h2 className="text-center mb-0 roboto-bold-24">{t("checkout")}</h2>
          </div>
        </header> */}
        <div className="container-fluid h-100">
          <div className="row">
            <div className="col-lg-8 col-md-10 col-xs-12 mx-auto my-4 vh-100p">
              <div className="LoginRegisterBlock position-relative OrderConfirmedBlock">
                <form action="#" method="post" encType="multipart/form-data">
                  <div className="LoginRegisterForm float-left w-100">
                    <div className="order_confirmed orderConfirmed w-75 mx-auto p-3 text-center mb-4">
                      <h3 className="roboto-medium-20">{t("order_success")}</h3>
                      <p className="roboto-medium-16 mb-2">
                        {t("order_thankyou")}
                      </p>
                      <p className="mb-2 roboto-regular-14">
                        {t("order_confirmation")}
                      </p>
                      <a
                        href="#"
                        onClick={loadMenu}
                        className="mt-3 mb-2 d-block roboto-medium-14"
                        style={{ color: "black", cursor: "pointer" }}
                      >
                        {t("start_new_order")}
                      </a>
                      {/* <div className="hr_grey pb-0"></div> */}
                    </div>
                    {sessionStorage.getItem("front") &&
                    sessionStorage.getItem("back") ? (
                      <div className="order_confirmed  orderConfirmed w-75 mx-auto p-3 text-center mb-4">
                        <p className="roboto-medium-20 mb-2">
                          {t("id_present_disclaimer")}{" "}
                        </p>

                        <div
                          style={{ display: "inline-block" }}
                          className="Upload_CaptureImageBlock w-100 mb-3 mt-2"
                        >
                          <div className="d-inline">
                            <div
                              style={{ marginRight: "10px" }}
                              className="bg-grey p-1 mt-2 d-inline-block rounded h-100px position-relative"
                            >
                              <div className="bg-white p-1">
                                <img
                                  id="firstSmall"
                                  src={
                                    IMAGE_ROOT +
                                    "/images/orders/" +
                                    sessionStorage.getItem("front")
                                  }
                                  alt="Frond Id Image"
                                  height="102px;"
                                />
                              </div>
                            </div>
                          </div>
                          <div className=" CaptureImage d-inline">
                            <div
                              style={{ marginRight: "10px" }}
                              className="bg-grey p-1 mt-2 d-inline-block rounded h-100px position-relative"
                            >
                              <div className="bg-white p-1">
                                <img
                                  id="firstSmall"
                                  src={
                                    IMAGE_ROOT +
                                    "/images/orders/" +
                                    sessionStorage.getItem("back")
                                  }
                                  alt="Frond Id Image"
                                  height="102px;"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <p className="fontHelveticaNuueMedium font-italic mb-2">
                          {/* FAILURE TO PROVIDE VALID ID WILL RESULT IN A
                        REFUNDED/CANCELED ORDER. */}
                          {t("failur_to_provide_id")}
                        </p>

                        <div className="hr_grey pb-0"></div>
                      </div>
                    ) : (
                      ""
                    )}
                    {ratingTrue == true &&
                      sessionStorage.getItem("guestFlag") === "N" && (
                        <div className="order_confirmed orderConfirmed w-75 mx-auto p-3 text-center mb-4">
                          {rating <= 0 ? (
                            <>
                              <h4 className="roboto-medium-18">
                                How do you rate ou customer service?
                              </h4>
                              <Rating
                                name="customized-empty"
                                defaultValue={rating}
                                emptyIcon={<FaRegStar fontSize="inherit" />}
                                onChange={(event, newValue) => {
                                  setRating(newValue);
                                  saveRating(newValue);
                                }}
                              />
                            </>
                          ) : (
                            <h4 className="roboto-medium-18">
                              Thank you for rating.
                            </h4>
                          )}
                        </div>
                      )}
                    {sessionStorage.getItem("orderType") === "C" ? (
                      <div className="order_confirmed text-center">
                        <h3 className="roboto-medium-20">
                          {t("curbside_information")}
                        </h3>

                        <p className="fontHelveticaNuueBold mb-2">{inst}</p>

                        <div className="hr_grey pb-0"></div>
                      </div>
                    ) : (
                      ""
                    )}

                    {store.store_loyalty_enabled === "Y" ? (
                      <div className="order_confirmed ">
                        {sessionStorage.getItem("guestFlag") === "N" ? (
                          <>
                            <h3 className="roboto-medium-20">
                              {t("earned_points", {
                                points: earnedPoints,
                              })}
                            </h3>
                            <h3 className="roboto-medium-20">
                              {/* YOUR POINTS TOTAL: {customerLoyaltyPoints}{" "} */}
                              {t("your_total_points")} {availablePoints}{" "}
                            </h3>
                          </>
                        ) : (
                          ""
                        )}

                        {sessionStorage.getItem("guestFlag") === "Y" ? (
                          <p className="fontHelveticaNuueBold mb-3 text-center roboto-regular-14">
                            {/* By creating an account, you can save and redeem points
                          for rewards such as: */}
                            {t("create_account_inst")}
                          </p>
                        ) : (
                          // nextReward > 0 ? (
                          //   <p className="fontHelveticaNuueBold mb-3 text-center roboto-regular-14">
                          //     {t('earned_points_away_rewords',
                          //       {
                          //         points: earnedPoints
                          //         , remainingPoints: parseInt(nextReward) -
                          //           parseInt(customerLoyaltyPoints)
                          //       }
                          //     )}
                          //   </p>
                          // ) :
                          availablePoints > 0 && (
                            <p className="fontHelveticaNuueBold mb-3 text-center roboto-regular-14">
                              {t("earned_points_on_this_order", {
                                points: earnedPoints,
                              })}
                            </p>
                          )
                        )}

                        {sessionStorage.getItem("guestFlag") === "Y" ? (
                          <div>
                            {productList.map((item, index) => {
                              var productImageHide =
                                "productsBlock ProductHome shadow1 RewardsBlock no-image p-3";

                              if (item.product_image !== "") {
                                productImageHide =
                                  "productsBlock ProductHome shadow1 RewardsBlock p-3";
                              }

                              return (
                                <div
                                  key={index}
                                  className="card productsBlock p-3"
                                >
                                  <div className="cardBody w-100 d-flex gap-2">
                                    {parseInt(0) <
                                    parseInt(item.product_loyalty_price) ? (
                                      item.product_image && (
                                        <div className="productsBlockRight product-img">
                                          {item.product_image && (
                                            <img
                                              src={
                                                IMAGE_ROOT +
                                                "/images/product/" +
                                                item.product_image
                                              }
                                              className="img-fluid img-reward rewardImage"
                                              alt="img"
                                            />
                                          )}

                                          {parseInt(0) <
                                          parseInt(
                                            item.product_loyalty_price
                                          ) ? (
                                            <div className="overlay-img"></div>
                                          ) : (
                                            <div
                                              className="overlay-img"
                                              style={{ width: "0%" }}
                                            ></div>
                                          )}
                                        </div>
                                      )
                                    ) : item.product_image !== "" ? (
                                      <div className="image">
                                        <img
                                          src={
                                            IMAGE_ROOT +
                                            "/images/product/" +
                                            item.product_image
                                          }
                                          className="rewardImage"
                                          alt=""
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="cardDetail w-100">
                                      <p
                                        className="roboto-medium-18 m-0"
                                        id={"product_name_" + item.product_id}
                                      >
                                        {localStorage.getItem(
                                          "store_language"
                                        ) === "en"
                                          ? item.product_name
                                          : item[
                                              "product_name_" +
                                                localStorage.getItem(
                                                  "store_language"
                                                )
                                            ] !== ""
                                          ? item[
                                              "product_name_" +
                                                localStorage.getItem(
                                                  "store_language"
                                                )
                                            ]
                                          : item.product_name}
                                      </p>
                                      <p className="roboto-regular-18 m-0">
                                        {localStorage.getItem(
                                          "store_language"
                                        ) === "en"
                                          ? item.product_description
                                          : item[
                                              "product_description_" +
                                                localStorage.getItem(
                                                  "store_language"
                                                )
                                            ] !== ""
                                          ? item[
                                              "product_description_" +
                                                localStorage.getItem(
                                                  "store_language"
                                                )
                                            ]
                                          : item.product_description}
                                      </p>
                                      <div className="cardBottom w-100 d-flex align-items-center justify-content-between">
                                        <p className="mb-0 float-left roboto-medium-16">
                                          {parseInt(item.product_loyalty_price)}{" "}
                                          {t("points")}
                                        </p>
                                        {parseInt(0) <
                                        parseInt(item.product_loyalty_price) ? (
                                          <button
                                            type="button"
                                            className="btn btnLogin btn-yellow float-right open-modal-addcard w-120px btn_locked py-1"
                                          >
                                            {t("redeem")}
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn btnLogin py-1 btn-yellow float-right open-modal-addcard w-120px"
                                            onClick={() => {
                                              goToProduct();
                                            }}
                                          >
                                            {t("redeem")}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div>
                            {productList.map((item, index) => {
                              var productImageHide =
                                "productsBlock ProductHome shadow1 RewardsBlock no-image p-3";

                              if (item.product_image !== "") {
                                productImageHide =
                                  "productsBlock ProductHome shadow1 RewardsBlock p-3";
                              }

                              return (
                                <div
                                  key={index}
                                  className="card productsBlock p-3"
                                >
                                  <div className="cardBody w-100 d-flex gap-2">
                                    {parseInt(availablePoints) <
                                    parseInt(item.product_loyalty_price) ? (
                                      item.product_image && (
                                        <div className="productsBlockRight product-img">
                                          {item.product_image && (
                                            <img
                                              src={
                                                IMAGE_ROOT +
                                                "/images/product/" +
                                                item.product_image
                                              }
                                              className="img-fluid img-reward rewardImage"
                                              alt="img"
                                            />
                                          )}

                                          {parseInt(availablePoints) <
                                          parseInt(
                                            item.product_loyalty_price
                                          ) ? (
                                            <div className="overlay-img"></div>
                                          ) : (
                                            <div
                                              className="overlay-img"
                                              style={{ width: "0%" }}
                                            ></div>
                                          )}
                                        </div>
                                      )
                                    ) : item.product_image !== "" ? (
                                      <div className="image">
                                        <img
                                          src={
                                            IMAGE_ROOT +
                                            "/images/product/" +
                                            item.product_image
                                          }
                                          className="rewardImage"
                                          alt=""
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="cardDetail w-100">
                                      <p
                                        className="roboto-medium-18 m-0"
                                        id={"product_name_" + item.product_id}
                                      >
                                        {localStorage.getItem(
                                          "store_language"
                                        ) === "en"
                                          ? item.product_name
                                          : item[
                                              "product_name_" +
                                                localStorage.getItem(
                                                  "store_language"
                                                )
                                            ] !== ""
                                          ? item[
                                              "product_name_" +
                                                localStorage.getItem(
                                                  "store_language"
                                                )
                                            ]
                                          : item.product_name}
                                      </p>
                                      <p className="roboto-regular-18 m-0">
                                        {localStorage.getItem(
                                          "store_language"
                                        ) === "en"
                                          ? item.product_description
                                          : item[
                                              "product_description_" +
                                                localStorage.getItem(
                                                  "store_language"
                                                )
                                            ] !== ""
                                          ? item[
                                              "product_description_" +
                                                localStorage.getItem(
                                                  "store_language"
                                                )
                                            ]
                                          : item.product_description}
                                      </p>
                                      <div className="cardBottom w-100 d-flex align-items-center justify-content-between">
                                        <p className="mb-0 float-left roboto-medium-16">
                                          {parseInt(item.product_loyalty_price)}{" "}
                                          {t("points")}
                                        </p>
                                        {parseInt(availablePoints) <
                                        parseInt(item.product_loyalty_price) ? (
                                          <button
                                            type="button"
                                            className="btn btnLogin btn-yellow float-right open-modal-addcard w-120px btn_locked py-1"
                                          >
                                            {t("redeem")}
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            className="btn btnLogin py-1 btn-yellow float-right open-modal-addcard w-120px"
                                            onClick={() => {
                                              goToProduct();
                                            }}
                                          >
                                            {t("redeem")}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* <div className="hr_grey pb-0"></div> */}

                    {sessionStorage.getItem("guestFlag") === "Y" ? (
                      <div className="OrderConfirmedCreateAccountBlock mt-2 mb-2 d-inline-block w-100">
                        <div className="OrderConfirmedCreateAccountBlock-w-70">
                          <div className="order_confirmed text-center">
                            <h3 className="roboto-medium-20">
                              {t("create_an_account")}
                            </h3>
                            <p className="fontHelveticaNuueBold roboto-regular-14">
                              {t(
                                "by_signing_up_your_have_access_to_features_such_as"
                              )}
                            </p>
                            <ul className="mb-4">
                              <li>• {t("special_offers")}</li>
                              <li>• {t("personalized_orofile")}</li>
                              <li>• {t("customer_loyalty_points")}</li>
                              <li>• {t("saved_payment_methods")}</li>
                            </ul>
                            <p>{t("assign_password")}</p>
                          </div>
                          <div className="clearfix"></div>
                          <div className="form-group mb-2 w-75 m-auto pt-2 w-m-100">
                            <label className="text-uppercase">
                              {t("password")}
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <div className="form-group mb-2 clearfix">
                            <div className="custom-control custom-checkbox float-left">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="termsChkBox"
                                onChange={(e) =>
                                  setTermsChkBox(e.target.checked)
                                }
                              />
                              <label
                                className="custom-control-label pt-6px fontHelveticaNuueBold font-13"
                                htmlFor="termsChkBox"
                              >
                                {t("age_disclaimer")}{" "}
                                <Link
                                  onClick={() => {
                                    fetchTermAndCon();
                                  }}
                                  className="underline"
                                  style={{ color: "var(--primaryColor)" }}
                                >
                                  {t("terms_link")}
                                </Link>
                                .
                              </label>
                            </div>
                          </div>
                          <div className="form-group mb-2 clearfix">
                            <div className="custom-control custom-checkbox float-left">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="marketingChkBox"
                                onChange={(e) =>
                                  setMarketingChkBox(e.target.checked)
                                }
                              />
                              <label
                                className="custom-control-label pt-6px fontHelveticaNuueBold font-13"
                                htmlFor="marketingChkBox"
                              >
                                {t("signup_check")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {sessionStorage.getItem("guestFlag") === "Y" ? (
                      <div className="form-group mb-4 w-50 m-auto">
                        <input
                          type="button"
                          className="btn btn-yellow w-100 btn-lg text-uppercase"
                          onClick={signup}
                          value={t("create_an_account")}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {open && (
          <div className="loader">
            <img src={loader} alt="" width={"300px"} height={"210px"} />
          </div>
        )}
        {openLoading && (
          <div className="loader">
            <img src={loader} alt="" width={"300px"} height={"210px"} />
          </div>
        )}
      </div>
    </>
  );
}
