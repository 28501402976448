let backendHost;
let imageHost;
let sub_domain;
const hostname = window && window.location && window.location.hostname;

imageHost = 'https://api.hashmedia.co.in';
// imageHost = 'https://app.tradefuel.buzz';
// imageHost = 'http://localhost:16000'
sub_domain = window.location.hostname;

if (hostname === 'localhost') {
     // backendHost = 'http://localhost:20000';
     // backendHost = 'https://app.tradefuel.buzz';
     backendHost = 'https://api.hashmedia.co.in';
     //
     imageHost = imageHost;

} else if (hostname === "192.168.1.11") {
     // backendHost = 'http://localhost:16000';
     backendHost = 'https://api.hashmedia.co.in';
     imageHost = backendHost;
} else {
     backendHost = 'https://' + hostname;
}
// console.log(backendHost);
export const API_ROOT = `${backendHost}/webservices`;
export const IMAGE_ROOT = `${imageHost}`;
export const SUB_DOMAIN = `${sub_domain}`;
export const DOMAIN = `${hostname}`;
export const PROJECT = "Tria Commerce";


export const Method = {
     //Auth
     LOGIN: "/login",
     SIGNUP: "/signup",
     FORGOT_PASSWORD: "/forgotpassword",
     FETCH_PROFILE: "/fetchprofile",
     UPDATE_PROFILE: "/updateprofile",

     //Other
     FETCH_ORDER_HISTORY: "/fetchorderhistory",
     FETCH_STORE_STATE: "/fetchstorestats",
     FETCH_ORDER_BY_ID: "/fetchorderbyid",
     FETCH_SAVED_CARDS: "/fetchsavedcards",
     ADD_CARD: "/addcard",
     FETCH_STORE_FROM_ID: "/fetchstoreid",
     FETCH_LOYALTY_MENU: '/fetchloyaltymenu',
     FETCH_DISTANCE: '/fetchdistance',
     FETCH_STORE_SCHEDULE: '/fetchstoreschedule',
     FETCH_STORE_TIMING: '/fetchstoretiming',
     VERIFY_COUPON: '/verifycoupon',
     CREATE_ORDER: '/createorder',
     FETCH_MENU: '/fetchmenu',
     FETCH_CATEGORY_LIST: '/fetchcategorylist',
     FETCH_CUSTOMER_POINT: '/fetchcustomerpoints',
     REORDER: '/reorder',
     FETCH_LOYALTY_MENU_END: '/fetchloyaltymenuend',
     UPLOAD_FILES: '/uploadfiles',
     FETCH_PRODUCT_DETAILS: '/fetchproductdetails',
     SAVE_RATING: '/saverating',
     VERIFY_SSO: '/verifysso',
     SAVE_TOKEN: '/savetoken',

     RESET_PASSWORD: "/resetpassword"
}
