import i18n from "i18next";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { BsDash } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { API_ROOT, IMAGE_ROOT, Method, SUB_DOMAIN } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import loader from "../image/res_home_page_loader.gif";
import { addToCart, editCartItem } from "../store/slices/CartSlice";
import { toggleModal } from "../store/slices/modalSlice";
import Accordion from "./Accordion";
import CustomDropdown from "./CustomDropdown";
import OverageImageUpload from "./OverageImageUpload";

function ProductDetails(props) {
  const { t } = useTranslation(); // use for change language
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isProductEdit = props.productData.productEdit; // if user click on product in cart page for update then arrive true otherwise false
  const productID = props.productData.productID; // selected product id
  const productIndex = props.productData.productIndex; // arrive product index drom product list
  const productLoyality = props.productData.loyalty; // if product add in order from reward page then Y otherwise undefined
  const cartItem = useSelector((state) => state.cart.cart);
  const [isLoading, setIsLoading] = useState(false);
  const [productQty, setProductQty] = useState(1); // product QTY set, default 1
  const [productDetails, setProductDetails] = useState([]); // after fetch product using id product data set
  const [modifiers, setModifiers] = useState([]); // after fetch product using id product modifiers set
  const [speacialNote, setSpecialNote] = useState(false); // for product special note "Y" then set true
  const [specialNoteTxt, setSpecialNoteTxt] = useState(""); // for product speacial note input value
  //   const [loyalty, setLoyalty] = useState("");
  //   const [type, setType] = useState("");
  const [modPrice, setModPrice] = useState(0);
  const [modOriginalPrice, setModOriginalPrice] = useState(0);
  //   const [totalModPrice, setTotalModPrice] = useState(0);
  const [validation, setValidation] = useState(false); // validation for requied modifier select
  const [overAgeProduct, setOverAgeProduct] = useState(false); // if over age product select then ID photo import modal open
  const [imageEmpty, setImageEmpty] = useState(false); // if product image empty string then set true otherwise false
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [heightLess600, setHeightLess600] = useState(false);
  // const storeDetails = useSelector((state) => state.fetchstore)
  const [store, setStore] = useState([]);

  useEffect(() => {
    let height = window.innerHeight;
    if (height < 600) {
      setHeightLess600(true);
    }

    fetchStoreData();
    fetchCartItem();
  }, []);

  /*
   * Function to fetch store data based on a specific store ID (sub_domain).
   * This function sets a loading state, constructs the URL, and fetches data from the API.
   * If the store is found and valid, it processes the data and manages session settings.
   * Otherwise, it handles cases where the store data isn't found.
   */
  const fetchStoreData = async () => {
    setIsLoading(true);
    // try {
    const url = `${API_ROOT}${Method.FETCH_STORE_FROM_ID}?sub_domain=${SUB_DOMAIN}`;

    const response = await fetch(url);
    const responseData = await response.json();

    if (responseData.status === 1) {
      const storeData = responseData.store;
      if (storeData) {
        // store details update in indexedDB
        dbconn.addStoreToIndexedDB(storeData);

        // Set primary color if provided
        if (storeData.store_primary_color != null) {
          document.documentElement.style.setProperty(
            "--primaryColor",
            storeData.store_primary_color
          );
        }
        if (storeData.store_secondary_color != null) {
          document.documentElement.style.setProperty(
            "--secondryColor",
            storeData.store_secondary_color
          );
        }

        // set language
        i18n
          .changeLanguage(localStorage.getItem("store_language"))
          .then((r) => {});

        productDetailFetch(storeData.store_id);
      } else {
        setIsLoading(false);
        navigate("/");
      }
    } else {
      setIsLoading(false);
      navigate("/");
    }
  };

  /*
   * productDetailFetch Function:
   * This function fetches product details based on the storeID. If isProductEdit is true, it retrieves existing product details from cartItem and bypasses the API fetch.
   * Flow:
     - If isProductEdit is true, the function uses existing product data, calls generateProductDetails, and returns early.
     - If not in edit mode, it fetches product data from an API endpoint.
     - Handles unauthorized access (403/401 status codes) by calling handleUnauthorizedAccess.
     - On successful response, it calls generateProductDetails with the fetched data.
     - Catches and logs errors, and sets loading state to false in case of error.
   */
  const productDetailFetch = async (storeID) => {
    if (isProductEdit) {
      const productData = cartItem[productIndex];
      generateProductDetails(productData);
      return;
    }

    setIsLoading(true);
    const guestFlag = sessionStorage.getItem("guestFlag");
    const URL = `${API_ROOT}/fetchproductdetails?guestFlag=${guestFlag}&store_id=${storeID}&product_id=${productID}`;

    try {
      const response = await fetch(URL);

      if (response.status === 403 || response.status === 401) {
        handleUnauthorizedAccess();
        return;
      }

      const productData = await response.json();
      generateProductDetails(productData);
    } catch (error) {
      console.error("Error fetching product details:", error);
      setIsLoading(false);
    }
  };

  /*
   * handleUnauthorizedAccess Function:
   * Handles cases where the user is unauthorized (403/401 status).
   * Actions:
     - Stops the loading indicator, clears certain user data in IndexedDB, sets the user as a guest, and navigates to the login page.
   */
  const handleUnauthorizedAccess = () => {
    setIsLoading(false);
    dbconn.deleteUserFromIndexedDB(4);
    sessionStorage.setItem("guestFlag", "Y");
    navigate("/login");
  };

  /*
   * generateProductDetails Function:
   * Processes product data for display and initialization.
   * Actions:
     - Verifies if the product is available based on its status.
     - Initializes product details, sets flags for image availability, and validates age restrictions.
     - Configures product modifiers and handles product-specific properties.
     - If in edit mode, sets specific details such as quantity and notes.
     - If not in edit mode, calls initializeModifiers to set up modifier options and prices.
  */
  const generateProductDetails = (product) => {
    const { product_data, dropdown_mod, status } = product;

    if (!isProductEdit && status !== 1) {
      setIsLoading(false);
      console.log("ERROR => Product not fetched");
      return;
    }

    setImageEmpty(!product_data.product_image);
    setProductDetails(product_data);
    overAgeValidation(product_data);
    setModifiers(dropdown_mod);

    if (isProductEdit) {
      setProductQty(product_data.product_quantity);
      setSpecialNoteTxt(product_data.product_notes);
      specialNoteEnabled(product_data.product_notes_enabled);
      calculateModifierPrice(dropdown_mod, product_data);
    } else {
      initializeModifiers(dropdown_mod);
      calculateModifierPrice(dropdown_mod, product_data);
    }

    setIsLoading(false);
  };

  /*
   * initializeModifiers function:
   * Initializes product modifiers, calculating total price and default selections.
   * Logic:
     - Iterates over each modifier and checks its type and selection constraints.
     - Calculates and sets the default price and selection status for each modifier.
  */
  const initializeModifiers = (modifiers) => {
    let modPrice = 0;
    let modOriginalPrice = 0;

    modifiers.forEach((modifier) => {
      if (modifier.modifier_type === "N") {
        if (modifier.modifier_multiselect === "N") {
          modPrice += setDefaultOptions(modifier, modOriginalPrice, false);
        } else {
          modPrice += setDefaultOptions(modifier, modOriginalPrice, true);
        }
      }
    });

    setModPrice(modPrice);
    setModOriginalPrice(modOriginalPrice);
    setModifiers(modifiers);
  };

  /*
   * setDefaultOptions function:
   * Sets default options for modifiers and calculates their price.
   * Logic:
     - Iterates over options within each modifier, checking if they’re marked as default.
     - If so, updates the price, original price, and selection status.
     - Compiles selected options for multi-select modifiers.
  */
  const setDefaultOptions = (modifier, modOriginalPrice, multiSelect) => {
    let selectedNames = [];
    let modifierPrice = 0;

    modifier.options.forEach((option, index) => {
      if (option.option_default === "Y") {
        modifierPrice += option.option_price;
        modOriginalPrice += option.option_original_price;

        option.option_selected = "Y";
        if (multiSelect) selectedNames.push(option.option_name);
      } else {
        option.option_selected = "N";
      }
    });

    modifier.mod_value = multiSelect ? selectedNames.join(",") : "";
    return modifierPrice;
  };

  /*
   * specialNoteEnabled Function:
   * Toggles a special note feature based on a flag
   */
  const specialNoteEnabled = (enabled) => {
    setSpecialNote(enabled === "Y");
  };

  /*
   * handleCheckboxChange function:
   * Handles the checkbox selection for a modifier option, updating the selection state and recalculating the modifier price.
   * Flow:
     - Extracts the checked status (isChecked) from the event.
     - Calls updateModifiersWithCheckbox to get an updated list of modifiers with the modified checkbox state.
     - Updates the modifiers state with setModifiers and recalculates the modifier price using calculateModifierPrice
  */
  const handleCheckboxChange = (modifierIndex, optionIndex, event) => {
    const isChecked = event.target.checked;
    const updatedModifiers = updateModifiersWithCheckbox(
      modifiers,
      modifierIndex,
      optionIndex,
      isChecked
    );

    setModifiers(updatedModifiers);
    calculateModifierPrice(updatedModifiers);
  };

  /*
   * updateModifiersWithCheckbox function:
   * Updates a specific modifier’s options based on the selected checkbox.
   * Logic:
     - Iterates over each modifier to find the target one by modifierIndex.
     - For the target modifier, it calls updateModifierOptions to update the option’s selected state.
     - After updating the options, it calculates the selected option names by calling getSelectedOptions, which will be stored in mod_value.
     - Returns the modified list of modifiers.
  */
  const updateModifiersWithCheckbox = (
    modifiers,
    modifierIndex,
    optionIndex,
    isChecked
  ) => {
    return modifiers.map((modifier, mIndex) => {
      if (mIndex !== modifierIndex) return modifier; // Only modify the target modifier

      const updatedOptions = updateModifierOptions(
        modifier.options,
        optionIndex,
        isChecked
      );

      return {
        ...modifier,
        options: updatedOptions,
        mod_value: getSelectedOptions(updatedOptions), // Helper function to get selected options
      };
    });
  };

  /*
   * updateModifierOptions function:
   * Updates the selection state of a specific option within a modifier based on the checkbox state.
   * Logic:
     - Iterates through the options within a modifier.
     - For the target option (matching optionIndex), sets its option_selected state based on isChecked.
     - Other options retain their current selection state.
     - Returns the updated list of options.
  */
  const updateModifierOptions = (options, optionIndex, isChecked) => {
    return options.map((option, oIndex) => ({
      ...option,
      option_selected:
        oIndex === optionIndex
          ? isChecked
            ? "Y"
            : "N"
          : option.option_selected,
    }));
  };

  /*
   * getSelectedOptions function:
   * Compiles the names of selected options within a modifier for display or storage.
   * Logic;
     - Filters the options to include only those with option_selected set to "Y".
     - Maps selected options to their names and joins them with a comma for easy display.
     - Returns an empty string if no options are selected.
  */
  const getSelectedOptions = (options) => {
    const selectedOptions = options
      .filter((option) => option.option_selected === "Y")
      .map((option) => option.option_name);
    return selectedOptions.length > 0 ? selectedOptions.join(", ") : "";
  };

  /*
   * handleDropdownChange funcation:
   * Handles the selection of an option from a dropdown for a specific modifier, updates the modifier's state, and recalculates the price.
   * Flow:
     - Calls updateModifiersWithDropdown with the modifier index and the selected option index to update the modifiers list.
     - Updates the modifiers state using setModifiers.
     - Recalculates the modifier price using calculateModifierPrice
  */
  const handleDropdownChange = (modifierIndex, selectedOptionIndex) => {
    const updatedModifiers = updateModifiersWithDropdown(
      modifiers,
      modifierIndex,
      selectedOptionIndex
    );

    setModifiers(updatedModifiers);
    calculateModifierPrice(updatedModifiers);
  };

  /*
   * updateModifiersWithDropdown function:
   * Updates a specific modifier’s options based on the selected dropdown option.
   * Logic:
     - Iterates over each modifier and checks if the current modifier is the one being updated (matches modifierIndex).
     - If it's the target modifier, it updates the selected option using updateDropdownOptions.
     - Sets mod_value to the selectedOptionIndex (or an empty string if the value is -1, indicating no selection).
     - Returns the updated list of modifiers.
  */
  const updateModifiersWithDropdown = (
    modifiers,
    modifierIndex,
    selectedOptionIndex
  ) => {
    return modifiers.map((modifier, index) => {
      if (index !== modifierIndex) return modifier;

      const updatedOptions = updateDropdownOptions(
        modifier.options,
        selectedOptionIndex
      );
      return {
        ...modifier,
        options: updatedOptions,
        mod_value: selectedOptionIndex === -1 ? "" : selectedOptionIndex,
      };
    });
  };

  /*
   * updateDropdownOptions function:
   * Updates the option_selected state for the options within a dropdown modifier
   * Logic:
     - Iterates through the modifier's options, checking if the current index matches the selected option index (selectedOptionIndex).
     - For the selected option, sets option_selected to "Y". For others, it sets option_selected to "N".
     - Returns the updated list of options
  */
  const updateDropdownOptions = (options, selectedOptionIndex) => {
    return options.map((option, index) => ({
      ...option,
      option_selected: index === selectedOptionIndex ? "Y" : "N",
    }));
  };

  /*
   * handleTextareaChange function:
   * Handles changes made to a textarea input for a specific modifier, updates the modifier's value, and recalculates the price
   * Flow:
     - Creates a shallow copy of the modifiers array (tempModifiers), ensuring that we do not mutate the original state directly.
     - Updates the mod_value of the specific modifier at modifierIndex with the new value entered in the textarea (e.target.value).
     - Sets the updated modifiers state with the modified array using setModifiers.
     - Recalculates the modifier price by calling calculateModifierPrice with the updated tempModifiers
  */
  const handleTextareaChange = (modifierIndex, e) => {
    let tempModifiers = [...modifiers];
    tempModifiers[modifierIndex] = {
      ...tempModifiers[modifierIndex],
      mod_value: e.target.value,
    };
    setModifiers(tempModifiers);
    calculateModifierPrice(tempModifiers);
  };

  /*
   * handleSpeacialNotes function:
   * Captures and updates the value of the special notes input (likely a textarea or text input field)
   * Flow:
     - Extracts the value entered by the user in the event (e.target.value).
     - Updates the state setSpecialNoteTxt with the new value, effectively storing the special note or comment.
  */
  const handleSpeacialNotes = (e) => {
    const value = e.target.value;
    setSpecialNoteTxt(value);
  };

  /*
   * calculateModifierPrice function:
   * This function calculates the total price of selected modifier options and updates the productDetails state with the calculated values.
   * Flow:
     - It initializes two variables, totalModPrice and totalOriginalModPrice, to keep track of the cumulative price of the selected modifier options and their original prices, respectively.
     - It then iterates over all modifiers and their options:
          - For each option that is selected (option_selected == "Y"), it adds the price (option_price) and original price (option_original_price) to the totals.
          - The condition option_required == "Y" seems to be a check for whether the option is required, but in the current code, it doesn't trigger any action if the option is not selected. This could be a spot for additional validation if needed
     - After calculating the total prices:
          - If data is not provided, it updates the productDetails state with the calculated modifier prices (modifier_price and modifier_original_price).
          - If data is provided (likely a custom product or passed in data), it updates that specific product with the new prices.
     - The modifierValidation function is called after price calculation, which likely performs additional checks or validation related to the modifiers.
  */
  const calculateModifierPrice = (tempModifiers, data) => {
    var totalModPrice = 0;
    var totalOriginalModPrice = 0;

    for (var i = 0; i < tempModifiers.length; i++) {
      for (var j = 0; j < tempModifiers[i].options.length; j++) {
        if (tempModifiers[i].options[j].option_required == "Y") {
          if (tempModifiers[i].options[j].option_selected == "N") {
          }
        }

        if (tempModifiers[i].options[j].option_selected == "Y") {
          totalModPrice += parseFloat(tempModifiers[i].options[j].option_price);
          totalOriginalModPrice += parseFloat(
            tempModifiers[i].options[j].option_original_price
          );
        }
      }
    }

    if (data == undefined) {
      let updatedProduct = { ...productDetails };
      updatedProduct.modifier_price = totalModPrice;
      updatedProduct.modifier_original_price = totalOriginalModPrice;
      setProductDetails(updatedProduct);
    } else {
      let updatedProduct = { ...data };
      updatedProduct.modifier_price = totalModPrice;
      updatedProduct.modifier_original_price = totalOriginalModPrice;
      setProductDetails(updatedProduct);
    }
    //     setTotalModPrice(totalModPrice);

    modifierValidation(tempModifiers);
  };

  /*
   * modifierValidation function:
   * This function validates the selected modifiers to ensure that required modifiers have at least one selected option or value. It checks both text-based modifiers and option-based ones.
   * Flow:
     - First, the tempModifiers array is deep-copied using JSON.parse(JSON.stringify()) to avoid direct mutations of the original state
     - The function iterates over each modifier in the tempModifiers array to check if it's marked as required (modifier_required == "Y")
     - For each required modifier, it performs the following checks:
          - Text-based Modifiers (modifier_type == "T"):
               If the modifier is text-based (i.e., the type is "T"), it checks if the mod_value is non-empty. If so, it sets a flag to true.
          - Option-based Modifiers:
               For option-based modifiers (non-text-based), it checks each option to see if it is selected (option_selected == "Y"). If any option is selected, it sets the flag to true and breaks out of the loop.
     - If no valid selection or value is found for a required modifier, modifierValidation is set to false, and the loop breaks.
     - The result of the validation (true or false) is then saved in the state using setValidation.
  */
  //   const modifierValidation = (tempModifiers) => {
  //     let modifier = JSON.parse(JSON.stringify(tempModifiers));
  //     var modifierValidation = true;
  //     for (var i = 0; i < modifier.length; i++) {
  //       if (modifier[i].modifier_required == "Y") {
  //         var flag = false;

  //         if (modifier[i].modifier_type == "T") {
  //           if (modifier[i].mod_value && modifier[i].mod_value != "") {
  //             flag = true;
  //           }
  //         } else {
  //           for (var j = 0; j < modifier[i].options.length; j++) {
  //             console.log("run");
  //             if (modifier[i].options[j].option_selected == "Y") {
  //               flag = true;
  //               break;
  //             }
  //           }
  //         }

  //         if (!flag) {
  //           modifierValidation = false;
  //           break;
  //         }
  //       }
  //     }

  //     setValidation(modifierValidation);
  //   };
  const modifierValidation = (tempModifiers) => {
    let modifiers = JSON.parse(JSON.stringify(tempModifiers));
    let isValid = true;

    for (let i = 0; i < modifiers.length; i++) {
      if (modifiers[i].modifier_required === "Y") {
        let hasSelection = false;

        if (modifiers[i].modifier_type === "T") {
          // Check if text-based modifier has a value
          if (modifiers[i].mod_value && modifiers[i].mod_value.trim() !== "") {
            hasSelection = true;
          }
        } else {
          // Check if at least one option is selected for option-based modifiers
          hasSelection = modifiers[i].options.some(
            (option) => option.option_selected === "Y"
          );
        }

        if (!hasSelection) {
          isValid = false;
          break;
        }
      }
    }

    setValidation(isValid);
  };

  /*
   * overAgeValidation function:
   * This function checks if the product has overage enabled and updates the state accordingly.
   * Flow:
     - It checks if the product_overage_enabled field in the data object is set to "Y", indicating that overage is enabled for the product.
     - If overage is enabled (product_overage_enabled === "Y"), it updates the state setOverAgeProduct(true) to indicate that overage is applicable.
     - If overage is not enabled, it sets setOverAgeProduct(false) to indicate that overage is not applicable for the product.
  */
  const overAgeValidation = (data) => {
    if (data.product_overage_enabled === "Y") {
      setOverAgeProduct(true);
    } else {
      setOverAgeProduct(false);
    }
  };

  const handleCloseToggel = (data) => {
    setIsModalOpen(data);
    handleAddtoCart();
  };

  const closeAgeImagePopupusingBtn = (data) => {
    setIsModalOpen(data);
  };

  /*
   * fetchCartItem function:
   * This function is responsible for fetching data from the cartItem array, copying the product data and modifier data for each cart item, and storing them in separate arrays (AllProductData and AllTempModifiresData).
   * Flow:
     - First, it clears the existing data in AllProductData and AllTempModifiresData by resetting them to empty arrays.
     - It then iterates through each item in the cartItem array, copying the product_data and dropdown_mod (which likely represent product details and modifiers) from each item.
     - The JSON.parse(JSON.stringify(item)) technique is used to create deep copies of the product_data and dropdown_mod to avoid mutating the original objects.
     - The copied product_data is added to AllProductData and the copied dropdown_mod is added to AllTempModifiresData
  */
  let AllProductData = [];
  let AllTempModifiresData = [];

  const fetchCartItem = () => {
    AllProductData = [];
    cartItem.forEach((item, index) => {
      AllProductData.push(JSON.parse(JSON.stringify(item.product_data)));
      AllTempModifiresData.push(JSON.parse(JSON.stringify(item.dropdown_mod)));
    });
  };

  const handleAddtoCart = () => {
    // 1. Modifier Validation Check
    // Ensures all required modifiers are selected before adding the product to the cart
    if (!validation) {
      toast.dismiss(); // Clears any existing toast notifications
      toast.error(`${t("modifier_required")}`); // Error message if required modifiers are missing
    }
    // 2. Age Restriction Check (if product is not in edit mode)
    else if (overAgeProduct && !isProductEdit) {
      // Checks if ID verification files have been uploaded for age-restricted products
      if (
        sessionStorage.getItem("idUpload1") &&
        sessionStorage.getItem("idUpload2")
      ) {
        // Adds the age-restricted product to cart
        dispatch(
          addToCart({
            product_data: {
              ...productDetails, // Copies existing product details
              product_quantity: productQty, // Sets the selected quantity
              product_notes: specialNoteTxt, // Adds any special notes entered by the user
              loyalty: productLoyality === "Y" ? "Y" : "N",
            },
            dropdown_mod: [...modifiers], // Applies any selected modifiers
          })
        );
        sessionStorage.removeItem("type"); // Clears session data related to age verification
        sessionStorage.removeItem("loyalty");
        toast.dismiss();
        toast.success(
          `${t("added")} ${productDetails.product_name}` // Success message after adding to cart
        );
        dispatch(toggleModal()); // Closes the modal after adding to cart
        setProductQty(1); // Resets quantity to 1 for future selections
      } else {
        setIsModalOpen(true); // Opens ID verification modal if required files are not uploaded
      }
    }
    // 3. Loyalty Product Check (if product requires loyalty points)
    else {
      if (productLoyality === "Y") {
        const point = Number(localStorage.getItem("points")); // Retrieves available loyalty points
        // Checks if user has enough loyalty points for the selected quantity
        if (point >= productQty * productDetails.product_loyalty_price) {
          if (isProductEdit) {
            // Updates cart item with new quantity and notes if editing an existing item
            dispatch(
              editCartItem({
                index: productIndex,
                product_data: {
                  ...productDetails,
                  product_quantity: productQty,
                  product_notes: specialNoteTxt,
                },
                dropdown_mod: [...modifiers],
              })
            );
            sessionStorage.removeItem("type"); // Clears session data
            sessionStorage.removeItem("loyalty");
          } else {
            // Adds loyalty product to the cart as a new item
            dispatch(
              addToCart({
                product_data: {
                  ...productDetails,
                  product_quantity: productQty,
                  product_notes: specialNoteTxt,
                  loyalty: productLoyality === "Y" ? "Y" : "N",
                },
                dropdown_mod: [...modifiers],
              })
            );
            sessionStorage.removeItem("type");
            sessionStorage.removeItem("loyalty");
            // calculateCartTotal()
            toast.dismiss();
            toast.success(
              `${t("added")} ${productDetails.product_name}` // Success message
            );
            if (productLoyality === "Y") {
              navigate("/"); // Redirects user after adding to cart if using loyalty points
            }
          }
          dispatch(toggleModal()); // Closes modal after action
          setProductQty(1); // Resets quantity
        } else {
          // Notify user if loyalty points are insufficient
          toast.dismiss();
          toast.error(t("pointsError")); // Error message if points are insufficient
        }
      } else {
        // 4. Standard Product (non-loyalty, non-age-restricted)
        if (isProductEdit) {
          // Edit existing cart item for regular product
          dispatch(
            editCartItem({
              index: productIndex,
              product_data: {
                ...productDetails,
                product_quantity: productQty,
                product_notes: specialNoteTxt,
              },
              dropdown_mod: [...modifiers],
            })
          );
          sessionStorage.removeItem("type");
          sessionStorage.removeItem("loyalty");
        } else {
          // Adds standard product to the cart as a new item
          dispatch(
            addToCart({
              product_data: {
                ...productDetails,
                product_quantity: productQty,
                product_notes: specialNoteTxt,
                loyalty: productLoyality === "Y" ? "Y" : "N",
              },
              dropdown_mod: [...modifiers],
            })
          );
          sessionStorage.removeItem("type");
          sessionStorage.removeItem("loyalty");
          toast.dismiss();
          toast.success(`${t("added")} ${productDetails.product_name}`);
        }
        dispatch(toggleModal()); // Closes modal
        setProductQty(1); // Resets quantity
      }
    }
  };

  return (
    <div
      className={`${
        imageEmpty ? `custom-modal-content-330` : `custom-modal-content-600`
      }`}
    >
      {isLoading ? (
        <div className="loader">
          <img src={loader} alt="" width={"300px"} height={"210px"} />
        </div>
      ) : (
        <>
          <div className="bg-white position-relative">
            {/* <h2 className="roboto-medium-16 m-0">Detail Menu</h2> */}
            <span
              className="d-flex position-absolute modalXBtn"
              onClick={() => dispatch(toggleModal())}
            >
              <IoIosCloseCircleOutline />
            </span>
          </div>
          <span className="d-flex w-100 gap-2 spanDiv">
            <div
              className={`d-flex justify-content-center w-md-50 w-100 ${
                imageEmpty && "imageNone"
              } ${
                productDetails.product_image !== "" && "custom-modal-header"
              } `}
            >
              {productDetails.product_image !== "" ? (
                <img
                  src={
                    IMAGE_ROOT +
                    "/images/product/" +
                    productDetails.product_image
                  }
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div
              className={`cardDetails-600 p-2 d-flex flex-column justify-content-between w-md-50 w-100`}
            >
              <div className="custom-modal-body">
                <div className="cardHeader">
                  {/* set product name */}
                  <h3 className="mb-1 roboto-medium-22 mt-3">
                    {localStorage.getItem("store_language") === "en"
                      ? productDetails.product_name
                      : productDetails[
                          "product_name_" +
                            localStorage.getItem("store_language")
                        ] !== ""
                      ? productDetails[
                          "product_name_" +
                            localStorage.getItem("store_language")
                        ]
                      : productDetails.product_name}
                  </h3>
                </div>
                <div className="cardDes">
                  <p
                    className="my-1 roboto-medium-16"
                    style={{ color: "gray" }}
                  >
                    {localStorage.getItem("store_language") === "en"
                      ? productDetails.product_description
                      : productDetails[
                          "product_description_" +
                            localStorage.getItem("store_language")
                        ] !== ""
                      ? productDetails[
                          "product_description_" +
                            localStorage.getItem("store_language")
                        ]
                      : productDetails.product_description}
                  </p>
                </div>

                {modifiers.length > 0 &&
                  modifiers.map((modifier, index) => (
                    <>
                      {modifier.modifier_type == "T" ? (
                        // Render a textarea for text-based modifiers
                        <textarea
                          value={modifier.mod_value}
                          onChange={(e) => handleTextareaChange(index, e)}
                          className="w-100 txtArea roboto-regular-16 mt-2"
                          placeholder={
                            modifier.modifier_required == "Y"
                              ? `${
                                  localStorage.getItem("store_language") ===
                                  "en"
                                    ? modifier.modifier_name
                                    : modifier.modifier_name_es.length > 0
                                    ? modifier.modifier_name_es
                                    : modifier.modifier_name
                                }*`
                              : localStorage.getItem("store_language") === "en"
                              ? modifier.modifier_name
                              : modifier.modifier_name_es.length > 0
                              ? modifier.modifier_name_es
                              : modifier.modifier_name
                          }
                          style={{ marginBottom: "-6px" }}
                        ></textarea>
                      ) : modifier.modifier_type == "N" &&
                        modifier.modifier_multiselect == "Y" ? (
                        // Render an accordion with checkboxes for multi-select modifiers
                        <Accordion
                          title={
                            modifier.mod_value && modifier.mod_value !== ""
                              ? modifier.mod_value
                              : modifier.modifier_required == "Y"
                              ? `${
                                  localStorage.getItem("store_language") ===
                                  "en"
                                    ? modifier.modifier_name
                                    : modifier.modifier_name_es.length > 0
                                    ? modifier.modifier_name_es
                                    : modifier.modifier_name
                                }*`
                              : localStorage.getItem("store_language") === "en"
                              ? modifier.modifier_name
                              : modifier.modifier_name_es.length > 0
                              ? modifier.modifier_name_es
                              : modifier.modifier_name
                          }
                        >
                          <span className="checkboxChild py-1">
                            {modifier.options.map((option, oindex) => (
                              <div
                                key={oindex}
                                className="d-flex justify-content-between align-items-center w-100"
                              >
                                <label className="d-flex justify-content-between align-items-center w-100 pointer">
                                  {/* Display option name and price if applicable */}
                                  <p className="m-0">
                                    {`${
                                      localStorage.getItem("store_language") ===
                                      "en"
                                        ? option.option_name
                                        : option.option_name_es.length > 0
                                        ? option.option_name_es
                                        : option.option_name_es.length > 0
                                        ? option.option_name_es
                                        : option.option_name
                                    } ${
                                      option.option_price > 0
                                        ? `($${Number(
                                            option.option_price
                                          ).toFixed(2)})`
                                        : ""
                                    }`}
                                  </p>
                                  {/* Checkbox for each multi-select option */}
                                  <input
                                    type="checkbox"
                                    checked={option.option_selected === "Y"}
                                    onChange={(e) =>
                                      handleCheckboxChange(index, oindex, e)
                                    }
                                  />
                                </label>
                              </div>
                            ))}
                          </span>
                        </Accordion>
                      ) : modifier.modifier_type == "N" &&
                        modifier.modifier_multiselect == "N" ? (
                        <>
                          {/* Render a custom dropdown for single-select modifiers */}
                          <CustomDropdown
                            // dropDownIndex={index}
                            options={modifier.options}
                            onChange={(value) =>
                              handleDropdownChange(index, value)
                            }
                            // initialValue={modifier.options.findIndex((option, index) => option.option_name === modifier.mod_value)}
                            initialValue={modifier.mod_value}
                            required={modifier.modifier_required === "Y"}
                            label={
                              localStorage.getItem("store_language") === "en"
                                ? modifier.modifier_name
                                : modifier.modifier_name_es.length > 0
                                ? modifier.modifier_name_es
                                : modifier.modifier_name
                            }
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                {/* <CustomDropdown /> */}
                {speacialNote && (
                  // Render special notes section if speacialNote is true
                  <div className="specialNotes">
                    <textarea
                      name=""
                      value={specialNoteTxt}
                      className="w-100 txtArea roboto-regular-16 mt-2"
                      onChange={(e) => handleSpeacialNotes(e)}
                      placeholder="Special Notes"
                    ></textarea>
                  </div>
                )}
              </div>
              {/*
               * Modal footer section for managing product quantity, validating user inputs,
               * handling age-restricted items, and adding or updating items in the shopping cart.
               * This footer contains controls for incrementing and decrementing product quantity,
               * validates modifiers and loyalty requirements, and applies specific conditions
               * for age-restricted products or loyalty-based purchases.
               */}
              <div className="custom-modal-footer">
                {/* Quantity Control Section */}
                <div className="d-flex align-items-center productIncrement">
                  {/* Decrement button - decreases quantity by 1 if quantity is greater than 1 */}
                  <span
                    className="dashBtn"
                    // Decrease quantity if productQty is more than 1
                    onClick={() => {
                      if (productQty > 1) {
                        // Prevents quantity from dropping below 1
                        setProductQty(productQty - 1);
                      }
                    }}
                  >
                    <BsDash /> {/* Icon representing 'minus' */}
                  </span>
                  {/* Display the current product quantity */}
                  <span className="totalQty roboto-medium-20">
                    {productQty}
                  </span>
                  {/* Increment button - increases quantity by 1 */}
                  <span
                    className="plusBtn"
                    onClick={() => setProductQty(productQty + 1)}
                  >
                    <FiPlus /> {/* Icon representing 'plus' */}
                  </span>
                </div>

                {/* Add to Cart / Update Cart Button */}
                <button
                  type="button"
                  className="addToCartBtn roboto-medium-16"
                  onClick={() => handleAddtoCart()}
                >
                  {/* Button Text and Price Display */}
                  {/* Shows "Update" or "Add to Cart" depending on whether the product is being edited or added */}
                  {isProductEdit ? t("update") : t("add_to_cart")}
                  ($
                  {(
                    (productDetails.product_price +
                      productDetails.modifier_price) *
                    productQty
                  ).toFixed(2)}{" "}
                  {/* Calculates and displays total price based on quantity and modifiers */}
                  )
                </button>
              </div>
            </div>
          </span>
          <Toaster />
        </>
      )}

      {/*
          // Conditional rendering of a modal component for age verification.
          // If the `isModalOpen` state is true, an overlay modal is displayed,
          // allowing the user to upload an ID image for age-restricted products.
     */}
      {isModalOpen && (
        <div className="custom-modal">
          {/* The modal displays the OverageImageUpload component, which provides an interface for uploading ID images */}
          <OverageImageUpload
            toggleFalse={handleCloseToggel}
            modalcloseWithButton={closeAgeImagePopupusingBtn}
          />{" "}
          {/* Passing handleCloseToggel function to close the modal */}
        </div>
      )}
    </div>
  );
}

export default ProductDetails;
