import { createSlice } from "@reduxjs/toolkit";
import * as dbconn from '../../api/dbconn';

let CartSlice = createSlice({
     name: "cart",
     initialState: {
          cart: [],
          cartTotal: 0
     },
     reducers: {
          addToCart: (state, action) => {
               const { product_data, dropdown_mod } = action.payload;

               // Function to compare dropdown_mod arrays
               const compareDropdownMod = (mod1, mod2) => {
                    if (mod1.length !== mod2.length) return false;
                    for (let i = 0; i < mod1.length; i++) {
                         if (mod1[i].modifier_id !== mod2[i].modifier_id) return false;

                         // If modifier_type is "T", compare mod_value
                         if (mod1[i].modifier_type === "T") {
                              if (mod1[i].mod_value.toLowerCase() !== mod2[i].mod_value.toLowerCase()) return false;
                         }

                         if (mod1[i].options.length !== mod2[i].options.length) return false;
                         for (let j = 0; j < mod1[i].options.length; j++) {
                              if (mod1[i].options[j].modifier_option_id !== mod2[i].options[j].modifier_option_id) return false;
                              if (mod1[i].options[j].option_selected !== mod2[i].options[j].option_selected) return false;
                         }
                    }
                    return true;
               };

               // Find existing item by product_id and dropdown_mod
               const existingItem = state.cart.find(
                    item =>
                         item.product_data.product_id === product_data.product_id &&
                         item.product_data.loyalty === product_data.loyalty &&
                         item.product_data.product_price === product_data.product_price &&
                         compareDropdownMod(item.dropdown_mod, dropdown_mod)
               );

               if (existingItem) {
                    // Merge quantities if the item already exists and has the same loyalty
                    state.cart = state.cart.map(item =>
                         item.product_data.product_id === product_data.product_id &&
                              item.product_data.loyalty === product_data.loyalty &&
                              compareDropdownMod(item.dropdown_mod, dropdown_mod)
                              ? {
                                   ...item,
                                   product_data: {
                                        ...item.product_data,
                                        product_quantity: item.product_data.product_quantity + product_data.product_quantity
                                   }
                              }
                              : item
                    );
               } else {
                    // Add new product if no matching item is found
                    state.cart.push({
                         product_data: { ...product_data },
                         dropdown_mod: [...dropdown_mod]
                    });
               }
          },

          editCartItem: (state, action) => {
               const { index, product_data, dropdown_mod } = action.payload;

               if (index >= 0 && index < state.cart.length) {
                    state.cart.splice(index, 1);
               }

               // Function to compare dropdown_mod arrays
               const compareDropdownMod = (mod1, mod2) => {
                    if (mod1.length !== mod2.length) return false;
                    for (let i = 0; i < mod1.length; i++) {
                         if (mod1[i].modifier_id !== mod2[i].modifier_id) return false;

                         // If modifier_type is "T", compare mod_value
                         if (mod1[i].modifier_type === "T") {
                              if (mod1[i].mod_value.toLowerCase() !== mod2[i].mod_value.toLowerCase()) return false;
                         }

                         if (mod1[i].options.length !== mod2[i].options.length) return false;
                         for (let j = 0; j < mod1[i].options.length; j++) {
                              if (mod1[i].options[j].modifier_option_id !== mod2[i].options[j].modifier_option_id) return false;
                              if (mod1[i].options[j].option_selected !== mod2[i].options[j].option_selected) return false;
                         }
                    }
                    return true;
               };

               const existingItem = state.cart.find(
                    item =>
                         item.product_data.product_id === product_data.product_id &&
                         item.product_data.loyalty === product_data.loyalty &&
                         compareDropdownMod(item.dropdown_mod, dropdown_mod)
               );

               if (existingItem) {
                    // Merge quantities if the item already exists and has the same loyalty
                    state.cart = state.cart.map(item =>
                         item.product_data.product_id === product_data.product_id &&
                              item.product_data.loyalty === product_data.loyalty &&
                              compareDropdownMod(item.dropdown_mod, dropdown_mod)
                              ? {
                                   ...item,
                                   product_data: {
                                        ...item.product_data,
                                        product_quantity: item.product_data.product_quantity + product_data.product_quantity
                                   }
                              }
                              : item
                    );
               } else {
                    // Add new product if no matching item is found
                    state.cart.push({
                         product_data: { ...product_data },
                         dropdown_mod: [...dropdown_mod]
                    });
               }
          },

          removeFromCart: (state, action) => {
               const indexToRemove = action.payload.productIndex;

               if (indexToRemove >= 0 && indexToRemove < state.cart.length) {
                    state.cart.splice(indexToRemove, 1);
               }
          },
          incrementQty: (state, action) => {
               const { productIndex } = action.payload;

               if (productIndex >= 0 && productIndex < state.cart.length) {
                    state.cart = state.cart.map((item, index) =>
                         index === productIndex
                              ? {
                                   ...item,
                                   product_data: {
                                        ...item.product_data,
                                        product_quantity: item.product_data.product_quantity + 1
                                   }
                              }
                              : item
                    );
               }

               // state.cartTotal = state.cart.reduce((total, item) => {
               //      total += item.product_data.product_quantity * ((item.product_data.loyalty !== "Y" ? item.product_data.product_price : 0) + item.product_data.modifier_price);
               //      return total;
               // }, 0);
               // sessionStorage.setItem("cartTotal", state.cartTotal)
          },
          decrementQty: (state, action) => {
               const { productIndex } = action.payload;

               state.cart = state.cart.map((item, index) =>
                    index === productIndex && item.product_data.product_quantity > 1
                         ? { ...item, product_data: { ...item.product_data, product_quantity: item.product_data.product_quantity - 1 } }
                         : item
               );

               // state.cartTotal = state.cart.reduce((total, item) => {
               //      total += item.product_data.product_quantity * ((item.product_data.loyalty !== "Y" ? item.product_data.product_price : 0) + item.product_data.modifier_price);
               //      return total;
               // }, 0);

               // sessionStorage.setItem("cartTotal", state.cartTotal)
          },
          clearCart: (state) => {
               state.cart = [];
               dbconn.deleteCartIndexedDB(3)
          }
     }
});

export let { addToCart, editCartItem, removeFromCart, incrementQty, decrementQty, clearCart } = CartSlice.actions;
export default CartSlice.reducer;
