import axios from "axios";
import $ from "jquery";
import React, { useEffect, useState } from "react";
import Camera, { FACING_MODES, IMAGE_TYPES } from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { useTranslation } from "react-i18next";
import Modal from "react-responsive-modal";
import { API_ROOT } from "../api/apiHandler";
import Loading from "../image/loading.gif";

export default function Capture(props) {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (!localStorage.getItem("store_language")) {
      localStorage.setItem("store_language", "en");
    }
    i18n.changeLanguage(localStorage.getItem("store_language"));
  }, [i18n]);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const urltoFile = (url, filename, mimeType) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  };

  const handleTakePhoto = (fileBase64) => {
    const mime = fileBase64.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    const strings = fileBase64.split(",");
    let extension;
    switch (strings[0]) {
      case "data:image/jpeg;base64":
        extension = "jpeg";
        break;
      case "data:image/png;base64":
        extension = "png";
        break;
      default:
        extension = "jpg";
        break;
    }

    urltoFile(fileBase64, "test." + extension, mime).then(function (file) {
      const formData = new FormData();
      formData.append("file", file);
      onOpenModal();
      axios
        .post(API_ROOT + "/uploadfiles", formData)
        .then((response) => {
          if (response.data.status === 1) {
            onCloseModal();
            sessionStorage.setItem("idType", "C");
            if (sessionStorage.getItem("idUpload1")) {
              sessionStorage.setItem("idUpload2", response.data.filename);
            } else {
              sessionStorage.setItem("idUpload1", response.data.filename);
            }
            sessionStorage.removeItem("cameraOpen");
            $(".react-html5-camera-photo").hide();
            $("#loading").show();
            props.onImageCaptured();
          } else {
            console.log("Error", response.data.message);
          }
        })
        .catch((error) => {
          onCloseModal();
          console.log(error);
        });
    });
  };

  const handleCameraError = (error) => {
    // handle camera error
  };

  const ready = sessionStorage.getItem("cameraOpen");

  return (
    <div style={{ textAlign: "center" }}>
      <img
        alt="Loading..."
        id="loading"
        width={"40"}
        height={"40"}
        src={Loading}
      />
      {ready ? (
        <Camera
          onCameraStart={(stream) => {
            $(".react-html5-camera-photo").show();
            $("#loading").hide();
          }}
          onTakePhoto={(dataUri) => {
            handleTakePhoto(dataUri);
          }}
          onCameraError={(error) => {
            handleCameraError(error);
          }}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          idealResolution={{ width: 640, height: 480 }}
          imageType={IMAGE_TYPES.JPG}
          imageCompression={0.97}
          isMaxResolution={true}
          isImageMirror={false}
          isSilentMode={false}
          isDisplayStartCameraError={true}
          isFullscreen={false}
          sizeFactor={0.8}
        />
      ) : (
        ""
      )}

      <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        open={open}
        onClose={onCloseModal}
        center
      >
        <h2>&nbsp;&nbsp;&nbsp;&nbsp;{t("saving")}.&nbsp;&nbsp;&nbsp;&nbsp;</h2>
        <p className="p-0 m-0" style={{ textAlign: "center" }}>
          <img width={"40"} height={"40"} src={Loading} alt="Loading..." />
        </p>
      </Modal>
    </div>
  );
}
