import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { API_ROOT, IMAGE_ROOT, Method } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import loader from "../image/res_home_page_loader.gif";
import "../style/Profile.css";

export default function EditProfile() {
  const { t, i18n } = useTranslation(); // i18n for translations
  const navigate = useNavigate(); // Navigation hook
  const [open, setOpen] = useState(false); // Loader state
  const [customerData, setCustomerData] = useState({}); // Customer data
  const [customerId, setCustomerId] = useState(""); // Customer ID
  const [customerToken, setCustomerToken] = useState(""); // Customer token
  const [phoneNumber, setPhoneNumber] = useState(""); // Phone number state
  const [redirect, setRedirect] = useState(false); // Redirect state

  // Form validation schema using Yup
  const validationSchema = Yup.object().shape({
    customer_firstname: Yup.string().required(t("required")),
    customer_lastname: Yup.string().required(t("required")),
    customer_email: Yup.string()
      .email(t("invalid_email"))
      .required(t("required")),
    customer_phone: Yup.string()
      .min(10, "Phone number must be at least 10 digits")
      .max(10, "Phone number must be less than or equal to 10 digits")
      .matches(/^[0-9]+$/, "Phone number must be only digits")
      .required(t("required")),
    customer_address: Yup.string().required(t("required")),
    customer_city: Yup.string().required(t("required")),
    customer_state: Yup.string().required(t("required")),
    customer_zip: Yup.string().required(t("required")),
  });

  // Form handling with react-hook-form and Yup resolver
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // Fetch user data and update the form on component mount
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("store_language") || "en");

    dbconn.getUserInfoFromIndexedDB().then((data) => {
      if (
        !data ||
        data.length < 1 ||
        sessionStorage.getItem("guestFlag") === "Y"
      ) {
        navigate("/");
      } else {
        setOpen(true);
        const { customer_id, customer_token } = data;
        setCustomerId(customer_id);
        setCustomerToken(customer_token);

        fetch(`${API_ROOT}${Method.FETCH_PROFILE}`)
          .then((response) => {
            if (response.status === 403 || response.status === 401) {
              setOpen(false);
              dbconn.deleteUserFromIndexedDB(4);
              sessionStorage.setItem("guestFlag", "Y");
              navigate("/login"); // or redirect to a relevant page
            }
            return response.json();
          })
          .then(({ status, customer_data }) => {
            setOpen(false);
            if (status === 1) {
              const custData = customer_data[0];
              dbconn.deleteUserFromIndexedDB(4);
              setCustomerData(custData);
              dbconn.addUserInfoToIndexedDB(custData);
              setPhoneNumber(custData.customer_phone);
              Object.keys(custData).forEach((key) => {
                if (key === "customer_zipcode") {
                  setValue("customer_zip", custData[key]);
                } else {
                  setValue(key, custData[key]);
                }
              });
            }
          });
      }
    });
  }, [i18n, navigate, setValue]);

  // Handle phone input to allow only numeric values and a maximum length of 10
  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/[^\d]/g, "");
    const phoneValue = input.length <= 10 ? input : input.slice(0, 10);

    setPhoneNumber(phoneValue);
    setValue("customer_phone", phoneValue); // Update the form value directly
  };

  // Form submission
  const onSubmit = async (data) => {
    setOpen(true);

    try {
      const response = await axios.post(`${API_ROOT}${Method.UPDATE_PROFILE}`, {
        ...data,
        customer_id: customerId,
        customer_token: customerToken,
      });

      setOpen(false);

      if (response.status === 403 || response.status === 401) {
        setOpen(false);
        console.error("Unauthorized access:", response.status);
        dbconn.deleteUserFromIndexedDB(4);
        sessionStorage.setItem("guestFlag", "Y");
        navigate("/login"); // Redirect to the login page
      }

      if (response.data.status === 1) {
        setRedirect(true);
      } else {
        Swal.fire({ title: response.data.message, icon: "error" });
      }
    } catch (error) {
      setOpen(false);
      console.error("Request error:", error);
      Swal.fire({ text: t("update_failed"), icon: "error" });
    }
  };

  // Redirect to profile page upon successful update
  useEffect(() => {
    if (redirect) {
      navigate("/profile");
    }
  }, [redirect, navigate]);

  return (
    <div className="confirmPageMainDiv">
      {/* Header section with logo */}
      <div className="loginLogo">
        <Link to="/">
          <img
            style={{ width: "70px" }}
            className="center-block"
            src={`${IMAGE_ROOT}/images/product/${localStorage.getItem(
              "store_image"
            )}`}
            alt="Logo"
          />
        </Link>
      </div>

      {/* Main container */}
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div
            className="col-md-7 col-sm-7 col-xs-12 vh1-100 vscroll bg-white"
            style={{
              height: "84vh",
              margin: "80px auto 20px",
              borderRadius: "10px",
            }}
          >
            {/* Back button */}
            <span
              className="backToHomeBtn"
              onClick={() => navigate("/profile")}
            >
              <IoIosArrowBack />
            </span>
            <div
              className="LoginRegisterBlock h-100"
              style={{ marginTop: "10px" }}
            >
              {/* Profile edit form */}
              <form
                onSubmit={handleSubmit(onSubmit)}
                encType="multipart/form-data"
              >
                <h3 className="mb-3 roboto-medium-18">{t("edit_profile")}</h3>
                <div className="d-flex gap-2 mb-2">
                  <div className="form-group w-50">
                    <label className="roboto-medium-16 inputLabel">
                      {t("first_name")}*
                    </label>
                    <input
                      type="text"
                      className="roboto-regular-16 inputBox"
                      {...register("customer_firstname")}
                    />
                    {errors.customer_firstname && (
                      <div className="error">
                        {errors.customer_firstname.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group w-50">
                    <label className="roboto-medium-16 inputLabel">
                      {t("last_name")}*
                    </label>
                    <input
                      type="text"
                      className="roboto-regular-16 inputBox"
                      {...register("customer_lastname")}
                    />
                    {errors.customer_lastname && (
                      <div className="error">
                        {errors.customer_lastname.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label className="roboto-medium-16 inputLabel">
                    {t("email_address")}*
                  </label>
                  <input
                    type="email"
                    className="roboto-regular-16 inputBox"
                    readOnly
                    {...register("customer_email")}
                  />
                  <small>{t("we_never_share_your_email")}</small>
                </div>
                <div className="form-group mb-2">
                  <label className="roboto-medium-16 inputLabel">
                    {t("phone_number")}*
                  </label>
                  <input
                    type="text"
                    className="roboto-regular-16 inputBox"
                    {...register("customer_phone")}
                  />
                  {errors.customer_phone && (
                    <div className="error">{errors.customer_phone.message}</div>
                  )}
                </div>
                <div className="form-group mb-2">
                  <label className="roboto-medium-16 inputLabel">
                    {t("address")}*
                  </label>
                  <input
                    type="text"
                    className="roboto-regular-16 inputBox"
                    {...register("customer_address")}
                  />
                  {errors.customer_address && (
                    <div className="error">
                      {errors.customer_address.message}
                    </div>
                  )}
                </div>
                <div className="d-flex gap-2 mb-2">
                  <div className="form-group w-50">
                    <label className="roboto-medium-16 inputLabel">
                      {t("city")}*
                    </label>
                    <input
                      type="text"
                      className="roboto-regular-16 inputBox"
                      {...register("customer_city")}
                    />
                    {errors.customer_city && (
                      <div className="error">
                        {errors.customer_city.message}
                      </div>
                    )}
                  </div>
                  <div className="form-group w-50">
                    <label className="roboto-medium-16 inputLabel">
                      {t("state")}*
                    </label>
                    <input
                      type="text"
                      className="roboto-regular-16 inputBox"
                      {...register("customer_state")}
                    />
                    {errors.customer_state && (
                      <div className="error">
                        {errors.customer_state.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label className="roboto-medium-16 inputLabel">
                    {t("zip_code")}*
                  </label>
                  <input
                    type="text"
                    className="roboto-regular-16 inputBox"
                    {...register("customer_zip")}
                  />
                  {errors.customer_zip && (
                    <div className="error">{errors.customer_zip.message}</div>
                  )}
                </div>
                <div className="form-group mb-2 mt-4 w-100 d-flex justify-content-center align-items-center">
                  <input
                    type="submit"
                    className="btn btn-yellow w-50 btn-lg"
                    value={t("update_profile")}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <div className="loader">
          <img src={loader} alt="Loading..." width="300" height="210" />
        </div>
      )}
    </div>
  );
}
