import axios from "axios";
import React, { useEffect, useState } from "react";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import { useTranslation } from "react-i18next";
import { FaChevronLeft } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API_ROOT, Method } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import vector from "../image/loginVector.jpg";
import ordernow from "../image/order-now-image.png";
import { fetchStoreData } from "../store/slices/fetchStore";
import FacebookLogin from "./FacebookLogin";
import GoogleLogin from "./GoogleLogin";
import TwitterLogin from "./TwitterLogin";

function LoginComponent({ pageType, closeModal }) {
  const signIn = useSignIn();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Component state for managing form inputs, errors, and store data
  const [redirect, setRedirect] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [store, setStore] = useState([]);

  // Separate error states for form validation and API responses
  const [validationErrors, setValidationErrors] = useState({});
  const [apiError, setApiError] = useState("");

  // Fetch store data when the component mounts
  useEffect(() => {
    sessionStorage.setItem("guestFlag", "Y");
    sessionStorage.setItem("isSSOLogin", false);
    dbconn.deleteUserFromIndexedDB(4);

    storeFetch();
  }, []);

  const storeFetch = async () => {
    const store = await dbconn.getStoreFromIndexedDB();
    setStore(store);
  };

  // Form validation for email and password inputs
  const validateForm = () => {
    const errors = {};
    // Check if email is provided and valid
    if (!userEmail) {
      errors.email = "er";
    } else {
      // Check if email format is valid
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(userEmail)) {
        errors.email = "ie";
      }
    }

    // Check if password is provided
    if (!password) {
      errors.password = "pr";
    }

    return errors;
  };

  // Handle form submission
  const onSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0) {
      try {
        setApiError(""); // Clear previous API error
        dbconn.deleteUserFromIndexedDB(4);
        dbconn.deleteCartIndexedDB(3);

        const response = await axios.post(API_ROOT + Method.LOGIN, {
          customer_username: userEmail,
          customer_password: password,
          loginType: "L",
          store_id: store.store_id,
          merchant_id: store.merchant_id,
        });

        if (response.data.status === 1) {
          setValidationErrors({});
          setApiError(""); // Clear API error on successful login
          dispatch(fetchStoreData());
          const userData = response.data.customer_data[0];

          signIn({
            auth: {
              token: userData.customer_token,
              type: "Bearer",
            },
            userState: userData.customer_id,
          });
          localStorage.setItem("isLogin", true);
          sessionStorage.setItem("guestFlag", "N");

          dbconn.addUserInfoToIndexedDB(userData);

          if (pageType !== "G") {
            sessionStorage.setItem("cartTotal", "0.00");
          }
          if (rememberMe) {
            localStorage.setItem("remember", "Y");
          } else {
            localStorage.setItem("remember", "N");
          }
          setRedirect(true);
        } else {
          localStorage.setItem("isLogin", false);
          setApiError(t("invalid_login"));
        }
      } catch (error) {
        console.error("Login error", error);
        setApiError(t("login_error"));
      }
    } else {
      setValidationErrors(formErrors);
    }
  };

  // Redirect after successful login
  useEffect(() => {
    if (redirect) {
      if (pageType === "G") {
        closeModal(false);
      } else {
        navigate("/");
      }
    }
  }, [redirect]);

  return (
    <div className={`${pageType === "G" ? "loginCard50" : "loginCard"}`}>
      <form
        className={`${
          pageType === "G" ? "formDiv100" : "formDiv"
        } d-flex flex-column justify-content-between p-4`}
        onSubmit={onSubmit}
      >
        <div>
          <div className="loginHeader text-center position-relative">
            <h3
              className="roboto-bold-24"
              style={{ color: "var(--primaryColor)" }}
            >
              {t("login")}
            </h3>

            {/* Display API error if login fails */}
            {apiError && (
              <h6 className="loginError roboto-medium-18">{apiError}</h6>
            )}

            {/* Back button for non-G pages */}
            {pageType !== "G" ? (
              <div className="backButton">
                <FaChevronLeft onClick={() => navigate("/")} />
              </div>
            ) : (
              ""
            )}
          </div>
          {/* Input for Email/Mobile */}
          <div className="form-group mb-2">
            <label className="roboto-medium-16 inputLabel">
              {t("mobile_or_email")}
            </label>
            <input
              type="text"
              className="roboto-regular-16 inputBox"
              value={userEmail}
              onChange={(e) => {
                setUserEmail(e.target.value);
                // Clear email validation error when user starts typing
                setValidationErrors((prevErrors) => {
                  const newErrors = { ...prevErrors };
                  if (newErrors.email) {
                    delete newErrors.email; // Remove email error from validationErrors
                  }
                  return newErrors;
                });
              }}
              autoComplete="username"
            />
            {/* Display validation error for email */}
            {validationErrors.email && (
              <p className="error roboto-regular-14" style={{ color: "red" }}>
                {validationErrors.email == "ie"
                  ? t("invalid_email")
                  : t("email_required")}
              </p>
            )}
          </div>
          {/* Input for Password */}
          <div className="form-group mb-2">
            <label className="roboto-medium-16 inputLabel">
              {t("password")}
            </label>
            <input
              type="password"
              className="inputBox roboto-regular-16"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="password"
            />
            {/* Display validation error for password */}
            {validationErrors.password && (
              <p className="error roboto-regular-14" style={{ color: "red" }}>
                {validationErrors.password == "pr"
                  ? t("password_required")
                  : ""}
              </p>
            )}
          </div>

          {/* Remember Me Checkbox and Forgot Password Link */}
          <div className="form-group my-3 clearfix">
            <div className="custom-control custom-checkbox float-left">
              <input
                type="checkbox"
                className="custom-control-input"
                id="rememberMe"
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <label
                className="roboto-medium-14 inputLabel"
                htmlFor="rememberMe"
              >
                {t("remember_me")}
              </label>
            </div>
            {pageType === "G" ? (
              ""
            ) : (
              <Link
                to="/forgotpassword"
                className="float-right forgotPass roboto-medium-14"
              >
                {t("forgot_password")}
              </Link>
            )}
          </div>
        </div>

        {/* Login Button and Social Logins */}
        <div>
          <div className="form-group col-12">
            <button
              type="submit"
              style={{ backgroundColor: "var(--primaryColor)" }}
              className="loginButton roboto-medium-16 py-2"
            >
              {t("login")}
            </button>
          </div>
          <div className="ssoDiv d-flex align-items-center gap-3 justify-content-center my-1">
            <FacebookLogin />
            <GoogleLogin />
            <TwitterLogin />
          </div>
          {/* Sign Up Link and Disclaimer */}
          <div className="form-group text-center mb-2">
            <Link
              to="/signup"
              className="createNewAccount"
              style={{ color: "var(--primaryColor)" }}
            >
              {t("create_new_account")}
            </Link>
          </div>
          <div className="form-group text-center">
            <p className="color333 roboto-medium-14 m-0">{t("disclaimer")}</p>
          </div>
        </div>
      </form>

      {/* Vector and Order Now Images for Non-G Pages */}
      {pageType !== "G" ? (
        <div className="vectorImage">
          <div className="ordernowBtn">
            <img
              src={ordernow}
              alt="Order Now"
              width={200}
              height={140}
              onClick={() => navigate("/")}
            />
          </div>
          <img className="mainImage" src={vector} alt="Login Vector" />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default LoginComponent;
