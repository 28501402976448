import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
     name: 'modal',
     initialState: {
          isOpen: false,
     },
     reducers: {
          toggleModal: (state) => {
               state.isOpen = !state.isOpen;
               if (state.isOpen) {
                    document.body.classList.add('body-locked');
               } else {
                    document.body.classList.remove('body-locked');
               }
          },
     },
});

export const { toggleModal } = modalSlice.actions;

export const selectModalState = (state) => state.modal.isOpen;

export default modalSlice.reducer;
