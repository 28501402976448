import fetch from "isomorphic-fetch";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsArrowRepeat } from "react-icons/bs";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_ROOT, IMAGE_ROOT, Method } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import loader from '../image/res_home_page_loader.gif';
import { addToCart } from "../store/slices/CartSlice";
import "../style/OrderSummery.css";

export default function OrderSummery() {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const params_id = useParams();
  // const store = useSelector((state) => state.fetchstore.data)
  const [customerID, setCustomeId] = useState(0)
  const [customerToken, setCustomeToken] = useState(0)
  const [redirect, setRedirect] = useState(false);
  const [nosessionredirect, setNosessionredirect] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);
  const [open, setOpen] = useState(false);
  const [order_id, setOrderId] = useState(params_id.id);
  const [productList, setProductList] = useState([]);
  const [nca, setNca] = useState(0);
  const [ncaAmtFlag, setNcaAmtFlag] = useState(false);
  const [tax, setTax] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [editItemRedirectFlag, setEditItemRedirectFlag] = useState(false);
  const [checkoutRedirectFlag, setCheckoutRedirectFlag] = useState(false);
  const [editItemProductID, setEditItemProductID] = useState(0);
  const [editItemCartID, setEditItemCartID] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [finalTax, setFinalTax] = useState(0);
  const [guestRedirectFlag, setGuestRedirectFlag] = useState(false);
  const [swalButton, setSwalButton] = useState("");
  const [cartRedirect, setCartRedirect] = useState(false);
  const [storeOrderType, setStoreOrderType] = useState("");
  const [storeDeliveryFee, setStoreDeliveryFee] = useState("");
  const [orderType, setOrderType] = useState("");
  const [orderDeliveryFee, setOrderDeliveryFee] = useState("");
  const [tip, setTip] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [timeSlot, setTimeSlot] = useState("");
  const [dateSlot, setDateSlot] = useState("");
  const [tableName, setTableName] = useState("");
  const [ncaName, setNcaName] = useState();
  const [reorderProduct, setReorderProduct] = useState([])
  const [allOrderProduct, setAllOrderProduct] = useState([])
  const [reOrderDetails, setReorderDetails] = useState([])
  const [store, setStore] = useState([])
  const initialLogRef = useRef(false);

  // useEffect(() => {
  //   if (store && !initialLogRef.current) {
  //     initialLogRef.current = true;
  //   }
  // }, [store])

  useEffect(() => {

    var primaryColor = localStorage.getItem("store_primary_color");
    if (primaryColor != null) {
      document.documentElement.style.setProperty(
        "--primaryColor",
        primaryColor
      );
    }
    var secondaryColor = localStorage.getItem("store_secondary_color");
    if (secondaryColor != null) {
      document.documentElement.style.setProperty(
        "--secondaryColor",
        secondaryColor
      );
    }

    storeFetch()

  }, []);

  const storeFetch = async () => {
    const store = await dbconn.getStoreFromIndexedDB();
    setStore(store)
  }

  // const onOpenModal = () => {
  //   setOpen(true);
  // };

  // const onCloseModal = () => {
  //   setOpen(false);
  // };

  useEffect(() => {
    if (!localStorage.getItem("store_language")) {
      localStorage.setItem("store_language", "en");
    }
    i18n.changeLanguage(localStorage.getItem("store_language")).then((r) => { });

    // onOpenModal();

    var url = "";
    dbconn.getUserInfoFromIndexedDB().then((data) => {
      if (!data || data.length < 1) {
        setNosessionredirect(true);
      } else {
        setCustomeId(data.customer_id)
        setCustomeToken(data.customer_token)
      }

      dbconn.getStoreFromIndexedDB().then((data) => {
        if (
          !data ||
          data.length < 1
        ) {
          // onCloseModal()
          setNosessionredirect(true);
        } else {

          if (localStorage.getItem("primaryColor") != null) {
            document.documentElement.style.setProperty("--primaryColor", data.store_primary_color);
          }
          url =
            API_ROOT +
            Method.FETCH_STORE_STATE +
            "?guestFlag=N&store_id=" +
            data.store_id;
          setOpen(true)
          fetch(url)
            .then((response) => response.json())
            .then((storeData) => {
              if (storeData.status == 1) {
                setNca(storeData.store_data[0].store_default_nca);
                setNcaName(storeData.store_data[0].store_nca_name);
                setNcaAmtFlag(storeData.store_data[0].store_nca_amount_flag);
                setStoreOrderType(storeData.store_data[0].store_order_type);
                setStoreDeliveryFee(storeData.store_data[0].store_delivery_fee);
                fetchOrderById()
              } else {
                setOpen(false)
              }
            });
        }
      });
    });

    // onCloseModal()
  }, [params_id]);

  // useEffect(() => {
  //   fetchOrderById()
  // }, [customerID, customerToken])

  const fetchOrderById = () => {
    var url1 =
      API_ROOT +
      Method.FETCH_ORDER_BY_ID +
      "?order_id=" +
      order_id;
    // onOpenModal()
    fetch(url1)
      .then((response) => response.json())
      .then((orderData) => {

        if (orderData.status == 1) {
          var taxArray = orderData.order_data.order_tax;
          var totalTax = 0;
          for (var i = 0; i < taxArray.length; i++) {
            var tax =
              (parseFloat(orderData.order_data.order_amount) *
                parseFloat(taxArray[i].tax_percentage)) /
              100;
            taxArray[i].tax_value = tax.toFixed(2);
            totalTax += parseFloat(tax.toFixed(2));
          }
          setTax(taxArray);
          setFinalTax(totalTax.toFixed(2));
          setProductList(orderData.order_data.product_data);
          setTableName(orderData.order_data.table_name);
          setReorderDetails(orderData.order_data.product_data)
          setCartTotal(orderData.order_data.order_amount);
          setOrderData(orderData.order_data);
          setNca(orderData.order_data.order_nca);
          setTip(orderData.order_data.order_tip);
          setDiscount(orderData.order_data.order_discount);
          setOrderType(orderData.order_data.store_order_type);
          setOrderDeliveryFee(
            orderData.order_data.order_delivery_charge
          );
          setSubTotal(orderData.order_data.order_total);
          setTimeSlot(orderData.order_data.order_time_slot);
          setDateSlot(
            orderData.order_data.order_date_slot !== ""
              ? moment(
                orderData.order_data.order_date_slot,
                "YYYY-MM-DD"
              ).format("MMMM Do YYYY")
              : ""
          );
          const product = orderData.order_data.product_data;
          if (product.length > 0) {
            product.map(async (product) => {
              await singleProductDetailFetch(product.product_id)
            })

          } else {
            // onCloseModal()
            setOpen(false)
            // singleProductDetailFetch(orderData.order_data.product_data[0].product_id)
          }

        } else {
          setOpen(false)
        }

      });
  }



  const singleProductDetailFetch = (productID) => {

    var url = "";
    //   var dbconn = new db();
    dbconn.getStoreFromIndexedDB().then((data) => {
      url =
        API_ROOT +
        "/fetchproductdetails?guestFlag=N&store_id=" +
        data.store_id +
        "&product_id=" +
        productID;
      // onOpenModal();
      fetch(url)
        .then((response) => response.json())
        .then((productData) => {
          allReorderProduct(productData)
          // onCloseModal()
        });
    });
  };

  const allReorderProduct = (productData) => {
    setAllOrderProduct((prev) => [...prev, productData])
    setOpen(false)
  }

  useEffect(() => {
    modifiyData()
  }, [allOrderProduct])

  const modifiyData = () => {
    const removeDuplicates = (data) => {
      const seenProductIds = new Set();
      return data.filter(item => {
        const productId = item.product_data.product_id;
        if (seenProductIds.has(productId)) {
          return false;
        } else {
          seenProductIds.add(productId);
          return true;
        }
      });
    };

    const uniqueData = removeDuplicates(allOrderProduct);

    for (let i = 0; i < reOrderDetails.length; i++) {
      for (let j = 0; j < uniqueData.length; j++) {
        if (reOrderDetails[i].product_id === uniqueData[j].product_data.product_id && uniqueData[j].product_data.product_trash === "N") {
          uniqueData[j].product_data.product_quantity = reOrderDetails[i].product_quantity;
          uniqueData[j].product_data.loyalty = reOrderDetails[i].product_loyalty;
          if (uniqueData[j].product_data.modifier_price === undefined) {
            uniqueData[j].product_data.modifier_price = 0
          }

          if (uniqueData[j].product_data.product_notes_enabled === "Y") {
            uniqueData[j].product_data.product_notes = reOrderDetails[i].product_notes;
          }

          if (reOrderDetails[i].product_modifiers.length > 0) {
            for (let m = 0; m < reOrderDetails[i].product_modifiers.length; m++) {
              for (let n = 0; n < uniqueData[j].dropdown_mod.length; n++) {
                if (reOrderDetails[i].product_modifiers[m].modifier_id === uniqueData[j].dropdown_mod[n].modifier_id) {
                  if (uniqueData[j].dropdown_mod[n].modifier_type === "N") {
                    for (let p = 0; p < uniqueData[j].dropdown_mod[n].options.length; p++) {
                      if (uniqueData[j].dropdown_mod[n].options[p].modifier_option_id === reOrderDetails[i].product_modifiers[m].option_id) {
                        uniqueData[j].dropdown_mod[n].options[p].option_selected = "Y";
                      } else {
                        if (uniqueData[j].dropdown_mod[n].options[p].option_selected === undefined) {
                          uniqueData[j].dropdown_mod[n].options[p].option_selected = "N";
                        }
                      }
                    }
                  } else {
                    uniqueData[j].dropdown_mod[n].mod_value = reOrderDetails[i].product_modifiers[m].option_name;
                  }
                }
              }
            }
          } else {
            if (uniqueData[j].dropdown_mod.length > 0) {
              for (let k = 0; k < uniqueData[j].dropdown_mod.length; k++) {
                if (uniqueData[j].dropdown_mod[k].options.length > 0) {
                  for (let l = 0; l < uniqueData[j].dropdown_mod[k].options.length; l++) {
                    uniqueData[j].dropdown_mod[k].options[l].option_selected = "N";
                  }
                }
              }
            }
          }
        }
      }
    }

    const dataModified = []

    if (uniqueData.length > 0) {
      for (let a = 0; a < uniqueData.length; a++) {
        dataModified.push({ product_data: uniqueData[a].product_data, dropdown_mod: uniqueData[a].dropdown_mod })
      }
    }

    if (reOrderDetails.length === uniqueData.length && reOrderDetails.length > 0) {
      setReorderProduct(JSON.stringify(dataModified))
      // setOpen(false)
    }

  };

  const reorderHandle = () => {
    const product = JSON.parse(reorderProduct)

    if (product.length > 0) {
      for (let a = 0; a < product.length; a++) {
        for (let b = 0; b < product[a].dropdown_mod.length; b++) {
          if (product[a].dropdown_mod[b].modifier_type === "N") {
            for (let c = 0; c < product[a].dropdown_mod[b].options.length; c++) {

              if (product[a].dropdown_mod[b].options[c].option_selected === "Y") {
                product[a].product_data.modifier_price += product[a].dropdown_mod[b].options[c].option_price
              }
            }
          }
        }
      }
    }
    const storeId = store.store_id
    const userId = customerID

    if (storeId == orderData.store_id) {
      if (userId == orderData.customer_id) {
        product.map((pDta) => {
          dispatch(addToCart({
            product_data: pDta.product_data,
            dropdown_mod: pDta.dropdown_mod
          }))
        })
        setCartRedirect(true);
      }
    }
  };

  if (nosessionredirect) {
    return navigate("/");
  }

  if (editItemRedirectFlag) {
    return navigate("/");
  }

  if (checkoutRedirectFlag) {
    return navigate("/checkout");
  }

  if (cartRedirect) {
    return navigate("/cart");
  }

  if (guestRedirectFlag) {
    return navigate("/checkout/guest");
  }

  var submitBtnHtml = "";
  var finalNca = 0;

  if (order_id == 0) {
    if (ncaAmtFlag == "Y") {
      finalNca = nca;
    } else {
      var ncaValue = ((parseFloat(cartTotal) * parseFloat(nca)) / 100).toFixed(
        2
      );
      finalNca = ncaValue;
    }
  }

  var deliveryVisible = "none";

  if (parseFloat(orderDeliveryFee) > 0) {
    deliveryVisible = "table-row";
  } else {
    deliveryVisible = "none";
  }

  var orderTypeText = i18n.t("pickup");
  if (orderType == "D") {
    orderTypeText = i18n.t("delivery");
  }
  if (orderType == "I") {
    orderTypeText = i18n.t("dinein");
  }

  var backUrl = "";

  if (order_id == 0) {
    backUrl = "/menu";
  } else {
    backUrl = "/order/history";
  }

  return (
    <div className="confirmPageMainDiv">
      {/* <header className="header-div bg-white position-fixed w-100 shadow">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Link to={backUrl} className="float-left">
            <BsChevronLeft />
          </Link>
          <h2 className="text-center mb-0 roboto-bold-24">
            {t("order_summary")}
          </h2>
        </div>
      </header> */}
      <div className="loginLogo" onClick={() => navigate(-1)}>
        <Link to="">
          <img
            style={{ width: "70px" }}
            className="center-block"
            src={
              IMAGE_ROOT +
              "/images/product/" +
              localStorage.getItem("store_image")
            }
            alt="Takecharge Diner Logo"
          />
        </Link>
      </div>
      <div className="container-fluid vh1-100 vscroll position-relative">
        <div className="row">
          <div
            className="col-lg-9 col-md-11 mx-auto col-xs-12 bg-white"
            style={{ borderRadius: "10px", marginTop: "80px", marginBottom: "50px" }}
          >
            <span className="backToHomeBtn" onClick={() => navigate('/order/history')}><IoIosArrowBack /></span>
            <div className="LoginRegisterBlock" style={{ marginTop: "0" }}>
              <div className="LoginRegisterForm order-detail float-left w-100">
                <form action="#" method="post" encType="multipart/form-data">
                  <h3 className="mb-5 mt-4 roboto-medium-22">
                    {t("order_summary")}
                  </h3>
                  <div className="orderDiv mx-1 p-2" style={{ marginBottom: "20px" }}>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <div className="d-flex align-items-center flex-column mb-1">
                        <h4 className="roboto-medium-14 m-0 grey">{t("order_number")}</h4>
                        <h4 className="roboto-medium-16 m-0">#{orderData.order_check_number}</h4>
                      </div>
                      <div className="d-flex align-items-center flex-column mb-1">
                        <h4 className="roboto-medium-14 m-0 grey">{t("order_date")}</h4>
                        <h4 className="roboto-medium-16 m-0">{orderData.order_created_at}</h4>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-1">
                      <div className="d-flex align-items-center flex-column mb-1">
                        <h4 className="roboto-medium-14 m-0 grey">{t("order_type")}</h4>
                        <h4 className="roboto-medium-16 m-0">{orderTypeText}</h4>
                      </div>
                      {timeSlot != "" &&
                        <div className="d-flex align-items-center flex-column mb-1">
                          <h4 className="roboto-medium-14 m-0 grey">{t("scheduled_for")}</h4>
                          <h4 className="roboto-medium-16 m-0">{dateSlot} {timeSlot}</h4>
                        </div>
                      }
                    </div>
                  </div>
                  <div>
                    {productList.map((item, index) => {
                      var productModifier = [];
                      return (
                        <>
                          <div className="orderCard m-1 p-2">
                            <div className="d-flex align-items-center gap-2">
                              <div className="orderImage" style={{ boxShadow: "0px 1px 4px 1px rgba(0,0,0,0.2)", borderRadius: "10px" }}>
                                <img src={IMAGE_ROOT + "/images/product/" + item.product_img} alt="" width={70} height={70} style={{ borderRadius: "10px" }} />
                              </div>
                              <div className="orderDetails w-100">
                                <div className="orderName d-flex align-items-center justify-content-between w-100">
                                  <h4 className="roboto-medium-16">{localStorage.getItem(
                                    "store_language"
                                  ) === "en"
                                    ? item.product_name
                                    : item[
                                      "product_name_" +
                                      localStorage.getItem(
                                        "store_language"
                                      )
                                    ] !== ""
                                      ? item[
                                      "product_name_" +
                                      localStorage.getItem(
                                        "store_language"
                                      )
                                      ]
                                      : item.product_name}
                                  </h4>

                                  <h4 className="roboto-medium-16">
                                    {item.product_loyalty == "N" ? (
                                      <span className="float-right text-right roboto-medium-14">
                                        $
                                        {parseFloat(item.product_price).toFixed(
                                          2
                                        )}
                                      </span>
                                    ) : (
                                      <span className="float-right d-flex text-right roboto-medium-14">
                                        {parseFloat(
                                          item.product_loyalty_spent
                                        ).toFixed(0)}{" "}
                                        {t("points")}
                                      </span>
                                    )}
                                  </h4>
                                </div>
                                <div className="w-100" style={{ overflowX: "hidden" }}>
                                  <h5 className="roboto-medium-14 grey" style={{ marginBottom: "4px" }}>
                                    {item.product_modifiers.length > 0 ? item.product_modifiers.map((mod) => (
                                      <div>
                                        <span>{mod.mod_name}:</span>
                                        <span>{mod.option_name} {`${mod.mod_price > 0 ? `($${mod.mod_price})` : ""}`}</span>
                                      </div>
                                    )) : ""}
                                    {/* {item.product_modifiers.map(
                                      (modifier1, index1) => {
                                        if (
                                          productModifier.includes(
                                            modifier1.mod_id
                                          )
                                        ) {
                                        } else {
                                          var modifier =
                                            (localStorage.getItem(
                                              "store_language"
                                            ) === "en"
                                              ? modifier1.mod_name
                                              : modifier1[
                                                "mod_name_" +
                                                localStorage.getItem(
                                                  "store_language"
                                                )
                                              ] !== ""
                                                ? modifier1[
                                                "mod_name_" +
                                                localStorage.getItem(
                                                  "store_language"
                                                )
                                                ]
                                                : modifier1.mod_name) + ": ";

                                          var commaCount = 0;
                                          item.product_modifiers.map(
                                            (modifier2, index2) => {
                                              if (
                                                parseInt(modifier2.mod_id) ==
                                                parseInt(modifier1.mod_id)
                                              ) {
                                                if (
                                                  modifier2.mod_selected ==
                                                  "Y"
                                                ) {
                                                  if (commaCount > 0) {
                                                    modifier +=
                                                      ", " +
                                                      (localStorage.getItem(
                                                        "store_language"
                                                      ) === "en"
                                                        ? modifier2.option_name
                                                        : modifier2[
                                                          "option_name_" +
                                                          localStorage.getItem(
                                                            "store_language"
                                                          )
                                                        ] !== ""
                                                          ? modifier2[
                                                          "option_name_" +
                                                          localStorage.getItem(
                                                            "store_language"
                                                          )
                                                          ]
                                                          : modifier2.option_name) +
                                                      " " +
                                                      (modifier2.mod_price > 0
                                                        ? "($" +
                                                        modifier2.mod_price.toFixed(
                                                          2
                                                        ) +
                                                        ")"
                                                        : "");
                                                  } else {
                                                    modifier +=
                                                      (localStorage.getItem(
                                                        "store_language"
                                                      ) === "en"
                                                        ? modifier2.option_name
                                                        : modifier2[
                                                          "option_name_" +
                                                          localStorage.getItem(
                                                            "store_language"
                                                          )
                                                        ] !== ""
                                                          ? modifier2[
                                                          "option_name_" +
                                                          localStorage.getItem(
                                                            "store_language"
                                                          )
                                                          ]
                                                          : modifier2.option_name) +
                                                      " " +
                                                      (modifier2.mod_price > 0
                                                        ? "($" +
                                                        modifier2.mod_price.toFixed(
                                                          2
                                                        ) +
                                                        ")"
                                                        : "");
                                                  }
                                                  commaCount++;
                                                } else {
                                                  if (
                                                    modifier2.mod_default ==
                                                    "Y"
                                                  ) {
                                                    if (commaCount > 0) {
                                                      modifier +=
                                                        "," +
                                                        i18n.t("no") +
                                                        " " +
                                                        (localStorage.getItem(
                                                          "store_language"
                                                        ) === "en"
                                                          ? modifier2.option_name
                                                          : modifier2[
                                                            "option_name_" +
                                                            localStorage.getItem(
                                                              "store_language"
                                                            )
                                                          ] !== ""
                                                            ? modifier2[
                                                            "option_name_" +
                                                            localStorage.getItem(
                                                              "store_language"
                                                            )
                                                            ]
                                                            : modifier2.option_name) +
                                                        " " +
                                                        (modifier2.mod_price >
                                                          0
                                                          ? "($" +
                                                          modifier2.mod_price.toFixed(
                                                            2
                                                          ) +
                                                          ")"
                                                          : "");
                                                    } else {
                                                      modifier +=
                                                        i18n.t("no") +
                                                        " " +
                                                        (localStorage.getItem(
                                                          "store_language"
                                                        ) === "en"
                                                          ? modifier2.option_name
                                                          : modifier2[
                                                            "option_name_" +
                                                            localStorage.getItem(
                                                              "store_language"
                                                            )
                                                          ] !== ""
                                                            ? modifier2[
                                                            "option_name_" +
                                                            localStorage.getItem(
                                                              "store_language"
                                                            )
                                                            ]
                                                            : modifier2.option_name) +
                                                        " " +
                                                        (modifier2.mod_price >
                                                          0
                                                          ? "($" +
                                                          modifier2.mod_price.toFixed(
                                                            2
                                                          ) +
                                                          ")"
                                                          : "");
                                                    }
                                                    commaCount++;
                                                  }
                                                }
                                              }
                                            }
                                          );

                                          productModifier.push(
                                            modifier1.mod_id
                                          );

                                          return (
                                            <div>
                                              {modifier}
                                              <br />
                                            </div>
                                          );
                                        }
                                      }
                                    )} */}
                                  </h5>
                                </div>
                                {item.product_notes.length > 0 ?
                                  <div className="d-flex align-items-center gap-2 grey w-100 roboto-medium-14 grey">
                                    <span>Notes:</span>
                                    <span>{item.product_notes}</span>
                                  </div> : ""}
                                <div className="d-flex align-items-center gap-2 grey w-100 roboto-medium-11">
                                  <span>Quantity:</span>
                                  <span>{item.product_quantity}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="orderDiv mx-1 p-2" style={{ margin: "20px 0" }}>
                    {orderType == "I" &&
                      <div className="d-flex align-items-center justify-content-between mb-1 grey">
                        <h4 className="roboto-medium-16 m-0">{t('table')}#</h4>
                        <h4 className="roboto-medium-16 m-0">{tableName}</h4>
                      </div>
                    }
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <h4 className="roboto-medium-16 m-0 grey">{t('subtotal')}</h4>
                      <h4 className="roboto-medium-16 m-0 grey">${parseFloat(cartTotal).toFixed(2)}</h4>
                    </div>
                    <hr />
                    {discount > 0 &&
                      <div className="d-flex align-items-center justify-content-between mb-1">
                        <h4 className="roboto-regular-16 grey m-0">{t("discount")}</h4>
                        <h4 className="roboto-regular-16 grey m-0">${parseFloat(discount).toFixed(2)}</h4>
                      </div>
                    }
                    {tax.map((tax, index) => (
                      <div className="d-flex align-items-center justify-content-between mb-1" key={tax.tax_id}>
                        <h4 className="m-0 roboto-regular-16 grey">{`${tax.tax_name} ${parseFloat(tax.tax_percentage).toFixed(2)}%`}</h4>
                        {/* <h4 className="m-0 roboto-regular-16 grey">{tax.tax_name} ({parseFloat(tax.tax_percentage).toFixed(2)}%)</h4> */}
                        <h4 className="m-0 roboto-regular-16 grey">${parseFloat(tax.tax_value).toFixed(2)}</h4>
                      </div>
                    ))}
                    {orderType == "D" ?
                      <div className="d-flex align-items-center justify-content-between mb-1" style={{ display: deliveryVisible }} >
                        <h4 className="roboto-regular-16 grey m-0">{t("delivery_charge")}</h4>
                        <h4 className="roboto-regular-16 grey m-0">${parseFloat(orderDeliveryFee).toFixed(2)}</h4>
                      </div>
                      : ""}

                    {tip > 0 &&
                      <div className="d-flex align-items-center justify-content-between mb-1"  >
                        <h4 className="roboto-regular-16 grey m-0">{t("tip")}</h4>
                        <h4 className="roboto-regular-16 grey m-0">${parseFloat(tip).toFixed(2)}</h4>
                      </div>
                    }
                    {nca > 0 &&
                      <div className="d-flex align-items-center justify-content-between mb-1" >
                        <h4 className="roboto-regular-16 grey m-0">{ncaName}</h4>
                        <h4 className="roboto-regular-16 grey m-0">${parseFloat(nca).toFixed(2)}</h4>
                      </div>
                    }
                    <hr />
                    <div className="d-flex align-items-center justify-content-between mb-1" style={{ marginTop: "20px" }} >
                      <h4 className="roboto-medium-16 m-0">{t('total')}</h4>
                      <h4 className="roboto-medium-16 m-0">${parseFloat(subTotal).toFixed(2)}</h4>
                    </div>
                  </div>

                  <div className="form-group mb-2 w-37 m-auto w-m-60 mt-4">
                    <button type="button" className="btn btnLogin btn-yellow w-100 btn-lg my-3" onClick={reorderHandle}><BsArrowRepeat /> {t("reorder_this")}</button>
                    {/* <input
                      type="button"
                      onClick={reorderHandle}
                      className="btn btnLogin btn-yellow w-100 btn-lg my-3"
                      name="reorderThis"
                      id="reorderThis"
                      value={t("reorder_this")}
                    /> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
      {open && (
        <div className="loader">
          <img src={loader} alt="" width={"300px"} height={"210px"} />
        </div>
      )
      }
    </div >
  );
}
