import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_ROOT, Method, SUB_DOMAIN } from '../../api/apiHandler';
import * as dbconn from '../../api/dbconn';

// export const fetchStoreData = createAsyncThunk('api/fetchData', async () => {
//      const response = await fetch('https://api.example.com/data'); // Replace with your API
//      const data = await response.json();
//      return data;
// });

export const fetchStoreData = createAsyncThunk(
     'store/fetchData',
     async (_, { rejectWithValue }) => {
          try {

               const url = `${API_ROOT}${Method.FETCH_STORE_FROM_ID}?sub_domain=${SUB_DOMAIN}`

               const response = await fetch(url);
               const data = await response.json();
               if (data.status === 1) {
                    const store = data.store
                    if (store.store_primary_color != null) {
                         document.documentElement.style.setProperty("--primaryColor", store.store_primary_color);
                    }
                    localStorage.setItem('store_image', store.store_image)
                    localStorage.setItem('store_name', store.store_name)
                    await dbconn.addStoreToIndexedDB(store);
                    // await sessionStorage.setItem("store", JSON.stringify(store))
                    return data.store;
               } else {
                    throw new Error('Failed to fetch store data');
               }
          } catch (error) {
               const indexedDBData = await dbconn.getStoreFromIndexedDB();
               if (indexedDBData) {
                    return indexedDBData;
               } else {
                    return rejectWithValue(error.message);
               }
          }
     }
);

const storeSlice = createSlice({
     name: 'store',
     initialState: {
          data: null,
          loading: false,
          error: null,
     },
     reducers: {},
     extraReducers: (builder) => {
          builder
               .addCase(fetchStoreData.pending, (state) => {
                    state.loading = true;
                    state.error = null;
               })
               .addCase(fetchStoreData.fulfilled, (state, action) => {
                    state.loading = false;
                    state.data = action.payload;
               })
               .addCase(fetchStoreData.rejected, (state, action) => {
                    state.loading = false;
                    state.error = action.payload;
               });
     },
});

export default storeSlice.reducer;