import React from "react";
import { IMAGE_ROOT } from "../api/apiHandler";
import imagePlaceholder from "../image/placeholder.png";

function ProductCard({ itemData, clickOnProduct }) {
  return (
    <>
      {/* Container for each product card */}
      <div className="order-card" onClick={() => clickOnProduct()}>
        {/* Conditional rendering of product image */}
        {itemData.product_image !== "" ? (
          // Display product image if available
          <img
            src={IMAGE_ROOT + "/images/product/" + itemData.product_image}
            alt=""
            className={
              itemData.product_enabled == "Y" // Check if product is enabled
                ? "order-image"
                : "order-image_blur" // Apply blur class if product is disabled
            }
          />
        ) : (
          // If no image, display a placeholder image
          <img
            src={imagePlaceholder}
            alt=""
            className={
              itemData.product_enabled == "Y"
                ? "order-image"
                : "order-image_blur" // Apply blur class if product is disabled
            }
          />
        )}

        {/* Product information */}
        <div className="order-info">
          {/* Product name display with language check */}
          <h3 className="order-name roboto-medium-18">
            {localStorage.getItem("store_language") === "en"
              ? itemData.product_name
              : itemData[
                  "product_name_" + localStorage.getItem("store_language")
                ] !== ""
              ? itemData[
                  "product_name_" + localStorage.getItem("store_language")
                ]
              : itemData.product_name_es.length > 0
              ? itemData.product_name_es
              : itemData.product_name}
          </h3>
          {/* Product category and price display */}
          <span className="bottom_line">
            <p className="order-category roboto-medium-16">
              {localStorage.getItem("store_language") === "en"
                ? itemData.category_name
                : itemData[
                    "category_name_" + localStorage.getItem("store_language")
                  ] !== ""
                ? itemData[
                    "category_name_" + localStorage.getItem("store_language")
                  ]
                : itemData.category_name_es.length > 0
                ? itemData.category_name_es
                : itemData.category_name}
            </p>
            {/* Product price display */}
            <p className="order-price roboto-medium-18">
              ${itemData.product_price.toFixed(2)}
            </p>
          </span>
        </div>
      </div>
    </>
  );
}

export default ProductCard;
