// Import the functions you need from the SDKs you need
import axios from "axios";
import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { getMessaging, getToken } from "firebase/messaging";
import { API_ROOT, Method } from "./api/apiHandler";
// Your web app's Firebase configuration
const firebaseConfig = {
     // apiKey: "AIzaSyCQsJe6EOhEufjPay2Ns5xIoVnfc2CL9u4",
     // authDomain: "tria-81cc8.firebaseapp.com",
     // projectId: "tria-81cc8",
     // storageBucket: "tria-81cc8.appspot.com",
     // messagingSenderId: "309441134382",
     // appId: "1:309441134382:web:d99e7696d4ba18fd1d44bf",
     // measurementId: "G-PH94HB5L0L"
     apiKey: "AIzaSyBJHgv0iVhjbOt0_xFF-H_iWanTQUTRIwg",
     authDomain: "tria-303119.firebaseapp.com",
     projectId: "tria-303119",
     storageBucket: "tria-303119.appspot.com",
     messagingSenderId: "916886143245",
     appId: "1:916886143245:web:3b31a859bb4938b369b044",
     measurementId: "G-5S0S954LVN"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider()
//const messaging = getMessaging(app);

export const generateToken = async () => {
     // const permission = await Notification.requestPermission()

     // if (permission === "granted") {
     //      const token = await getToken(messaging, { vapidKey: "BM6yPUQe07hBS02X_09_fqDQr65opmAZDTOcxrQxdUFhzCt0b457HUjAnGj1QIvg4_u_Fb1zO8BSufAgo1l7aPc" })
     //      console.log("token ->", token)
     // }

     if (typeof window !== 'undefined') {
          const messaging = getMessaging(app);

          await navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
               getToken(messaging, {
                    vapidKey: 'BPh4Cb5NtrI-mUu7ePnxcjPmxbfwYCyEamkI4mD1UtAwiLf_8D-LUaf2r3LC86-tdte-OteAuzyR4CQvc83RI6I',
                    serviceWorkerRegistration: registration,
               })
                    .then(currentToken => {
                         // console.log("token ->", currentToken)
                         sessionStorage.setItem("fcm_token", currentToken)
                         if (currentToken.length > 0 && sessionStorage.getItem("guestFlag") === "N") {
                              sendTokenBackend(currentToken)
                         }
                    })
                    .catch(err => {
                         //console.log('An error occurred while retrieving token. ', err);
                    });
          });

          // if ('serviceWorker' in navigator) {

          //      navigator.serviceWorker.addEventListener('message', event => {
          //           console.log('EVENT', event.data.data);
          //           toast(event.data.data.google.c.a.c_l)
          //      });
          // }
     }

}

const sendTokenBackend = async (token) => {
     const response = await axios.post(API_ROOT + Method.SAVE_TOKEN, {
          customer_id: localStorage.getItem("user_id"),
          token: token
     });

     // console.log("response =>", response)

}


export { auth, facebookProvider, googleProvider, twitterProvider };

