import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function ErrorPage() {
     const navigate = useNavigate()
     const [primaryColor, setPrimaryColor] = useState()
     const [buttonColor, setButtonColor] = useState()
     useEffect(() => {
          var primaryColor = localStorage.getItem("store_primary_color");
          var secondaryColor = localStorage.getItem("store_secondary_color");
          var buttonColor = localStorage.getItem("store_button_color");

          if (primaryColor != null) {
               setPrimaryColor(primaryColor)
          }
          if (buttonColor != null) {
               setButtonColor(buttonColor)
          }

          if (secondaryColor != null) {
               document.documentElement.style.setProperty(
                    "--secondaryColor",
                    secondaryColor
               );
          }
     }, [])
     return (
          <div className='errorPage'>
               <div className="centerDiv text-center">
                    <h1 className='oops roboto-bold' style={{ color: `${primaryColor}` }}>Oops!</h1>
                    <h5 className='roboto-medium-20'>404 - PAGE NOT FOUND</h5>
                    <p className='roboto-regular-18'>The page you are looking for might have been removed had its name changed or is temporarily unavailabel.</p>
                    <button className='errorBtn roboto-medium-18' style={{ backgroundColor: `${buttonColor}` }} onClick={() => {
                         navigate('/');
                    }}>GO TO HOME</button>
               </div>
          </div>
     )
}

export default ErrorPage
