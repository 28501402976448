// CustomDropdown.js

import React, { useEffect, useState } from "react";
import downArrow from "../image/chevron-down.svg";
import upArrow from "../image/chevron-up.svg";

const CustomDropdown = ({
  options,
  onChange,
  initialValue,
  required,
  label,
}) => {
  const [isOpen, setIsOpen] = useState(false); // State to control dropdown visibility
  const [selectedValue, setSelectedValue] = useState(-1); // State to track the selected option

  useEffect(() => {
    // Set initial selected value based on initialValue prop
    if (initialValue === undefined) {
      setSelectedValue(-1);
    } else if (initialValue === "") {
      setSelectedValue(-1);
    } else {
      setSelectedValue(initialValue);
    }
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen); // Toggle dropdown visibility
  };

  const handleOptionClick = (optionIndex) => {
    setSelectedValue(optionIndex); // Set selected option
    setIsOpen(false); // Close the dropdown
    onChange(optionIndex); // Notify parent component of selection
  };

  return (
    <div className="custom-dropdown">
      {/* Dropdown header - toggles dropdown on click */}
      <div
        className="dropdown-header roboto-medium-16"
        onClick={toggleDropdown}
      >
        {selectedValue !== -1
          ? `${
              localStorage.getItem("store_language") === "en"
                ? options[selectedValue]?.option_name
                : options[selectedValue]?.option_name.length > 0
                ? options[selectedValue]?.option_name_es
                : options[selectedValue]?.option_name
            } ${
              options[selectedValue]?.option_price > 0
                ? `($${Number(options[selectedValue]?.option_price).toFixed(
                    2
                  )})`
                : ""
            }`
          : // `${options[selectedValue]?.option_name} ${options[selectedValue]?.option_price > 0 ? `($${options[selectedValue]?.option_price})` : ""}`
          required
          ? `${label}*`
          : label}
        <p className="m-0">
          {isOpen ? (
            <img src={upArrow} alt="" />
          ) : (
            <img src={downArrow} alt="" />
          )}
        </p>
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {/* Option to reset the selection */}
          <li
            className="roboto-regular-16"
            onClick={() => handleOptionClick(-1)}
          >
            {required ? `${label}*` : label}
          </li>
          {/* Map over options and display each option */}
          {options.map((option, oindex) => (
            <li
              className={`${
                oindex === selectedValue ? "selected" : ""
              } roboto-regular-16`}
              key={oindex}
              onClick={() => handleOptionClick(oindex)}
            >
              {`${
                localStorage.getItem("store_language") === "en"
                  ? option.option_name
                  : option.option_name_es.length > 0
                  ? option.option_name_es
                  : option.option_name
              } ${
                option.option_price > 0
                  ? `($${Number(option.option_price).toFixed(2)})`
                  : ""
              }`}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
