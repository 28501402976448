import React from "react";
import { useTranslation } from "react-i18next";
import { BsDash } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import { IMAGE_ROOT } from "../api/apiHandler";
import {
  decrementQty,
  incrementQty,
  removeFromCart,
} from "../store/slices/CartSlice";

function CartCard({ itemData, clickOnProduct, productIndex, removeCoupon }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Calculate loyalty points and total price for the product
  const loyalityPoints =
    itemData.product_loyalty_price * itemData.product_quantity;
  const total =
    (itemData.product_price + itemData.modifier_price) *
    itemData.product_quantity;

  // Function to remove item from cart
  const removeItem = (e) => {
    e.stopPropagation(); // Prevent click from affecting parent elements
    removeCoupon(); // Remove any coupon applied to the item
    dispatch(removeFromCart({ productIndex })); // Dispatch action to remove item from cart
  };

  // Function to increase product quantity
  const handleIncrement = (e) => {
    /// Prevent incrementing if item has certain coupon types
    if (itemData.coupon_type == "G" || itemData.coupon_type == "H") return;

    e.stopPropagation(); // Prevent click from affecting parent elements
    dispatch(incrementQty({ productIndex })); // Dispatch action to increment item quantity

    // Remove coupon if conditions are met
    const couponApplied = sessionStorage.getItem("couponApplied");
    const couponType = sessionStorage.getItem("couponType");

    if (couponApplied === "Y") {
      if (couponType == "G" || couponType === "H") {
        removeCoupon();
      }
    }
  };

  // Function to decrease product quantity
  const handleDecrement = (e) => {
    // Prevent decrementing if item has certain coupon types
    if (itemData.coupon_type == "G" || itemData.coupon_type == "H") return;

    e.stopPropagation(); // Prevent click from affecting parent elements
    dispatch(decrementQty({ productIndex })); // Dispatch action to decrement item quantity

    // Remove coupon if conditions are met
    const couponApplied = sessionStorage.getItem("couponApplied");
    const couponType = sessionStorage.getItem("couponType");

    if (couponApplied === "Y") {
      if (couponType == "G" || couponType === "H") {
        removeCoupon();
      }
    }
  };

  return (
    <>
      <div
        className="item card mb-3 bg-white c-pointer"
        onClick={() => clickOnProduct()} // Trigger click handler when card is clicked
      >
        <div className="card-body p-0 d-flex align-items-center">
          {/* Display product image if available */}
          {itemData.product_image !== "" ? (
            <img
              src={IMAGE_ROOT + "/images/product/" + itemData.product_image}
              alt="img"
              className="border-radius-img img-fluid productImage"
            />
          ) : (
            ""
          )}

          <div className="p-2 flex-grow-1 w-100">
            <div className="d-flex justify-content-between align-items-center mb-1 productDetail">
              {/* Display product name with support for different languages */}
              <h5
                className={`card-title m-0 roboto-medium-18 ${
                  itemData.product_image !== "" ? "productName" : "withOutImage"
                }`}
              >
                {localStorage.getItem("store_language") === "en"
                  ? itemData.product_name
                  : itemData.product_name_es.length > 0
                  ? itemData.product_name_es
                  : itemData.product_name}
              </h5>

              {/* Quantity control with increment and decrement buttons */}
              <div className="d-flex align-items-center productIncrement">
                <span className="dashBtn" onClick={handleDecrement}>
                  <BsDash />
                </span>
                <span className="totalQty roboto-medium-18">
                  {itemData.product_quantity}x
                </span>
                <span className="plusBtn" onClick={handleIncrement}>
                  <FiPlus />
                </span>
              </div>

              {/* Display price or loyalty points */}
              {itemData.loyalty == "Y" ? (
                <p className="card-text m-0 roboto-medium-16 price">
                  {loyalityPoints} {t("points")}
                </p>
              ) : (
                <p className="card-text m-0 roboto-medium-16 price">
                  ${total.toFixed(2)}
                </p>
              )}

              {/* Delete button */}
              <span className="deleteBtn" onClick={removeItem}>
                <MdDelete style={{ fontSize: "22px", color: "red" }} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CartCard;
