// import { configureStore } from "@reduxjs/toolkit";
// import CartSlice from "./slices/CartSlice";
// import modalReducer from "./slices/modalSlice";

// const saveToLocalStorage = (state) => {
//      try {
//           let serializedState = JSON.stringify(state);
//           localStorage.setItem('cart', serializedState);
//      } catch (e) {
//           console.error('Could not save state', e);
//      }
// };

// const loadFromLocalStorage = () => {
//      try {
//           let serializedState = localStorage.getItem('cart');
//           if (serializedState === null) return undefined;
//           return JSON.parse(serializedState);
//      } catch (e) {
//           console.error('Could not load state', e);
//           return undefined;
//      }
// };

// const preloadedState = loadFromLocalStorage();


// let Store = configureStore({
//      reducer: {
//           cart: CartSlice,
//           modal: modalReducer,
//      },
//      // preloadedState: {
//      //      cart: preloadedState,
//      // },
// })

// Store.subscribe(() => {
//      saveToLocalStorage(Store.getState().cart);
// });
// export default Store;
// ===========================================================================local storge store

// import { configureStore } from "@reduxjs/toolkit";
// import CartSlice from "./slices/CartSlice";
// import fetchStoreReducer from './slices/fetchStore';
// import modalReducer from "./slices/modalSlice";
// import sidebarReducer from './slices/sidebarSlice';

// const saveToLocalStorage = (state) => {
//      try {
//           let serializedState = JSON.stringify(state);
//           localStorage.setItem('cart', serializedState);
//      } catch (e) {
//           console.error('Could not save state', e);
//      }
// };

// const loadFromLocalStorage = () => {
//      try {
//           let serializedState = localStorage.getItem('cart');
//           if (serializedState === null) return undefined;
//           return JSON.parse(serializedState);
//      } catch (e) {
//           console.error('Could not load state', e);
//           return undefined;
//      }
// };

// const preloadedState = loadFromLocalStorage();

// const store = configureStore({
//      reducer: {
//           cart: CartSlice,
//           modal: modalReducer,
//           sidebar: sidebarReducer,
//           fetchstore: fetchStoreReducer
//      },
//      preloadedState: {
//           cart: preloadedState, // Pass preloaded state here
//      },
// });

// store.subscribe(() => {
//      saveToLocalStorage(store.getState().cart); // Save only the cart slice to local storage
// });

// export default store;

import { configureStore } from "@reduxjs/toolkit";
import * as dbconn from '../api/dbconn';
import CartSlice from "./slices/CartSlice";
import fetchStoreReducer from './slices/fetchStore';
import modalReducer from "./slices/modalSlice";
import sidebarReducer from './slices/sidebarSlice';

const preloadedState = await dbconn.getCartFromIndexedDB();

const store = configureStore({
     reducer: {
          cart: CartSlice,
          modal: modalReducer,
          sidebar: sidebarReducer,
          fetchstore: fetchStoreReducer,
     },
     preloadedState: {
          cart: preloadedState,
     },
});

// Save the cart state to IndexedDB whenever it changes
store.subscribe(() => {
     dbconn.addCartToIndexedDB(store.getState().cart); // Save only the cart slice to IndexedDB
});

export default store;
