import CryptoJS from "crypto-js";
import i18n from "i18next";
import payform from "payform";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import axios from "axios";
import moment from "moment-timezone";
import { API_ROOT, Method } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import loader from "../image/res_home_page_loader.gif";
import acceptCard from "../image/visa-mastercard-discover-american-express-icons-removebg-preview.png";
import { clearCart } from "../store/slices/CartSlice";
import { fetchStoreData } from "../store/slices/fetchStore";

export default function Checkout() {
  const { t } = useTranslation();
  // const store = useSelector((state) => state.fetchstore.data)
  const [open, setOpen] = useState(false);
  const cartItem = useSelector((state) => state.cart.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const key = "x!A%D*G-KaPdSgVkYp3s5v8y/B?E(H+M";
  const [redirect, setRedirect] = useState(false);

  const [cardList, setCardList] = useState([]);
  const [guestFlag, setGuestFlag] = useState(false);
  const [storeDeliveryFee, setStoreDeliveryFee] = useState(0);
  const [ncaOnTipEnabled, setNcaOnTipEnabled] = useState("Y");
  const [cardDate, setCardDate] = useState("");
  const [cardNo, setCardNo] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardCvv, setCardCvv] = useState("");
  const [cardZip, setCardZip] = useState("");
  const [storeDisclaimer, setStoreDisclaimer] = useState("");
  const [deliveryDisclaimer, setDeliveryDisclaimer] = useState("");
  const [tos, setTos] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");

  const [storeGateway, setStoreGateway] = useState("");
  const [link, setLink] = useState("");
  const [openPaymentModel, setOpenPaymentModel] = useState(false);
  const [productData, setProductData] = useState([]);
  const [store, setStore] = useState([]);

  useEffect(() => {
    storeFetchFromIndexedDB();
  }, []);

  /**
   * START -- storeFetchFromIndexedDB / Store Data Fetching and State Management
   *
   * - Fetches store-related data from IndexedDB.
   * - Sets state variables with store information like gateway, disclaimers, privacy policy, etc.
   * - Stores specific data in `localStorage` (store curbside description).
   * - Dynamically updates CSS variables for styling based on the store's secondary color.
   * - Handles delivery fee based on the order type from `sessionStorage`.
   * - Checks if the user is a guest and updates the guest flag.
   * - If the user is not a guest, fetches card details using `fetchCardDetails()` function.
   * - Handles potential errors during data fetching and state updates.
   *
   * Description:
   * This function interacts with IndexedDB to retrieve store-related information and updates various state variables
   * that represent the store's configuration in the app. The data retrieved includes the store gateway, tip settings,
   * disclaimers, privacy policies, and delivery fees.
   *
   * The function also manages UI updates by setting a dynamic secondary color for the store's theme using CSS variables
   * and saves essential data such as curbside descriptions in `localStorage` for persistence.
   * If the user is logged in (checked via `guestflag`), additional card details are fetched to complete the checkout process.
   * If the user is a guest, the process skips card detail fetching.
   *
   * On each action, the function updates the app's state and ensures that user session data is accurately reflected.
   */
  const storeFetchFromIndexedDB = async () => {
    const data = await dbconn.getStoreFromIndexedDB();
    setStore(data);
    setStoreGateway(data.store_gateway);
    setNcaOnTipEnabled(data.store_tip_nca_enabled);
    setStoreDisclaimer(data.store_disclaimer_msg);
    setDeliveryDisclaimer(data.store_delivery_disclaimer);
    setTos(data.store_tos);
    setPrivacyPolicy(data.store_privacy_policy);
    localStorage.setItem("store_curbside_desc", data.store_curbside_desc);
    setStoreDeliveryFee(
      sessionStorage.getItem("orderType") === "D"
        ? sessionStorage.getItem("orderDeliveryFee")
        : 0
    );
    setGuestFlag(sessionStorage.getItem("guestflag") == "Y" ? true : false);

    document.documentElement.style.setProperty(
      "--secondaryColor",
      data.store_secondary_color
    );

    if (sessionStorage.getItem("guestflag") == "N") {
      fetchCardDetails();
    }
  }; // END -- storeFetchFromIndexedDB / Store Data Fetching and State Management

  /**
   * START -- fetchCardDetails / Fetch Saved Cards for User

   * Action List:
   * - Check if the user is not a guest (using `guestflag` from `sessionStorage`).
   * - Fetch user information from IndexedDB (`getUserInfoFromIndexedDB()`).
   * - Attempt to fetch saved card data from the API using the user’s customer ID.
   * - If the API request is successful:
   *     - Check the response status and update the card list state with the saved card data.
   * - If the API request fails (unauthorized or forbidden):
   *     - Delete the user info from IndexedDB and redirect to the login page.
   * - Handle other errors by logging the error message.
   * - Ensure that card data is only fetched if the user is logged in (not a guest).

   * Description:
   * This function is used to fetch saved card details for a user if they are not a guest. It retrieves user data
   * from IndexedDB and sends a request to the API to fetch saved card information. If the request is successful,
   * it updates the card list state with the fetched data. If the user is not authorized (unauthenticated or forbidden),
   * the user data is cleared, and they are redirected to the login page. Error handling is done for API failures,
   * including logging error messages and redirecting as necessary.
   */
  const fetchCardDetails = async () => {
    if (sessionStorage.getItem("guestflag") == "N") {
      const userData = [];
      dbconn.getUserInfoFromIndexedDB().then((data) => {
        userData.push(data);
      });
      try {
        const response = await fetch(
          `${API_ROOT}/fetchsavedcards?customer_id=${userData.customer_id}`
        );

        const savedCardData = await response.json();

        if (!response.ok) {
          if (response.status === 403 || response.status === 401) {
            // toast.error("Please login", {
            //   duration: 1000,
            // })
            dbconn.deleteUserFromIndexedDB(4);
            sessionStorage.setItem("guestFlag", "Y");
            navigate("/login"); // or redirect to a relevant page
            // return
          } else {
            console.error("Error fetching saved cards:", response.status);
            // Handle other status codes as needed
          }
          return;
        }

        if (savedCardData.status === 1) {
          setCardList(savedCardData.card_data);
        } else {
          console.error("Error:", savedCardData.message);
          // Handle cases where the status is not 1
        }
      } catch (error) {
        console.error("Fetch error:", error.message);
      }

      // const savedCardData = await response.json();
      // if (savedCardData.status === 1) {
      //   setCardList(savedCardData.card_data);
      // }
    }
  }; // END -- fetchCardDetails / Fetch Saved Cards for User

  /**
   * START -- useEffect / Check Out Function Trigger Based on Store Gateway
   *
   * Action List:
   * - When the `storeGateway` or `productData` changes, check if the `storeGateway` is "I".
   * - If the condition is met, invoke the `checkOutFunction` with `storeGateway` and `productData` as parameters.
   *
   * Description:
   * This `useEffect` hook is used to trigger the `checkOutFunction` whenever there is a change in the `storeGateway` or `productData` values.
   * - If the `storeGateway` value is equal to "I" (likely indicating a specific payment gateway or process), the `checkOutFunction` is called with the required parameters (`storeGateway` and `productData`).
   * - The effect depends on two variables, `storeGateway` and `productData`, meaning the function will re-run every time either of these variables changes.
   *
   * This logic ensures that the checkout process is only initiated when the gateway condition is satisfied (in this case, when it's set to "I"), and it will react to changes in both `storeGateway` and `productData` to trigger the checkout process accordingly.
   */
  useEffect(() => {
    if (storeGateway === "I") {
      checkOutFunction(storeGateway, productData);
    }
  }, [storeGateway, productData]); // END -- useEffect / Check Out Function Trigger Based on Store Gateway

  /**
   * START -- encryptData / Data Encryption Function
   *
   * Action List:
   * - Accepts two parameters: `data` (the data to be encrypted) and `encryptionKey` (the key used to encrypt the data).
   * - Uses the AES encryption algorithm from CryptoJS to encrypt the provided `data` using the `encryptionKey`.
   * - Converts the encrypted data to a string format and returns it.
   *
   * Description:
   * This function performs AES encryption using the CryptoJS library. It takes the provided `data` and `encryptionKey`, encrypts the data using the AES algorithm, and then returns the encrypted data as a string.
   * - The `data` parameter represents the plain data (text or information) that needs to be encrypted.
   * - The `encryptionKey` parameter is the key that will be used to encrypt the `data`.
   * - CryptoJS.AES.encrypt() is used to encrypt the data, which is a secure method for protecting sensitive information.
   * - The `.toString()` method is called on the result of the encryption to convert the encrypted data into a string that can be safely stored or transmitted.
   *
   * The result of this function is the encrypted data that can be used for secure communication or storage, ensuring the confidentiality of the original `data`.
   */
  const encryptData = (data, encryptionKey) => {
    return CryptoJS.AES.encrypt(data, encryptionKey).toString();
  }; // END -- encryptData / Data Encryption Function

  const totalCartPrice = Number(
    parseFloat(sessionStorage.getItem("cartTotal")).toFixed(2)
  );

  // ========================== for the use of cart data start ====================

  /**
   * START -- useEffect for Payment Handling and Cart Data Management
   *
   * Action List:
   * - Dispatches an action to fetch store data from an API (via `dispatch(fetchStoreData())`).
   * - Converts the cart data (`cartItem`) using the `convertCartData` function and stores it in `productData`.
   * - Sets up a listener for payment responses using the `window.addEventListener("message", handlePaymentResponse)`.
   * - Listens for a `payment_successful` or `payment_declined` message from the payment system and triggers appropriate actions.
   * - On payment success, it:
   *   - Clears the order and cart data in IndexedDB.
   *   - Resets session storage related to cart and coupon details.
   *   - Shows a success alert with order confirmation.
   *   - Clears the cart and redirects to the confirmation page after the user clicks "OK".
   * - On payment decline, it shows an error alert and redirects the user to retry the payment.
   *
   * Full Description:
   * This `useEffect` hook runs when the component is mounted and performs the following tasks:
   *
   * 1. **Fetching Store Data**:
   *    - It dispatches `fetchStoreData()` to fetch the store-related data (e.g., product information, store configuration) and updates the state with that information.
   *
   * 2. **Handling Cart Data**:
   *    - Converts the raw `cartItem` data to a more usable format using `convertCartData(cartItem)` and updates the `productData` state.
   *
   * 3. **Payment Handling via Message Listener**:
   *    - A message listener (`window.addEventListener("message", handlePaymentResponse)`) is set up to listen for messages from the payment gateway.
   *    - When a message is received, it checks if the message is `"payment_successful"` or `"payment_declined"`.
   *    - For successful payments:
   *      - Clears all order and user data, resets session storage, and displays a success message using `Swal.fire()`.
   *      - After the user clicks "OK", it resets the cart and redirects the user to the confirmation page.
   *    - For declined payments:
   *      - Displays a warning message using `Swal.fire()` and offers the user an option to go back and retry the payment.
   *
   * 4. **Cleanup**:
   *    - The event listener for payment responses is removed when the component is unmounted to avoid memory leaks (`return () => { window.removeEventListener("message", handlePaymentResponse); }`).
   *
   * This effect ensures that the app manages payment responses efficiently and performs necessary cleanups after successful or declined payments, updating the cart and session data accordingly.
   */
  useEffect(() => {
    dispatch(fetchStoreData());

    const data = convertCartData(cartItem);
    setProductData(data);

    const handlePaymentResponse = (event) => {
      const { data } = event;

      if (event.data == "payment_successful") {
        dbconn.deleteAllOrder();

        if (sessionStorage.getItem("guestFlag") == "Y") {
          dbconn.deleteUser();
        }

        sessionStorage.setItem("cartTotal", 0);
        sessionStorage.removeItem("couponApplied");
        sessionStorage.removeItem("couponID");
        sessionStorage.removeItem("couponDetails");
        sessionStorage.removeItem("couponCode");
        sessionStorage.removeItem("couponType");
        sessionStorage.removeItem("couponMinSubtotal");
        sessionStorage.removeItem("couponDeliveryType");
        sessionStorage.removeItem("couponDiscount");
        sessionStorage.removeItem("couponProductDetails");
        setOpen(false);

        Swal.fire({
          title: i18n.t("order_received"),
          text:
            t("your_order_number") +
            " " +
            sessionStorage.getItem("lastOrderID"),
          showCancelButton: false,
          confirmButtonText: t("ok"),
          allowOutsideClick: false,
          customClass: {
            confirmButton: "my-confirm-button",
          },
        }).then((result) => {
          if (result.value) {
            sessionStorage.setItem("cartTotal", 0);
            dispatch(clearCart());
            dbconn.deleteCartIndexedDB();
            sessionStorage.setItem("cartTotal", 0);
            sessionStorage.removeItem("couponApplied");
            sessionStorage.removeItem("couponID");
            sessionStorage.removeItem("couponDetails");
            sessionStorage.removeItem("couponCode");
            sessionStorage.removeItem("couponType");
            sessionStorage.removeItem("couponMinSubtotal");
            sessionStorage.removeItem("couponDeliveryType");
            sessionStorage.removeItem("couponDiscount");
            sessionStorage.removeItem("couponProductDetails");
            sessionStorage.removeItem("userType");
            sessionStorage.removeItem("curbsideDesc");
            navigate("/confirm");
          }
        });
      } else if (data === "payment_declined") {
        Swal.fire({
          title: "payment declined",
          text: "Your payment is declined. Please try again.",
          showCancelButton: false,
          confirmButtonText: t("ok"),
          allowOutsideClick: false,
          customClass: {
            confirmButton: "my-confirm-button",
          },
        }).then((result) => {
          if (result.value) {
            navigate(-1);
          }
        });
      }
    };

    window.addEventListener("message", handlePaymentResponse);

    return () => {
      window.removeEventListener("message", handlePaymentResponse);
    };
  }, []); //END -- useEffect for Payment Handling and Cart Data Management

  /**
   * START -- convertCartData
   *
   * Action List:
   * - Iterates through `cartData` to process modifiers (dropdown & text).
   * - Collects selected dropdown options and text modifier values.
   * - Returns a transformed cart item with selected modifiers.
   *
   * Full Description:
   * This function processes each item in the `cartData`, extracting selected options from dropdown modifiers and values from text modifiers. It returns a transformed cart item with detailed modifier data, which is required for order processing or API submission. If no modifiers are selected, an empty array is returned.
   */
  const convertCartData = (cartData) => {
    return cartData.map((item) => {
      const selectedModifiers = [];

      item.dropdown_mod.flatMap((modifier) => {
        if (modifier.modifier_type === "N") {
          modifier.options
            .filter((option) => option.option_selected === "Y")
            .map((option) => {
              selectedModifiers.push({
                mod_id: modifier.modifier_id,
                mod_type: modifier.modifier_type,
                mod_name: modifier.modifier_name,
                mod_name_es: modifier.modifier_name_es,
                mod_required: modifier.modifier_required,
                option_id: option.modifier_option_id,
                mod_price: option.option_price,
                option_original_price: option.option_original_price,
                mod_default: option.option_default,
                mod_selected: "Y",
                option_name: option.option_name,
                option_name_es: option.option_name_es,
              });
            });
        } else {
          if (
            modifier.modifier_type === "T" &&
            modifier.mod_value &&
            modifier.mod_value.length > 0
          ) {
            selectedModifiers.push({
              mod_id: modifier.modifier_id,
              mod_type: modifier.modifier_type,
              mod_name: modifier.modifier_name,
              mod_name_es: modifier.modifier_name_es,
              mod_required: modifier.modifier_required,
              modifier_multiselect: modifier.modifier_multiselect,
              mod_value: modifier.mod_value,
              mod_selected: "Y",
              option_id: 0,
              mod_price: 0,
              option_original_price: 0,
              mod_default: "",
              option_name: modifier.mod_value,
              option_name_es: "",
            });
          }
        }
      });

      return {
        product_data: {
          ...item.product_data,
          product_modifiers:
            selectedModifiers.length > 0 ? selectedModifiers : [],
        },
        product_modifiers:
          selectedModifiers.length > 0 ? selectedModifiers : [],
      };
    });
  }; // END -- convertCartData

  const onCloseModal = () => {
    setOpen(false);
  };

  /**
   * START -- handleChange
   *
   * Action:
   * - Handles input change event, normalizes the value, and updates `cardDate`.
   *
   * Full Description:
   * This function is triggered on input change. It retrieves the new value from the event, normalizes it using the `normalizeInput` function, and updates the `cardDate` state.
   */
  const handleChange = (e) => {
    const value = e.target.value;
    const normalizedValue = normalizeInput(value, cardDate);
    setCardDate(normalizedValue);
  }; // END -- handleChange

  /**
   * START -- normalizeInput
   *
   * Action:
   * - Normalizes the input by removing non-numeric characters and formats the value.
   *
   * Full Description:
   * This function takes the user input, removes any non-digit characters, and formats it into a specific format (e.g., MM/YY for date fields). It adjusts the formatting based on the length of the input.
   */
  const normalizeInput = (value, previousValue) => {
    if (!value) return value;

    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 2) return currentValue;

      if (cvLength < 4) {
        return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
      }
      return `${currentValue.slice(0, 2)}/${currentValue.slice(2)}`;
    } else {
      return currentValue;
    }
  }; // END -- normalizeInput

  /**
   * START -- openTosAndPrivacy
   *
   * Action:
   * - Opens a modal displaying either the Terms of Service (TOS) or Privacy Policy.
   * - Handles both cases where content is available or not.
   *
   * Full Description:
   * This function shows a modal with either the Terms of Service or Privacy Policy based on the 'type' parameter.
   * If the content is unavailable, a default message is shown. The function uses Swal (SweetAlert) for the modal.
   *
   * Parameters:
   * - type: String (either "T" for TOS or "P" for Privacy Policy)
   */
  const openTosAndPrivacy = (type) => {
    let heading = "";
    let text = "";

    if (type === "T") {
      heading = i18n.t("tos");
      text = tos !== "" && tos !== null ? tos : i18n.t("no_tos_available");
    } else if (type === "P") {
      heading = i18n.t("privacy_policy");
      text =
        privacyPolicy !== "" && privacyPolicy !== null
          ? privacyPolicy
          : i18n.t("no_privacy_policy_available");
    }

    if (
      text === i18n.t("no_privacy_policy_available") ||
      text === i18n.t("no_tos_available")
    ) {
      Swal.fire({
        title:
          text === "No Privacy Policy Available."
            ? t("privacy_policy")
            : t("tos"),
        text: text,
        showCancelButton: false,
        confirmButtonText: t("ok"),
        allowOutsideClick: false,
        customClass: {
          confirmButton: "my-confirm-button",
        },
      });
    } else {
      Swal.fire({
        title: '<h4 id="guidelineHeading">' + heading + "</h4>",
        allowOutsideClick: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        customClass: {
          confirmButton: "btn-green",
        },
        buttonsStyling: false,
        width: "90%",
        html: "<div id='guideLine'>" + text + "</div>",
      });
    }
  }; // END -- openTosAndPrivacy

  // useEffect(() => {
  //   const couponApplied = sessionStorage.getItem("couponApplied");
  //   if (couponApplied == "Y") {
  //     const type = sessionStorage.getItem("couponType");
  //     const coupon = sessionStorage.getItem("couponID");
  //     const totalDiscount = sessionStorage.getItem("totalDiscount");
  //     // setCouponDiscount(Number(totalDiscount));
  //     if (type === "A" || type === "I") {
  //       // setCouponDiscount(sessionStorage.getItem("totalDiscount"));
  //     }
  //     if (type === "B" || type === "F") {
  //       const discount = parseFloat(sessionStorage.getItem("couponDiscount"));
  //       // setCouponDiscount((Number(totalCartPrice) * discount) / 100);
  //     }
  //   }

  //   // const height = window.innerHeight;
  //   // setWindowHeight(height);
  //   let totalLoyalty = 0;
  //   for (let i = 0; i < cartItem.length; i++) {
  //     if (cartItem[i].product_data.loyalty === "Y") {
  //       totalLoyalty +=
  //         parseInt(cartItem[i].product_data.product_loyalty_price) *
  //         parseInt(cartItem[i].product_data.product_quantity);
  //     }
  //   }
  //   // setTotalLoyaltyPoints(totalLoyalty);
  // }, [storeDisclaimer]);

  /**
   * START -- handleCardNoChange
   *
   * Action:
   * - Formats and updates the card number as the user types.
   *
   * Full Description:
   * This function listens for changes to the card number input, formats it using the `payform.formatCardNumber` method,
   * and updates the state with the formatted card number.
   *
   * Parameters:
   * - e: Event object containing the card number input value.
   */
  const handleCardNoChange = (e) => {
    const formattedCardNo = payform.formatCardNumber(e.target.value);
    setCardNo(formattedCardNo);
  }; // END -- handleCardNoChange

  /**
   * START -- handleCheckButton
   *
   * Action:
   * - Verifies the necessary conditions before proceeding with checkout.
   * - Displays a disclaimer if applicable and confirms with the user before proceeding.
   *
   * Full Description:
   * This function handles the checkout button click event, verifies if all conditions are met using `toVerify()`.
   * Based on the order type (pickup or delivery), it either directly proceeds with the checkout or shows a disclaimer message.
   * If the disclaimer is presented, the user is asked to confirm if they wish to continue. If confirmed, the checkout process is triggered.
   *
   * Parameters:
   * - e: Event object triggered by the button click.
   */
  const handleCheckButton = (e) => {
    if (toVerify()) {
      const orderType = sessionStorage.getItem("orderType");
      console.log("storeDisclaimer", storeDisclaimer);

      if (orderType === "P") {
        if (storeDisclaimer == "") {
          checkOutFunction(storeGateway, productData);
        } else {
          let title = "";
          if (
            !sessionStorage.getItem("guestFlag") ||
            sessionStorage.getItem("guestFlag") == "Y"
          ) {
            title = i18n.t("disclaimer");
          }
          Swal.fire({
            title: title,
            text: storeDisclaimer + " " + i18n.t("continue_question"),
            // text: t('must_present_id') + " " + i18n.t("continue_question"),
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: "  " + i18n.t("yes") + "  ",
            cancelButtonText: "  " + i18n.t("no") + "  ",
            customClass: {
              confirmButton: "my-confirm-button",
              cancelButton: "my-cancel-button",
            },
          }).then((result) => {
            if (result.value) {
              checkOutFunction(storeGateway, productData);
            }
          });
        }
      } else if (orderType === "D") {
        if (deliveryDisclaimer == "") {
          checkOutFunction(storeGateway, productData);
        } else {
          let title = "";
          if (
            !sessionStorage.getItem("guestFlag") ||
            sessionStorage.getItem("guestFlag") == "Y"
          ) {
            title = i18n.t("disclaimer");
          }
          Swal.fire({
            title: title,
            text: deliveryDisclaimer + " " + i18n.t("continue_question"),
            // text: t('must_present_id') + " " + i18n.t("continue_question"),
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: "  " + i18n.t("yes") + "  ",
            cancelButtonText: "  " + i18n.t("no") + "  ",
            customClass: {
              confirmButton: "my-confirm-button",
              cancelButton: "my-cancel-button",
            },
          }).then((result) => {
            if (result.value) {
              checkOutFunction(storeGateway, productData);
            }
          });
        }
      }

      // if (orderType === "P" && storeDisclaimer !== "") {
      //   let title = "";
      //   if (!sessionStorage.getItem("guestFlag") || sessionStorage.getItem("guestFlag") == 'Y') {
      //     title = i18n.t("disclaimer");
      //   }
      //   Swal.fire({
      //     title: title,
      //     text: storeDisclaimer + " " + i18n.t("continue_question"),
      //     // text: t('must_present_id') + " " + i18n.t("continue_question"),
      //     allowOutsideClick: false,
      //     showCancelButton: true,
      //     confirmButtonText: "  " + i18n.t("yes") + "  ",
      //     cancelButtonText: "  " + i18n.t("no") + "  ",
      //     customClass: {
      //       confirmButton: "form-control bkg-purple-btn font w-auto m-0",
      //       cancelButton:
      //         "form-control bkg-purple-btn font w-auto m-0 cancelButton",
      //     },
      //   }).then((result) => {
      //     if (result.value) {
      //       // checkOutFunction(storeGateway, productData);
      //     }
      //   });
      // } else if (orderType === "D" && deliveryDisclaimer !== "") {
      //   // checkOutFunction(storeGateway, productData);
      // }
    }
  }; // END -- handleCheckButton

  const checkOutFunction = (storeGateway, productData) => {
    if (productData.length > 0) {
      setOpen(true);

      let grandTotal = 0;
      const totalDiscount = sessionStorage.getItem("totalDiscount");
      const couponType = sessionStorage.getItem("couponType");
      const orderType = sessionStorage.getItem("orderType");
      const orderDeliveryFee = sessionStorage.getItem("orderDeliveryFee");
      const GrandTotal = sessionStorage.getItem("GrandTotal");
      const cartTotal = sessionStorage.getItem("cartTotal");

      if (orderType === "D") {
        if (couponType == "C" || couponType == "D") {
          const addDeliveryInTotal =
            Number(parseFloat(GrandTotal).toFixed(2)) +
            Number(parseFloat(orderDeliveryFee).toFixed(2));
          const minusDiscout =
            addDeliveryInTotal - Number(parseFloat(totalDiscount).toFixed(2));

          grandTotal = grandTotal + minusDiscout;
        } else {
          const addDeliveryInTotal =
            Number(parseFloat(GrandTotal).toFixed(2)) +
            Number(parseFloat(orderDeliveryFee).toFixed(2));
          grandTotal = grandTotal + addDeliveryInTotal;
        }
      } else {
        grandTotal = grandTotal + Number(parseFloat(GrandTotal).toFixed(2));
      }

      sessionStorage.setItem("grandTotalForConfirm", Math.round(cartTotal));

      if (sessionStorage.getItem("guestFlag") == "Y") {
        var orderArr = [];

        var orderOriginalPrice = 0;
        var alcoholINOrder = "N";

        for (var i = 0; i < productData.length; i++) {
          var arrObj = {};
          arrObj["product_id"] = productData[i].product_data.product_id;
          arrObj["product_original_price"] =
            productData[i].product_data.product_original_price?.toFixed(2);
          productData[i].product_data.product_discount_type =
            sessionStorage.getItem("couponType");
          if (
            productData[i].product_data.product_discount_type == "" ||
            "undefined"
          ) {
            arrObj["product_price"] =
              productData[i].product_data.product_price.toFixed(2);
          } else if (
            productData[i].product_data.product_discount_type == "P" ||
            productData[i].product_data.product_discount_type == "H" ||
            productData[i].product_data.product_discount_type == "G"
          ) {
            arrObj["product_price"] = (
              parseFloat(productData[i].product_data.product_price) -
              (parseFloat(productData[i].product_data.product_price) *
                parseFloat(productData[i].product_data.product_discount)) /
                100
            ).toFixed(2);
            arrObj["product_original_price"] = (
              parseFloat(productData[i].product_data.product_original_price) -
              (parseFloat(productData[i].product_data.product_original_price) *
                parseFloat(productData[i].product_data.product_discount)) /
                100
            ).toFixed(2);
          }

          if (productData[i].product_data.product_discount_type == "G") {
            arrObj["mod_price"] = 0;
          } else {
            arrObj["mod_price"] = productData[i].product_data.modifier_price;
          }

          arrObj["product_quantity"] =
            productData[i].product_data.product_quantity;
          arrObj["product_mod"] = productData[i].product_data.product_modifiers
            ? productData[i].product_data.product_modifiers
            : [];
          arrObj["product_notes"] =
            productData[i].product_data.product_notes_enabled === "Y"
              ? productData[i].product_data.product_notes
              : "";
          arrObj["product_discount"] = productData[i].product_data
            .product_discount
            ? productData[i].product_data.product_discount
            : 0;
          arrObj["product_discount_type"] = productData[i].product_data
            .product_discount_type
            ? productData[i].product_data.product_discount_type
            : "";
          arrObj["product_loyalty"] = productData[i].product_data.loyalty;
          arrObj["product_loyalty_spent"] =
            productData[i].product_data.loyalty == "Y"
              ? Number(productData[i].product_data.product_loyalty_price) *
                Number(productData[i].product_data.product_quantity)
              : 0;

          orderOriginalPrice =
            orderOriginalPrice +
            (parseFloat(arrObj["product_original_price"] ?? 0) +
              parseFloat(
                productData[i].product_data.modifier_original_price ?? 0
              )) *
              parseFloat(arrObj["product_quantity"]);

          if (productData[i].product_data.product_overage_enabled == "Y") {
            alcoholINOrder = "Y";
          }
          orderArr.push(arrObj);
        }

        var order_data = { products: orderArr };

        var cardType = payform.parseCardType(cardNo);
        var cardType = "";
        if (cardType == null) {
          cardType = "Other";
        }

        let cardInfo = "";
        var cardDates = cardDate.split("/");

        if (storeGateway !== "I") {
          cardInfo = {
            cardName: encryptData(cardName, key),
            cardNo: encryptData(cardNo, key),
            cardYear: encryptData(cardDates[1], key),
            cardMonth: encryptData(cardDates[0], key),
            cardCvv: encryptData(cardCvv, key),
            cardZip: encryptData(cardZip, key),
            cardType: encryptData(payform.parseCardType(cardNo), key),
          };
        }

        var url;
        var options;

        var orderNca = 0;

        if (ncaOnTipEnabled == "Y") {
          orderNca = finalNca;
        } else {
          orderNca = finalNca;
        }

        url = API_ROOT + Method.CREATE_ORDER;

        dbconn.getUserInfoFromIndexedDB().then((data) => {
          var date = sessionStorage.getItem("date");

          if (sessionStorage.getItem("date") == "") {
            date = moment().format("YYYY-MM-DD");
          }
          const couponDetails = JSON.parse(
            sessionStorage.getItem("couponDetails")
          );
          const taxAfterCalculation = JSON.parse(sessionStorage.getItem("tax"));
          // console.log("couponDetails", couponDetails);
          options = {
            user_type: "C",
            order_number: 1,
            order_data: JSON.stringify(order_data),
            order_sum: totalCartPrice,
            order_original_price: orderOriginalPrice,
            order_tax: JSON.stringify(taxAfterCalculation),
            order_nca: sessionStorage.getItem("store_nca"),
            idUpload1: sessionStorage.getItem("idUpload1")
              ? sessionStorage.getItem("idUpload1")
              : "",
            idUpload2: sessionStorage.getItem("idUpload2")
              ? sessionStorage.getItem("idUpload2")
              : "",
            table_name: sessionStorage.getItem("table"),
            order_tip: Number(
              parseFloat(
                sessionStorage.getItem("selectedTipCalculation")
              ).toFixed(2)
            ),
            order_discount:
              sessionStorage.getItem("totalDiscount") != null
                ? sessionStorage.getItem("totalDiscount")
                : 0,
            order_time_slot: sessionStorage.getItem("slots"),
            order_date_slot: date,
            order_total: grandTotal,
            store_order_type: sessionStorage.getItem("orderType"),
            order_notes: sessionStorage.getItem("curbsideDesc"),
            order_delivery_fee: sessionStorage.getItem("orderDeliveryFee"),
            order_fcm_token: sessionStorage.getItem("fcm_token"),
            store_language: localStorage.getItem("store_language"),
            cardInfo: cardInfo,
            safeId: "",
            couponID: couponDetails ? couponDetails.coupon_id : 0,
            // saveCard: "#saveCard".prop("checked") == true ? "Y" : "N",
            saveCard: "N",
            guestFlag: "Y",
            timezone: moment.tz.guess(),
            alcohol_present: alcoholINOrder,
            store_id: store.store_id,
            customer_firstname: data.customer_firstname,
            customer_lastname: data.customer_lastname,
            customer_email: data.customer_email,
            customer_phone: data.customer_phone,
            customer_address: data.customer_address,
            customer_state: data.customer_state,
            customer_city: data.customer_city,
            customer_zip: data.customer_zip,
            token: sessionStorage.getItem("fcm_token"),
            store_gateway: storeGateway,
            txn_batch_number: "",
            txn_ref_no: "",
          };
          setOpen(true);
          axios
            .post(url, options, { timeout: 60000 })
            .then((response) => {
              onCloseModal();

              if (response.data.status == 1) {
                if (storeGateway == "I") {
                  setOpen(false);
                  // setCreateOrderResponse(response);
                  setOpenPaymentModel(true);
                  // console.log("response.data.link =>", response.data.link)
                  setLink(response.data.link);
                  // setLink("https://api.hashmedia.co.in/webservices/simulate");
                  sessionStorage.setItem("rating", response.data.rating);
                  sessionStorage.setItem(
                    "lastOrderID",
                    response.data.order_check_number
                  );
                } else {
                  setOpen(false);
                  dbconn.deleteAllOrder();
                  dbconn.deleteUser();

                  sessionStorage.setItem("rating", response.data.rating);
                  sessionStorage.setItem(
                    "lastOrderID",
                    response.data.order_check_number
                  );
                  sessionStorage.setItem("cartTotal", 0);
                  sessionStorage.removeItem("couponApplied");
                  sessionStorage.removeItem("couponID");
                  sessionStorage.removeItem("couponDetails");
                  sessionStorage.removeItem("couponCode");
                  sessionStorage.removeItem("couponType");
                  sessionStorage.removeItem("couponMinSubtotal");
                  sessionStorage.removeItem("couponDeliveryType");
                  sessionStorage.removeItem("couponDiscount");
                  sessionStorage.removeItem("couponProductDetails");
                  // sessionStorage.removeItem("GrandTotal");
                  sessionStorage.removeItem("store_nca");
                  sessionStorage.removeItem("tax");
                  sessionStorage.removeItem("tip");
                  sessionStorage.removeItem("totalDiscount");
                  sessionStorage.removeItem("totalPayTax");
                  const front = sessionStorage.getItem("idUpload1");
                  const back = sessionStorage.getItem("idUpload2");
                  if (front && back) {
                    sessionStorage.setItem("front", front);
                    sessionStorage.setItem("back", back);
                  }
                  sessionStorage.removeItem("idUpload1");
                  sessionStorage.removeItem("idUpload2");
                  setOpen(false);

                  Swal.fire({
                    title: i18n.t("order_received"),
                    text:
                      t("your_order_number") +
                      " " +
                      response.data.order_check_number,
                    showCancelButton: false,
                    confirmButtonText: t("ok"),
                    allowOutsideClick: false,
                    customClass: {
                      confirmButton: "my-confirm-button",
                    },
                  }).then((result) => {
                    if (result.value) {
                      sessionStorage.setItem("cartTotal", 0);
                      setRedirect(true);
                      // console.log("995")
                    }
                  });
                }
              } else {
                setOpen(false);
                Swal.fire({
                  title: response.data.message,
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
              }
            })
            .catch(function (error) {
              setOpen(false);
              alert(error);
              console.log(error);
            });
        });
      } else {
        dbconn.getUserInfoFromIndexedDB().then((data) => {
          // console.log("data", data)
          var customer_id = data.customer_id;
          var customer_token = data.customer_token;
          var customer_firstname = data.customer_firstname;
          var customer_lastname = data.customer_lastname;
          var customer_email = data.customer_email;
          var customer_phone = data.customer_phone;
          var customer_address = data.customer_address;
          var customer_state = data.customer_state;
          var customer_city = data.customer_city;
          var customer_zip = data.customer_zip;
          var orderArr = [];
          var orderOriginalPrice = 0;

          var alcoholINOrder = "N";

          // console.log("productData =>", productData)

          for (var i = 0; i < productData.length; i++) {
            var arrObj = {};
            // console.log("productData[i].product_data", productData[i].product_data)
            arrObj["product_id"] = productData[i].product_data.product_id;
            arrObj["product_original_price"] =
              productData[i].product_data.product_original_price?.toFixed(2);
            arrObj["product_quantity"] =
              productData[i].product_data.product_quantity;
            arrObj["product_mod"] = productData[i].product_data
              .product_modifiers
              ? productData[i].product_data.product_modifiers
              : [];
            arrObj["product_notes"] =
              productData[i].product_data.product_notes_enabled === "Y"
                ? productData[i].product_data.product_notes
                : "";
            arrObj["product_discount"] = productData[i].product_data
              .product_discount
              ? productData[i].product_data.product_discount
              : 0;
            arrObj["product_discount_type"] = productData[i].product_data
              .product_discount_type
              ? productData[i].product_data.product_discount_type
              : "";
            arrObj["product_loyalty"] = productData[i].product_data.loyalty;
            arrObj["product_loyalty_spent"] =
              productData[i].product_data.loyalty == "Y"
                ? Number(productData[i].product_data.product_loyalty_price) *
                  Number(productData[i].product_data.product_quantity)
                : 0;
            if (
              productData[i].product_data.product_discount_type == "" ||
              "undefined"
            ) {
              arrObj["product_price"] =
                productData[i].product_data.product_price.toFixed(2);
              arrObj["product_original_price"] =
                productData[i].product_data.product_original_price.toFixed(2);
            } else if (
              productData[i].product_data.product_discount_type == "P" ||
              productData[i].product_data.product_discount_type == "H" ||
              productData[i].product_data.product_discount_type == "G"
            ) {
              const productPrice =
                (Number(productData[i].product_data.product_price) *
                  (100 -
                    Number(productData[i].product_data.product_discount))) /
                100;
              arrObj["product_price"] = Number(productPrice).toFixed(2);
              // arrObj["product_price"] = (
              //   parseFloat(productData[i].product_data.product_price) -
              //   (parseFloat(productData[i].product_data.product_price) *
              //     parseFloat(productData[i].product_data.product_discount)) /
              //   100
              // ).toFixed(2);
              const productOriginalPrice =
                (Number(productData[i].product_data.product_original_price) *
                  (100 -
                    Number(productData[i].product_data.product_discount))) /
                100;
              arrObj["product_original_price"] =
                Number(productOriginalPrice).toFixed(2);
              // arrObj["product_original_price"] = (
              //   parseFloat(productData[i].product_data.product_original_price) -
              //   (parseFloat(productData[i].product_data.product_original_price) *
              //     parseFloat(productData[i].product_data.product_discount)) /
              //   100
              // ).toFixed(2);
            }

            if (productData[i].product_data.product_discount_type == "G") {
              arrObj["mod_price"] = 0;
            } else {
              arrObj["mod_price"] = productData[i].product_data.modifier_price;
            }

            orderOriginalPrice =
              orderOriginalPrice +
              (parseFloat(arrObj["product_original_price"] ?? 0) +
                parseFloat(
                  productData[i].product_data.modifier_original_price ?? 0
                )) *
                parseFloat(arrObj["product_quantity"]);

            if (productData[i].product_data.product_overage_enabled == "Y") {
              alcoholINOrder = "Y";
            }
            // console.log("arrObj", arrObj)
            orderArr.push(arrObj);
          }

          // sessionStorage.setItem("orderTotal", orderOriginalPrice);

          var order_data = { products: orderArr };

          var cardInfo = "";
          var cardDates = cardDate.split("/");

          if (storeGateway !== "I") {
            cardInfo = {
              cardName: encryptData(cardName, key),
              cardNo: encryptData(cardNo, key),
              cardYear: encryptData(cardDates[1], key),
              cardMonth: encryptData(cardDates[0], key),
              cardCvv: encryptData(cardCvv, key),
              cardZip: encryptData(cardZip, key),
              cardType: encryptData(payform.parseCardType(cardNo), key),
            };
          }
          var safeId = "";

          var url;
          var options;

          var orderNca = 0;

          if (ncaOnTipEnabled == "Y") {
            orderNca = finalNca;
          } else {
            orderNca = finalNca;
          }

          url = API_ROOT + Method.CREATE_ORDER;

          var date = sessionStorage.getItem("date");

          if (sessionStorage.getItem("date") == "") {
            date = moment().format("YYYY-MM-DD");
          }
          const couponDetails = JSON.parse(
            sessionStorage.getItem("couponDetails")
          );
          const taxAfterCalculation = JSON.parse(sessionStorage.getItem("tax"));

          options = {
            customer_id: customer_id,
            user_type: "C",
            customer_token: customer_token,
            order_number: 1,
            store_id: store.store_id,
            order_data: JSON.stringify(order_data),
            order_original_price: orderOriginalPrice,
            table_name: sessionStorage.getItem("table"),
            idUpload1: sessionStorage.getItem("idUpload1")
              ? sessionStorage.getItem("idUpload1")
              : "",
            idUpload2: sessionStorage.getItem("idUpload2")
              ? sessionStorage.getItem("idUpload2")
              : "",
            order_sum: totalCartPrice,
            order_tip: Number(
              parseFloat(
                sessionStorage.getItem("selectedTipCalculation")
              ).toFixed(2)
            ),
            order_discount:
              sessionStorage.getItem("totalDiscount") != null
                ? sessionStorage.getItem("totalDiscount")
                : 0,
            order_tax: JSON.stringify(taxAfterCalculation),
            store_language: localStorage.getItem("store_language"),
            order_nca: sessionStorage.getItem("store_nca"),
            couponID: couponDetails ? couponDetails.coupon_id : 0,
            order_total: grandTotal,
            order_time_slot: sessionStorage.getItem("slots"),
            order_date_slot: date,
            store_order_type: sessionStorage.getItem("orderType"),
            order_notes: sessionStorage.getItem("curbsideDesc"),
            order_delivery_fee: sessionStorage.getItem("orderDeliveryFee"),
            order_fcm_token: sessionStorage.getItem("fcm_token"),
            cardInfo: cardInfo,
            safeId: safeId,
            alcohol_present: alcoholINOrder,
            timezone: moment.tz.guess(),
            saveCard: "N",
            guestFlag: guestFlag,
            store_gateway: storeGateway,
            txn_batch_number: "",
            txn_ref_no: "",
            customer_firstname: customer_firstname,
            customer_lastname: customer_lastname,
            customer_email: customer_email,
            customer_phone: customer_phone,
            customer_address: customer_address,
            customer_state: customer_state,
            customer_city: customer_city,
            token: sessionStorage.getItem("fcm_token"),
            customer_zip: customer_zip,
          };

          axios
            .post(url, options)
            .then((response) => {
              onCloseModal();
              // console.log("Order Create response =>", response)
              if (response.data.status == 1) {
                if (storeGateway == "I") {
                  setOpen(false);
                  // setCreateOrderResponse(response);
                  setOpenPaymentModel(true);
                  setLink(response.data.link);
                  // setLink("https://api.hashmedia.co.in/webservices/simulate");
                  // console.log("response.data.link =>", response.data.link)
                  sessionStorage.setItem("rating", response.data.rating);
                  sessionStorage.setItem(
                    "lastOrderID",
                    response.data.order_check_number
                  );
                } else {
                  setOpen(false);
                  dbconn.deleteAllOrder();

                  sessionStorage.setItem("rating", response.data.rating);
                  sessionStorage.setItem(
                    "lastOrderID",
                    response.data.order_check_number
                  );
                  sessionStorage.setItem("cartTotal", 0);
                  sessionStorage.setItem("selectedTipOption", 0);
                  sessionStorage.setItem("selectedTipCalculation", 0);
                  sessionStorage.removeItem("couponApplied");
                  sessionStorage.removeItem("couponID");
                  sessionStorage.removeItem("couponDetails");
                  sessionStorage.removeItem("couponCode");
                  sessionStorage.removeItem("couponType");
                  sessionStorage.removeItem("couponDeliveryType");
                  sessionStorage.removeItem("couponMinSubtotal");
                  sessionStorage.removeItem("couponDiscount");
                  sessionStorage.removeItem("couponProductDetails");
                  sessionStorage.removeItem("store_nca");
                  sessionStorage.removeItem("tax");
                  sessionStorage.removeItem("tip");
                  sessionStorage.removeItem("totalDiscount");
                  sessionStorage.removeItem("totalPayTax");
                  const front = sessionStorage.getItem("idUpload1");
                  const back = sessionStorage.getItem("idUpload2");
                  if (front && back) {
                    sessionStorage.setItem("front", front);
                    sessionStorage.setItem("back", back);
                  }
                  sessionStorage.removeItem("idUpload1");
                  sessionStorage.removeItem("idUpload2");

                  Swal.fire({
                    title: i18n.t("order_received"),
                    text:
                      t("your_order_number") +
                      " " +
                      response.data.order_check_number,
                    showCancelButton: false,
                    confirmButtonText: t("ok"),
                    allowOutsideClick: false,
                    customClass: {
                      confirmButton: "my-confirm-button",
                    },
                  }).then((result) => {
                    if (result.value) {
                      setRedirect(true);
                    }
                  });
                }
              } else {
                setOpen(false);
                Swal.fire({
                  title: response.data.message,
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
              }
            })
            .catch(function (error) {
              //this.onCloseModal();
              setOpen(false);
              alert(error);
              console.log(error);
            });
        });
      }
    } else {
      setOpen(false);
      Swal.fire({
        title: "Cart is Empty",
        customClass: {
          confirmButton: "link_button btn-green",
        },
      });
    }
    // });
  };

  /**
   * START -- toVerify Function
   * Action List With Description:
   * - Validation for storeGateway "I" - If storeGateway equals "I", the function returns true directly (indicating no further validation is needed).
   * - Card information validation - Checks if all required card fields (name, number, expiration date, CVV, and zip code) are filled.
   * - Expiry date validation - Verifies if the card's expiry date matches the correct format (MM/YY).
   * - Alert messages - If any validation fails, a Swal (SweetAlert) message is displayed to the user.
   * Full Description:
   * This function validates the payment details provided by the user. It checks:
   * - If the storeGateway is "I", the function returns true.
   * - If any required fields are empty or null, it triggers an alert notifying the user to fill in the missing fields.
   * - If the expiry date does not match the expected format, it triggers a specific expiry validation alert.
   * After performing all checks, it returns true if all validations pass, otherwise false.
   */
  const toVerify = () => {
    if (storeGateway == "I") {
      return true;
    } else {
      // if (
      //   ".userCards:checked".val() === undefined ||
      //   ".userCards:checked".val() == "undefined"
      // ) {
      var n = cardDate.search(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/i);

      if (
        cardName == "" ||
        cardName == null ||
        cardNo == "" ||
        cardNo == null ||
        cardDate == "" ||
        cardDate == null ||
        cardCvv == "" ||
        cardCvv == null ||
        cardZip == "" ||
        cardZip == null
      ) {
        Swal.fire({
          title: i18n.t("field_validation"),
          customClass: {
            confirmButton: "link_button btn-green",
          },
        });
        return false;
      } else if (n < 0) {
        Swal.fire({
          title: i18n.t("expiry_validation"),
          customClass: {
            confirmButton: "link_button btn-green",
            cancelButton: "link_button",
          },
          allowOutsideClick: false,
        });
        return false;
      }
    }

    return true;
    // }
  }; // END -- toVerify Function

  const selectCard = (cardId) => {
    var selectedCard = cardList.map((item, index) => {
      if ((item.card_id = cardId)) {
        return item;
      }
    });

    "#saveCard".attr("disabled", true);
    "#saveCard".prop("checked", false);
  };

  if (redirect) {
    dispatch(clearCart());
    dbconn.deleteCartIndexedDB();
    navigate("/confirm");
  }

  var backBtnURL = "";
  const handleBackBtn = () => {
    if (
      !sessionStorage.getItem("guestFlag") ||
      sessionStorage.getItem("guestFlag") == "Y"
    ) {
      sessionStorage.removeItem("guestFlag");
      // backBtnURL = "/checkout/guest";
      navigate("/checkout/guest");
    } else {
      // backBtnURL = "/cart";
      navigate("/cart");
    }
  };
  // var finalTax = 0;
  var finalNca = 0;
  var subTotal = 0;
  var deliveryCharge = 0;
  var finalTip = parseFloat(sessionStorage.getItem("tip"));
  var savedCards = "";
  var ncaVisible = "none";
  var ncaWithoutTip = "none";
  var deliveryVisible = "none";
  var finalDeliveryCharge = parseFloat(
    sessionStorage.getItem("orderDeliveryFee")
  ).toFixed(2);
  if (
    parseFloat(storeDeliveryFee) > 0 &&
    sessionStorage.getItem("orderType") == "D"
  ) {
  } else {
    finalDeliveryCharge = 0;
  }

  if (
    parseFloat(storeDeliveryFee) > 0 &&
    sessionStorage.getItem("orderType") == "D"
  ) {
    deliveryVisible = "";
  } else {
    deliveryVisible = "none";
  }

  var saveCard = "";

  saveCard = guestFlag ? (
    ""
  ) : (
    <div className="form-group clear">
      <label className="ch_box">
        {t("save_payment_information")}
        <input type="checkbox" id="saveCard" tabIndex="6" name="saveCard" />
        <span className="checkmark"></span>
      </label>
    </div>
  );

  return (
    <div>
      {store && (
        <div className="row mt-3" style={{ justifyContent: "center" }}>
          <div className="col-12 mx-3 bg-white rounded-3 px-4">
            {storeGateway !== "I" ? (
              <h3
                className="mb-3 text-center roboto-medium-18"
                style={{ color: "var(--primaryColor)" }}
              >
                {t("payment_information")}
              </h3>
            ) : (
              <h3
                className="mb-3 text-center roboto-medium-18"
                style={{ color: "var(--primaryColor)" }}
              >
                {t("checkout")}
              </h3>
            )}
            {!openPaymentModel ? (
              <div className="">
                <div className=" float-left w-100">
                  <form
                    className="h-100"
                    action="#"
                    method="post"
                    encType="multipart/form-data"
                  >
                    <>
                      {storeGateway !== "I" && (
                        <>
                          <div className="form-group mb-1 w-10\">
                            <img src={acceptCard} width={300} />
                          </div>
                          <span className="creditCard">
                            <div className="form-group mb-2">
                              <input
                                type="text"
                                className="roboto-regular-16 inputBox"
                                name="cardName"
                                placeholder={t("cardholder")}
                                tabIndex="1"
                                id="cardName"
                                onChange={(e) => setCardName(e.target.value)}
                              />
                            </div>
                            <div className="form-group mb-2">
                              {/* <label className="fontHelveticaNuueBold">
                              {t("ccnumber")}
                            </label> */}
                              <input
                                type="text"
                                className="roboto-regular-16 inputBox"
                                name="cardNo"
                                tabIndex="2"
                                placeholder={t("ccnumber")}
                                id="cardNo"
                                maxLength="19"
                                value={cardNo}
                                onChange={handleCardNoChange}
                                // onChange={(e) => setCardNo(e.target.value)}
                              />
                            </div>
                            <div className="form-group mb-2 float-left w-100">
                              <div className="col-div-3">
                                {/* <label className="fontHelveticaNuueBold w-100">
                                {t("expiry")}
                              </label> */}
                                <input
                                  type="text"
                                  className="roboto-regular-16 inputBox"
                                  id="cardDate"
                                  value={cardDate || ""}
                                  onChange={handleChange}
                                  tabIndex="3"
                                  // placeholder={t("expiry")}
                                  name="cardDate"
                                  placeholder="MM/YY"
                                  // maxLength="5"
                                />
                              </div>
                              <div className="col-div-3">
                                {/* <label className="fontHelveticaNuueBold">
                                CVV/CVC
                              </label> */}
                                <input
                                  type="text"
                                  className="cvv roboto-regular-16 inputBox"
                                  name="cardCvv"
                                  tabIndex="4"
                                  id="cardCvv"
                                  placeholder="CVV/CVC"
                                  // onChange={cvvcheck}
                                  onChange={(e) =>
                                    setCardCvv(
                                      e.target.value.replace(/\D/g, "")
                                    )
                                  }
                                  maxLength="4"
                                  value={cardCvv}
                                />
                              </div>
                              <div className="col-div-3 float-right last">
                                {/* <label className="fontHelveticaNuueBold">
                                {t("zip_code")}
                              </label> */}
                                <input
                                  type="text"
                                  className="roboto-regular-16 inputBox"
                                  name="cardZip"
                                  tabIndex="5"
                                  id="cardZip"
                                  placeholder={t("zip_code")}
                                  // onChange={zipcodecheck}
                                  onChange={(e) =>
                                    setCardZip(
                                      e.target.value.replace(/\D/g, "")
                                    )
                                  }
                                  value={cardZip}
                                  maxLength="5"
                                />
                              </div>
                            </div>
                          </span>
                          {guestFlag ? (
                            ""
                          ) : (
                            <div className="form-group mb-2 clearfix d-none">
                              <div className="custom-control custom-checkbox float-left">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="saveCard"
                                  tabIndex="6"
                                  name="saveCard"
                                />
                                <label
                                  className="custom-control-label pt-6px"
                                  htmlFor="saveCard"
                                >
                                  {t("save_payment_information")}
                                </label>
                              </div>
                            </div>
                          )}
                          <p style={{ marginTop: "128px" }}>
                            {t("payment_disclaimer_1")}
                            <a
                              style={{
                                cursor: "pointer",
                                color: "var(--primaryColor)",
                              }}
                              onClick={() => {
                                openTosAndPrivacy("T");
                              }}
                            >
                              {t("terms_link")}
                            </a>{" "}
                            {t("payment_disclaimer_2")}{" "}
                            <a
                              style={{
                                cursor: "pointer",
                                color: "var(--primaryColor)",
                              }}
                              onClick={() => {
                                openTosAndPrivacy("P");
                              }}
                            >
                              {t("privacy_policy")}
                            </a>
                            .
                          </p>
                          <div className="form-group pb-3 m-auto w-m-90 mt-5 text-center">
                            <input
                              onClick={handleCheckButton}
                              type="button"
                              className="btn btn-yellow btn-lg"
                              id="checkoutBtn"
                              style={{ width: "300px" }}
                              value={t("place_order")}
                            />
                            <button
                              onClick={() => {
                                navigate("/");
                              }}
                              className="btn btn-cancel btn-lg"
                              style={{
                                width: "300px",
                                marginLeft: "10px",
                                backgroundColor: "var(--secondaryColor)",
                              }}
                            >
                              {t("cancel_order")}
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  </form>
                </div>
              </div>
            ) : (
              <div className="mt-white checkout h-100 ">
                <div className="simple-box m-b-0 bp-0 p-b-35 bkg-white middleContent">
                  <iframe
                    style={{ width: "100%" }}
                    src={link}
                    title="Payment Gateway"
                    width="700px"
                    height="800px"
                  ></iframe>
                  <span
                    className="w-100"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <button
                      onClick={() => {
                        navigate("/");
                      }}
                      className="btn btn-cancel btn-lg"
                      style={{
                        width: "300px",
                        marginLeft: "10px",
                        backgroundColor: "var(--secondaryColor)",
                      }}
                    >
                      {t("cancel_order")}
                    </button>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {open && (
        <div className="loader">
          <img src={loader} alt="" width={"300px"} height={"210px"} />
        </div>
      )}
    </div>
  );
}
