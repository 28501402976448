import axios from "axios";
import fetch from "isomorphic-fetch";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import { MdDeliveryDining } from "react-icons/md";
import { TbShoppingBag } from "react-icons/tb";
import Notifications from "react-notify-toast";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_ROOT, Method, SUB_DOMAIN } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import loader from '../image/res_home_page_loader.gif';
import "../style/OrderType.css";

export default function Ordertype(props) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [store, setStore] = useState([])
  // console.log("store", store)

  const [ready, setReady] = useState(true);
  const [open, setOpen] = useState(false);
  const storeOrderType = props.storeOrderType

  // console.log("props", props)
  // const [storeOrderType, setStoreOrderType] = useState()
  // const [, setStoreOrderType] = useState(props.storeOrderType);

  // const [storeDeliveryFee, setStoreDeliveryFee] = useState(
  //   props.storeDeliveryFee
  // );
  // const [storeWeeklyOrder, setStoreWeeklyOrder] = useState(
  //   props.storeWeeklyOrder
  // );
  // const [tip, setTip] = useState(props.tip);
  const [timeLists, setTimeLists] = useState([]);
  const [dateLists, setDateLists] = useState([]);
  const [dineInFlag, setDineInFlag] = useState("");
  const [guestRedirectFlag, setGuestRedirectFlag] = useState(null);
  const [nosessionredirect, setNosessionredirect] = useState(null);
  const [checkoutRedirectFlag, setCheckoutRedirectFlag] = useState(null);
  const [timingStatus, setTimingStatus] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [disc, setDisc] = useState("");
  const [sealCurbside, setSealCurbside] = useState("");

  const [timeSelectValue, setTimeSelectValue] = useState("");
  const [timeSelectCss, setTimeSelectCss] = useState("none");
  const [dateSelect, setDateSelect] = useState("");

  const [btnDinein, setBtnDinein] = useState("");
  const [btnPickup, setBtnPickup] = useState("");
  const [btnCurbside, setBtnCurbside] = useState("");
  const [btnDelivery, setBtnDelivery] = useState("");

  useEffect(() => {
    fetchStoreID()
  }, [])


  const fetchStoreID = async () => {
    setOpen(true)
    // try {
    const url = `${API_ROOT}${Method.FETCH_STORE_FROM_ID}?sub_domain=${SUB_DOMAIN}`

    const response = await fetch(url);
    const res = await response.json();

    if (res.status === 1) {
      const data = res.store
      if (data) {
        setStore(data)
        dbconn.addStoreToIndexedDB(data)

        if (data.store_primary_color) {
          document.documentElement.style.setProperty("--primaryColor", data.store_primary_color);
        }
        localStorage.setItem('store_image', data.store_image)
        localStorage.setItem('store_name', data.store_name)
        // await dbconn.addStoreToIndexedDB(store);
        fetchStoreSchedule(data)
        setOpen(false)
      } else {
        setOpen(false)
        navigate('/')
      }
    } else {
      setOpen(false)
      navigate('/')
    }


    // } catch (error) {
    //   setOpen(false)
    //   console.log("error", error)
    //   const indexedDBData = await dbconn.getStoreFromIndexedDB();
    //   if (indexedDBData) {
    //     return indexedDBData;
    //   }
    // }
  }

  // useEffect(() => {
  //   sessionStorage.setItem("userType", "")
  //   var primaryColor = localStorage.getItem("store_primary_color");
  //   var secondaryColor = localStorage.getItem("store_secondary_color");
  //   var buttonColor = localStorage.getItem("store_button_color");

  //   if (primaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--primaryColor",
  //       primaryColor
  //     );
  //   }
  //   if (buttonColor != null) {
  //     document.documentElement.style.setProperty("--buttonColor", buttonColor);
  //   }

  //   if (secondaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--secondaryColor",
  //       secondaryColor
  //     );
  //   }
  // }, []);

  // useEffect(() => {
  //   if (storeOrderType.indexOf("D") > 1) {
  //     const couponApplied = sessionStorage.getItem("couponApplied");
  //     let deliveryFee = storeDeliveryFee;
  //     let disc = "";

  //     if (couponApplied) {
  //       const type = sessionStorage.getItem("couponType");
  //       const discount = parseFloat(sessionStorage.getItem("couponDiscount"));
  //       if (type === "C") {
  //         deliveryFee -= discount;
  //         disc = ` $${discount.toFixed(2)}`;
  //       } else if (type === "D") {
  //         deliveryFee = (deliveryFee * discount) / 100;
  //         disc = `${discount}%`;
  //       }
  //     }

  //     setDeliveryFee(deliveryFee);
  //     setDisc(disc);
  //   }
  // }, []);

  useEffect(() => {
    if (!localStorage.getItem("store_language")) {
      localStorage.setItem("store_language", "en");
    }
    i18n.changeLanguage(localStorage.getItem("store_language"));

    // fetchStoreSchedule();
  }, []);

  useEffect(() => {
    if (timingStatus == 0) {
      fetchTiming();
    }
  }, [timingStatus]);

  const fetchStoreSchedule = (data) => {
    onOpenModal();
    fetch(`${API_ROOT}${Method.FETCH_STORE_SCHEDULE}?store_id=${data.store_id}`)
      .then((response) => {
        if (response.status === 403 || response.status === 401) {
          // Handle 403 error
          onCloseModal();
          dbconn.deleteUserFromIndexedDB(4)
          sessionStorage.setItem("guestFlag", "Y")
          navigate('/login'); // or redirect to a relevant page
          // return
        }
        return response.json();
      })
      .then((storeData) => {
        onCloseModal();
        var timeList = [];
        var datetList = [];

        if (props.storeWeeklyOrder == "N") {
          if (storeData.status == 1) {
            setDineInFlag(storeData.dineInFlag);
            timeList.push("");
            if (storeData.slots.length > 0) {
              if (storeData.dineInFlag == "Y") {
                timeList.push("ASAP");
              }
            }

            for (var i = 0; i < storeData.slots.length; i++) {
              timeList.push(storeData.slots[i]);
            }

            if (storeData.dineInFlag == "Y") {
              // setTimeout(function () {
              //   setTimeSelectValue("ASAP");
              // }, 100);

              setTimeSelectCss("none");
              setTimingStatus(0);
            } else {
              setTimeSelectCss("block");

              setTimingStatus(1);
            }
          } else {
            Swal.fire({
              title: storeData.message,
              showCancelButton: false,
              confirmButtonText: t("ok"),
              allowOutsideClick: false,
              customClass: {
                confirmButton: 'my-confirm-button'
              }
            });
          }
        } else {
          /***** WEEKLY ORDER ******/
          if (storeData.status == 1) {
            setDineInFlag(storeData.dineInFlag);

            timeList.push("");

            if (storeData.slots.length > 0) {
              if (storeData.dineInFlag == "Y") {
                timeList.push("ASAP");
              }
            }

            for (var i = 0; i < storeData.slots.length; i++) {
              timeList.push(storeData.slots[i]);
            }

            datetList.push("");

            for (var i = 0; i < storeData.dates.length; i++) {
              datetList.push(storeData.dates[i]);
            }

            if (storeData.slots.length > 0) {
              if (storeData.dineInFlag == "Y") {
                // (".timeSelect").css("display", "none");
                setTimeSelectCss("none");

                // setTimeout(function () {
                // ("#timeSelect").val("ASAP");
                // setTimeSelectValue("ASAP");

                // ("#dateSelect").val(("#dateSelect option:eq(1)").val());
                // }, 100);

                setTimingStatus(0);
                //  $('#timeSelect').parent().find('.holder').html($('#timeSelect').val());
              } else {
                //   $('#timeSelect').parent().find('.holder').html("SELECT ANY");
                // (".timeSelect").css("display", "block");
                setTimeSelectCss("block");
                setTimingStatus(1);
              }

              //  $('#dateSelect').val(moment().format("YYYY-MM-DD"));
              // ("#dateSelect").val(("#dateSelect option:eq(1)").val());

              fetchTiming();

              // $('#dateSelect').parent().find('.holder').html(moment($('#dateSelect').val(), "YYYY-MM-DD").format("MMMM Do YYYY"));
            } else {
              setTimingStatus(1);
              setTimeSelectCss("block");
            }
          } else {
            Swal.fire({
              title: storeData.message,
              showCancelButton: false,
              confirmButtonText: t("ok"),
              allowOutsideClick: false,
              customClass: {
                confirmButton: 'my-confirm-button'
              }
            });
          }
        }
        // console.log("timeList", timeList)
        setTimeLists(timeList);
        setDateLists(datetList);
        setDateSelect(datetList[1]);
      });
  };

  const pickup = (e) => {
    const couponApplied = sessionStorage.getItem("couponApplied")
    const couponAppliedOn = JSON.parse(sessionStorage.getItem("couponDetails"))
    if (couponApplied === "Y" && couponAppliedOn && couponAppliedOn.coupon_delivery_type.length == 1 && couponAppliedOn.coupon_delivery_type == "D") {
      Swal.fire({
        title: "Coupon is not valid for pickup",
        showCancelButton: false,
        confirmButtonText: t("ok"),
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'my-confirm-button'
        }
      });
    } else {
      if (timingStatus == 0) {
        sessionStorage.setItem("orderType", "P");
        sessionStorage.setItem("orderDeliveryFee", 0);
        sessionStorage.setItem("slots", "ASAP");
        sessionStorage.setItem("curbsideDesc", "");
        sessionStorage.setItem("table", "");
        if (props.storeWeeklyOrder == "N") {
          sessionStorage.setItem("date", "");
        } else {
          sessionStorage.setItem("date", dateSelect);
        }
        loadNext();
      } else {
        if (toVerifyDelivery("P")) {
          sessionStorage.setItem("orderType", "P");
          sessionStorage.setItem("orderDeliveryFee", 0);
          sessionStorage.setItem("slots", timeSelectValue);
          sessionStorage.setItem("curbsideDesc", "");
          sessionStorage.setItem("table", "");

          if (props.storeWeeklyOrder == "N") {
            sessionStorage.setItem("date", "");
          } else {
            sessionStorage.setItem("date", dateSelect);
          }
          loadNext();
        }
      }
    }
  };
  const delivery = (e) => {
    const couponApplied = sessionStorage.getItem("couponApplied")
    const couponAppliedOn = JSON.parse(sessionStorage.getItem("couponDetails"))
    if (couponApplied === "Y" && couponAppliedOn && couponAppliedOn.coupon_delivery_type.length == 1 && couponAppliedOn.coupon_delivery_type == "P") {
      Swal.fire({
        title: "Coupon is not valid for delivery",
        showCancelButton: false,
        confirmButtonText: t("ok"),
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'my-confirm-button'
        }
      });
    } else {
      if (timingStatus == 0) {
        sessionStorage.setItem("orderType", "D");
        sessionStorage.setItem("orderDeliveryFee", props.storeDeliveryFee);

        sessionStorage.setItem("slots", "ASAP");
        sessionStorage.setItem("curbsideDesc", "");
        sessionStorage.setItem("table", "");
        if (props.storeWeeklyOrder == "N") {
          sessionStorage.setItem("date", "");
        } else {
          sessionStorage.setItem("date", dateSelect);
        }

        loadNext();
      } else {
        if (toVerifyDelivery("D")) {
          sessionStorage.setItem("orderType", "D");
          sessionStorage.setItem("orderDeliveryFee", props.storeDeliveryFee);

          sessionStorage.setItem("slots", timeSelectValue);
          sessionStorage.setItem("curbsideDesc", "");
          sessionStorage.setItem("table", "");
          if (props.storeWeeklyOrder == "N") {
            sessionStorage.setItem("date", "");
          } else {
            sessionStorage.setItem("date", dateSelect);
          }

          loadNext();
        }
      }
    }


  };


  const loadNext = () => {
    // sessionStorage.removeItem('isSubTotal')
    // sessionStorage.removeItem('isStoreDeliveryFee')
    dbconn.getAllOrder().then((productData) => {
      var overAgeFlag = false;
      for (var i = 0; i < productData.length; i++) {
        if (productData[i].product_overage_enabled == "Y") {
          overAgeFlag = true;
        }
      }

      if (overAgeFlag && sessionStorage.getItem("orderType") != "I") {
        if (
          sessionStorage.getItem("idUpload1") &&
          sessionStorage.getItem("idUpload2")
        ) {
          if (
            sessionStorage.getItem("orderType") == "P" ||
            sessionStorage.getItem("orderType") == "C"
          ) {
            if (
              !sessionStorage.getItem("guestFlag") ||
              sessionStorage.getItem("guestFlag") == "Y"
            ) {
              setGuestRedirectFlag(true);
            } else {
              setCheckoutRedirectFlag(true);
            }
          } else if (sessionStorage.getItem("orderType") == "D") {
            if (
              !sessionStorage.getItem("guestFlag") ||
              sessionStorage.getItem("guestFlag") == "Y"
            ) {
              setGuestRedirectFlag(true);
            } else {
              if (sessionStorage.getItem("orderType") == "D") {
                checkCustomerDistance();
              } else {
                setCheckoutRedirectFlag(true);
              }
            }
          }
        } else {
          props.handleOpenImageUpload();
        }
      } else {
        if (
          sessionStorage.getItem("orderType") == "P" ||
          sessionStorage.getItem("orderType") == "C" ||
          sessionStorage.getItem("orderType") == "I"
        ) {
          if (
            !sessionStorage.getItem("guestFlag") ||
            sessionStorage.getItem("guestFlag") == "Y"
          ) {
            setGuestRedirectFlag(true);
          } else {
            setCheckoutRedirectFlag(true);
          }
        } else if (sessionStorage.getItem("orderType") == "D") {
          if (
            !sessionStorage.getItem("guestFlag") ||
            sessionStorage.getItem("guestFlag") == "Y"
          ) {
            setGuestRedirectFlag(true);
          } else {
            if (sessionStorage.getItem("orderType") == "D") {
              checkCustomerDistance();
            } else {
              setCheckoutRedirectFlag(true);
            }
          }
        }
      }
    });
  };


  const dinein = (e) => {
    if (dineInFlag == "Y") {
      Swal.fire({
        title:
          '<span style="width:100%;text-align: center;">' +
          i18n.t("dinein") +
          "</span>",
        input: "number",
        html: "<h5>" + i18n.t("enter_table_no") + "</h5>",
        showCancelButton: true,
        customClass: {
          confirmButton: "link_button",
          cancelButton: "link_button",
        },
        buttonsStyling: false,
        confirmButtonText: i18n.t("continue"),
        preConfirm: (login) => {
          if (login == "") {
            Swal.showValidationMessage(i18n.t("enter_table_name"));
          }
        },
      }).then((result) => {
        if (result.dismiss) {
          fetchStoreSchedule();
        } else {
          sessionStorage.setItem("orderType", "I");
          sessionStorage.setItem("orderDeliveryFee", 0);

          sessionStorage.setItem("slots", "ASAP");
          sessionStorage.setItem("table", result.value);
          sessionStorage.setItem("curbsideDesc", "");

          sessionStorage.setItem("date", "");

          loadNext();
        }
      });
    } else {
      Swal.fire({
        title: i18n.t("store_close_inst"),
        showCancelButton: false,
        confirmButtonText: t("ok"),
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'my-confirm-button'
        }
      });
    }
  };

  // const curbside = (e) => {
  //   if (toVerifyDelivery("C")) {
  //     Swal.fire({
  //       title:
  //         '<span style="width:100%;text-align: center;">' +
  //         i18n.t("curbside_information") +
  //         "</span>",
  //       input: "textarea",
  //       html: i18n.t("curbside_inst"),
  //       showCancelButton: true,
  //       customClass: {
  //         confirmButton: "link_button",
  //         cancelButton: "link_button",
  //       },
  //       buttonsStyling: false,
  //       confirmButtonText: i18n.t("continue"),
  //       preConfirm: (login) => {
  //         if (login == "") {
  //           Swal.showValidationMessage(i18n.t("vehicle_info"));
  //         }
  //       },
  //     }).then((result) => {
  //       if (result.dismiss) {
  //         fetchStoreSchedule();
  //       } else {
  //         sessionStorage.setItem("orderType", "C");
  //         sessionStorage.setItem("orderDeliveryFee", 0);
  //         sessionStorage.setItem("curbsideDesc", result.value);
  //         sessionStorage.setItem("tip", tip);
  //         sessionStorage.setItem("slots", timeSelectValue);
  //         sessionStorage.setItem("table", "");
  //         if (storeWeeklyOrder == "N") {
  //           sessionStorage.setItem("date", "");
  //         } else {
  //           sessionStorage.setItem("date", dateSelect);
  //         }

  //         loadNext();
  //       }
  //     });
  //   }
  // };

  const updateTime = () => {
    if (timingStatus == 0) {
      setTimeSelectCss("block");
      setBtnDinein("none");
      setTimingStatus(1);
      setBtnPickup("inline-block");
      setBtnCurbside("inline-block");
      setBtnDelivery("inline-block");
    } else {
      setTimeSelectCss("none");
      setTimingStatus(0);
      setBtnDinein("inline-block");

      if (dineInFlag == "Y") {
        setBtnPickup("inline-block");
        setBtnCurbside("inline-block");
        setBtnDelivery("inline-block");
      } else {
        setBtnPickup("none");
        setBtnCurbside("none");
        setBtnDelivery("none");
      }
    }
  };

  const fetchTiming = (date) => {
    // if (("#dateSelect").val() && ("#dateSelect").val() != "") {
    // if ((dateSelect && dateSelect != "")) {
    if (date && date != "") {
      // var url =
      //   API_ROOT +
      //   Method.FETCH_STORE_TIMING +
      //   "?store_id=" +
      //   localStorage.getItem("store_id") +
      //   "&date=" +
      //   date;
      onOpenModal();
      fetch(`${API_ROOT}${Method.FETCH_STORE_TIMING}?store_id=${store.store_id}&date=${date}`)
        .then((response) => {
          return response.json();
        })
        .then((storeData) => {
          onCloseModal();

          var timeList = [];
          if (storeData.status == 1) {
            timeList.push("");

            const givenDate = moment(date); // Date to check
            const today = moment(); // Today's date

            // Check if the given date is the same as today
            if (givenDate.isSame(today, 'day')) {
              if (storeData.dineInFlag == "Y") {
                timeList.push("ASAP");
              }
            }


            for (var i = 0; i < storeData.slots.length; i++) {
              timeList.push(storeData.slots[i]);
            }

            // if (
            //   dateSelect == moment().format("YYYY-MM-DD") &&
            //   storeData.slots.length > 0
            // ) {
            //   if (storeData.dineInFlag == "Y") {
            //     setTimeSelectValue("ASAP");
            //     //   $('#timeSelect').parent().find('.holder').html($('#timeSelect').val());
            //   } else {
            //     //   $('#timeSelect').parent().find('.holder').html("Select Any");
            //   }
            // }
          } else {
            Swal.fire({
              title: storeData.message,
              showCancelButton: false,
              confirmButtonText: t("ok"),
              allowOutsideClick: false,
              customClass: {
                confirmButton: 'my-confirm-button'
              }
            });
          }
          // console.log("timeList", timeList)
          setTimeLists(timeList);
        });
    } else {
      if (dineInFlag == "Y") {
        // setTimeout(function () {
        // ("#timeSelect").val("ASAP");
        // setTimeSelectValue("ASAP");

        // ("#dateSelect").val(("#dateSelect option:eq(1)").val());
        // }, 100);
      }
    }
  };

  const checkCustomerDistance = () => {
    onOpenModal();

    dbconn.getUserInfoFromIndexedDB().then((data) => {
      if (!sessionStorage.getItem("guestFlag")) {
        setNosessionredirect(true);
      } else {
        if (
          store.store_delivery_range > 0 &&
          store.store_latitude != "" &&
          store.store_longitude != ""
        ) {
          var address = "";
          if (data.customer_addreess != "") {
            address = data.customer_address;
          }
          if (data.customer_city != "") {
            address += ", " + data.customer_city;
          }
          if (data.customer_state != "") {
            address += ", " + data.customer_state;
          }
          if (data.customer_zipcode != "") {
            address += ", " + data.customer_zipcode;
          }
          var url = API_ROOT + Method.FETCH_DISTANCE;

          var options = {
            address: address,
            latitude: store.store_latitude,
            longitude: store.store_longitude,
            distance: store.store_delivery_range,
          };

          axios
            .post(url, options)
            .then((response) => {
              onCloseModal();

              if (response.data.status == 1) {
                // orderTypeDialog.close();
                setCheckoutRedirectFlag(true);
              } else {
                Swal.fire({
                  title: response.data.message,
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: 'my-confirm-button'
                  }
                });
              }
            })
            .catch(function (error) {
              onCloseModal();
              console.log(error);
            });
        } else {
          onCloseModal();
          setCheckoutRedirectFlag(true);
        }
      }
    });
  };

  const toVerifyDelivery = (type) => {
    var flag = true;

    if (type != "I") {
      if (props.storeWeeklyOrder == "N") {
        if (timeSelectValue == "") {
          // if (("#timeSelect").val() == "") {
          toast.dismiss();
          toast.error("time_slot_inst");
          return false;
        }
      } else {
        if (timingStatus == 1) {
          if (dateSelect == "") {
            // if (("#dateSelect").val() == "") {
            toast.dismiss();
            toast.error(i18n.t("date_inst"));

            return false;
          }
        } else {
          if (dineInFlag == "N") {
            if (dateSelect == "") {
              // if (("#dateSelect").val() == "") {
              toast.dismiss();
              toast.error(i18n.t("date_inst"));

              return false;
            }
          }
        }

        if (timeSelectValue == "") {
          // if (("#timeSelect").val() == "") {
          toast.dismiss();
          toast.error(i18n.t("time_slot_inst"));
          return false;
        }
      }
    }

    var couponApplied = sessionStorage.getItem("couponApplied");

    if (couponApplied === "Y") {
      const couponDetails = JSON.parse(sessionStorage.getItem('couponDetails'))
      var deliveryType = couponDetails.coupon_delivery_type

      if (deliveryType.indexOf(type) < 0) {
        if (type == "P") {
          toast.dismiss();
          toast.error(i18n.t("coupon_invalid", { type: i18n.t("pickup") }));
          return false;
        } else if (type == "D") {
          toast.dismiss();
          toast.error(i18n.t("coupon_invalid", { type: i18n.t("delivery") }));
          return false;
        }
        //  else if (type == "C") {
        //   toast.error(i18n.t("coupon_invalid", { type: i18n.t("curbside") }));
        //   return false;
        // } else if (type == "I") {
        //   toast.error(i18n.t("coupon_invalid", { type: i18n.t("dinein") }));
        //   return false;
        // }
      }
    }

    return flag;
  };

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const changeDateSelect = (e) => {
    if (e.target.value == "") {
      setDateSelect("Select Any");
      //  $('#dateSelect').parent().find('.holder').html('Select Any');
    } else {
      //  $('#dateSelect').parent().find('.holder').html(moment($('#dateSelect').val(), "YYYY-MM-DD").format("MMMM Do YYYY"));
      setDateSelect(e.target.value, "YYYY-MM-DD");
      // setDateSelect(moment(e.target.value, "YYYY-MM-DD").format("MMMM Do YYYY"))

      fetchTiming(e.target.value);
    }
  };

  const changeTimeSelect = (e) => {
    if (e.target.value != "") {
      setTimeSelectValue(e.target.value);
    }
  };

  useEffect(() => {
    if (checkoutRedirectFlag) {
      navigate("/checkout");
      sessionStorage.setItem("userType", "checkout")
    }
    if (guestRedirectFlag) {
      sessionStorage.setItem("userType", "checkoutGuest")
      navigate("/checkout");
    }
  }, [checkoutRedirectFlag, guestRedirectFlag]);

  const handleClose = () => {
    props.onComponentClose();
  };

  return (
    <>
      {ready && (
        <div className="modalMainDiv p-3 position-relative">
          <span className="closeModal" onClick={handleClose}>
            <IoClose />
          </span>
          <div id="my-modal-checkout">
            {/* <div className="p-0 float-left w-100 bg-white"> */}
            <form>
              <div className="col-md-12">
                <div className="w-100">
                  {/* <div className=" border-0"> */}
                  <h4 className="roboto-medium-16 mb-3">
                    {t("when_would_you_order")}
                  </h4>
                  <div className="tab-block" id="tab-block">
                    <div className="tab-top position-relative">
                      <ul className="tab-mnu mb-3">
                        <li key="tip34" className="m-0">
                          <input
                            type="radio"
                            id="a34"
                            name="time"
                            value="N"
                            checked={timingStatus == 0}
                            onChange={updateTime}
                          />
                          <label htmlFor="a34" className="roboto-medium-18 m-0">
                            {t("now")}
                          </label>
                        </li>
                        <li key="tip35" className="m-0">
                          <input
                            type="radio"
                            id="a35"
                            name="time"
                            value="L"
                            checked={timingStatus == 1}
                            onChange={updateTime}
                          />
                          <label htmlFor="a35" className="roboto-medium-18 m-0">
                            {t("later")}
                          </label>
                        </li>
                      </ul>
                      <div className="tab-cont">
                        <div className="tab-pane">
                          <div className="tabViewOrderBlock">
                            <h3 className="text-center roboto-medium-18">
                              {t("select_order_type")}
                            </h3>

                            <div
                              className="timeSelect"
                              style={{ display: timeSelectCss }}
                            >
                              <div className="form-group mb-2  clearfix ml-2 mr-2">
                                <div className="select custom-select p-0">
                                  <select className="orderTypeSelect custom-select w-100 border rounded"
                                    id="dateSelect"
                                    onChange={changeDateSelect}>
                                    {dateLists.map((item, index) => {
                                      // Check if the item is empty string or not
                                      if (item === "") {
                                        return null; // Skip rendering an option for empty string
                                      } else {
                                        return (
                                          <option value={item} key={index}>
                                            {moment(item, "YYYY-MM-DD").format(
                                              "MMMM Do YYYY"
                                            )}
                                          </option>
                                        );
                                      }
                                    })}
                                  </select>
                                </div>
                              </div>

                              <div className="form-group mb-2 clearfix ml-2 mr-2">
                                <div className="select custom-select p-0">
                                  <select
                                    className="orderTypeSelect custom-select w-100  border rounded"
                                    id="timeSelect"
                                    onChange={changeTimeSelect}
                                  >
                                    {timeLists.map((item, index) => {
                                      if (item == "") {
                                        return (
                                          <option value="" key={index}>
                                            select any
                                          </option>
                                        );
                                      } else {
                                        return (
                                          <option value={item} key={index}>
                                            {item}{" "}
                                          </option>
                                        );
                                      }
                                    })}
                                    v
                                  </select>
                                </div>
                              </div>
                            </div>

                            {storeOrderType.indexOf("D") > -1 ? (
                              <p className="mt-4 mb-3 text-center roboto-regular-italic-12">
                                {t("delivery_instruction", {
                                  delivery: props.storeDeliveryFee + store.store_delivery_disclaimer,
                                })}
                              </p>
                            ) : (
                              ""
                            )}


                            {storeOrderType.indexOf("P") > -1 ? (
                              <>
                                {btnPickup === "none" && <span className="roboto-medium-22 m-0" style={{ color: "red" }}>{t('store_close')}</span>}
                                <button type="button"

                                  id="btnPickup"
                                  className="btn orderTypeButton w-100 radius-0 mb-2 py-2 roboto-medium-16"
                                  onClick={pickup}
                                  style={{ display: btnPickup }}
                                >
                                  {/* PICKUP */}
                                  <TbShoppingBag style={{ fontSize: '22px' }} /> {t("pickup")}
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                            {storeOrderType.indexOf("D") > -1 ? (
                              <button type="button"

                                id="btnDelivery"
                                className="btn orderTypeButton w-100 radius-0 mb-2 py-2 roboto-medium-16"
                                onClick={delivery}
                                style={{ display: btnDelivery }}
                              >
                                {/* DELIVERY */}
                                <MdDeliveryDining style={{ fontSize: '22px', marginRight: "4px" }} />{t("delivery")}
                              </button>
                            ) : (
                              ""
                            )}
                            {storeOrderType.indexOf("I") > -1 ? (
                              <button
                                type="button"
                                id="btnDinein"
                                className="btn orderTypeButton w-100 radius-0 mb-2 py-2 roboto-medium-16 d-none"
                                onClick={dinein}
                                style={{ display: btnDinein }}
                              >
                                {/* DINE-IN */}
                                {t("dinein")}
                              </button>
                            ) : (
                              ""
                            )}
                            {/* {storeOrderType.indexOf("C") > -1 ? (
                              <button
                                type="button"
                                id="btnCurbside"
                                className="btn orderTypeButton w-100 radius-0 py-2 roboto-medium-16 d-none"
                                onClick={curbside}
                                style={{ display: btnCurbside }}
                              >
                                {/* CURBSIDE
                            {t("curbside")}
                          </button>
                          ) : (
                          ""
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </form>
            {/* </div> */}
          </div >

          <Notifications
            options={{ zIndex: 99999, width: "100%", top: "50px" }}
          />
          {
            open && (
              <div className="loader">
                <img src={loader} alt="" width={"300px"} height={"210px"} />
              </div>
            )
          }

          <Toaster />
        </div >
      )
      }
    </>
  );
}
