import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsChevronLeft } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import spanish from '../image/spanishFlag.png';
import usa from '../image/usaFlag.webp';

function Header({ header, path }) {
     const { t, i18n } = useTranslation();
     var lang = localStorage.getItem("store_language");
     const langRef = useRef(null)
     const [language, setLanguage] = useState([{
          id: 1, name: "English", image: "en"
     }, {
          id: 2, name: "Spanish", image: "es"
     }]);
     const [listLanguage, setListLanguage] = useState(false)

     useEffect(() => {
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
               document.removeEventListener("mousedown", handleClickOutside);
          };
     }, []);


     const handleClickOutside = (event) => {

          if (langRef.current && !langRef.current.contains(event.target)) {
               setListLanguage(false)
          }
     };

     const languageHandle = () => {
          setListLanguage(!listLanguage)
     }

     const langSelected = (lang) => () => {
          localStorage.setItem("store_language", lang == "Spanish" ? "es" : 'en')
          i18n.changeLanguage(localStorage.getItem("store_language")).then((r) => { });
          setListLanguage(false)
     };
     return (
          <header className="header-div bg-white position-fixed w-100 shadow">
               <div className="d-flex justify-content-between align-items-center">
                    <Link to={path} className="float-left">
                         <BsChevronLeft />
                    </Link>
                    <h2 className="text-center mb-0">
                         <strong className="roboto-bold-24">{header}</strong>
                    </h2>
                    <button ref={langRef} className="flagImagebutton float-right" onClick={() => languageHandle()}>{lang == "en" ? <img className="langImage" src={usa} alt="" width={30} height={30} /> : <img className="langImage" src={spanish} alt="" width={30} height={30} />}</button>
                    {listLanguage &&
                         <ul className="flagImageDiv p-2">
                              {language.map((lang, index) =>
                              (
                                   <li key={index}
                                        className="roboto-medium-16 mb-2"
                                        onClick={langSelected(lang.name)}>
                                        {lang.image == "en" ? <img src={usa} alt="" width={15} height={15} /> : <img src={spanish} alt="" width={15} height={15} />} {lang.name}
                                   </li>
                              ))}
                         </ul>
                    }
               </div>
          </header>
     )
}

export default Header