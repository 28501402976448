import { getAuth, signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { GrUserAdmin } from "react-icons/gr";
import { HiOutlineUserRemove } from "react-icons/hi";
import { IoIosHome, IoMdHelpCircleOutline } from "react-icons/io";
import { LuHistory } from "react-icons/lu";
import { MdOutlinePolicy } from "react-icons/md";
import { RiCoinFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IMAGE_ROOT } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import { auth } from "../firebase";
import { fetchStoreData } from "../store/slices/fetchStore";
import { selectSidebarStatus } from "../store/slices/sidebarSlice";

function Sidebar() {
  const navigate = useNavigate();
  const isSidebarOpen = useSelector(selectSidebarStatus); // Sidebar open state from redux store
  const [activeMenu, setActiveMenu] = useState(1); // Active menu state
  const dispatch = useDispatch();
  const [height, setHeight] = useState(window.innerHeight); // Sidebar height based on window size
  const [isLogin, setIsLogin] = useState(false); // Track login state

  const handleResize = () => {
    setHeight(window.innerHeight); // Update height on window resize
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize); // Listen for window resize
    return () => window.removeEventListener("resize", handleResize); // Clean up event listener
  }, []);

  useEffect(() => {
    // Check if the user is logged in (based on sessionStorage guest flag)
    const userLogin = sessionStorage.getItem("guestFlag");
    if (userLogin === "N") {
      setIsLogin(true); // User is logged in
    } else {
      setIsLogin(false); // User is a guest
    }
  }, []);

  const logout = () => {
    /// Logout function to clear user data and sign out from firebase
    dbconn.deleteUser();
    dbconn.deleteUserFromIndexedDB(4);
    auth.signOut(); // Sign out from firebase
    localStorage.setItem("isLogin", true);
    sessionStorage.removeItem("login");
    sessionStorage.removeItem("rating");
    localStorage.removeItem("remember");
    localStorage.removeItem("refundPolicy");
    localStorage.removeItem("tos");
    localStorage.removeItem("privacyPolicy");
    sessionStorage.setItem("guestFlag", "Y"); // Set guest flag to "Y"
    localStorage.setItem("isLogin", false); // Set user login status to "Y"
    localStorage.removeItem("cart");
    sessionStorage.removeItem("ssotype");
    sessionStorage.setItem("couponApplied", "N"); // Reset coupon applied status
    sessionStorage.removeItem("couponCode");
    sessionStorage.removeItem("couponDetails");
    sessionStorage.removeItem("couponType");
    setIsLogin(false); // Set login state to false
    dispatch(fetchStoreData()); // Fetch store data after logout
    const isSSOLogin = sessionStorage.getItem("isSSOLogin"); // Check if SSO login is used

    if (isSSOLogin === "true") {
      const auth = getAuth();
      signOut(auth) // Sign out from SSO service
        .then(() => {
          // Sign-out successful.
          console.log("User logged out successfully.");
          // You can redirect the user to a different page after logout or update the UI accordingly
        })
        .catch((error) => {
          // An error happened.
          console.error("Error logging out: ", error);
        });
    }
    navigate("/"); // Redirect to homepage after logout
  };

  const handleActiveMenu = (id) => {
    // Function to handle active menu and navigation
    if (id == 1) {
      setActiveMenu(1);
      navigate("/");
    } else if (id == 2) {
      setActiveMenu(2);
      navigate("/rewards");
    } else if (id == 3) {
      setActiveMenu(3);
      navigate("/order/history");
    } else if (id == 4) {
      setActiveMenu(4);
      navigate("/policy");
    } else if (id == 5) {
      setActiveMenu(5);
      navigate("/help");
    } else if (id == 0) {
      sessionStorage.setItem("guestFlag", "Y");
      dbconn.deleteUserFromIndexedDB(4);
      setActiveMenu(5);
      navigate("/login");
    } else if (id == -1) {
      logout();
    } else if (id == 6) {
      setActiveMenu(6);
      navigate("/profile");
    } else if (id == 7) {
      setActiveMenu(7);
      navigate("/signup");
    }
  };
  return (
    <>
      <div
        className=" d-flex flex-column justify-content-between mx-2"
        style={{ height: `${height}px` }}
      >
        <span>
          {/* Sidebar Logo */}
          <div className="logoImg text-center py-2">
            <img
              src={
                IMAGE_ROOT +
                "/images/product/" +
                localStorage.getItem("store_image")
              }
              alt=""
              height={isSidebarOpen ? "80px" : "42px"} // Adjust logo height based on sidebar state
            />
          </div>

          {/* Navigation links */}
          <div className="navLink">
            <ul className="nav-list">
              {/* Home link */}
              <li className="">
                <a
                  onClick={() => handleActiveMenu(1)}
                  className={`d-flex align-items-center roboto-medium-16 ${
                    isSidebarOpen ? `` : "justify-content-center"
                  } ${activeMenu == 1 && "activeMenu"}`}
                >
                  <IoIosHome />{" "}
                  {isSidebarOpen ? <span className="link_name">Home</span> : ""}
                </a>
              </li>
              {/* Conditional links for logged-in users */}
              {isLogin === true && (
                <>
                  <li>
                    <a
                      onClick={() => handleActiveMenu(2)}
                      className={`d-flex align-items-center roboto-medium-16 ${
                        isSidebarOpen ? `` : "justify-content-center"
                      } ${activeMenu == 2 && "activeMenu"}`}
                    >
                      <RiCoinFill />{" "}
                      {isSidebarOpen ? (
                        <span className="link_name">Rewards</span>
                      ) : (
                        ""
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleActiveMenu(3)}
                      className={`d-flex align-items-center roboto-medium-16 ${
                        isSidebarOpen ? `` : "justify-content-center"
                      } ${activeMenu == 3 && "activeMenu"}`}
                    >
                      <LuHistory />
                      {isSidebarOpen ? (
                        <span className="link_name">Order History</span>
                      ) : (
                        ""
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleActiveMenu(4)}
                      className={`d-flex align-items-center roboto-medium-16 ${
                        isSidebarOpen ? `` : "justify-content-center"
                      } ${activeMenu == 4 && "activeMenu"}`}
                    >
                      <MdOutlinePolicy />{" "}
                      {isSidebarOpen ? (
                        <span className="link_name">Policy</span>
                      ) : (
                        ""
                      )}
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => handleActiveMenu(5)}
                      className={`d-flex align-items-center roboto-medium-16 ${
                        isSidebarOpen ? `` : "justify-content-center"
                      } ${activeMenu == 5 && "activeMenu"}`}
                    >
                      <IoMdHelpCircleOutline />{" "}
                      {isSidebarOpen ? (
                        <span className="link_name">Help</span>
                      ) : (
                        ""
                      )}
                    </a>
                  </li>
                </>
              )}

              {/* Login link for guests */}
              {isLogin === false ? (
                <li>
                  <a
                    onClick={() => handleActiveMenu(0)}
                    className={`d-flex align-items-center roboto-medium-16 ${
                      isSidebarOpen ? `` : "justify-content-center"
                    }`}
                  >
                    <GrUserAdmin />{" "}
                    {isSidebarOpen ? (
                      <span className="link_name">Login</span>
                    ) : (
                      ""
                    )}
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </span>
        <div className="userProfile">
          <ul className="nav-list">
            {/* Profile link for logged-in users */}
            {isLogin === true && (
              <li>
                <a
                  onClick={() => handleActiveMenu(6)}
                  className={`d-flex align-items-center roboto-medium-16 ${
                    isSidebarOpen ? `` : "justify-content-center"
                  }`}
                >
                  <FaRegUserCircle />{" "}
                  {isSidebarOpen ? (
                    <span className="link_name">Profile</span>
                  ) : (
                    ""
                  )}
                </a>
              </li>
            )}
            {/* Logout link for logged-in users */}
            <li>
              {isLogin === true && (
                <a
                  onClick={() => handleActiveMenu(-1)}
                  className={`d-flex align-items-center roboto-medium-16 ${
                    isSidebarOpen ? `` : "justify-content-center"
                  }`}
                >
                  <HiOutlineUserRemove />{" "}
                  {isSidebarOpen ? (
                    <span className="link_name">Logout</span>
                  ) : (
                    ""
                  )}
                </a>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
