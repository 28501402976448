import React, { useEffect } from "react";
import "react-responsive-modal/styles.css";
import { Link, useNavigate } from "react-router-dom";
import {
  IMAGE_ROOT
} from "../api/apiHandler";
import LanguageSelector from "../components/LanguageSelector";
import LoginComponent from "../components/LoginComponent";
import "../style/login.css";

function Login() {
  const navigate = useNavigate();


  useEffect(() => {
    if (sessionStorage.getItem('guestFlag') === "N") {
      navigate('/')
    }
  }, [])

  return (
    <div className="mainLogin">
      <div className="loginLogo">
        <Link to="/">
          <img
            style={{ width: "70px" }}
            className="center-block"
            src={`${IMAGE_ROOT}/images/product/${localStorage.getItem("store_image")}`}
            alt="Takecharge Diner Logo"
          />
        </Link>
      </div>
      <div className="language">
        <LanguageSelector />
      </div>
      <LoginComponent />
    </div>
  );
}

export default Login;
