import fetch from "isomorphic-fetch";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_ROOT, IMAGE_ROOT, Method } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import loader from '../image/res_home_page_loader.gif';
import "../style/Profile.css";

export default function Profile() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerCity, setCustomerCity] = useState("");
  const [customerState, setCustomerState] = useState("");
  const [customerZip, setCustomerZip] = useState("");
  const [customerId, setCustomerId] = useState(0);
  const [customerToken, setCustomerToken] = useState("");

  // useEffect(() => {
  //   var primaryColor = localStorage.getItem("store_primary_color");
  //   var secondaryColor = localStorage.getItem("store_secondary_color");
  //   var buttonColor = localStorage.getItem("store_button_color");

  //   if (primaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--primaryColor",
  //       primaryColor
  //     );
  //   }
  //   if (buttonColor != null) {
  //     document.documentElement.style.setProperty("--buttonColor", buttonColor);
  //   }

  //   if (secondaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--secondaryColor",
  //       secondaryColor
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (!localStorage.getItem("store_language")) {
      localStorage.setItem("store_language", "en");
    }
    i18n.changeLanguage(localStorage.getItem("store_language")).then((r) => { });

    Swal.mixin({
      showCancelButton: false,
      confirmButtonText: t("ok"),
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'my-confirm-button'
      }
    });

    dbconn.getUserInfoFromIndexedDB().then((data) => {
      if (
        !data ||
        data.length < 1 ||
        sessionStorage.getItem("guestFlag") === "Y"
      ) {
        navigate("/");
      } else {
        setOpen(true);
        var customer_id = data.customer_id;
        var customer_token = data.customer_token;
        var url =
          API_ROOT +
          Method.FETCH_PROFILE +
          "?customer_id=" +
          customer_id +
          "&customer_token=" +
          customer_token;

        fetch(url)
          .then((response) => response.json())
          .then((customerData) => {
            setOpen(false);
            if (customerData.status === 1) {
              var custData = customerData.customer_data[0];
              setCustomerName(
                custData.customer_firstname + " " + custData.customer_lastname
              );
              setCustomerEmail(
                i18n.t("email") + ": " + custData.customer_email
              );
              setCustomerPhone(
                i18n.t("phone") + ": " + custData.customer_phone
              );
              setCustomerAddress(custData.customer_address);
              setCustomerCity(custData.customer_city + ", ");
              setCustomerState(custData.customer_state);
              setCustomerZip(custData.customer_zipcode);
              setCustomerId(data.customer_id);
              setCustomerToken(data.customer_token);
            }
          });
      }
    });

    // document.title =
    //   "My Profile - " + localStorage.getItem("store_name") + " - " + PROJECT;

    // var primaryColor = localStorage.getItem("store_primary_color");
    // if (primaryColor != null) {
    //   document.documentElement.style.setProperty(
    //     "--primaryColor",
    //     primaryColor
    //   );
    // }
    // var secondaryColor = localStorage.getItem("store_secondary_color");
    // if (secondaryColor != null) {
    //   document.documentElement.style.setProperty(
    //     "--secondaryColor",
    //     secondaryColor
    //   );
    // }
  }, []);

  const editProfile = () => {
    navigate("/profile/edit");
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <div className="confirmPageMainDiv">
      {/* <header className="header-div bg-white position-fixed w-100 shadow">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Link to="/" className="float-left">
            <BsChevronLeft />
          </Link>
          <h2 className="text-center mb-0 roboto-bold-24">{t("profile")}</h2>
        </div>
      </header> */}
      <div className="loginLogo">
        <Link to="/">
          <img
            style={{ width: "70px" }}
            className="center-block"
            src={
              IMAGE_ROOT +
              "/images/product/" +
              localStorage.getItem("store_image")
            }
            alt="Takecharge Diner Logo"
          />
        </Link>
      </div>
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-8 col-xs-12 m-auto vscroll bg-white" style={{ height: "84vh", margin: "50px auto", borderRadius: "10px" }}>
            <span className="backToHomeBtn" onClick={() => navigate('/')}><IoIosArrowBack /></span>
            <div className="LoginRegisterBlock h-100" style={{ marginTop: "0" }}>
              <div className="LoginRegisterForm float-left w-100">
                <form action="#" method="post" encType="multipart/form-data">
                  <h3 className="mb-4 mt-4 color333 roboto-medium-16">
                    {customerName}
                  </h3>
                  <div className="form-group mb-4 text-center user_info">
                    <p className="roboto-regular-16">{customerEmail}</p>
                    <p className="roboto-regular-16">{customerPhone}</p>
                    <p className="roboto-regular-16">{customerAddress}</p>
                    <p className="roboto-regular-16">
                      {customerCity} {customerState} {customerZip}
                    </p>
                  </div>
                  <div className="form-group mb-2 w-100 d-flex justify-content-center align-items-center">
                    <input
                      type="button"
                      onClick={editProfile}
                      className="btn btnLogin btn-yellow w-50 btn-lg mb-3"
                      value={t("edit_info")}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <div className="loader">
          <img src={loader} alt="" width={"300px"} height={"210px"} />
        </div>
      )}
      {/* <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        open={open}
        onClose={onCloseModal}
        center
      >
        <h2>&nbsp;&nbsp;&nbsp;&nbsp;{t("loading")}&nbsp;&nbsp;&nbsp;&nbsp;</h2>

        <p style={{ textAlign: "center" }}>
          <img width={"40"} height={"40"} src={Loading} alt="loading_img" />
        </p>
      </Modal> */}
    </div>
  );
}
