import { openDB } from "idb";

const mainDB = openDB("CF_DB", 1, {
     upgrade(DB) {
          const user = DB.createObjectStore("CF_User", {
               keyPath: "user_id",
               autoIncrement: true,
          });
          user.createIndex("user_id", "user_id", { unique: false });
          const store = DB.createObjectStore("CF_Store");

     },
});

const orderDB = openDB("CF_Order", 1, {
     upgrade(DB) {
          DB.createObjectStore("order_store");
     },
});


// export async function get(key) {
//   return (await storeDB).get("CF_Store", key);
// }


// export async function set(key, val) {
//   return (await storeDB).put("CF_Store", val, key);
// }


// export async function del(key) {
//   return (await storeDB).delete("CF_Store", key);
// }


// export async function clear() {
//   return (await storeDB).clear("CF_Store");
// }

// export async function getAllStore() {
//   const db = await storeDB;
//   const tx = db.transaction("CF_Store", "readonly");
//   const store = tx.objectStore("CF_Store");
//   return (await store).getAll();
// }
export async function getStore() {
     const db = await mainDB;
     const tx = db.transaction("CF_Store", "readonly");
     const store = tx.objectStore("CF_Store");
     return (await store).getAll();
}

export async function getUser() {
     const db = await mainDB;
     const tx = db.transaction("CF_User", "readonly");
     const user = tx.objectStore("CF_User");
     return (await user).getAll();
}

export async function deleteStoreDetails(key) {
     const db = await mainDB;
     const tx = db.transaction("CF_Store", "readwrite");
     tx.objectStore("CF_Store").delete(key);
     tx.objectStore("CF_Store").getAll();
     await tx.complete;
}

export async function deleteUserDetails(key) {
     const db = await mainDB;
     const tx = db.transaction("CF_User", "readwrite");
     tx.objectStore("CF_User").delete(key);
     tx.objectStore("CF_User").getAll();
     await tx.complete;
}

export async function getAllKeys() {
     const db = await orderDB
     const tx = db.transaction("order_store", "readwrite");
     return tx.objectStore("order_store").getAllKeys();
}

export async function setUser(val) {
     const db = await mainDB;
     const tx = db.transaction("CF_User", "readwrite");
     tx.objectStore("CF_User").put(val);

     await tx.complete;
}

export async function setStore(val) {

     try {
          const db = await mainDB;
          const tx = db.transaction('CF_Store', 'readwrite');
          tx.objectStore('CF_Store').put(val, 'store');
          await tx.done;
     } catch (e) {
          console.error('Could not save state to IndexedDB', e);
     }

}

export async function deleteUser() {
     const data = await getUser();
     for (let i = 0; i < data.length; i++) {
          await deleteUserDetails(data[i].user_id);
     }
}

export async function deletStore() {
     const data = await getStore();
     for (let i = 0; i < data.length; i++) {
          await deleteStoreDetails(data[i].user_id);
     }
}


// export async function setToStore(val) {
//   try {
//     const db = await storeDB;
//     if (!db) {
//       throw new Error("Database is not available.");
//     }

//     const tx = db.transaction("CF_Store", "readwrite");
//     if (!tx) {
//       throw new Error("Transaction could not be created.");
//     }

//     const store = tx.objectStore("CF_Store");
//     if (!store) {
//       throw new Error("Object store 'CF_Store' not found.");
//     }

//     store.put(val);
//     await tx.complete;

//     console.log("Value successfully set in store.");
//   } catch (error) {
//     console.error("Error setting value in store:", error);
//   }
// }





export async function setOrder(val, key) {
     const db = await orderDB;
     const tx = db.transaction("order_store", "readwrite");
     tx.objectStore("order_store").put(val, key);
     return tx.complete;
}

export async function getAllOrder() {
     const db = await orderDB
     const tx = db.transaction("order_store", "readonly");
     const store = tx.objectStore("order_store");
     return store.getAll();
}

export async function getOrder(key) {
     const db = await orderDB
     const tx = db.transaction("order_store", "readonly");
     const store = tx.objectStore("order_store");
     return store.openCursor(IDBKeyRange.only(key));
}


export async function deleteOrder(key) {
     const db = await orderDB
     const tx = db.transaction("order_store", "readwrite");
     tx.objectStore("order_store").delete(key);
     tx.objectStore("order_store").getAll();
     return tx.complete;
}

export const fetchStoreDetails = async (key) => {
     try {
          const db = await openDatabase();
          const tx = db.transaction('store_data', 'readonly');
          const data = await tx.objectStore('store_data').get(key);
          return data[key];
     } catch (error) {
          console.error(`Could not fetch ${key} from IndexedDB`, error);
          return null;
     }
};
export const fetchAllStore = async (key) => {
     try {
          const db = await openDatabase();
          const tx = db.transaction('store_data', 'readonly');
          const data = await tx.objectStore('store_data').get(key);
          return data;
     } catch (error) {
          console.error(`Could not fetch ${key} from IndexedDB`, error);
          return null;
     }
};

export async function deleteAllOrder() {
     const data = await getAllOrder();
     for (let i = 0; i < data.length; i++) {
          await deleteOrder(data[i].cart_id);
     }
}

export async function deleteAllCouponOrder(couponType) {
     const data = await getAllOrder();
     for (var i = 0; i < data.length; i++) {
          if (data[i].product_discount_type === couponType) {
               await deleteOrder(data[i].cart_id);
          }
     }
}


// =============================== New Database ===============================

//for create a store =======================================================
async function initDB() {
     return openDB('storeInfo', 1, {
          upgrade(db) {
               if (!db.objectStoreNames.contains('CF_Store')) {
                    db.createObjectStore('CF_Store', { keyPath: 'id' });
               }
               if (!db.objectStoreNames.contains('CF_Store_product')) {
                    db.createObjectStore('CF_Store_product', { keyPath: 'id' });
               }
               if (!db.objectStoreNames.contains('CF_Cart')) {
                    db.createObjectStore('CF_Cart', { keyPath: 'id' });
               }
               if (!db.objectStoreNames.contains('CF_User')) {
                    db.createObjectStore('CF_User', { keyPath: 'id' });
               }
          },
     });
}

//for add data for store
export async function addStoreToIndexedDB(data) {
     const db = await initDB();
     const tx = db.transaction('CF_Store', 'readwrite');
     const store = tx.objectStore('CF_Store');
     await store.put({ id: 1, ...data });
     await tx.done;
}

// for read data/fetch store from indexedDB
export async function getStoreFromIndexedDB() {
     const db = await initDB();
     const tx = db.transaction('CF_Store', 'readonly');
     const store = tx.objectStore('CF_Store');
     const data = await store.get(1);
     await tx.done;
     return data;
}

//for add data for product =======================================================
export async function addAllProductToIndexedDB(data) {
     const db = await initDB();
     const tx = db.transaction('CF_Store_product', 'readwrite');
     const productStore = tx.objectStore('CF_Store_product');
     await productStore.put({ id: 2, ...data });
     await tx.done;
}

// for read data/fetch store from indexedDB
export async function getAllProductFromIndexedDB() {
     const db = await initDB();
     const tx = db.transaction('CF_Store', 'readonly');
     const productGet = tx.objectStore('CF_Store');
     const products = await productGet.get(2);
     await tx.done;
     return products;
}

//for add data for Cart =======================================================

export const addCartToIndexedDB = async (data) => {
     try {
          const db = await initDB();
          const tx = db.transaction('CF_Cart', 'readwrite');
          const cart = tx.objectStore('CF_Cart');
          await cart.put({ id: 3, ...data });
          await tx.done;
     } catch (e) {
          console.error('Could not save state to IndexedDB', e);
     }
};

export const getCartFromIndexedDB = async () => {
     try {
          const db = await initDB();
          const tx = db.transaction('CF_Cart', 'readonly');
          const cart = tx.objectStore('CF_Cart');
          const data = await cart.get(3);
          await tx.done;
          return data || undefined;
     } catch (e) {
          console.error('Could not load state from IndexedDB', e);
          return undefined;
     }
};

export async function deleteCartIndexedDB() {
     const db = await initDB();
     const tx = db.transaction('CF_User', 'readwrite');
     const user = tx.objectStore('CF_User');
     await user.delete(3);
     await tx.done;
}

//for add user info data ===========================================================
export async function addUserInfoToIndexedDB(data) {
     const db = await initDB();
     const tx = db.transaction('CF_User', 'readwrite');
     const user = tx.objectStore('CF_User');
     await user.put({ id: 4, ...data });
     await tx.done;
}

// for read data/fetch user info from indexedDB
export async function getUserInfoFromIndexedDB() {
     const db = await initDB();
     const tx = db.transaction('CF_User', 'readonly');
     const user = tx.objectStore('CF_User');
     const data = await user.get(4);
     await tx.done;
     return data;
}

export async function deleteUserFromIndexedDB(id) {
     const db = await initDB();
     const tx = db.transaction('CF_User', 'readwrite');
     const user = tx.objectStore('CF_User');
     await user.delete(id);
     await tx.done;
}
//for coupon CRUD ===========================================================
export async function addCouponToIndexedDB(data) {
     const db = await initDB();
     const tx = db.transaction('CF_Coupon', 'readwrite');
     const user = tx.objectStore('CF_Coupon');
     await user.put({ id: 5, ...data });
     await tx.done;
}

// for read data/fetch user info from indexedDB
export async function getCouponFromIndexedDB() {
     const db = await initDB();
     const tx = db.transaction('CF_Coupon', 'readonly');
     const user = tx.objectStore('CF_Coupon');
     const data = await user.get(5);
     await tx.done;
     return data;
}

export async function deleteCouponFromIndexedDB(id) {
     const db = await initDB();
     const tx = db.transaction('CF_Coupon', 'readwrite');
     const user = tx.objectStore('CF_Coupon');
     await user.delete(id);
     await tx.done;
}