import axios from "axios";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
    API_ROOT
} from "../api/apiHandler";

import Swal from "sweetalert2";
import * as dbconn from "../api/dbconn";
import loader from '../image/res_home_page_loader.gif';

const FBLoginPage = (props) => {
    const { t, i18n } = useTranslation();
    //const [swalTable, setSwalTable] = useState('');
    const [redirectlogin, setRedirectLogin] = useState(false);
    const [redirectsignup, setRedirectSignup] = useState(false);
    const [redirectcard, setRedirectCard] = useState(false);
    const [redirectguest, setRedirectGuest] = useState(false);
    const [redirectmenu, setRedirectMenu] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();


    // useEffect(() => {
    //     var primaryColor = localStorage.getItem("store_primary_color");
    //     var secondaryColor = localStorage.getItem("store_secondary_color");
    //     var buttonColor = localStorage.getItem("store_button_color");

    //     if (primaryColor != null) {
    //         document.documentElement.style.setProperty(
    //             "--primaryColor",
    //             primaryColor
    //         );
    //     }
    //     if (buttonColor != null) {
    //         document.documentElement.style.setProperty(
    //             "--buttonColor",
    //             buttonColor
    //         );
    //     }

    //     if (secondaryColor != null) {
    //         document.documentElement.style.setProperty(
    //             "--secondaryColor",
    //             secondaryColor
    //         );
    //     }
    // }, [])



    useEffect(() => {


        setOpen(true);
        // console.log("TSET " + window.location);
        const params = new URLSearchParams(window.location.search);



        if (params.toString() !== "") {
            axios.post(`${API_ROOT}/login`, {
                logintoken: params.get('logintoken'),
                loginType: 'F',
                store_id: localStorage.getItem("store_id"),
                merchant_id: localStorage.getItem("merchant_id"),
            })
                .then((response) => {
                    // console.log(response.data);
                    setOpen(false);
                    if (response.data.status === 1) {

                        const data = response.data.customer_data[0];
                        dbconn.deleteUserFromIndexedDB(4)
                        dbconn.deleteCartIndexedDB(3)
                        dbconn.addUserInfoToIndexedDB(data)
                        // dbconn.setUser({
                        //     customer_id: data.customer_id,
                        //     customer_token: data.customer_token,
                        //     customer_firstname: data.customer_firstname,
                        //     customer_lastname: data.customer_lastname,
                        //     customer_email: data.customer_email,
                        //     customer_address: data.customer_address,
                        //     customer_city: data.customer_city,
                        //     customer_state: data.customer_state,
                        //     customer_zipcode: data.customer_zipcode,
                        //     customer_phone: data.customer_phone,
                        // });
                        // localStorage.setItem("user_id", data.customer_id);
                        // localStorage.setItem(
                        //     "user_name",
                        //     data.customer_firstname + " " + data.customer_lastname
                        // );
                        // localStorage.setItem("user_email", data.customer_email);
                        sessionStorage.setItem("guestFlag", "N");
                        sessionStorage.setItem("cartTotal", "0.00");


                        localStorage.setItem("remember", "N");


                        if (localStorage.getItem("redirect") === "L") {

                            navigate('/menu');
                        } else if (localStorage.getItem("redirect") === "S") {

                            navigate('/signup');
                        }
                    } else {

                        Swal.fire({
                            title: t("invalid_login"),
                            showCancelButton: false,
                            confirmButtonColor: "var(--primaryColor)",
                            confirmButtonText: t("ok"),
                        }).then(() => {
                            if (localStorage.getItem("redirect") === "L") {
                                navigate('/login');
                            } else if (localStorage.getItem("redirect") === "S") {
                                navigate('/signup');
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            if (localStorage.getItem("redirect") === "L") {
                navigate('/login');
            } else if (localStorage.getItem("redirect") === "S") {
                navigate('/signup');
            }
        }
    }, []);


    return (
        <div className="loadingContainer">
            {/*<h3>Logging in...</h3>*/}
            {/*<img alt="Loading..." src="/img/loading.gif"/>*/}
            {open && (
                <div className="loader">
                    <img src={loader} alt="" width={"300px"} height={"210px"} />
                </div>
            )}
        </div>

    );
};

export default FBLoginPage;
