import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { generateToken } from "./firebase";
import Capture from "./pages/Capture";
import ConfirmationPage from "./pages/ConfirmationPage";
import EditProfile from "./pages/EditProfile";
import ErrorPage from "./pages/ErrorPage";
import FBLoginPage from "./pages/FBLoginPage";
import ForgotPassword from "./pages/ForgotPassword";
import Help from "./pages/Help";
import Home from "./pages/Home";
import Login from "./pages/Login";
import MyAccount from "./pages/MyAccount";
import OrderHistory from "./pages/OrderHistory";
import OrderSummery from "./pages/OrderSummery";
import PaymentMethods from "./pages/PaymentMethods";
import Policy from "./pages/Policy";
import Profile from "./pages/Profile";
import ResetPassword from "./pages/ResetPassword";
import Rewards from "./pages/Rewards";
import Signup from "./pages/Signup";
import Yourorder from "./pages/Yourorder";

import { FaCheckCircle } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckoutSteps from "./pages/CheckoutSteps";

function App() {
  useEffect(() => {
    if (localStorage.getItem("primaryColor") != null) {
      document.documentElement.style.setProperty(
        "--primaryColor",
        localStorage.getItem("primaryColor")
      );
    }
    // for token send for notification....
    generateToken();
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        const title = event.data.notification.title;
        const message = event.data.notification.body;
        if (message.includes("cancelled") === true) {
          toast.dismiss();
          toast.error(`${title}: ${message}`, {
            hideProgressBar: true,
            style: { backgroundColor: "#CC0001", color: "#fff" },
            icon: (
              <IoCloseCircle
                style={{
                  backgroundColor: "#fff", // Set the icon background color to white
                  borderRadius: "50%", // Make it circular
                  padding: "1px", // Adjust the padding around the icon
                  color: "#CC0001", // Icon color matching the toast background
                  fontSize: "18px",
                }}
              />
            ),
          });
        } else {
          toast.dismiss();
          toast.success(`${title}: ${message}`, {
            hideProgressBar: true,
            style: { backgroundColor: "#428B33", color: "#fff" },
            icon: (
              <FaCheckCircle
                style={{
                  backgroundColor: "#fff", // Set the icon background color to white
                  borderRadius: "50%", // Make it circular
                  padding: "1px", // Adjust the padding around the icon
                  color: "#428B33", // Icon color matching the toast background
                  fontSize: "18px",
                }}
              />
            ),
          });
        }
      });
    }
  }, []);
  return (
    <Router>
      <ToastContainer closeButton={false} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route
          path="/password/reset/:randomNumber"
          element={<ResetPassword />}
        />
        <Route path="/myaccount" element={<MyAccount />} />
        <Route path="/rewards" element={<Rewards />} />
        <Route path="/order/history" element={<OrderHistory />} />
        <Route path="/order/summary/:id" element={<OrderSummery />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/profile/edit" element={<EditProfile />} />
        <Route path="/cart" element={<Yourorder />} />
        <Route path="/payment/methods" element={<PaymentMethods />} />
        <Route path="/help" element={<Help />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/confirm" element={<ConfirmationPage />} />
        <Route path="/fbloginpage" element={<FBLoginPage />} />
        <Route path="/capture" element={<Capture />} />
        <Route path="/checkout" element={<CheckoutSteps />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
