import React from "react";
// import './Cart.css';
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { BsDash } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IMAGE_ROOT } from "../api/apiHandler";
import cartImg from "../image/shopping_cart_24dp_FILL1_wght400_GRAD0_opsz24.svg";
import trashIcon from "../image/trash.svg";
import {
  decrementQty,
  incrementQty,
  removeFromCart,
} from "../store/slices/CartSlice";

function Cart({ clickOnProduct }) {
  const { t } = useTranslation();
  const dispatch = useDispatch(); // Redux dispatch to trigger actions
  const navigate = useNavigate(); // For navigation to other routes
  const cartItem = useSelector((state) => state.cart.cart); // Retrieve cart items from the Redux store

  // console.log("cartItem", cartItem);
  // Calculate the total price of the items in the cart
  const totalPrice = cartItem.reduce((total, item) => {
    total +=
      item.product_data.product_quantity *
      ((item.product_data.loyalty !== "Y"
        ? item.product_data.product_price
        : 0) +
        item.product_data.modifier_price);
    return total;
  }, 0);

  // Handle incrementing the quantity of a product in the cart
  const handleIncrement = (event, productIndex) => {
    event.stopPropagation(); // Prevent event propagation
    dispatch(incrementQty({ productIndex })); // Dispatch increment action
  };

  // Handle decrementing the quantity of a product in the cart
  const handleDecrement = (event, productIndex) => {
    event.stopPropagation(); // Prevent event propagation
    dispatch(decrementQty({ productIndex })); // Dispatch decrement action
  };

  // Handle removing a product from the cart
  const handleRemoveFromCart = (event, productIndex, itemName) => {
    event.stopPropagation(); // Prevent event propagation
    toast.dismiss(); // Dismiss any previous toasts
    toast.error(`${itemName} ${t("removed")}`); // Show error toast with the product name
    dispatch(removeFromCart({ productIndex })); // Dispatch remove action to the Redux store
  };

  return (
    <div
      className="bg-white p-0 border d-flex justify-content-between flex-column rounded"
      style={{ height: "100%", boxShadow: "0 1px 4px 1px rgba(0, 0, 0, 0.1)" }}
    >
      <div>
        {/* Header section with the "My Order" text */}
        <h4
          className="roboto-medium-18 m-0 rounded-top"
          style={{ height: "44px", padding: "12px 0 0 12px" }}
        >
          {t("my_order")}
        </h4>
        <div
          className="p-2 d-flex flex-column justify-content-center"
          style={{ height: "74vh" }}
        >
          {/* If the cart is empty, show an empty cart message */}
          {cartItem.length <= 0 ? (
            <div className="emptyCardTxt">
              <div className="d-flex justify-content-center">
                <div className="empCart rounded-circle">
                  <img src={cartImg} alt="" />
                </div>
              </div>
              <p
                className="m-0 text-center mt-1 roboto-bold-16"
                style={{ color: "#ADADAD" }}
              >
                {t("cart_empty")}
              </p>
            </div>
          ) : (
            <>
              {/* List of products in the cart */}
              <div
                className="cartProductList d-flex flex-column overflow-scroll"
                style={{ height: "70vh" }}
              >
                {cartItem.map((item, index) => (
                  <div
                    key={index}
                    className="cartItem p-1 bg-white text-black d-flex c-pointer my-1"
                    style={{ borderRadius: "10px", height: "76px" }}
                    onClick={() => clickOnProduct(item, index)} // Navigate to product details when clicked
                  >
                    {item.product_data.product_image !== "" && (
                      <img
                        style={{ borderRadius: "7px", width: "62px" }}
                        src={
                          IMAGE_ROOT +
                          "/images/product/" +
                          item.product_data.product_image
                        }
                        alt="img"
                      />
                    )}
                    <div
                      className="d-flex flex-column w-100 justify-content-between"
                      style={{ paddingLeft: "6px" }}
                    >
                      {/* Product name and remove button */}
                      <div className="nameDiv d-flex align-items-center justify-content-between">
                        {item.product_data.product_image !== "" ? (
                          <div className="roboto-medium-16">
                            {(localStorage.getItem("store_language") === "en"
                              ? item.product_data.product_name
                              : item.product_data.product_name_es.length > 0
                              ? item.product_data.product_name_es
                              : item.product_data.product_name
                            ).slice(0, 17)}
                            {item.product_data.product_name.length > 17 &&
                              "..."}
                          </div>
                        ) : (
                          <div className="roboto-medium-16">
                            {(localStorage.getItem("store_language") === "en"
                              ? item.product_data.product_name
                              : item.product_data.product_name_es.length > 0
                              ? item.product_data.product_name_es
                              : item.product_data.product_name
                            ).slice(0, 25)}
                            {item.product_data.product_name.length > 25 &&
                              "..."}
                          </div>
                        )}

                        {/* Remove item button */}
                        <span
                          onClick={(event) =>
                            handleRemoveFromCart(
                              event,
                              index,
                              item.product_data.product_name
                            )
                          }
                          className="mx-1"
                        >
                          <img src={trashIcon} alt="Delete" />
                        </span>
                      </div>
                      {/* Product price and quantity controls */}
                      <div className="priceDiv d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center productIncrement">
                          {/* Decrement quantity button */}
                          <span
                            className="dashBtnCart"
                            onClick={(event) => handleDecrement(event, index)}
                          >
                            <BsDash />
                          </span>
                          <span className="totalQty roboto-medium-18">
                            {item.product_data.product_quantity}
                          </span>
                          {/* Increment quantity button */}
                          <span
                            className="plusBtnCart"
                            onClick={(event) => handleIncrement(event, index)}
                          >
                            <FiPlus />
                          </span>
                        </div>
                        <p className="m-0 mx-1 roboto-regular-16">
                          {/* Display price based on loyalty points or regular price */}
                          {item.product_data.loyalty == "Y"
                            ? `${
                                item.product_data.product_loyalty_price *
                                item.product_data.product_quantity
                              } ${t("points")}`
                            : `$${(
                                (item.product_data.product_price +
                                  item.product_data.modifier_price) *
                                item.product_data.product_quantity
                              ).toFixed(2)}`}{" "}
                          {/* Regular price calculation */}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      {/* Checkout button if the cart has items */}
      {cartItem.length > 0 && (
        <span
          className="w-100 d-flex my-2 justify-content-center align-items-center"
          style={{ height: "40px" }}
        >
          <button
            onClick={() => {
              navigate("/cart");
            }}
            className="bg-green w-50 h-100 border-0 roboto-medium-16 text-white"
            style={{ padding: "0 5px", borderRadius: "10px" }}
          >
            <span>{t("checkout")}</span> {/* Checkout button text */}
          </button>
        </span>
      )}
    </div>
  );
}

export default Cart;
