import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import fetch from "isomorphic-fetch";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io";
import Notifications from "react-notify-toast";
import { useDispatch, useSelector } from "react-redux";
import { API_ROOT, IMAGE_ROOT, Method } from "../api/apiHandler";
import * as dbconn from '../api/dbconn';
import ProductDetails from "../components/ProductDetails";
import loader from '../image/res_home_page_loader.gif';
import { addToCart } from "../store/slices/CartSlice";
import { fetchStoreData } from "../store/slices/fetchStore";
import { selectModalState, toggleModal } from "../store/slices/modalSlice";
import "../style/Rewards.css";

export default function Rewards() {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  // const store = useSelector((state) => state.fetchstore.data)
  const cartItem = useSelector((state) => state.cart.cart);
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const [homeredirect, setHomeRedirect] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [productList, setProductList] = useState([]);
  const [productID, setProductID] = useState();
  const [nosessionredirect, setNoSessionRedirect] = useState(false);
  const [loyaltyPoints, setLoyaltyPoints] = useState("0 points");
  const [points, setPoints] = useState(0)
  const [expiryDate, setExpiryDate] = useState("");
  const [availableProduct, setAvailableProduct] = useState(0);
  const [lockedProduct, setLockedProduct] = useState(0);
  const isModalOpen = useSelector(selectModalState);
  const initialLogRef = useRef(false);
  // useEffect(() => {
  //   if (store && !initialLogRef.current) {
  //     initialLogRef.current = true;
  //   }
  // }, [store])
  // useEffect(() => {
  //   var primaryColor = localStorage.getItem("store_primary_color");
  //   var secondaryColor = localStorage.getItem("store_secondary_color");
  //   var buttonColor = localStorage.getItem("store_button_color");

  //   if (primaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--primaryColor",
  //       primaryColor
  //     );
  //   }
  //   if (buttonColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--buttonColor",
  //       buttonColor
  //     );
  //   }

  //   if (secondaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--secondaryColor",
  //       secondaryColor
  //     );
  //   }
  // }, [])


  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("store_language")) {
      localStorage.setItem("store_language", "en");
    }
    i18n.changeLanguage(localStorage.getItem("store_language")).then((r) => {
    });
    dispatch(fetchStoreData())

    fetchLoyaltyMenu();
  }, []); //

  const fetchLoyaltyMenu = async () => {
    // var dbconn = new db();
    onOpenModal()
    const store = await dbconn.getStoreFromIndexedDB();
    dbconn.getUserInfoFromIndexedDB().then((data) => {
      if (
        !data ||
        data.length < 1 ||
        sessionStorage.getItem("guestFlag") === "Y"
      ) {
        onCloseModal();
        setNoSessionRedirect(true);
      } else {
        fetch(`${API_ROOT}${Method.FETCH_LOYALTY_MENU}?store_id=${store.store_id}`)
          .then((response) => {
            if (response.status === 403 || response.status === 401) {
              // Handle 403 error
              onCloseModal();
              // toast.error("Please login", {
              //   duration: 1000,
              // })
              dbconn.deleteUserFromIndexedDB(4)
              sessionStorage.setItem("guestFlag", "Y")
              navigate('/login'); // or redirect to a relevant page
              // return
            }
            return response.json();
          })
          .then((data) => {
            // console.log("data", data)

            if (data.status === 1) {
              localStorage.setItem('points', data.data)
              let cartLoyaltyPoints = 0;
              // console.log("cartItem =>", cartItem)
              for (let i = 0; i < cartItem.length; i++) {
                if (cartItem[i].product_data.loyalty === "Y") {
                  cartLoyaltyPoints += cartItem[i].product_data.product_loyalty_price
                }
              }
              setPoints(parseInt(data.data) - cartLoyaltyPoints)
              setLoyaltyPoints(
                parseInt(data.data) -
                cartLoyaltyPoints +
                " " +
                i18n.t("points")
              );
              if (data.expirydate !== "") {
                setExpiryDate(i18n.t("points_expire") + data.expirydate);
              }

              var availableList = 0;
              var lockedList = 0;

              for (var i = 0; i < data.product_data.length; i++) {
                if (
                  parseFloat(parseInt(data.data) - cartLoyaltyPoints) >
                  parseFloat(data.product_data[i].product_loyalty_price)
                ) {
                  availableList++;
                } else {
                  lockedList++;
                }
              }

              setProductList(data.product_data);
              setAvailableProduct(availableList);
              setLockedProduct(lockedList);
              onCloseModal();
            }
            onCloseModal();
          });
        // onCloseModal();
      }
    });
  };

  useEffect(() => {
    const primaryColor = localStorage.getItem("store_primary_color");
    if (primaryColor != null) {
      document.documentElement.style.setProperty(
        "--primaryColor",
        primaryColor
      );
    }
    const secondaryColor = localStorage.getItem("store_secondary_color");
    if (secondaryColor != null) {
      document.documentElement.style.setProperty(
        "--secondaryColor",
        secondaryColor
      );
    }
  }, [])

  const goToProduct = (product_id, position) => {
    if (productList[position].modifier_count > 0 || productList[position].product_notes_enabled === "Y") {
      const data = {
        productID: product_id,
        loyalty: "Y"
      };
      setProductID(data)
      dispatch(toggleModal());
    } else {
      // setProductID(product_id)
      const product = productList[position]
      product.product_notes_enabled = "Y"
      product.product_notes = ""
      dispatch(
        addToCart({
          product_data: { ...product, product_quantity: 1, loyalty: "Y" },
          dropdown_mod: [],
        })
      );
      fetchLoyaltyMenu();
      navigate("/")
    }
  }

  const handleClose = () => {
    setShow(false);
    fetchLoyaltyMenu();
  };

  return (
    <div className="confirmPageMainDiv">
      {/* <header className="header-div position-fixed w-100 shadow">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Link to="/" className="float-left">
            <BsChevronLeft />
          </Link>
          <h2 className="text-center mb-0 roboto-bold-24">{t("my_rewards")}</h2>
        </div>
      </header> */}

      <div className="loginLogo">
        <Link to="/">
          <img
            style={{ width: "70px" }}
            className="center-block"
            src={
              IMAGE_ROOT +
              "/images/product/" +
              localStorage.getItem("store_image")
            }
            alt="Takecharge Diner Logo"
          />
        </Link>
      </div>
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-lg-9 col-md-10 col-xs-12 m-auto vh1-100 vscroll">
            <div className="LoginRegisterBlock position-relative RewardsBlockMain" style={{ marginTop: "85px" }}>
              <div className="LoginRegisterForm float-left w-100">
                <span className="backToHomeBtn" onClick={() => navigate('/')} style={{ margin: "0 0 10px 0" }}><IoIosArrowBack /></span>
                <div className="points_box">
                  <div className="title_points">
                    {/* <h3 className="text-center m-0 text-uppercase roboto-medium-22">
                      {t("my_points")}
                    </h3> */}
                  </div>
                  <h2 className="roboto-bold-28">{loyaltyPoints}</h2>
                  <p className="mb-2 text-center">{expiryDate}</p>
                </div>
                <div className="d-flex flex-column">
                  {availableProduct > 0 ? (
                    <div>
                      <p className="text-capitalize h4-hr roboto-regular-16">
                        {/* FREE ON YOUR NEXT ORDER */}
                        {t('free_on_next_order')}
                      </p>
                      {productList.map((item, index) => {
                        var productImageHide =
                          "productsBlock ProductHome shadow1 RewardsBlock no-image p-3";
                        if (item.product_image !== "") {
                          productImageHide =
                            "productsBlock ProductHome shadow1 RewardsBlock p-3";
                        }
                        if (
                          parseFloat(loyaltyPoints) >=
                          parseFloat(item.product_loyalty_price)
                        ) {
                          return (
                            <div key={index} className="card productsBlock p-3">
                              <div className="cardBody w-100 d-flex gap-2">
                                {item.product_image !== "" ?
                                  <div className="image">
                                    <img src={IMAGE_ROOT + "/images/product/" + item.product_image} className="rewardImage" alt="" />
                                  </div> : ""}
                                < div className="cardDetail w-100">
                                  <p className="roboto-medium-18 m-0" id={"product_name_" + item.product_id}>
                                    {localStorage.getItem("store_language") ===
                                      "en"
                                      ? item.product_name
                                      : item[
                                        "product_name_" +
                                        localStorage.getItem("store_language")
                                      ] !== ""
                                        ? item[
                                        "product_name_" +
                                        localStorage.getItem("store_language")
                                        ]
                                        : item.product_name}
                                  </p>
                                  <p className="roboto-regular-18 m-0">
                                    {localStorage.getItem("store_language") ===
                                      "en"
                                      ? item.product_description
                                      : item[
                                        "product_description_" +
                                        localStorage.getItem("store_language")
                                      ] !== ""
                                        ? item[
                                        "product_description_" +
                                        localStorage.getItem("store_language")
                                        ]
                                        : item.product_description}
                                  </p>
                                  <div className="cardBottom w-100 d-flex align-items-center justify-content-between">
                                    <p className="mb-0 float-left roboto-medium-16">
                                      {parseInt(item.product_loyalty_price)}{" "}
                                      {t("points")}
                                    </p>
                                    <button
                                      className="btn btn-yellow float-right open-modal-addcard w-120px"
                                      onClick={() =>
                                        goToProduct(item.product_id, index)
                                      }
                                    >
                                      {t("redeem")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="clearfix"></div>
                  {lockedProduct > 0 ? (
                    <div>
                      <p className="text-capitalize h4-hr roboto-regular-16">{t('locked_rewords')}</p>
                      {productList.map((item, index) => {
                        var productImageHide =
                          "productsBlock ProductHome shadow1 RewardsBlock no-image p-3";
                        if (item.product_image !== "") {
                          productImageHide =
                            "productsBlock ProductHome shadow1 RewardsBlock p-3";
                        }
                        if (
                          parseFloat(loyaltyPoints) <
                          parseFloat(item.product_loyalty_price)
                        ) {
                          return (
                            <div key={index} className="card productsBlock p-3">
                              <div className="cardBody w-100 d-flex gap-2">
                                {item.product_image &&
                                  <div className="productsBlockRight product-img">
                                    {item.product_image && (
                                      <img src={IMAGE_ROOT + "/images/product/" + item.product_image} className="img-fluid img-reward rewardImage" alt="img" />
                                    )}

                                    <div className="overlay-img"></div>
                                  </div>
                                }
                                < div className="cardDetail w-100">
                                  <p className="roboto-medium-18 m-0" id={"product_name_" + item.product_id}>
                                    {localStorage.getItem("store_language") ===
                                      "en"
                                      ? item.product_name
                                      : item[
                                        "product_name_" +
                                        localStorage.getItem("store_language")
                                      ] !== ""
                                        ? item[
                                        "product_name_" +
                                        localStorage.getItem("store_language")
                                        ]
                                        : item.product_name}
                                  </p>
                                  <p className="roboto-regular-18 m-0">
                                    {localStorage.getItem("store_language") ===
                                      "en"
                                      ? item.product_description
                                      : item[
                                        "product_description_" +
                                        localStorage.getItem("store_language")
                                      ] !== ""
                                        ? item[
                                        "product_description_" +
                                        localStorage.getItem("store_language")
                                        ]
                                        : item.product_description}
                                  </p>
                                  <div className="cardBottom w-100 d-flex align-items-center justify-content-between">
                                    <p className="mb-0 float-left roboto-medium-16">
                                      {parseInt(item.product_loyalty_price)}{" "}
                                      {t("points")}
                                    </p>
                                    <button
                                      className="btn btnLogin btn-yellow float-right open-modal-addcard w-120px btn_locked"
                                    >
                                      {t("redeem")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        isModalOpen && (
          <div className="custom-modal">
            <ProductDetails
              productData={productID}
            />
          </div>
        )
      }
      {
        open && (
          <div className="loader">
            <img src={loader} alt="" width={"300px"} height={"210px"} />
          </div>
        )
      }
      <Notifications options={{ zIndex: 9999, width: "100%", top: "50px" }} />
      <Toaster />
    </div >
  );
}