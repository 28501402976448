import React, { useState } from "react";
import downArrow from "../image/chevron-down.svg";
import upArrow from "../image/chevron-up.svg";

const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false); // State to track if accordion is open or closed

  const toggleAccordion = () => {
    setIsOpen(!isOpen); // Toggle the open state on click
  };

  return (
    <div className="accordion mt-2">
      {/* Accordion header with click functionality */}
      <div className="accordion-header" onClick={toggleAccordion}>
        <span className="d-flex justify-content-between align-items-center w-100">
          {/* Accordion title */}
          <p className="m-0 roboto-medium-16" style={{ color: "#6C7589" }}>
            {title}
          </p>
          {/* Arrow icon toggles based on open/close state */}
          <p className="m-0">
            {isOpen ? (
              <img src={upArrow} alt="" />
            ) : (
              <img src={downArrow} alt="" />
            )}
          </p>
        </span>
      </div>
      {/* Accordion content displayed only if isOpen is true */}
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
};

export default Accordion;
