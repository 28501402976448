import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import "react-responsive-modal/styles.css";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_ROOT, IMAGE_ROOT, Method, SUB_DOMAIN } from "../api/apiHandler";
import * as dbconn from '../api/dbconn';
import Header from "../components/Header";
import loader from '../image/res_home_page_loader.gif';
import { fetchStoreData } from "../store/slices/fetchStore";

export default function ForgotPassword() {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [redirect404, setRedirect404] = useState(false);
  const [load, setLoad] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [open, setOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  // const store = useSelector((state) => state.fetchstore.data)

  const initialLogRef = useRef(false);

  // useEffect(() => {
  //   if (store && !initialLogRef.current) {
  //     initialLogRef.current = true;
  //   }
  // }, [store])

  // useEffect(() => {
  //   var primaryColor = localStorage.getItem("store_primary_color");
  //   var secondaryColor = localStorage.getItem("store_secondary_color");
  //   var buttonColor = localStorage.getItem("store_button_color");

  //   if (primaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--primaryColor",
  //       primaryColor
  //     );
  //   }
  //   if (buttonColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--buttonColor",
  //       buttonColor
  //     );
  //   }

  //   if (secondaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--secondaryColor",
  //       secondaryColor
  //     );
  //   }
  // }, [])

  useEffect(() => {
    if (!localStorage.getItem("store_language")) {
      localStorage.setItem("store_language", "en");
    }
    i18n.changeLanguage(localStorage.getItem("store_language"));

    dispatch(fetchStoreData())

    // const dbconn = new db();
    // dbconn.getUser().then((data) => {
    //   if (localStorage.getItem("remember") === "Y" || data && data.length > 0) {
    //     navigate('/')
    //     // sessionStorage.setItem("guestFlag", "N");
    //     // let cartTotal = 0;
    //     // dbconn.getAllOrder().then((productData) => {
    //     //   productData.forEach((item) => {
    //     //     cartTotal +=
    //     //       (parseFloat(item.product_price) + parseFloat(item.mod_price)) *
    //     //       item.product_quantity;
    //     //   });
    //     //   sessionStorage.setItem("cartTotal", cartTotal);
    //     //   setRedirect(true);
    //     // });
    //   }
    // });
  }, []);

  const onOpenModal = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  const forgotPassword = async () => {
    const store = await dbconn.getStoreFromIndexedDB();
    let mail = userEmail;
    const subDomain = SUB_DOMAIN
    const substringBeforeDot = subDomain.split('.')[0];
    if (mail !== "") {
      onOpenModal();
      axios
        .post(API_ROOT + Method.FORGOT_PASSWORD, {
          merchant_id: store.merchant_id,
          store_id: store.store_id,
          customer_username: mail,
          store_subdomain: SUB_DOMAIN == "localhost" ? "tbgwpb" : substringBeforeDot
        })
        .then((response) => {
          onCloseModal();
          if (response.data.status == 1) {
            Swal.fire({
              text: i18n.t("reset_link_inst"),
              showCancelButton: false,
              confirmButtonColor: "#a42f1e",
              confirmButtonText: t("ok"),
            }).then((result) => {
              if (result.value) {
                // var dbconn = new db();
                var data = response.data;

                setRedirect(true);
              }
            });
          } else {
            Swal.fire({
              // text: response.data.message,
              text: t('enter_valid_username_or_email'),
              showCancelButton: false,
              confirmButtonColor: "#a42f1e",
              confirmButtonText: t("ok"),
            });
          }
        })
        .catch((error) => {
          //   onCloseModal();
          console.log(error);
        });
    } else {
      Swal.fire({
        text: i18n.t("forgot_disclaimer"),
        showCancelButton: false,
        confirmButtonColor: "#a42f1e",
        confirmButtonText: t("ok"),
      });
    }
  };

  useEffect(() => {
    if (redirect) {
      navigate("/login");
    }

    if (redirect404) {
      navigate("/404");
    }
  }, [redirect, redirect404]);


  return (
    <div>
      <Header
        header={t("order_online")}
        path="/login"
      />
      {/* <header className="header-div bg-white position-fixed w-100 shadow">
        <div className="d-flex justify-content-between align-items-center">
          <Link to="/login" className="float-left">
            <BsChevronLeft />
          </Link>
          <h2 className="text-center mb-0">
            <strong className="roboto-bold-24"></strong>
          </h2>
          <button className="flagImagebutton float-right" onClick={() => languageHandle()}>{lang == "en" ? <img className="langImage" src={usa} alt="" width={30} height={30} /> : <img className="langImage" src={spanish} alt="" width={30} height={30} />}</button>
          {listLanguage &&
            <ul className="flagImageDiv p-2">
              {language.map((lang, index) =>
              (
                <li key={index}
                  className="roboto-medium-16 mb-2"
                  onClick={langSelected(lang.name)}>
                  {lang.image == "en" ? <img src={usa} alt="" width={15} height={15} /> : <img src={spanish} alt="" width={15} height={15} />} {lang.name}
                </li>
              ))}
            </ul>
          }
        </div>
      </header> */}
      <div className="container-fluid vh1-100 position-relative pt-40">
        {/* <div className="select d-flex justify-content-end custom-select w-100 loginSelect position-absolute border-0">
          <select
            className="bg-transparent roboto-regular-20"
            value={lang}
            onChange={changeLanguage}
          >
            {language.map((item, index) => (
              <option key={item} value={item}>
                {langname[index]}
              </option>
            ))}
          </select>
        </div> */}
        <div className="row vh1-100">
          <div className="col-md-7 col-sm-7 col-xs-12 m-auto vscroll vh1-100 bg-white">
            <div className="LoginRegisterBlock h-100">
              <div className="Logo text-center">
                <Link to="/">
                  {load && (
                    <img
                      style={{ width: "140px" }}
                      className="center-block"
                      src={
                        IMAGE_ROOT +
                        "/images/product/" +
                        localStorage.getItem("store_image")
                      }
                      alt="Takecharge Diner Logo"
                    />
                  )}
                </Link>
              </div>

              <div className="LoginRegisterForm float-left w-100">
                <h3 className="roboto-bold-24">{t("forgot_password")}</h3>
                <p className="roboto-medium-16">{t("forgot_disclaimer")}</p>
                <form action="#" method="post" encType="multipart/form-data" />
                <div className="form-group mb-2">
                  <label>{t("email_address")}*</label>
                  <input
                    type="text"
                    className="form-control p-2"
                    id="userNameTxt"
                    name="userNameTxt"
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                </div>

                <div className="form-group mb-4 w-50 m-auto w-m-100">
                  <input
                    onClick={forgotPassword}
                    type="button"
                    className="btn btnLogin btn-yellow w-100 btn-lg"
                    name="submitButton"
                    id="submitButton"
                    value={t("reset_password")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fixed-bottom-div bg-grey fixed-bottom-div-desktop">
        <p className="mb-0 text-center text-black-50 roboto-regular">
          <a
            href="https://triaserves.com"
            target="_blank"
            style={{ color: "black" }}
          >
            {/* Online Ordering From Tría */}
            {t('online_ordering_tria')}
          </a>
        </p>
      </div>
      {open && (
        <div className="loader">
          <img src={loader} alt="" width={"300px"} height={"210px"} />
        </div>
      )}
      {/* <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        open={open}
        onClose={onCloseModal}
        center
      >
        <h2>
          &nbsp;&nbsp;&nbsp;&nbsp;{t("processing")}&nbsp;&nbsp;&nbsp;&nbsp;
        </h2>

        <p style={{ textAlign: "center" }}>
          <img width={"40"} height={"40"} src={Loading} />
        </p>
      </Modal> */}
    </div>
  );
}
