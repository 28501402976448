import { DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { FaCartShopping, FaStar } from "react-icons/fa6";
import { FiSearch } from "react-icons/fi";
import { RxHamburgerMenu } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_ROOT, Method, SUB_DOMAIN } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import LanguageSelector from "../components/LanguageSelector";
import ProductCard from "../components/ProductCard";
import ProductDetails from "../components/ProductDetails";
import Sidebar from "../components/Sidebar";
import loader from "../image/res_home_page_loader.gif";
import { addToCart, clearCart } from "../store/slices/CartSlice";
import { selectModalState, toggleModal } from "../store/slices/modalSlice";
import {
  selectSidebarStatus,
  toggleSidebar,
} from "../store/slices/sidebarSlice";
import Cart from "./Cart";

function Home() {
  const { t, i18n } = useTranslation(); // used for language transation
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isModalOpen = useSelector(selectModalState); // for open product Details modal
  const isSidebarOpen = useSelector(selectSidebarStatus); // for a sidebar open and close
  const sidebarRef = useRef(null); //sidebar close if other screen click
  const cartRef = useRef(null); //cart close if other screen click
  const categoryListRef = useRef(null); // Ref to keep track of the category list element
  const cartItem = useSelector((state) => state.cart.cart); // cart item data state using redux
  const [isLoading, setIsLoading] = useState(false); // for loader
  const [storeClosed, setStoreClosed] = useState(false); // for show store open or not modal
  const [isStoreFound, setIsStoreFound] = useState(false); // if store not found then show modal
  const [storeName, setStoreName] = useState("");
  const [storeOpenHours, setStoreOpenHours] = useState(""); // for store time show in header
  const [isCartVisible, setIsCartVisible] = useState(false); // for Cart sidebar
  const [activeCategory, setActiveCategory] = useState(null); // selected sidebar category
  const [allDataCategoryWise, setAllDataCategoryWise] = useState([]); // all product set category wise
  const [searchTerm, setSearchTerm] = useState(""); // for a search bar
  const [selectedItemId, setSelectedItemId] = useState({}); //to track the currently selected Product ID
  const [loyaltyPoints, setLoyaltyPoints] = useState("0"); // Holds the user's loyalty points
  const [rating, setRating] = useState(0); // Holds the store rating
  const [height, setHeight] = useState(window.innerHeight); // Stores the screen's inner height to help with responsive design
  const [width, setWidth] = useState(window.innerWidth); // Stores the screen's inner width to help with responsive design
  const [storeTime, setStoreTime] = useState([]); // Stores the schedule for the store on seven days
  const [isDragging, setIsDragging] = useState(false); // Tracks if the user is currently dragging
  const [startX, setStartX] = useState(0); // Stores the starting X position when the drag begins
  const [scrollLeft, setScrollLeft] = useState(0); // Stores the initial scroll position when the drag starts
  const [dragged, setDragged] = useState(false); // Tracks if an item has been dragged (moved) during the drag action

  useEffect(() => {
    resetGuestFlag(); // when component load, set guestFlag if login then "N" otherwise "Y"
    setAndClearSessionStorage(); // when component load, set and clear sessionStorage
    clearLocalStorage(); // when component load, clear localStorage
    clearCoupon(); // when component load and couponApplied is "Y" and cartItem more then 0 then cancel coupon
    fetchStoreData(); // fetch store details when page render

    // Add event listeners for resize and outside clicks
    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleWidthResize);
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup: Remove event listeners on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleWidthResize);
    };
  }, []);

  /*
   * when component load, reset guest flag function call
    - first check if guestFlag is in sessionStorage or not, If not then set "Y"
      (means user not login) and delete user data from indexedDB
    - If the guestFlag is set in sessionStorage, then it will update again same.
  */
  const resetGuestFlag = () => {
    const guestFlag = sessionStorage.getItem("guestFlag");

    if (guestFlag) {
      sessionStorage.setItem("guestFlag", guestFlag);
    } else {
      sessionStorage.setItem("guestFlag", "Y");
      dbconn.deleteUserFromIndexedDB(4);
    }

    setLoginStatus(); //Check login status and set guestFlag accordingly
  };

  /*
   * setLoginStatus Function
    - Sets the login status and updates the guestFlag in sessionStorage based on the user's login state.
    - If no login status is found, defaults to guest mode.
  */
  const setLoginStatus = () => {
    const login = localStorage.getItem("isLogin");
    if (login) {
      if (login === "false") {
        sessionStorage.setItem("guestFlag", "Y"); // Set guestFlag if not logged in
      } else {
        sessionStorage.setItem("guestFlag", "N");
      }
    } else {
      localStorage.setItem("isLogin", "false");
      sessionStorage.setItem("guestFlag", "Y"); // Default to guestFlag if no login status
    }
  };

  // when component load, some not IMP details remove from sessionStorage and some IMP details set
  const setAndClearSessionStorage = () => {
    sessionStorage.setItem("selectedTipOption", 0);
    sessionStorage.setItem("selectedTipCalculation", 0);
    sessionStorage.setItem("couponApplied", "N");

    sessionStorage.removeItem("couponCode");
    sessionStorage.removeItem("couponDetails");
    sessionStorage.removeItem("couponType");
    sessionStorage.removeItem("date");
    sessionStorage.removeItem("loyalty");
    sessionStorage.removeItem("orderDeliveryFee");
    sessionStorage.removeItem("orderType");
    sessionStorage.removeItem("slots");
    sessionStorage.removeItem("table");
    sessionStorage.removeItem("tip");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("useblePoint");
    sessionStorage.removeItem("userType");
    sessionStorage.removeItem("store_nca");
    sessionStorage.removeItem("GrandTotal");
    sessionStorage.removeItem("curbsideDesc");
    sessionStorage.removeItem("type");
    sessionStorage.removeItem("loyalty");
    sessionStorage.removeItem("cartTotal");
    sessionStorage.removeItem("tax");
    sessionStorage.removeItem("totalPayTax");
    sessionStorage.removeItem("first");
    sessionStorage.removeItem("back");
  };

  // when component load, some not IMP details remove from localStorage
  const clearLocalStorage = () => {
    localStorage.removeItem("isEdit");
    localStorage.removeItem("points");
    localStorage.removeItem("store_curbside_desc");
  };

  //* Functions to handle coupon removal and update cart items when a coupon is invalid or canceled.
  const clearCoupon = () => {
    // Cancel the coupon if there are items in the cart
    if (cartItem.length > 0) {
      cancelCoupon();
    }
  };

  //* Cancel coupon by clearing coupon data and updating the cart with items without coupons
  const cancelCoupon = () => {
    if (!cartItem || cartItem.length === 0) return;
    const cartItemCopy = JSON.parse(JSON.stringify(cartItem)); // Create a deep copy of the cart items

    const updatedCart = removeCouponFromItem(cartItemCopy);
    resetCartWithModifiedItems(updatedCart);

    clearCouponData();
  };

  // Remove coupons from items that are not eligible and return modified cart items
  const removeCouponFromItem = (cartItem) => {
    let removeCoupon = []; // Array to hold items with coupon to remove

    // Loop through cart items to check and remove invalid coupons
    for (let i = 0; i < cartItem.length; i++) {
      if (cartItem[i].product_data.coupon_type != undefined) {
        if (
          cartItem[i].product_data.coupon_type === "H" ||
          cartItem[i].product_data.coupon_type === "G"
        ) {
        } else {
          // Remove coupon-related data from items
          delete cartItem[i].product_data.coupon_type;
          delete cartItem[i].product_data.product_discount;
          delete cartItem[i].product_data.product_discount_type;
          removeCoupon.push(cartItem[i]); // Add modified item to the list
        }
      } else {
        removeCoupon.push(cartItem[i]); // Add item without coupon to the list
      }
    }

    return removeCoupon;
  };

  // Clear the cart and add modified items back to it
  const resetCartWithModifiedItems = (modifiedItems) => {
    dispatch(clearCart());

    // Add the modified items back to the cart
    for (let i = 0; i < modifiedItems.length; i++) {
      let createNewOrder = JSON.parse(JSON.stringify(modifiedItems[i]));
      dispatch(
        addToCart({
          product_data: { ...createNewOrder.product_data },
          dropdown_mod: [...createNewOrder.dropdown_mod],
        })
      );
    }
  };

  // Clear coupon-related data from sessionStorage
  const clearCouponData = () => {
    const couponKeys = [
      "couponID",
      "couponDesc",
      "totalDiscount",
      "couponType",
      "couponCode",
      "couponMinSubtotal",
      "couponDeliveryType",
      "couponDiscount",
      "couponDiscountType",
      "couponProductDetails",
      "couponDiscountedProductDetails",
    ];

    couponKeys.forEach((key) => sessionStorage.removeItem(key));
    sessionStorage.setItem("couponApplied", "N");
  };

  /*
   * Function to fetch store data based on a specific store ID (sub_domain).
   * This function sets a loading state, constructs the URL, and fetches data from the API.
   * If the store is found and valid, it processes the data and manages session settings.
   * Otherwise, it handles cases where the store data isn't found.
   */
  const fetchStoreData = async () => {
    setIsLoading(true);

    const fetchStoreUrl = `${API_ROOT}${Method.FETCH_STORE_FROM_ID}?sub_domain=${SUB_DOMAIN}`;

    const response = await fetch(fetchStoreUrl);
    const responseData = await response.json();

    if (responseData.status === 1) {
      const storeData = responseData.store;
      if (storeData) {
        processStoreData(storeData);

        if (localStorage.getItem("remember") == "Y") {
          sessionStorage.setItem("guestFlag", "N");
        }
      } else {
        setIsLoading(false);
        handleStoreNotFound();
      }
    } else {
      setIsLoading(false);
      handleStoreNotFound();
    }
  };

  //* Handle case when store is not found
  function handleStoreNotFound() {
    setIsStoreFound(true);
  }

  //* Process all data related to the store
  function processStoreData(store) {
    setStoreName(store.store_name);
    updateIndexedDB(store);
    setPrimaryColor(store.store_primary_color);
    saveStoreDetails(store);
    handleSSOInfo();
    setStoreLanguage(store.store_language);
    updateStoreSchedule(store.store_schedule, store.store_timezone);
    fetchProductsAndCategories(store.store_id);
  }

  // Adds the store data to IndexedDB for offline access.
  function updateIndexedDB(store) {
    dbconn.addStoreToIndexedDB(store);
  }

  // Set primary color if provided
  function setPrimaryColor(color) {
    if (color) {
      document.documentElement.style.setProperty("--primaryColor", color);
      localStorage.setItem("primaryColor", color);
    }
  }

  // Save store details in local storage
  function saveStoreDetails(store) {
    // localStorage.setItem("store_name", store.store_name);
    localStorage.setItem("store_image", store.store_image);
    setRating(store.store_rating);
  }

  // Handle SSO-related information
  function handleSSOInfo() {
    localStorage.removeItem("sso_user_id");
    localStorage.removeItem("sso_user_name");
    localStorage.removeItem("sso_user_phone");
    localStorage.removeItem("sso_user_email");
    localStorage.removeItem("isSSOLogin");
    localStorage.removeItem("ssoType");

    if (localStorage.getItem("sso_user_id") === null) {
      sessionStorage.setItem("isSSOLogin", false);
      sessionStorage.removeItem("ssotype");
    } else {
      sessionStorage.setItem("isSSOLogin", true);
    }
  }

  // Set store language and update the app language
  function setStoreLanguage(language) {
    if (!localStorage.getItem("store_language")) {
      localStorage.setItem("store_language", language);
    }
    i18n.changeLanguage(localStorage.getItem("store_language"));
  }

  // Update store schedule and determine if store is open
  function updateStoreSchedule(schedule, timezone) {
    const currentDay = moment.tz(timezone).isoWeekday();
    const currentTime = moment.tz(timezone);
    const formattedSchedule = formatSchedule(
      schedule,
      currentDay,
      currentTime,
      timezone
    );

    setStoreTime(formattedSchedule.scheduleList); // set for time table list
    setStoreClosed(formattedSchedule.isStoreClose); // set for store open or close
    setStoreOpenHours(formattedSchedule.storeOpenTime); // set for store open Hours for set in store close popup
  }

  // Format schedule data and check if store is open
  function formatSchedule(schedule, currentDay, currentTime, timezone) {
    const dayNames = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let isStoreClose = true;
    let storeOpenTime = "";

    const scheduleList = schedule
      .map((entry) => {
        const isToday = currentDay === entry.schedule_day;
        const isOpenToday = entry.schedule_enabled === "Y";
        const formattedDay = dayNames[entry.schedule_day - 1];
        const timeText = isOpenToday
          ? `${entry.schedule_from} - ${entry.schedule_to}`
          : "Closed";

        if (isToday && isOpenToday) {
          const openTime = moment.tz(entry.schedule_from, "h:mm A", timezone);
          const closeTime = moment.tz(entry.schedule_to, "h:mm A", timezone);
          if (currentTime.isBetween(openTime, closeTime, null, "[]")) {
            isStoreClose = false;
            storeOpenTime = timeText;
          }
        }
        return {
          day: `${formattedDay}:`,
          schedule_from: entry.schedule_from,
          schedule_to: entry.schedule_to,
          store_active: isToday,
        };
      })
      .reverse();

    return { scheduleList, isStoreClose, storeOpenTime };
  }

  //* Updates the component's height state when the window is resized.
  const handleResize = () => {
    setHeight(window.innerHeight);
  };

  //* Updates the component's weight state when the window is resized.
  const handleWidthResize = () => {
    setWidth(window.innerWidth);
  };

  /*
    * organizeData Function
      - Function to organize and process menu data, mapping products to categories,
      - grouping products by category, and marking product availability based on the current day and time.
  */
  const organizeData = (menuData) => {
    const { category_data, product_data } = menuData;
    const todayDay = moment().format("dddd");
    const currentTime = moment();

    // Map products to categories with added fields
    const menuItem = mapProductsToCategories(product_data, category_data);

    // Group products by category
    const categorizedProducts = groupProductsByCategory(
      category_data,
      menuItem
    );

    // Process each category's schedule to determine product availability
    categorizedProducts.forEach((category) => {
      category.category_schedule.forEach((schedule) => {
        if (
          schedule.WeekName === todayDay &&
          !isWithinOperatingHours(schedule, currentTime)
        ) {
          // If not within operating hours, mark products as unavailable
          category.products.forEach((product) => {
            product.type = "Y"; // Mark as unavailable
          });
        }
      });
    });

    // Save to IndexedDB and update state
    dbconn.addAllProductToIndexedDB(categorizedProducts);
    setAllDataCategoryWise(categorizedProducts);
    setIsLoading(false);
  };

  // Helper function to add category name and index to each product
  const mapProductsToCategories = (productData, categoryData) => {
    return productData.map((product, index) => {
      const category = categoryData.find(
        (cat) => cat.category_id === product.category_id
      );
      return {
        ...product,
        index,
        type: "N",
        category_name: category ? category.category_name : null,
        category_name_es: category ? category.category_name_es : null,
      };
    });
  };

  // Helper function to group products by category
  const groupProductsByCategory = (categories, products) => {
    return categories.map((category) => ({
      ...category,
      products: products.filter(
        (product) => product.category_id === category.category_id
      ),
    }));
  };

  // Helper function to format time and check availability
  const isWithinOperatingHours = (schedule, currentTime) => {
    const openTime = moment(schedule.category_schedule_from, "hh:mm A");
    const closeTime = moment(schedule.category_schedule_to, "hh:mm A");
    return currentTime.isBetween(openTime, closeTime, null, "[]");
  };

  /*
   * fetchProductsAndCategories Function
      - Function to fetch products and categories based on the store ID and user login status.
      - Checks if the user is a guest or logged in, fetches menu data, and handles cases for session expiration and login status.
      - If the user is not a guest, it also fetches loyalty points.
  */
  const fetchProductsAndCategories = (storeID) => {
    const guestFlag = sessionStorage.getItem("guestFlag"); // Check if user is a guest or logged in

    fetch(
      `${API_ROOT}${Method.FETCH_MENU}?guestFlag=${guestFlag}&store_id=${storeID}`
    )
      .then((response) => {
        // Handle unauthorized access or expired session
        if (response.status === 403 || response.status === 401) {
          setIsLoading(false); // Stop loading
          dbconn.deleteUserFromIndexedDB(4); // Clear user data from IndexedDB
          sessionStorage.setItem("guestFlag", "Y"); // Set guest flag to true
          localStorage.setItem("isLogin", false); // Set login status to false
          navigate("/login"); // Redirect to login page
        }
        return response.json(); // Parse the response as JSON
      })
      .then((menuData) => {
        // If the menu data is valid, organize it
        if (menuData.status === 1) {
          organizeData(menuData); // Organize and process data
        } else {
          setIsLoading(false); // Stop loading
          // If the message indicates the user needs to log in
          if (menuData.message.includes("login to continue")) {
            sessionStorage.setItem("guestFlag", "Y"); // Set guest flag to true
            localStorage.setItem("isLogin", false); // Set login status to false
            navigate("/login"); // Redirect to login page
          }
        }
      });

    // Fetch loyalty points if user is logged in (not a guest)
    if (guestFlag === "N") {
      fetchLoyaltyMenu(storeID); // Fetch loyalty points
    }
  };

  /*
   * fetchLoyaltyMenu function :
    - Function to fetch loyalty menu data for a given store ID.
    - Checks for session validity and handles redirection to the login page if unauthorized.
    - On success, stores loyalty points data in localStorage.
  */
  const fetchLoyaltyMenu = async (storeID) => {
    // Fetch loyalty menu data for the store
    fetch(`${API_ROOT}${Method.FETCH_LOYALTY_MENU}?store_id=${storeID}`)
      .then((response) => {
        // If the response status is 401 (Unauthorized) or 403 (Forbidden), the session has expired or is invalid
        if (response.status === 403 || response.status === 401) {
          setIsLoading(false); // Stop loading state
          dbconn.deleteUserFromIndexedDB(4); // Remove user data from IndexedDB
          sessionStorage.setItem("guestFlag", "Y"); // Set guest flag to true
          navigate("/login"); // Redirect the user to the login page
        }
        return response.json(); // Parse the response as JSON
      })
      .then((data) => {
        // If the response contains valid loyalty points data (status = 1)
        if (data.status === 1) {
          localStorage.setItem("points", data.data); // Store the loyalty points data in localStorage
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the fetch
        console.error("Error fetching loyalty menu:", error);
        setIsLoading(false); // Stop loading in case of error
      });
  };

  /*
  * handleToggleSidebar function:

    - This function dispatches an action to toggle the visibility of the sidebar.
    - It triggers the Redux action `toggleSidebar` to update the state, controlling
    - whether the sidebar is open or closed.
*/
  const handleToggleSidebar = () => {
    dispatch(toggleSidebar(true)); // Dispatch the action to open the sidebar
  };

  useEffect(() => {
    /**
     * This effect calculates the remaining loyalty points available to the user
     * after considering the loyalty points used in the cart. It checks each item in the cart
     * to determine if it qualifies for loyalty points and updates the loyalty balance accordingly.

     * Steps:
        1. Loops through the cart items to calculate the total loyalty points being used.
        2. Retrieves the user's total loyalty points from localStorage.
        3. Deducts the points used in the cart from the total points and updates the state.
   */

    // Initialize a variable to accumulate the total loyalty points used in the cart
    var cartLoyaltyPoints = 0;

    // Loop through each item in the cart to calculate total loyalty points
    for (let i = 0; i < cartItem.length; i++) {
      if (cartItem[i].product_data.loyalty === "Y") {
        // Add loyalty points for items eligible for loyalty
        cartLoyaltyPoints +=
          cartItem[i].product_data.product_loyalty_price *
          cartItem[i].product_data.product_quantity;
      }
    }

    // Get the current loyalty points from localStorage
    const point = localStorage.getItem("points");

    // Update the remaining loyalty points after deducting points used in the cart
    setLoyaltyPoints(point - cartLoyaltyPoints);
  }, [cartItem, loyaltyPoints]); // Dependency array: Re-run when cartItems or loyaltyPoints change

  /*
   * This code filters the `allDataCategoryWise` based on the `searchTerm` and `activeCategory`
   * to return a filtered list of categories and products.

     - If `searchTerm` is provided, it filters products whose names or options contain the search term (case-insensitive).
     - If no `searchTerm` is provided, it checks whether `activeCategory` is set. If so, it filters by the `category_id`.
     - If neither `searchTerm` nor `activeCategory` are provided, it returns all the data without filtering.
 */
  const filteredData = searchTerm
    ? allDataCategoryWise
        .map((category) => ({
          ...category,
          // Filter products within the category based on the search term
          products: category.products.filter(
            (product) =>
              product.product_name
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              product.options.toLowerCase().includes(searchTerm.toLowerCase())
          ),
        }))
        // Filter out categories that have no products after filtering
        .filter((category) => category.products.length > 0)
    : activeCategory
    ? // If no search term, filter by activeCategory
      allDataCategoryWise.filter(
        (category) => category.category_id === activeCategory
      )
    : // If neither search term nor active category is set, return all categories
      allDataCategoryWise;

  /*
   * Toggles the visibility of the cart.
     - If the cart is visible, it will be hidden; otherwise, it will be shown.
   */
  const toggleCart = () => {
    setIsCartVisible(!isCartVisible);
  };

  /*
   * Handles category click event to set the active category.
     - Sets the selected category as active, updating the UI to reflect the chosen category.
   */
  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
  };

  /*
   * Handles click outside the sidebar or cart, triggering actions based on the area clicked.
     - Closes the sidebar if the click is outside its bounds.
     - Hides the cart if the click is outside the cart.
   */
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      dispatch(toggleSidebar(false)); // Close sidebar
    }
    if (cartRef.current && !cartRef.current.contains(event.target)) {
      setIsCartVisible(false); // Hide cart
    }
  };

  /*
    * Handles modal close action.
      - Dispatches an action to toggle the modal visibility.
   */
  const handleClose = () => {
    dispatch(toggleModal()); // Close the modal
  };

  /*
   * Handles horizontal scroll on the category list.
     - Prevents the default scrolling behavior and applies custom scrolling based on mouse wheel movement.
     - Adjust the scroll speed with `deltaY` value.
   */
  const handleScroll = (event) => {
    if (event.deltaY !== 0) {
      event.preventDefault(); // Prevent default scrolling
      categoryListRef.current.scrollLeft += event.deltaY * 1; // Adjust for desired speed
    }
  };

  /*
   * Handles mouse down event for starting the drag action on the category list.
     - Records the initial mouse position and scroll position for drag operation.
   */
  const handleMouseDown = (event) => {
    setIsDragging(true); // Start dragging
    setDragged(false); // Reset dragged flag
    setStartX(event.pageX - categoryListRef.current.offsetLeft); // Get initial mouse position
    setScrollLeft(categoryListRef.current.scrollLeft); // Store the current scroll position
  };

  /*
   * Handles mouse move event for dragging the category list horizontally.
     - Calculates the horizontal scroll movement based on mouse movement.
     - Sets the `dragged` flag if the movement exceeds a threshold (e.g., 5px).
   */
  const handleMouseMove = (event) => {
    if (!isDragging) return; // Exit if not in drag mode
    const x = event.pageX - categoryListRef.current.offsetLeft;
    const walk = (x - startX) * 1; // Calculate horizontal drag distance

    // Set dragged to true if movement exceeds a threshold (e.g., 5px)
    if (Math.abs(walk) > 5) {
      setDragged(true);
    }

    categoryListRef.current.scrollLeft = scrollLeft - walk; // Apply the calculated scroll position
  };

  /*
   * Handles mouse up event to stop the dragging action.
     - Stops the drag when the mouse button is released.
   */
  const handleMouseUp = () => {
    setIsDragging(false); // Stop dragging on mouse release
  };

  /*
   * Handles category item click event while considering dragging.
      - Only triggers the category click if the user hasn't dragged.
   */
  const handleCategoryItemClick = (categoryId) => {
    // Only call handleCategoryClick if no drag occurred
    if (!dragged) {
      handleCategoryClick(categoryId);
    }
  };

  return (
    <div className="d-flex position-relative mainPage">
      {isLoading && (
        <div className="loader">
          <img src={loader} alt="" width={"300px"} height={"210px"} />
        </div>
      )}
      {/* =========== Left Side Screen Start============= */}
      {/* Sidebar Code Start */}
      <div
        className={`sidebar ${isSidebarOpen ? "sidebarShow" : ""}`}
        ref={sidebarRef}
        style={{ height: `${height}px` }}
      >
        <Sidebar />
      </div>
      {/* Sidebar Code End */}
      {/* =========== Left Side Screen End============= */}
      {/* ======================================================================================== */}
      {/*================================> Right Side Screen Start <=================================*/}
      <div
        className="d-flex flex-column right-section"
        style={{ height: `${height}px` }}
      >
        {/* ======================================================================================== */}
        {/*================================> Navbar Code Start <=====================================*/}
        <div className="navigatinBar">
          <div className="d-flex justify-content-between align-items-center p-2 border border-1 border-top-0 border-left-0 border-right-0 text-primary">
            <span className="d-flex align-items-center">
              <span className="hamBurger">
                <p
                  className="d-flex align-items-center mb-0 px-1 border roboto-medium-16"
                  onClick={handleToggleSidebar}
                >
                  <RxHamburgerMenu />
                </p>
              </span>
              <div className="storeName">
                {" "}
                {/* Store name */}
                <h3 className="m-0 roboto-medium-22 px-2">{storeName}</h3>
              </div>
            </span>
            <span className="loginBtn">
              <div className="d-flex align-items-center">
                {/* If component's width is more then 715 then store schedual and store rating show in main header */}
                {width > 715 ? (
                  <>
                    {/* store schedual div*/}
                    <div className="dropdown">
                      {storeTime.map((time, index) => (
                        <span className="timeheader" key={index}>
                          {time.store_active == true
                            ? `Today - ${time.schedule_from} - ${time.schedule_to}`
                            : ""}
                        </span>
                      ))}
                      <div className="dropdown-content">
                        <table>
                          <tr>
                            <th>Day</th>
                            <th>Open</th>
                            <th>Close</th>
                          </tr>
                          {storeTime.map((time, index) => (
                            <tr key={index}>
                              <td>{time.day}</td>
                              <td>{time.schedule_from}</td>
                              <td>{time.schedule_to}</td>
                            </tr>
                          ))}
                        </table>

                        {/* // <p className="timelist">{time ? `${time.day}-${time.schedule_from} to ${time.schedule_to}` : ""}</p> */}
                      </div>
                    </div>
                    {/* store rating div*/}
                    <div className="ratingComponent">
                      <p className="ratepoint">
                        {t("rating")}: {rating.toFixed(2)}
                      </p>
                      <FaStar />
                    </div>
                  </>
                ) : (
                  ""
                )}
                <LanguageSelector /> {/* Language selector component */}
              </div>
            </span>
          </div>
          {/* If component's width is less then 715 then store schedual and store rating show is second navbar */}
          <div className="d-flex justify-content-between align-items-center px-2 text-primary">
            {width < 715 ? (
              <>
                {/* store schedual div*/}
                <div className="dropdown">
                  {storeTime.map((time) => (
                    <span className="timeheader">
                      {time.store_active == true
                        ? `Today - ${time.schedule_from} - ${time.schedule_to}`
                        : ""}
                    </span>
                  ))}
                  <div className="dropdown-content">
                    <table>
                      <tr>
                        <th>Day</th>
                        <th>Open</th>
                        <th>Close</th>
                      </tr>
                      {storeTime.map((time) => (
                        <tr>
                          <td>{time.day}</td>
                          <td>{time.schedule_from}</td>
                          <td>{time.schedule_to}</td>
                        </tr>
                      ))}
                    </table>

                    {/* // <p className="timelist">{time ? `${time.day}-${time.schedule_from} to ${time.schedule_to}` : ""}</p> */}
                  </div>
                </div>
                {/* store rating div*/}
                <div className="ratingComponent">
                  <p className="ratepoint">
                    {t("rating")}: {rating.toFixed(2)}
                  </p>
                  <FaStar />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        {/*================================> Navbar Code END <=====================================*/}
        {/* ======================================================================================== */}
        {/*======================> Product Details and Cart code Start <============================*/}
        <div className="position-relative w-100" style={{ height: "100vh" }}>
          <div className="d-flex">
            {/* >>>>>>>>>>>>>>>>>>>>>>>>> Middel screen Start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
            <div
              className={`${
                isCartVisible ? "productShow productShowActive" : "productShow"
              }`}
            >
              {/* >>>>>>>>>>>>>>>>>>> Search and cart button component start <<<<<<<<<<<<<<<<<<<<<<<*/}
              <div className="searchBar d-flex align-items-center mt-3">
                <div className="d-flex align-items-center w-100">
                  <span className="searchIcon">
                    <FiSearch />
                  </span>
                  <input
                    type="text"
                    className="w-100 border-0 position-relative roboto-medium-14 rounded"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder={t("what_would_you_like_eat?")}
                  />
                </div>
                {isCartVisible ? (
                  ""
                ) : (
                  <span className="btnCart">
                    <button
                      className="cartButton roboto-medium-16 d-flex"
                      onClick={toggleCart}
                    >
                      <FaCartShopping /> Cart {cartItem.length}
                    </button>
                  </span>
                )}
              </div>
              {/* >>>>>>>>>>>>>>>>>>> Search and cart button component END <<<<<<<<<<<<<<<<<<<<<<<*/}
              {/* >>>>>>>>>>>>>>>>>>>>>>>>> Category List Div Start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
              <div className="catagoryList py-3 pb-1">
                <ul
                  className="w-100 gap-2"
                  ref={categoryListRef} // Reference for scrolling and dragging
                  onWheel={handleScroll} // Handles horizontal scroll with mouse wheel
                  onMouseDown={handleMouseDown} // Start dragging
                  onMouseMove={handleMouseMove} // Continue dragging if mouse is down
                  onMouseUp={handleMouseUp} // Stop dragging on mouse release
                  onMouseLeave={handleMouseUp} // Stop dragging if mouse leaves the list
                >
                  {isLoading === false && (
                    <li
                      className={`d-flex align-items-center roboto-medium-18 c-pointer m-0 ${
                        activeCategory === null ? "navActive" : ""
                      }`}
                      onClick={() => handleCategoryItemClick(null)} // Handles click for 'Available Menu' option, this means all product show category wise
                    >
                      {/* Available Menu */}
                      {t("available_menu")}
                    </li>
                  )}
                  {/* Render each category */}
                  {allDataCategoryWise.map((category) => (
                    <li
                      className={`d-flex align-items-center roboto-medium-18 c-pointer m-0 ${
                        activeCategory === category.category_id
                          ? "navActive"
                          : ""
                      }`} // Applies active styling if this category is selected
                      key={category.category_id} // Unique key for each category
                      onClick={() =>
                        handleCategoryItemClick(category.category_id)
                      } // Click event to select this category
                    >
                      {/* Display category name based on selected language */}
                      {localStorage.getItem("store_language") === "en"
                        ? category.category_name
                        : category[
                            "category_name_" +
                              localStorage.getItem("store_language")
                          ] !== ""
                        ? category[
                            "category_name_" +
                              localStorage.getItem("store_language")
                          ]
                        : category.category_name}
                    </li>
                  ))}
                </ul>
              </div>
              {/* >>>>>>>>>>>>>>>>>>>>>>>>>> Category List Div END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
              <div className="px-3 scroll-item ">
                {filteredData.length > 0 ? (
                  filteredData.map((category) => (
                    <div
                      key={category.category_id}
                      id={`category-${category.category_id}`}
                    >
                      {/* <h4 className="roboto-bold-20 mt-2">{category.category_name}</h4> */}
                      <h4 className="roboto-bold-20 mt-2">
                        {localStorage.getItem("store_language") === "en"
                          ? category.category_name
                          : category[
                              "category_name_" +
                                localStorage.getItem("store_language")
                            ] !== ""
                          ? category[
                              "category_name_" +
                                localStorage.getItem("store_language")
                            ]
                          : category.category_name}
                      </h4>
                      <span className="row">
                        {category.products.map((item, index) => (
                          <div className="col-md-4 col-sm-6 col-xs-12 custom-col-4 my-3">
                            <ProductCard
                              key={index}
                              itemData={item}
                              clickOnProduct={() => {
                                if (item.product_enabled === "Y") {
                                  if (
                                    item.product_overage_enabled === "Y" &&
                                    !sessionStorage.getItem("underage")
                                  ) {
                                    Swal.fire({
                                      title: t("age_older"),
                                      allowOutsideClick: false,
                                      showCancelButton: true,
                                      // html: "Must be 21 Years of age to purchase alcohol. You will be required to upload Photo ID during checkout to confirm age for alcohol purchase and delivery.",
                                      html: t("age_discription"),
                                      confirmButtonText: t("older_age"),
                                      cancelButtonText: t("younger_age"),
                                      customClass: {
                                        confirmButton: "link_button2",
                                        cancelButton: "link_button2",
                                      },
                                    }).then((result) => {
                                      if (result.value) {
                                        sessionStorage.setItem("underage", "N");
                                        sessionStorage.setItem("type", "A");
                                        sessionStorage.setItem("loyalty", "N");
                                        const data = {
                                          productID: item.product_id,
                                          productIndex: item.index,
                                          productEdit: false,
                                        };
                                        setSelectedItemId(data);
                                        dispatch(toggleModal());
                                      } else {
                                      }
                                    });
                                  } else {
                                    sessionStorage.setItem("type", "A");
                                    sessionStorage.setItem("loyalty", "N");
                                    const data = {
                                      productID: item.product_id,
                                      productIndex: item.index,
                                      productEdit: false,
                                    };
                                    setSelectedItemId(data);
                                    dispatch(toggleModal());
                                  }
                                } else {
                                  Swal.fire({
                                    text: `${t("item_not_available")} ${
                                      item.product_schedule
                                    }`,
                                    showCancelButton: false,
                                    confirmButtonColor: "#a42f1e",
                                    confirmButtonText: t("ok"),
                                  });
                                }
                              }}
                            />
                          </div>
                        ))}
                      </span>
                    </div>
                  ))
                ) : (
                  <div className="w-100 text-center">
                    <h4 className="m-0 roboto-medium-16 pt-4">
                      No results found...
                    </h4>
                  </div>
                )}
              </div>
            </div>
            {/* >>>>>>>>>>>>>>>>>>>>>>>>> Middel screen END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
            {/* >>>>>>>>>>>>>>>>>>>>>>>>> Cart code Start <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
            <div
              className={`${
                isCartVisible ? "cartPage cartActive m-2" : "cartPage m-2"
              }`}
              ref={cartRef}
            >
              <Cart
                clickOnProduct={(item, index) => {
                  sessionStorage.setItem("type", "A");
                  sessionStorage.setItem("loyalty", "N");
                  const data = {
                    productID: item.product_id,
                    productIndex: index,
                    productEdit: true,
                  };
                  setSelectedItemId(data);
                  dispatch(toggleModal());
                }}
              />
            </div>
            {/* >>>>>>>>>>>>>>>>>>>>>>>>>> Cart code END <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<*/}
          </div>
          {/* ++++++++++++++++++++++  Product Details Dialog Start ++++++++++++++++++++++++++ */}
          <Dialog
            maxWidth={"md"}
            open={isModalOpen}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "10px",
                width: "auto", // Reset width for larger screens
                maxWidth: "md",
                "@media (max-width:600px)": {
                  width: "60%", // Reset width for larger screens
                  maxWidth: "60%", // Default maxWidth for medium screens
                },
                "@media (max-width:425px)": {
                  width: "90%", // Reset width for larger screens
                  maxWidth: "90%", // Default maxWidth for medium screens
                },
              },
            }}
          >
            <ProductDetails productData={selectedItemId} />
          </Dialog>
          {/* ++++++++++++++++++++++  Product Details Dialog END ++++++++++++++++++++++++++ */}
          {/* ++++++++++++++++++++++  Store closed Dialog Start ++++++++++++++++++++++++++ */}
          <Dialog open={storeClosed}>
            <DialogTitle style={{ textAlign: "center" }}>
              SORRY! WE ARE CLOSED
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <DialogContentText>
                We apologize for any inconvenience,
                <br /> but we are currently closed. Our
                <br /> operating hour are <br />
                <h5>{storeOpenHours}</h5>
                <br /> Please visit us again during our business day. <br />
                <br /> Thank you for your understanding.
              </DialogContentText>
            </DialogContent>
          </Dialog>
          {/* ++++++++++++++++++++++  Store closed Dialog END ++++++++++++++++++++++++++ */}
          {/* ++++++++++++++++++++++  Store not Found Dialog Start ++++++++++++++++++++++++++ */}
          <Dialog open={isStoreFound}>
            <DialogTitle style={{ textAlign: "center" }}>
              Store not Found
            </DialogTitle>
          </Dialog>
          {/* ++++++++++++++++++++++  Store not Found Dialog END ++++++++++++++++++++++++++ */}
        </div>
        {/*======================> Product Details and Cart code END <============================*/}
        {/* ======================================================================================== */}
      </div>
      {/*================================> Right Side Screen END <=================================*/}
      {/* ======================================================================================== */}
      <Toaster />
    </div>
  );
}
export default Home;
