import axios from "axios";
import { signInWithPopup } from "firebase/auth";
import React from "react";
import toast from "react-hot-toast";
import { FaSquareXTwitter } from "react-icons/fa6";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ROOT, Method } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import { auth, twitterProvider } from "../firebase";

function TwitterLogin({ setRefresh }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  // Function to trigger a component refresh
  const refreshComponent = () => {
    setRefresh(true); // Update the refresh state in the parent component
  };

  // Handle the Twitter login process
  const handleLogin = async () => {
    try {
      // Sign in with Twitter using Firebase Authentication
      const result = await signInWithPopup(auth, twitterProvider);
      const userData = result.user.providerData[0]; // Extract user data from the result

      // Store SSO (Single Sign-On) data in localStorage
      localStorage.setItem("ssoType", "T"); // Mark the login type as Twitter
      localStorage.setItem("isSSOLogin", true); // Mark the user as logged in via SSO

      localStorage.setItem("sso_user_id", userData.uid);
      localStorage.setItem(
        "sso_user_name",
        userData.displayName === null ? "" : userData.displayName
      );
      localStorage.setItem(
        "sso_user_phone",
        userData.phoneNumber === null ? "" : userData.phoneNumber
      );
      localStorage.setItem(
        "sso_user_email",
        userData.email === null ? "" : userData.email
      );

      // If a user ID is present, verify the SSO login
      if (userData.uid.length > 0) {
        verifySSO(userData.uid); // Call the verifySSO function
      }

      refreshComponent(); // Trigger the parent component's refresh
      toast.dismiss(); // Dismiss any active toast messages
      toast.success("Login Successful."); // Display success toast
    } catch (error) {
      // Handle login errors
      localStorage.removeItem("isSSOLogin"); // Clean up localStorage on error
      localStorage.removeItem("ssotype");
      sessionStorage.setItem("guestFlag", "Y"); // Set guest flag to "Y" (indicating not logged in)
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_email");
      localStorage.removeItem("customer_fb_id");
      console.error("Error logging in with Google:", error); // Log the error

      // Handle specific error cases
      if (error.code === "auth/popup-closed-by-user") {
        console.error("Popup closed by user. Please try again."); // User closed the popup
      } else {
        console.error("Login failed. Please try again."); // General login failure
      }
    }
  };

  // Function to verify SSO login and fetch user details from the backend
  const verifySSO = async (id) => {
    const store = await dbconn.getStoreFromIndexedDB(); // Fetch store data from IndexedDB
    const response = await axios.post(API_ROOT + Method.VERIFY_SSO, {
      sso_id: id,
      sso_type: "T", // Specify that the SSO type is Twitter
      store_id: store.store_id,
    });

    // Check if the verification is successful
    if (response.data.status === 1) {
      // If successful, clear old user data and update with new data
      dbconn.deleteUserFromIndexedDB(4); // Remove old user data from IndexedDB
      dbconn.deleteCartIndexedDB(3); // Remove old cart data from IndexedDB
      const data = response.data.customer_data[0]; // Get customer data from the response
      dbconn.addUserInfoToIndexedDB(data); // Add new user info to IndexedDB

      // Update session storage to reflect that the user is logged in
      sessionStorage.setItem("guestFlag", "N");
      sessionStorage.setItem("cartTotal", "0.00");

      // Clear temporary SSO-related data
      localStorage.removeItem("sso_user_id");
      localStorage.removeItem("sso_user_name");
      localStorage.removeItem("sso_user_phone");
      localStorage.removeItem("sso_user_email");
      sessionStorage.removeItem("isSSOLogin");
      localStorage.setItem("isLogin", true);
      localStorage.removeItem("ssoType");
      navigate("/"); // Redirect to the homepage after successful verification
    } else {
      // If verification fails, log the error and redirect to the signup page
      console.log("Error : ", response.data.message);
      navigate("/signup");
    }
  };

  return (
    // Twitter login button (Icon) that triggers the login function on click
    <FaSquareXTwitter
      style={{ fontSize: "25px", cursor: "pointer" }}
      onClick={handleLogin} // Call handleLogin when the icon is clicked
    />
  );
}

export default TwitterLogin;
