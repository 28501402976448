import fetch from "isomorphic-fetch";
import moment from "moment-timezone";
// import { Column } from 'primereact/column';
// import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowBack } from "react-icons/io";
import { TbListDetails } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { API_ROOT, IMAGE_ROOT, Method, PROJECT } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import loader from '../image/res_home_page_loader.gif';
import "../style/OrderHistory.css";

export default function OrderHistory() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  // const store = useSelector((state) => state.fetchstore.data)
  const [nosessionredirect, setNoSessionRedirect] = useState(false);
  const [open, setOpen] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [load, setLoad] = useState(true);
  const [store, setStore] = useState([])

  // useEffect(() => {
  //   if (store && !initialLogRef.current) {
  //     initialLogRef.current = true;
  //   }
  // }, [store])

  const storeFetch = async () => {
    const store = await dbconn.getStoreFromIndexedDB();
    setStore(store)
    orderHistory()
  }

  useEffect(() => {
    storeFetch()
    var primaryColor = localStorage.getItem("store_primary_color");
    if (primaryColor != null) {
      document.documentElement.style.setProperty(
        "--primaryColor",
        primaryColor
      );
    }

    if (!localStorage.getItem("store_language")) {
      localStorage.setItem("store_language", "en");
    }
    i18n.changeLanguage(localStorage.getItem("store_language")).then((r) => { });
  }, []);

  const orderHistory = () => {
    dbconn.getStoreFromIndexedDB().then((data) => {
      if (
        !data ||
        data.length < 1
      ) {
        setNoSessionRedirect(true);
      } else {
        const url = `${API_ROOT + Method.FETCH_ORDER_HISTORY}?store_id=${data.store_id}&timezone=${moment.tz.guess()}`;
        searchOrder(url);
      }
    });
  }



  // useEffect(() => {
  //   var primaryColor = localStorage.getItem("store_primary_color");
  //   var secondaryColor = localStorage.getItem("store_secondary_color");
  //   var buttonColor = localStorage.getItem("store_button_color");

  //   if (primaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--primaryColor",
  //       primaryColor
  //     );
  //   }
  //   if (buttonColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--buttonColor",
  //       buttonColor
  //     );
  //   }

  //   if (secondaryColor != null) {
  //     document.documentElement.style.setProperty(
  //       "--secondaryColor",
  //       secondaryColor
  //     );
  //   }
  // }, [])

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    dbconn.getUserInfoFromIndexedDB().then((data) => {
      if (
        !data ||
        data.length < 1 ||
        sessionStorage.getItem("guestFlag") == "Y"
      ) {
        setNoSessionRedirect(true)
      } else {
        var customer_id = data.customer_id;
        var customer_token = data.customer_token;

        var url = "";
        if (searchString == "") {
          url =
            API_ROOT +
            Method.FETCH_ORDER_HISTORY + "?customer_id=" +
            customer_id +
            "&store_id=" +
            store.store_id +
            "&customer_token=" +
            customer_token +
            "&timezone=" +
            moment.tz.guess();
        } else {
          url =
            API_ROOT +
            Method.FETCH_ORDER_HISTORY + "?customer_id=" +
            customer_id +
            "&store_id=" +
            store.store_id +
            "&search=" +
            searchString +
            "&customer_token=" +
            customer_token +
            "&timezone=" +
            moment.tz.guess();
        }

        searchOrder(url);
      }
    });
  };
  const searchOrder = (url) => {
    setOpen(true);
    fetch(url)
      .then((response) => response.json())
      .then((orderData) => {
        setOpen(false);
        if (orderData.status === 1) {
          setOrderList(orderData.order_data);
        } else {
          Swal.fire({
            title: orderData.message,
            showCancelButton: false,
            confirmButtonText: t("ok"),
            allowOutsideClick: false,
            customClass: {
              confirmButton: 'my-confirm-button'
            }
          });
        }
      });
  };

  useEffect(() => {
    document.title = `Order History - ${localStorage.getItem(
      "store_name"
    )} - ${PROJECT}`;
  }, []);

  const loadMenu = () => {
    setRedirect(true);
  };

  const navigateHandle = (id) => {
    navigate(`/order/summary/${id}`)
  }

  // const paymentStatusBodyTemplate = (rowData) => {
  //   return rowData.order_payment_complete === "Y" ? "Completed" : "Not Completed";
  // };

  // const detailsButtonTemplate = (rowData) => {
  //   return (
  //     <TbListDetails className="orderDetailsBtn" onClick={() => navigateHandle(rowData.order_id)} />
  //   );
  // };

  if (nosessionredirect) {
    return navigate("/");
  }
  if (redirect) {
    return navigate("/");
  }

  return (
    <div className="confirmPageMainDiv">
      <div className="loginLogo">
        <Link to="/">
          {load && (
            <img
              style={{ width: "70px" }}
              className="center-block"
              src={
                IMAGE_ROOT +
                "/images/product/" +
                localStorage.getItem("store_image")
              }
              alt="Takecharge Diner Logo"
            />
          )}
        </Link>
      </div>
      {/* <header className="header-div bg-white position-fixed w-100 shadow">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <Link to="/" className="float-left">
            <BsChevronLeft />
          </Link>
          <h2 className="text-center mb-0 roboto-bold-24">{t("order_history")}</h2>
        </div>
      </header> */}
      <div className="container-fluid h-100 position-relative">
        <div className="row">
          <div className="col-lg-9 col-md-11 col-xs-12 mx-auto vh1-100 vscroll bg-white" style={{ marginTop: "80px", borderRadius: "10px" }}>
            <span className="backToHomeBtn" onClick={() => navigate('/')}><IoIosArrowBack /></span>
            <div className="LoginRegisterBlock h-100" style={{ marginTop: "0" }}>
              <div className="LoginRegisterForm float-left" style={{ width: "100%" }}>
                <h3 className=" roboto-medium-22">{t("order_history")}</h3>
                <div className="form-group mb-4 text-center order-history">
                  <input
                    type="text"
                    className="form-control mb-3 p-2 roboto-medium-16"
                    name="searchStringTxt"
                    id="searchStringTxt"
                    placeholder={t("search_order")}
                    onChange={(e) => (setSearchString(e.target.value))}
                    onKeyDown={handleKeyDown}
                  />
                  <table>
                    <tr>
                      <th>#</th>
                      <th>Order Name</th>
                      <th>Date</th>
                      <th>Price</th>
                      <th>Payment</th>
                      <th>Details</th>
                    </tr>
                    {orderList.map((item, index) => (
                      <tr key={item.order_id}>
                        <td>{item.order_check_number}</td>
                        <td>{item.order_summary}</td>
                        <td>{item.order_created_at}</td>
                        <td>${parseFloat(item.order_total).toFixed(2)}</td>
                        <td>{item.order_payment_complete == "Y" ? "Completed" : "Not Completed"}</td>
                        <td className="orderDetailsBtn"><TbListDetails onClick={() => navigateHandle(item.order_id)} /></td>
                      </tr>
                    ))}
                  </table>
                  {/* <DataTable
                    value={orderList}
                    paginator
                    rows={5}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                  >
                    <Column field="order_check_number" header="#" style={{ width: '10%' }}></Column>
                    <Column field="order_summary" header="Order Name" style={{ width: '25%' }}></Column>
                    <Column field="order_created_at" header="Created At" style={{ width: '20%' }}></Column>
                    <Column field="order_total" header="Price" style={{ width: '15%' }} body={(rowData) => `$${rowData.order_total.toFixed(2)}`}></Column>
                    <Column header="Payment" body={paymentStatusBodyTemplate} style={{ width: '15%' }}></Column>
                    <Column header="Details" body={detailsButtonTemplate} style={{ width: '15%' }}></Column>
                  </DataTable> */}
                  {/* {orderList.map((item, index) => (
                    <p key={item.order_id} className="orderHistoryList roboto-regular-16">
                      <Link
                        to={"/order/summary/" + item.order_id}
                        className="roboto-medium-16"
                        style={{ color: "var(--primaryColor)" }}
                      >
                        #{item.order_check_number}
                      </Link>{" "}
                      - {item.order_created_at} - ${item.order_total.toFixed(2)}{" "}
                      - {item.order_summary}
                    </p>
                  ))} */}
                </div>
                <div className="form-group mb-2 m-auto w-m-60">
                  <input
                    type="button"
                    onClick={loadMenu}
                    className="btn btnLogin btn-yellow w-100 btn-lg mb-3"
                    value={t("start_new_order")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal
        closeOnEsc={false}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        open={open}
        onClose={() => setOpen(false)}
        center
      >
        <h2>
          &nbsp;&nbsp;&nbsp;&nbsp;{t("loading")}...&nbsp;&nbsp;&nbsp;&nbsp;
        </h2>
        <p style={{ textAlign: "center" }}>
          <img width={"40"} height={"40"} src={Loading} />
        </p>
      </Modal> */}
      {open && (
        <div className="loader">
          <img src={loader} alt="" width={"300px"} height={"210px"} />
        </div>
      )}
    </div>
  );
}
