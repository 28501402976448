import { Modal } from "@mui/material";
import { Button, Col, Input, Row } from "antd";
import axios from "axios";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { API_ROOT, Method, SUB_DOMAIN } from "../api/apiHandler";
import * as dbconn from "../api/dbconn";
import GoogleAddress from "../components/GoogleAddress";
import LoginComponent from "../components/LoginComponent";
import loader from "../image/res_home_page_loader.gif";
import "../style/Guest.css";

export default function Guest(props) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [location, setLocation] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [store, setStore] = useState([]);

  /*
   * START -- storeFetchFromIndexedDB Function
     Action: Fetch store data from IndexedDB if available
     Description: This asynchronous function retrieves the store data from the browser's IndexedDB via the `dbconn.getStoreFromIndexedDB` method.
     After fetching, it updates the state with the fetched store data.
   */
  const storeFetchFromIndexedDB = async () => {
    const store = await dbconn.getStoreFromIndexedDB();
    setStore(store);
  }; // END -- storeFetchFromIndexedDB

  /*
   * START -- fetchStoreData Function
     Action: Fetch store data from server and update IndexedDB if data is found
     Description: This function constructs the API endpoint URL and makes a GET request to fetch store data.
     - If the response status is successful (status === 1), it stores the received data in IndexedDB.
     - If an error occurs, it logs the error to the console.
   */
  const fetchStoreData = async () => {
    const fetchStoreUrl = `${API_ROOT}${Method.FETCH_STORE_FROM_ID}?sub_domain=${SUB_DOMAIN}`;

    const response = await fetch(fetchStoreUrl);
    const responseData = await response.json();

    if (responseData.status === 1) {
      const storeData = responseData.store;
      if (storeData) {
        dbconn.addStoreToIndexedDB(storeData);
      }
    } else {
      console.log(responseData.error);
    }
  }; // END -- fetchStoreData Function

  /*
   * START -- useState for initialValue
     Action: Initializes state variable `initialValue` with empty/default values for user information form fields.
     Description: This state holds the form data, including fields for personal information, contact details, and checkbox states.

     Fields:
     - `firstname`, `lastname`, `email`, `phone`, `address`, `city`, `state`, `zipcode`: Strings initialized as empty for user input.
     - `password`: String for user's password input, initially empty.
     - `termsChkBox`, `marketChkBox`: Booleans to track whether terms and marketing options are checked, both set to false by default.
   */
  const [initialValue, setInitialValue] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipcode: "",
    password: "",
    termsChkBox: false,
    marketChkBox: false,
  }); // END -- useState for initialValue

  /*
   * START -- useEffect Hook for SSO Login Initialization
     Action: Updates `initialValue` state based on SSO login data in session and local storage.
     Description: This hook runs on component mount and whenever `refresh` dependency changes. It checks if the user has logged in using Single Sign-On (SSO).

     Step-by-Step Actions:
     1. Retrieves `isSSOLogin` from session storage. If it exists, user data will be set using SSO.
     2. Gets SSO user details (`sso_user_name`, `sso_user_phone`, `sso_user_email`) from local storage:
        - Splits `sso_user_name` into `firstName` and `lastName`.
        - Uses `sso_user_phone` for phone and `sso_user_email` for email.
     3. Updates `initialValue` with these values. Empty/default values are used for other fields.
        - Additional fields like `confirmpassword` and `birthdate` are initialized as empty strings.
        - `termsChkBox` and `marketChkBox` remain unchecked.
   */
  useEffect(() => {
    const isSSOLogin = sessionStorage.getItem("isSSOLogin");

    if (isSSOLogin) {
      const name = localStorage.getItem("sso_user_name") || "";
      let [firstName, lastName] = name.split(" ");
      const phone = localStorage.getItem("sso_user_phone") || "";
      const email = localStorage.getItem("sso_user_email") || "";

      setInitialValue({
        firstname: firstName,
        lastname: lastName,
        email: email,
        phone: phone,
        address: "",
        city: "",
        state: "",
        zipcode: "",
        password: "",
        confirmpassword: "",
        birthdate: "",
        termsChkBox: false,
        marketChkBox: false,
      });
    }
  }, [refresh]); // End useEffect Hook

  /*
   * START -- useEffect Hook
     Action: Initializes store data, language, and color preferences upon component load.
     Description: This useEffect hook runs once on component mount to set up the initial environment and user preferences.

     Step-by-Step Actions:
     1. Calls storeFetchFromIndexedDB() to fetch store data from IndexedDB.
     2. Calls fetchStoreData() to fetch store data from the server.
     3. Checks and sets language preference in local storage (default is "en").
     4. Changes app language with i18n based on local storage setting.
     5. Applies primary and secondary color themes from local storage to the document root.
     6. Checks if the user is a guest (via session storage). If yes:
        - Fetches user info from IndexedDB.
        - If user data is found, populates form fields and sets the initial location based on the user's address.
   */
  useEffect(() => {
    storeFetchFromIndexedDB();
    fetchStoreData();

    if (!localStorage.getItem("store_language")) {
      localStorage.setItem("store_language", "en");
    }
    i18n.changeLanguage(localStorage.getItem("store_language")).then((r) => {});

    const primaryColor = localStorage.getItem("store_primary_color");
    if (primaryColor) {
      document.documentElement.style.setProperty(
        "--primaryColor",
        primaryColor
      );
    }

    const secondaryColor = localStorage.getItem("store_secondary_color");
    if (secondaryColor) {
      document.documentElement.style.setProperty(
        "--secondaryColor",
        secondaryColor
      );
    }

    const guestFlag = sessionStorage.getItem("guestFlag");

    // If user is a guest (guestFlag is "Y"), fetch user info from IndexedDB
    if (guestFlag == "Y") {
      dbconn.getUserInfoFromIndexedDB().then((data) => {
        // If user data exists, set form fields with user's information
        if (data) {
          setInitialValue({
            firstname: data.customer_firstname,
            lastname: data.customer_lastname,
            email: data.customer_email,
            phone: data.customer_phone,
            address: data.customer_address,
            city: data.customer_city,
            state: data.customer_state,
            zipcode: data.customer_zip,
            password: "", // Password is kept empty for security
            termsChkBox: false, // Initialize terms checkbox as unchecked
            marketChkBox: false, // Initialize marketing checkbox as unchecked
          });

          //Set location with user's address
          setLocation(data.customer_address);
        }
      });
    }
  }, []); // End useEffect Hook

  /*
   * START -- validationSchema using Yup
     Action: Define form validation rules for user input fields using Yup.
     Description: This validation schema ensures required fields and specific formatting for each field, such as proper email format and phone number length.

     Validation Rules for Each Field:
     - `firstname`: Must be a string and is required. Displays "Required" if empty.
     - `lastname`: Must be a string and is required. Displays "Required" if empty.
     - `email`: Must be in valid email format and is required.
        - Displays "Invalid email format" if not a valid email.
        - Displays "Required" if empty.
     - `phone`: Must be a string of exactly 10 digits and is required.
        - Only digits are allowed; displays "Phone number must be only digits" if it contains non-digit characters.
        - Displays "Phone number must be at least 10 digits" if fewer than 10 digits.
        - Displays "Phone number must be less than or equal to 10 digits" if more than 10 digits.
        - Displays "Required" if empty.
     - `address`: Must be a string and is required. Displays "Required" if empty.
     - `termsChkBox`: Must be checked (boolean true) to accept terms. Displays "You must accept the terms and conditions" if not checked.
   */

  const validationSchema = Yup.object({
    firstname: Yup.string().required("Required"), // First name is required
    lastname: Yup.string().required("Required"), // Last name is required
    email: Yup.string().email("Invalid email format").required("Required"), // Email must be in a valid format and is required
    // Phone number must be digits only, exactly 10 digits, and is required
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be only digits") // Ensures only digits
      .min(10, "Phone number must be at least 10 digits") // Minimum 10 digits
      .max(10, "Phone number must be less than or equal to 10 digits") // Maximum 10 digits
      .required("Required"),
    address: Yup.string().required("Required"), // Address is required
    // Terms checkbox must be checked and is required
    termsChkBox: Yup.bool()
      .oneOf([true], "You must accept the terms and conditions")
      .required("Required"),
  }); // END -- validationSchema using Yup

  /*
   * START -- onOpenModal Function
     Action: Opens the modal by setting the `open` state to true.
     Description: This function is triggered to display the modal by updating the `open` state to true, which is typically bound to the modal’s visibility in the UI.
   */
  const onOpenModal = () => {
    setOpen(true);
  }; // END -- onOpenModal Function

  /*
   * START -- onCloseModal Function
     Action: Closes the modal by setting the `open` state to false.
     Description: This function is called to hide the modal by updating the `open` state to false.
   */
  const onCloseModal = () => {
    setOpen(false);
  }; // END -- onCloseModal Function

  /*
   * START -- handleShow Function
     Action: Displays a specific element or modal by setting the `show` state to true.
     Description: This function updates the `show` state to true, which is used to toggle the visibility of a particular UI element (such as an alert or tooltip).
   */
  const handleShow = () => {
    setShow(true);
  };

  /*
   * START -- handleClose Function
     Action: Hides a specific element or modal by setting the `show` state to false.
     Description: This function updates the `show` state to false, used to toggle visibility off for a particular UI element.
   */
  const handleClose = () => {
    setShow(false);
  };

  /*
   * START -- onSubmit Function
     Action: Handles form submission, processes user data, checks delivery range, and stores user information.
     Description: This function performs multiple actions upon submission:
     1. Extracts user data from `data` object.
     2. Checks if delivery service is selected (order type "D").
     3. Validates delivery location against the store's delivery range.
     4. Stores user information in IndexedDB if valid, and directs to checkout if applicable.

     Parameters:
     - `data`: Contains user form input, such as name, address, email, phone, etc.

     Step-by-Step Actions:

     1. Extract User Data:
        - `firstname`, `lastname`, `email`, `phone`, `address`, `city`, `cust_pwd` (password), `state`, `zip` (zipcode), `customer_marketing_optin` (opt-in for marketing).
        - `customer_marketing_optin`: "Y" if checked, "N" if unchecked.

     2. Check Delivery Order Type (orderType = "D"):
        - If `orderType` is "D" (Delivery):
          - If `store` exists, open modal and validate delivery distance:
              - Makes API call to `FETCH_DISTANCE` endpoint to check if user's address is within the store's delivery range.
              - Closes modal after response.
              - On success, deletes existing user data in IndexedDB and adds new user info.
              - If password is empty, sets `guestFlag` as "Y" and navigates to checkout.
          - On failure:
              - Shows error alert with the appropriate message, using `Swal.fire`.
          - Catches any API errors and logs to console.
        - If no store is available, deletes existing user data and saves new user info in IndexedDB.

     3. Non-Delivery Order Type:
        - Directly saves user information to IndexedDB.
        - If password is empty, sets `guestFlag` as "Y" and navigates to checkout.
   */
  const onSubmit = (data) => {
    var firstname = data.firstname;
    var lastname = data.lastname;
    var email = data.email;
    var phone = data.phone;
    var address = data.address;
    var city = data.city;
    var cust_pwd = data.password;
    var state = data.state;
    var zip = data.zipcode;
    var customer_marketing_optin = data.marketChkBox ? "Y" : "N";

    if (sessionStorage.getItem("orderType") === "D") {
      if (store) {
        onOpenModal();
        var url = API_ROOT + Method.FETCH_DISTANCE;
        var options = {
          address: address,
          latitude: store.store_latitude,
          longitude: store.store_longitude,
          distance: store.store_delivery_range,
        };
        axios
          .post(url, options)
          .then((response) => {
            onCloseModal();
            if (response.data.status === 1) {
              dbconn.deleteUserFromIndexedDB(4);
              const userInfo = [
                {
                  customer_firstname: firstname,
                  customer_lastname: lastname,
                  customer_email: email,
                  customer_phone: phone,
                  customer_address: address,
                  customer_state: state,
                  customer_city: city,
                  customer_zip: zip,
                  customer_password: cust_pwd,
                  customer_marketing_optin: customer_marketing_optin,
                },
              ];

              dbconn.addUserInfoToIndexedDB({
                ...userInfo[0],
                guestFlag: sessionStorage.getItem("guestFlag"),
              });

              if (data.password == "") {
                sessionStorage.setItem("guestFlag", "Y");
                goToCheckoutPage();
              }
            } else {
              if (
                response.data.message ==
                "This store is not delivering to your area. Please place a pickup order."
              ) {
                Swal.fire({
                  text: t("store_not_delivering_your_area"),
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
              } else if (
                response.data.message ==
                "We were not able to validate your address. Please try again or place a pickup order."
              ) {
                Swal.fire({
                  text: t("not_able_to_validate_address"),
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
              } else {
                Swal.fire({
                  text: response.data.message,
                  showCancelButton: false,
                  confirmButtonText: t("ok"),
                  allowOutsideClick: false,
                  customClass: {
                    confirmButton: "my-confirm-button",
                  },
                });
              }
            }
          })
          .catch((error) => {
            onCloseModal();
            console.log(error);
          });
      } else {
        dbconn.deleteUserFromIndexedDB(4);
        const userInfo = [
          {
            customer_firstname: firstname,
            customer_lastname: lastname,
            customer_email: email,
            customer_phone: phone,
            customer_address: address,
            customer_state: state,
            customer_city: city,
            customer_zip: zip,
            customer_password: cust_pwd,
            customer_marketing_optin: customer_marketing_optin,
          },
        ];

        dbconn.addUserInfoToIndexedDB(userInfo[0]);
        if (data.password == "") {
          sessionStorage.setItem("guestFlag", "Y");
          goToCheckoutPage();
        }
      }
    } else {
      dbconn.deleteUserFromIndexedDB(4);
      const userInfo = [
        {
          customer_firstname: firstname,
          customer_lastname: lastname,
          customer_email: email,
          customer_phone: phone,
          customer_address: address,
          customer_state: state,
          customer_city: city,
          customer_zip: zip,
          customer_password: cust_pwd,
          customer_marketing_optin: customer_marketing_optin,
        },
      ];

      dbconn.addUserInfoToIndexedDB(userInfo[0]);
      if (data.password == "") {
        sessionStorage.setItem("guestFlag", "Y");
        goToCheckoutPage();
      }
    }
  }; // END -- onSubmit Function

  /*
   * START -- goToCheckoutPage Function
     Action: Sends data to parent component and navigates to the checkout page.
     Description: This function performs two primary actions:
     1. Sends a piece of data (in this case, `2`) to the parent component using `props.onSendData`.
     2. Sets a session storage item `userType` to "checkout", indicating that the user is being redirected to the checkout page.

     Parameters: None (uses `props.onSendData` to send data to parent component).

     Actions:
     - Sends the value `2` to the parent component via the `onSendData` callback function passed down as a prop.
     - Updates session storage to set `userType` as "checkout", which may be used to track the user's state or for redirect purposes.
   */
  const goToCheckoutPage = () => {
    const data = 2;
    props.onSendData(data);
    sessionStorage.setItem("userType", "checkout");
  }; // END -- goToCheckoutPage Function

  /*
   * START -- handleModalClose Function
     Action: Closes the modal and navigates to the checkout page.
     Description: This function performs two actions:
     1. Closes the modal by updating the `show` state to the value passed as the `data` parameter.
     2. Redirects the user to the checkout page by calling the `goToCheckoutPage` function.

     Parameters:
     - `data`: A boolean value that controls whether the modal is shown (`true`) or hidden (`false`).

     Actions:
     - Sets the `show` state to `data`, which will trigger the modal to open or close depending on the value of `data`.
     - Calls `goToCheckoutPage`, which handles navigating to the checkout page.
   */
  const handleModalClose = (data) => {
    setShow(data);
    goToCheckoutPage();
  };

  /*
   * START -- termAndCondition Function
     Action: Displays the Terms of Service (TOS) using a SweetAlert modal.
     Description: This function checks if Terms of Service (TOS) are available in localStorage.
     If the TOS is not available or marked as "no_tos_available", it shows an alert with a message.
     If the TOS is available, it displays the content in a styled SweetAlert modal.

     Parameters: None

     Actions:
     - Retrieves TOS content from `localStorage` using `localStorage.getItem("tos")`.
     - If the TOS is not available or set to "no_tos_available", it displays an alert with a message using `Swal.fire`.
     - If TOS is available, it shows the content in a modal with custom HTML and styling.
     - The modal can be closed by the user, and it allows for outside clicks.

     Step-by-step:
     1. Checks if `tos` is either not set or contains the "no_tos_available" value.
        - If true: Displays a simple alert with the text "No TOS Available".
     2. If TOS is available: Displays it in a custom modal with a heading and the TOS content in HTML format.
   */
  const termAndCondition = () => {
    const tos = localStorage.getItem("tos");
    if (tos === i18n.t("no_tos_available") || tos == null) {
      Swal.fire({
        title: t("tos"),
        text: t("no_tos_available"),
        confirmButtonText: t("ok"),
      });
    } else {
      Swal.fire({
        title: `<h4 id="guidelineHeading">${i18n.t("tos")}</h4>`,
        allowOutsideClick: true,
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        width: "90%",
        html: `<div id='guideLine'>${tos}</div>`,
      });
    }
  };

  return (
    <div>
      {/* ============ User information Form Start ============== */}
      <Formik
        initialValues={initialValue} // Initial values for the form fields
        validationSchema={validationSchema} // Validation rules for the form
        onSubmit={onSubmit} // Function to handle form submission
        enableReinitialize={true} // Ensures form gets re-initialized if initialValues change
      >
        {({ submitForm, setFieldValue }) => (
          <div className="form">
            <span className="d-flex gap-2 w-100">
              <div className="form-group mb-2 w-50">
                {/* First Name and Last Name Fields */}
                <label htmlFor="firstname" className="inputLabel">
                  {t("first_name")}*
                </label>
                <Field
                  as={Input}
                  className="roboto-regular-16 inputBox"
                  name="firstname"
                  id="firstname"
                />
                <ErrorMessage
                  name="firstname"
                  className="roboto-regular-14"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>
              <div className="form-group mb-2 w-50">
                <label htmlFor="lastname" className="inputLabel">
                  {t("last_name")}*
                </label>
                <Field
                  as={Input}
                  className="roboto-regular-16 inputBox"
                  name="lastname"
                  id="lastname"
                />
                <ErrorMessage
                  name="lastname"
                  className="roboto-regular-14"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>
            </span>

            {/* Email Field */}
            <div className="form-group mb-2">
              <label htmlFor="email" className="inputLabel">
                {t("email_address")}*
              </label>
              <Field
                as={Input}
                className="roboto-regular-16 inputBox"
                name="email"
                id="email"
              />
              <ErrorMessage
                name="email"
                className="roboto-regular-14"
                component="div"
                style={{ color: "red" }}
              />
              <small>{t("we_never_share_your_email")}</small>
            </div>

            {/* Phone Field */}
            <div>
              <label htmlFor="phone" className="roboto-medium-16 inputLabel">
                {t("phone_number")}*
              </label>
              <Field
                as={Input}
                id="phone"
                className="roboto-regular-16 inputBox"
                name="phone"
              />
              <ErrorMessage
                name="phone"
                className="roboto-regular-14"
                component="div"
                style={{ color: "red" }}
              />
            </div>

            {/* Google Address Autocomplete */}
            <GoogleAddress
              onPlaceSelect={(place) => {
                setFieldValue("address", place.formatted_address);

                const city =
                  place.address_components.find((c) =>
                    c.types.includes("locality")
                  )?.long_name || "";
                const state =
                  place.address_components.find((c) =>
                    c.types.includes("administrative_area_level_1")
                  )?.long_name || "";
                const zipcode =
                  place.address_components.find((c) =>
                    c.types.includes("postal_code")
                  )?.long_name || "";

                setFieldValue("city", city);
                setFieldValue("state", state);
                setFieldValue("zipcode", zipcode);
                setLocation(place.formatted_address);
              }}
              location={location}
              setLocation={setLocation}
            />
            <ErrorMessage
              name="address"
              className="roboto-regular-14"
              component="div"
              style={{ color: "red" }}
            />

            {/* Address Fields (hidden for now) */}
            <div className="form-group mb-2" style={{ display: "none" }}>
              <div className="col-div-3">
                <label className="inputLabel">{t("city")}*</label>
                <Field
                  as={Input}
                  type="text"
                  className="roboto-regular-16 inputBox"
                  name="city"
                  id="city"
                />
                <ErrorMessage
                  name="city"
                  className="roboto-regular-14"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>
              <div className="col-div-3">
                <label htmlFor="state" className="inputLabel">
                  {t("state")}*
                </label>
                <Field
                  as={Input}
                  type="text"
                  className="roboto-regular-16 inputBox"
                  name="state"
                  id="state"
                />
                <ErrorMessage
                  name="state"
                  className="roboto-regular-14"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>
              <div className="col-div-3 float-right last">
                <label htmlFor="zipcode" className="inputLabel">
                  {t("zip_code")}*
                </label>
                <input
                  type="text"
                  className="roboto-regular-16 inputBox"
                  name="zipcode"
                  id="zipcode"
                />
                <ErrorMessage
                  name="zipcode"
                  className="roboto-regular-14"
                  component="div"
                  style={{ color: "red" }}
                />
              </div>
            </div>

            {/* Password Field (optional) */}
            <div className="form-group mb-2">
              <label htmlFor="password" className="inputLabel">
                {`${t("account_signup")}(optional)`}
              </label>
              <input
                type="text"
                className="roboto-regular-16 inputBox"
                name="password"
                id="password"
              />
              <small>{t("account_inst")}</small>
            </div>

            {/* Terms and Conditions Checkbox */}
            <Row gutter={16} className="w-100 pt-2">
              <Col xs={24}>
                <div className="form-group mb-2 clearfix">
                  <div className="custom-control custom-checkbox float-left">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="termsChkBox"
                      name="termsChkBox"
                    />
                    <label
                      className="custom-control-label pt-6px"
                      htmlFor="termsChkBox"
                    >
                      {t("age_disclaimer")}{" "}
                      <Link
                        onClick={() => {
                          termAndCondition();
                        }}
                        className="underline"
                        style={{ color: "var(--primaryColor)" }}
                      >
                        {t("terms_link")}
                      </Link>
                      .
                    </label>
                    <ErrorMessage
                      name="termsChkBox"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            {/* Marketing Opt-in Checkbox */}
            <Row gutter={16} className="w-100 pt-2">
              <Col xs={24}>
                <div className="form-group mb-2 clearfix">
                  <div className="custom-control custom-checkbox float-left">
                    <Field
                      type="checkbox"
                      className="custom-control-input"
                      id="marketChkBox"
                      name="marketChkBox"
                    />
                    <label
                      className="custom-control-label pt-6px"
                      htmlFor="marketChkBox"
                    >
                      {t("signup_check")}
                    </label>
                    <ErrorMessage
                      name="marketChkBox"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            {/* Submit Button */}
            <Row
              style={{
                width: "100%",
                marginTop: "20px",
                justifyContent: "center",
              }}
            >
              <Col xs={24} md={12}>
                <Button
                  type="primary"
                  className="btn-btn btnLogin btn-yellow btn-lg"
                  onClick={submitForm}
                  block
                >
                  {t("continue_to_checkout")}
                </Button>
              </Col>
            </Row>

            {/* Sign-in Link */}
            <div className="form-group text-center">
              <p className=" roboto-medium-14">
                {t("already_account")}{" "}
                <a
                  onClick={handleShow}
                  className="open-modal-signin roboto-medium-14"
                  style={{ cursor: "pointer", color: "var(--primaryColor)" }}
                >
                  {t("signin")}
                </a>
              </p>
            </div>
          </div>
        )}
      </Formik>
      {/* ============ User information Form END ============== */}

      {open && (
        // Conditionally render the loader when `open` state is true
        <div className="loader">
          <img src={loader} alt="" width={"300px"} height={"210px"} />
        </div>
      )}

      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {/* LoginComponent is rendered inside the modal */}
        <LoginComponent
          pageType="G" // Passing a `pageType` prop to LoginComponent
          closeModal={(data) => handleModalClose(data)} // closeModal function is passed to handle closing logic
        />
      </Modal>
    </div>
  );
}
