import i18n from "i18next"; // Import i18n
import React from "react";
import AuthProvider from "react-auth-kit/AuthProvider";
import createStore from "react-auth-kit/createStore";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next"; // Import I18nextProvider
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import Store from "./store/Store";
import enTranslation from "./translations/en.json"; // Import English translation file
import esTranslation from "./translations/es.json"; // Import French translation file

// Initialize i18n
i18n.init({
  lng: "en",
  resources: {
    en: {
      translation: enTranslation,
    },
    es: {
      translation: esTranslation,
    },
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

const store = createStore({
  authName: '_auth',
  authType: 'cookie',
  cookieDomain: window.location.hostname,
  // cookieSecure: window.location.protocol === 'https:',
  cookieSecure: false,
});


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <AuthProvider store={store}>
    <I18nextProvider i18n={i18n}>
      <Provider store={Store}>
        <App />
      </Provider>
    </I18nextProvider >
  </AuthProvider>
  // </React.StrictMode>
);
